import React from "react";
import { Component } from "react";
import user from './../../settings/user';
import _ from 'underscore';
import SelectTypeAccount from "../forms/SelectTypeAccount";
import Popover from './../others/Popover';

class AccountForm extends Component {

    constructor(props) {
        super(props);
        const config = user.config();
        if (user.rol.es_administrador !== true) {
            this.filter_from = config && config.filter_from ? new Date(config.filter_from) : null;
        }

        this.state = this.state_init();
    }

    fecha_elaboracion = null;
    filter_from = null;
    fecha_registro = '';
    verificar = true;

    state_init = () => {
        const d = new Date();
        this.fecha_registro =  d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();
        this.dataTypeAccounts = [];
        return {
            codigo: '',
            nombre:'',
            descripcion: '',
            // tipo: '',
            id_tipo_cuenta: ''
        }
    }

    setRegisterDate = (fecha) => {
        let d = new Date();
        if (_.isString(fecha)) {
            d = new Date(fecha);
            this.fecha_registro = d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();
        } else {
            this.fecha_registro = d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();
        }
        
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // para resetear el formulario
        if (nextProps.resetform === true) {
            this.setState(this.state_init());
        }

        //verificamos si le pasamos datos para editar, de lo contarrio es registro
        if (!_.isEmpty(nextProps.data) && nextProps.data.objectId) {

            // this.setRegisterDate(nextProps.data.fecha_registro);

            this.setState(nextProps.data);//Seteamo los nuevos valores al setState

        }
    }

    //asiganmos los valores al data componente padre, se ejecuta cuando se reciben nuevos accesorios o estado en el componente
    UNSAFE_componentWillUpdate = (nextProps, nextState) => {

        nextProps.setData(nextState);
    }

    handleChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        //parseamos si un input es de tipo numero
        if (!_.isEmpty(value) && target.type === "number") {
            value = parseInt(value);
        }

        this.setState({
            [name]: value
        });

    }

    

    render() {
        
        return(
            <>
                <fieldset className="form-group card">
                    <div className="card-body">
                        <legend>Datos generales de la cuenta</legend>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    {
                                        !this.props.readOnly ? <>
                                            <label>Fecha de registro</label>
                                            <span className="form-control text-muted">{this.fecha_registro}</span>
                                        </> : <>
                                            <small className="text-muted">Fecha de registro</small>
                                            <p>
                                                {this.fecha_registro}
                                            </p>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className="col-12"></div>

                            <div className="col-md-6">
                                <div className="form-group">
                                {
                                    !this.props.readOnly ? <>
                                    <label htmlFor="nombre">Código</label> <span className="no-show-pdf"><Popover title="Código" content="Es el código que debe estar registrado en la plataforma de Contalink." /></span>
                                    <input type="text" className="form-control text-uppercase" id="codigo" name="codigo" value={this.state.codigo} onChange={this.handleChange}/>                                    
                                    </> : <>
                                        <small className="text-muted">Código</small>
                                        <p className="text-uppercase">{this.state.codigo}</p>
                                    </>
                                }
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    {
                                        !this.props.readOnly ?
                                        <>
                                            <label htmlFor="nombre">Nombre de la cuenta</label>
                                            <input type="text" className="form-control text-uppercase" id="nombre" name="nombre" value={this.state.nombre} onChange={this.handleChange}/>
                                        </> : <>
                                            <small className="text-muted">Nombre de la cuenta</small>
                                            <p className="text-uppercase">{this.state.nombre}</p>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    {
                                        !this.props.readOnly ?
                                        <>
                                            <label htmlFor="nombre">Descripción</label>
                                            <input type="text" className="form-control text-uppercase" id="descripcion" name="descripcion" value={this.state.descripcion} onChange={this.handleChange}/>
                                        </> : <>
                                            <small className="text-muted">Descripción</small>
                                            <p className="text-uppercase">{this.state.descripcion}</p>
                                        </>
                                    }
                                 </div>
                            </div>

                            <div className="form-group col-md-6">
                                <div className="form-group">
                                    {!this.props.readOnly ? <label htmlFor="promotor">
                                        Tipo de cuenta
                                    </label> : 
                                    <small className="text-muted">
                                        Tipo de cuenta
                                    </small>}
                                    <SelectTypeAccount readOnly={this.props.readOnly} name="id_tipo_cuenta" id="id_tipo_cuenta" value={this.state.id_tipo_cuenta} onChange={this.handleChange} required={true} />
                                </div>

                            </div>

                        </div>

                    </div>
                </fieldset>
            </>
        )
    }
}

export default AccountForm;