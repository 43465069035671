import Rest from "../Server/Rest";
import config from "../settings/config";

export const getEmpleyeeByUserId = async (userId) => {
    const where = JSON.stringify({
        User: {
            __type: 'Pointer',
            className: '_User',
            objectId: userId
        }
    });

    const params = new URLSearchParams({
        limit: 1,
        where: where,
    });

    return Rest.peticion(`${config.api_url}/classes/Empleados?${params.toString()}`, {
        method: 'GET'
    })
    .then(res => {
        return res.json();
    })
    .then(res => {
        return res.results[0];
    })
    .catch(error => {
        return error;
    });
}