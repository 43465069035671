import React, { Component } from 'react';

class InputCheckbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            [this.props.name]: this.props.value,
            // status: ''
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = this.props.handleChange;

    render() {
        return (
            <label className={`position-relative btn m-0 p-3 ${this.props.disabled?'disabled':''} ${this.props.value?'fas fa-check-square text-primary':'far fa-square'}`}>
                <input className="d-none" type="checkbox" id={this.props.id} name={this.props.name} value={this.props.value} checked={this.props.value} onChange={this.handleChange} disabled={this.props.disabled}/>
                {/* {this.state.status === 'success' && <small className="text-success position-absolute ml-1" style={styles.status}>Actualizado</small>}
                {this.state.status === 'error' && <small className="text-danger position-absolute ml-1" style={styles.status}>Error</small>}
                {this.state.status === 'sending' && <i className="position-absolute ml-1 text-info fas fa-spinner fa-pulse"></i>} */}
            </label>
        );
    }
}

export default InputCheckbox;

// const styles = {
//     status: {
//         fontFamily: 'sans-serif',
//         zIndex: 1
//     }
// }