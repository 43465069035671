import React from 'react';
import Parse from 'parse';
import Rest from '../../Server/Rest';
import config from '../../settings/config';
import GroupList from './GroupList';
import _ from 'underscore';
import GroupSearchRequisition from './GroupSearchRequisition';
import Spinner from './../others/Spinner';
import TablePagination from './../others/TablePagination';
import TableCountInfo from './../others/TableCountInfo';

class GroupListAutorization extends GroupList {

    title = 'Autorizaciones';

    status = 'autorizacion';

    order = '-fecha_registro';

    include = 'Grupo,Sucursal';

    columnas = ['#', 'Nombre', 'ID', 'Sucursal', 'Status', 'Fecha de registro'];

    setWhere = (_where) => {
        let where = {};
        where = _.extend({
            status: this.status,
            Grupo: {
                $inQuery: {
                    where: {
                        objectId: {
                            $exists: true
                        }
                    },
                    className: 'Grupos'
                }
            },
            Sucursal: Parse.User.current().toJSON().Sucursal || undefined
        }, _where)
        this.where = where;
        return where;
    }

    getResource = (index = 0, _where = {}) => {

        this.setState(state => ({
            index: index
        }));
        
        this.handleSpinner();

        let params;
        let where = this.setWhere(_where);

        where = JSON.stringify(where);

        params = new URLSearchParams({
            
            limit: this.limit,
            skip: index,
            where: where,
            count: 1,
            order: this.order,
            include: this.include
        });
        
        Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                this.rows = res.count;
                this.setState({
                    collection: _.map(res.results, (item, index) => { item.index = index +1 + parseInt(this.state.index); return item; }),
                    total: res.count
                });
            }
        })
        .then(() => {
            this.handleSpinner();
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    setDate = (requisition) => {
        try {
            const d = new Date(requisition.fecha_registro.iso);
            return  d.toLocaleDateString();
        } catch (error) {
            console.log(error);
            return  'Sin especificar';
        }
    }

    redirectTo = (item, diccionario) => {
        this.props.history.push(`${this.props.match.url}/${diccionario.objectId}/${item.Grupo.objectId}/solicitud-actual/${item.objectId}`);
    }

    goSolicitud = (item) => {
        this.setState({
            loading: true
        })
        const params = new URLSearchParams({
            limit: 1,
            where: JSON.stringify({
                Grupo: {
                    __type: 'Pointer',
                    className: 'Grupos',
                    objectId: item.Grupo.objectId
                }
            }),
            count: 1
        });
        Rest.peticion(`${config.api_url}/classes/GruposDiccionario?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            this.setState({
                loading: false
            })
            this.redirectTo(item, res.results[0]);
        })
        .catch(error => {
            this.setState({
                loading: false
            });
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    setHead = <h3 className="text-uppercase text-pink pt-3 pb-3 d-flex">
        <i className="fas fa-users d-flex align-items-start mr-2">
            <small><small><i className="fas fa-clock"></i></small></small>
        </i>
        {this.title}
    </h3>

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />
                
                {this.setHead}
                
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-8">
                                <GroupSearchRequisition handleFilter={this.handleFilter} />
                            </div>
                        </div>
                        <br />

                        <TableCountInfo total={this.state.total} />

                        <div className="table-responsive">    
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                    <th scope="col">{this.columnas[0]}</th>
                                    <th scope="col">{this.columnas[1]}</th>
                                    <th scope="col">{this.columnas[2]}</th>
                                    <th scope="col">{this.columnas[3]}</th>
                                    <th scope="col">{this.columnas[4]}</th>
                                    <th scope="col">{this.columnas[5]}</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.collection.length && this.state.collection.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.index}</td>
                                                <td className="td-responsive-text">
                                                    
                                                    {item.Grupo.nombre}
                                                    
                                                </td>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code className="text-body">{this.setID(item.Grupo.id_en_sucursal, item.Sucursal)}</code>
                                                </td>
                                                <td>{this.setLugarRegistro(item.Sucursal)}</td>
                                                <td className="text-uppercase">{this.setStatus(item.status)}</td>
                                                <td>{this.setDate(item)}</td>
                                                <td className="p-1 text-right">
                                                    <button className="btn btn-link btn-lg p-1 pl-3 pr-3" onClick={() => this.goSolicitud(item)}>
                                                        <i className="fas fa-chevron-right"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ) || <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="alert alert-warning m-0" role="alert">
                                                    No se hallaron registros
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>    
                    
                        <TableCountInfo total={this.state.total} />

                        <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where} />
                    
                    </div>
                </div>

                <div className="p-5"></div>
            </>
        );
    }
}

export default GroupListAutorization;