import React, {Component} from "react";
import Spinner from "../others/Spinner";
import TableCountInfo from "../others/TableCountInfo";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import _ from "underscore";
import TablePagination from "../others/TablePagination";
import Parse from 'parse';
import moment from "moment";
moment.locale('es');

class PolicyDetails extends Component{

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            index: 0,
            total: 0,
            NoPoliza: 0,
            periodo: 0,
            collection: [],
            collection_all: [],
            fecha: '',
        }
    }

    limit = 50;
    rows = 0;
    where = {};

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    componentDidMount = () => {
        this.setState({
            NoPoliza: this.props.match.params.num_poliza,
            periodo: this.props.match.params.period_poliza
        })

        this.getInfoPolicy()
        this.getDetailsPolicy();
        this.getAllDetailsPolicy();
    }

    getInfoPolicy = () =>{
        
        this.handleSpinner();

        
        const id = this.props.match.params.id_poliza;

        Rest.peticion(`${config.api_url}/classes/GLPoliza/${id}`, {
            method: 'GET',
        }).then(response =>{
            this.handleSpinner();
            return response.json();
        }).then( res => {

            // console.log("Información de la póliza => ", res)

            if (res.code) {
                // this.handleSpinner();
                alert('Ocurrió un error al consultar información de la póliza. Intente más tarde o contacte a soporte');
            } else {

                this.setState({
                   fecha: moment(res.fecha.iso).format('YYYY-MM-DD')
                });

            }

        }).catch(err => {
            this.handleSpinner();
            alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
        })
    }

    getDetailsPolicy = (index = 0, _where = {}) =>{
        
        this.handleSpinner();

        this.setState(({
            index: index
        }));

        let where = {};

        where = _where;
        where.poliza_id = this.props.match.params.id_poliza;

        where = JSON.stringify(where);//si aplicamos filtros viene en _where

        let params = new URLSearchParams({
            keys: 'movimiento,abono,cargo,type_transaction.tipo,type_transaction.descripcion,cuenta_codigo.codigo,cuenta_codigo.Tipo_Cuenta.value,segmento_negocio.nombre,grupo_id.nombre',
            limit: this.limit,
            count: 1,
            order: '-createdAt',
            skip: index,
            where,
            include: 'TypesTransactions,CatalogoCuentas,TypesAccounts,Sucursales,Grupos'
        })

        Rest.peticion(`${config.api_url}/classes/GLPolizaDetalle?${params.toString()}`, {
            method: 'GET',
        }).then(response =>{
            this.handleSpinner();
            return response.json();
        }).then( res => {

            // console.log("Detalles de la póliza => ", res)

            if (res.code) {
                // this.handleSpinner();
                alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
            } else {

                this.rows = res.count;
                this.setState({
                    collection: _.map(res.results, (item, index) => { 
                        item.index = index + 1 + parseInt(this.state.index);
                        return item; 
                    }),
                    total: res.count
                });

            }

        }).catch(err => {
            this.handleSpinner();
            alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
        })
    }

    getAllDetailsPolicy = (index = 0, _where = {}) =>{
        
        this.handleSpinner();

        this.setState(({
            index: index
        }));

        let where = {};

        where = _where;
        where.poliza_id = this.props.match.params.id_poliza;

        where = JSON.stringify(where);//si aplicamos filtros viene en _where

        let params = new URLSearchParams({
            keys: 'movimiento,abono,cargo,type_transaction.tipo,type_transaction.descripcion,cuenta_codigo.codigo,cuenta_codigo.Tipo_Cuenta.value,segmento_negocio.nombre,grupo_id.nombre',
            limit: 100000,
            count: 1,
            order: '-createdAt',
            skip: index,
            where,
            include: 'TypesTransactions,CatalogoCuentas,TypesAccounts,Sucursales,Grupos'
        })

        Rest.peticion(`${config.api_url}/classes/GLPolizaDetalle?${params.toString()}`, {
        // Rest.peticion(`${config.api_url}/classes/GLPolizaDetalle`, {
            method: 'GET',
        }).then(response =>{
            this.handleSpinner();
            return response.json();
        }).then( res => {

            // console.log("Detalles totales de la póliza => ", res)

            if (res.code) {
                // this.handleSpinner();
                alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
            } else {

                this.rows = res.count;
                this.setState({
                    collection_all: _.map(res.results, (item, index) => { 
                        item.index = index + 1 + parseInt(this.state.index);
                        return item; 
                    }),
                    total: res.count
                });

            }

        }).catch(err => {
            this.handleSpinner();
            alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
        })
    }

    capitalizarPrimeraLetra(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    sendContalinkApi = async(event) => {

        try {

            if(event) event.preventDefault();

            const confirm = window.confirm("¿Estás seguro de enviar esta póliza a Contalink?");
    
            if (confirm) {
                this.handleSpinner();
                let account_records = [];
    
                for( let i=0 ; i<this.state.collection_all.length ; i++ ) {
        
                    let data = this.state.collection_all[i];
        
                    account_records.push({
                        "account_code": data.cuenta_codigo.codigo,
                        "credit": data.abono !== 0 ? data.abono : 0,//abono
                        "debit": data.cargo !== 0 ? data.cargo : 0 //cargo
                    })
        
                }
    
                const descripcion = this.state.collection[0].type_transaction.descripcion;
    
                const data = {
                    accounting_records: account_records,
                    description: descripcion,
                    record_date: this.state.fecha
                    // record_date: moment().format('YYYY-MM-DD')
                }
        
                // console.log("Enviar a contalink => ", data);
    
                // const sendApi = await fetch('http://localhost:8080/https://794lol2h95.execute-api.us-east-1.amazonaws.com/prod/accounting/manual-accounting-policy/', {
                const sendApi = await fetch('https://794lol2h95.execute-api.us-east-1.amazonaws.com/prod/accounting/manual-accounting-policy/', {
                    headers : {
                        // "Access-Control-Allow-Origin": "*",
                        // "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                        // "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
                        "Authorization": "8c75c522-ab5c-45ea-9c2d-20fd9032c581 95477695-c3e5-40a3-ba09-ec499107489d",
                        "Content-Type": "application/json"
                    },
                    method: "POST",
                    body: JSON.stringify(data)
                })
                const res = await sendApi.json();
    
                // console.log("Respuesta => ", res );
    
                if(res.status === 1){
                    await this.updatePolicyData(res)
                } else {
                    this.handleSpinner();
                    return alert("Ocurrió un error al enviar la póliza a ContaLink")
                }
            }

        } catch (err) {

            console.log("Error catch => ", err);
            this.handleSpinner();
            return alert("Ocurrió un error. Contacte a soporte")
        }

    }

    updatePolicyData = async(res) => {
        // this.props.match.params.id_poliza

        const currentUser = Parse.User.current();

        const newData = {
            enviosContalink: {
                __op: 'Add',
                objects: [
                    {
                        id: res.policy_data.id,
                        numero: res.policy_data.policy_number,
                        fecha_envío: res.policy_data.record_date,
                        enviado_por: {
                            __type: 'Pointer',
                            className: '_User',
                            objectId: currentUser.id
                        }
                    }
                ]
            }
        }

        return Rest.peticion(`${config.api_url}/classes/GLPoliza/${this.props.match.params.id_poliza}`, {
            method: 'PUT',
            body: JSON.stringify(newData)
        }).then(res => {

            this.handleSpinner();
            return res.json();

        }).then(async res => {            

            if (res.code || res.error) {
                return alert('La póliza se envió a contalink. \nPero ocurrió un error al actualizar los datos de la póliza principal.');
            } else {
                return alert('La póliza ha sido enviada exitosamente.');
            }

        }).catch(error => {
            console.log(error);
            this.handleSpinner();
            return alert('La póliza se envió a contalink. \nPero ocurrió un error al actualizar los datos de la póliza principal.');
            // this.handleSpinner();
        });
    }

    render () {

        return(
            <>
                {/* <Spinner loading={this.state.loading}/> */}
                <Spinner loading={this.state.loading}/>

                <h3 className=" text-pink pt-3 pb-3">
                    <i className="fas fa-file-alt mr-2"></i>
                    Detalles de la póliza <b>No. {this.state.NoPoliza} - Periodo {this.state.periodo}</b>
                </h3>

                <div className="card">
                    <div className="card-body">

                        <div className='text-right'>
                            <div className="btn-group" role="group">
                                {/* <button type="button" className="btn btn-primary btn-block" data-toggle="modal" data-target="#modal-createPolice-form" data-backdrop="static" data-keyboard="false">
                                    Generar póliza
                                </button> */}
                                <button type="button" className="btn btn-primary btn-block" data-toggle="modal" data-target="#modal-sendContalink-form" data-backdrop="static" data-keyboard="false" onClick={(e) => this.sendContalinkApi(e) }>
                                    <i className="fas fa-paper-plane mr-2"></i>
                                    <span>Enviar a ContaLink</span>
                                </button>
                            </div>
                        </div>

                        <TableCountInfo total={this.state.total}/>
                        <div className="table-responsive">
                            <table className="table table-hover">

                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Movimiento</th>
                                        <th scope="col">Cargo</th>
                                        <th scope="col">Abono</th>
                                        <th scope="col">Tipo de cuenta</th>
                                        <th scope="col">Código de cuenta</th>
                                        <th scope="col">Tipo de transacción</th>
                                        <th scope="col">Sucursal</th>
                                        <th scope="col">Nombre del grupo</th>
                                    </tr>
                                </thead>

                                <tbody className="">
                                    {
                                        this.state.collection.length ? this.state.collection.map((item, idx) => 
                                            <tr key={idx}>
                                                <td>{item.index}</td>
                                                <td className="td-responsive-text">{this.capitalizarPrimeraLetra(item.movimiento)}</td>
                                                {/* <td className="td-responsive-text">{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.cargo )}</td> */}
                                                <td className="td-responsive-text">{
                                                    item.cargo !== 0 ? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.cargo )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.cargo )
                                                }</td>
                                                <td className="td-responsive-text">{
                                                    item.abono !== 0 ? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.abono )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.abono )
                                                }</td>
                                                <td className="td-responsive-text">{item.cuenta_codigo.Tipo_Cuenta.value}</td>
                                                <td className="td-responsive-text">{item.cuenta_codigo.codigo}</td>
                                                <td className="td-responsive-text">{item.type_transaction.descripcion}</td>
                                                <td className="td-responsive-text">{item.segmento_negocio.nombre}</td>
                                                <td className="td-responsive-text">{item.grupo_id.nombre}</td>
                                            </tr>
                                        ) :
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="alert alert-warning m-0" role="alert">
                                                    No se hallaron registros
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>

                            </table>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getDetailsPolicy} where={this.where}/>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default PolicyDetails;