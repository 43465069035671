import React, { useCallback, useEffect, useRef, useState } from "react";
import Rest from "../../../Server/Rest";
import config from "../../../settings/config";
import Parse from "parse";
import InputDocument from "./InputDocument";
import Signature from "./Signature";
import $ from 'jquery';
import Tabs from "./Tabs";
import MyMaps from "./MyMaps";
import Amounts from "./Amounts";
import { getAmountModel, getCuentasBancariasModel, getDocumentsModel, getEconomicDataModel, getPersonalDataModel, getPledgeDataModel } from "./models";
import _ from 'underscore';
import textUtils from '../../../utilities/format-string';
import RequisitionModel from "../../../models/RequisitionModel";
import { saveIdBusqueda, updateIdBusqueda } from "../../../services/group";
import { addSolicitud, saveAmounts, setAmounts, updatePartnersStatus } from "../../../services/requisition";
import { getEmpleyeeByUserId } from "../../../services/employee";
import { Link } from 'react-router-dom';
import PartnerSearchPopup from "../../partners/PartnerSearchPopup";
import Spinner from "../../others/Spinner";
import ChargeFactor from "../../forms/ChargeFactor";
require('./style.css');
const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

const toDay = new Date().getTime();

export function openDB() {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open('localGroups', 1);
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('myStore')) {
          db.createObjectStore('myStore');
        }
      };
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
}

const GroupRegisterWizard = React.memo((props) => {

    const metaDataSteps = [{
        titleStep: 'Nombre y foto del grupo'
    }, {
        titleStep: 'Cargar documentos'
    }, {
        titleStep: 'Datos de las clientas'
    }, {
        titleStep: 'Préstamo'
    }, {
        titleStep: 'Ajustes finales'
    }];

    const [loading, setLoading] = useState(false);
    const [localStorageIdNewCicle, setLocalStorageIdNewCicle] = useState(null);
    const [localStorageId, setLocalStorageId] = useState(`${Parse.User.current().toJSON().objectId}-local-new-group-${toDay}`);
    const timeoutRef = useRef(null);
    const [data, setData] = useState({
        clients: [{
            ...getPersonalDataModel(),
            ...getPledgeDataModel(),
            ...getEconomicDataModel(),
            
        }],
        grupo: {
            nombre: '',
            Sucursal: Parse.User.current().toJSON().Sucursal ? Parse.User.current().toJSON().Sucursal.objectId : '', // Pointer
            modo_desembolso: '',
            foto_grupal_url: null,
            responsable: undefined,
            charge_factor_type: '',
            id_en_sucursal: null,
            grupo_reducido: false,
        },
        solicitud: {
            domicilio_geopoint: {
                __type: 'GeoPoint',
                latitude: 0,
                longitude: 0
            },
            amounts: [getAmountModel(0)],
            cuentas_bancarias: [{...getCuentasBancariasModel()}],
            responsable: undefined,
            factor_cobro: 0,
            charge_factor_type: '',
            ciclo: 1,
            prestamo_personalizado: false,
            prestamo_personalizado_cantidad_pagos: 0,
            prestamo_personalizado_periodicidad: 0,
            prestamo_personalizado_interes: 0,
            garantia: '',
            garantia_valor: '',
        },
        documents: [{...getDocumentsModel()}]
    });

    const [groupNameExist, setGroupNameExist] = useState(null);
    // controla la navegación del wizard
    const [step, setStep] = useState(0);
    const [allowNextStep, setAllowNextStep] = useState(0);
    // controla la navegación entre las secciones: wizard, guardando y felicitaciones
    const [currentSection, setCurrentSection] = useState('wizard');
    const [groupObjectId, setGroupObjectId] = useState(null);
    const [diccionarioId, setDiccionarioId] = useState(null);
    const [groupOriginal, setGroupOriginal] = useState({
        Sucursal: {}
    });
    const [warranty, setWarranty] = useState(false);

    const readLocalDataGroup = async () => {
        try {
            const keyLocalData = `${Parse.User.current().toJSON().objectId}-${props.match.params.localGroupId}`;
            const db = await openDB();
            const tx = db.transaction('myStore', 'readonly');
            const store = tx.objectStore('myStore');
            const request = store.get(keyLocalData);
            await new Promise((resolve) => {
                request.onsuccess = () => {
                    if (request.result) {
                        setLocalStorageId(keyLocalData);
                        setData(request.result);
                        handleValidName(request.result);
                    }
                    resolve();
                };
            });
            console.log('Object retrieved from IndexedDB:', request.result);
        } catch (error) {
            console.error('Error retrieving object from IndexedDB:', error);
        }
    };

    const readLocalDataNewCicle = async () => {
        try {
            const keyLocalData = `${Parse.User.current().toJSON().objectId}-local-new-cicle-by-group-${props.match.params.groupId}`;
            const db = await openDB();
            const tx = db.transaction('myStore', 'readonly');
            const store = tx.objectStore('myStore');
            const request = store.get(keyLocalData);
            await new Promise((resolve) => {
                request.onsuccess = async () => {
                    const group = await reloadData(props.match.params.groupId);
                    if (request.result) {
                        setLocalStorageId(keyLocalData);
                        setData(request.result);
                        handleValidName(request.result);
                        setGroupOriginal(group);
                        // setTimeout(() => {
                        //     deleteLocalData(keyLocalData);
                        // }, 3000);
                    } else {
                        setGroupOriginal(group);
                        setDataForUpdate(group);
                    }
                    resolve();
                };
            });
            console.log('Object new cicle retrieved from IndexedDB:', request.result);
        } catch (error) {
            console.error('Error new cicle retrieving object from IndexedDB:', error);
        }
    };

    const reloadData = (groupObjectId) => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            const params = new URLSearchParams({
                include: 'Socios,Solicitudes,Sucursal',
                keys: 'Socios,'+
                        'Solicitudes.ciclo,'+
                        'Solicitudes.status,'+
                        'nombre,'+
                        'Sucursal,'+
                        'Sucursal.sigla,'+
                        'foto_grupal_url,'+
                        'charge_factor_type,'+
                        'id_en_sucursal,'+
                        'grupo_reducido,'+
                        'foto_grupal_comentario',
            });

            Rest.peticion(`${config.api_url}/classes/Grupos/${groupObjectId}?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                resolve(res);
                
            })
            .catch(error => {
                reject(error);
            })
            .finally(() => {
                setLoading(false);
            });
        })
    }

    const getBase64Image = async (url) => {
        const response = await fetch(url);
      
        const data = await response.arrayBuffer();
      
        const base64 = btoa(data);
      
        return base64;
      }

    const setDataForUpdate = async (res) => {
        console.log('res --> ', res);

        const clients = res.Socios;
        
        const grupo = {
            nombre: res.nombre,
            Sucursal: res.Sucursal.objectId,
            id_en_sucursal: res.id_en_sucursal,
            grupo_reducido: res.grupo_reducido || false,
        };
        
        
        const documents = new Array(res.Socios.length).fill(getDocumentsModel());
        
        let currentCicle = 0;
        for (let index = 0; index < res.Solicitudes.length; index++) {
            const iSolicitud = res.Solicitudes[index];
            if (iSolicitud.status === 'baja') {
                currentCicle = iSolicitud.ciclo;
                continue;
            }
        }
        
        let { solicitud } = data;
        solicitud = {
            ...solicitud,
            ciclo: currentCicle + 1,
        }

        setData({
            ...data,
            clients,
            documents,
            grupo,
            solicitud
        });
    }

    const deleteLocalData = async (localStorageId) => {
        try {
          const db = await openDB();
          const tx = db.transaction('myStore', 'readwrite');
          const store = tx.objectStore('myStore');
          store.delete(localStorageId);
          await new Promise((resolve) => {
            tx.oncomplete = resolve;
          });
          console.log('Object deleted from IndexedDB');
        } catch (error) {
          console.error('Error deleting object from IndexedDB:', error);
        }
    };

    // cargar datos guardados en local del grupo
    useEffect(() => {
        if (props.match.params.localGroupId) {
            readLocalDataGroup();
        }

        /**
         * esto se ejecuta para renovaciones de grupos.
         * groupId solo se envia desde la edición de grupos
         */
        if (props.match.params.groupId) {
            setGroupObjectId(props.match.params.groupId);
            setDiccionarioId(props.match.params.id_diccionario)
            setLocalStorageIdNewCicle(`${Parse.User.current().toJSON().objectId}-local-new-cicle-by-group-${props.match.params.groupId}`);
            readLocalDataNewCicle();
        }
    }, [props.match.params.localGroupId]);
    /**
     * Este hook se usa para ejecutar la función que valida que
     * todos los documentos se cargaron, que al cumplirse se
     * podrá avanzar a la siguiente pantalla.
     */
    useEffect(() => {
        verifyLoadedDocumnents();
    }, [data.documents]);


    const handleLocalStorage = useCallback(async () => {
        if (
            !_.isEmpty(data.grupo.nombre)
        ) {
            let dataClon = JSON.parse(JSON.stringify(data));
            try {
                const db = await openDB();
                const tx = db.transaction('myStore', 'readwrite');
                const store = tx.objectStore('myStore');
                if (_.isEmpty(localStorageIdNewCicle)) {
                    store.put(dataClon, localStorageId);
                } else {
                    store.put(dataClon, localStorageIdNewCicle);
                }
                await new Promise((resolve) => {
                  tx.oncomplete = resolve;
                });
                console.log('Object saved to IndexedDB');
            } catch (error) {
                console.error('Error saving object to IndexedDB:', error);
            }
        }
    }, [data, localStorageId])

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(handleLocalStorage, 1000);
    }, [allowNextStep, data, handleLocalStorage, localStorageId]);

    const verifyLoadedDocumnents = () => {
        let allowChange = true;
        if (data.documents.length) {
            for (let index = 0; index < data.documents.length; index++) {
                const clientDocument = data.documents[index];
                
                const ineFrontSide = clientDocument.ineFrontSide;
                const ineBackSide = clientDocument.ineBackSide;
                const birthCertificate = clientDocument.birthCertificate;
                const curp = clientDocument.curp;
                const proofAddress = clientDocument.proofAddress;
                const signature = clientDocument.signature;
                
                if (
                    (ineFrontSide.imageUrl === null) ||
                    (ineBackSide.imageUrl === null) ||
                    (birthCertificate.imageUrl === null) ||
                    (curp.imageUrl === null) ||
                    (proofAddress.imageUrl === null) ||
                    (signature.imageUrl === null)
                ) {
                    allowChange = false;
                    continue;
                }
            }
            
            if (allowChange) {
                setAllowNextStep(step+1);
            } else {
                setAllowNextStep(step);
            }
        } else {
            setAllowNextStep(step);
        }
    }

    const handleChangeGroupData = (e) => {
        if (e.target.type === 'checkbox') {
            console.log('e.target.checked --> ', e.target.checked);
            setData({...data, grupo: {...data.grupo, [e.target.name]: e.target.checked}});
        } else {
            let newData = {};
            if (
                e.target.name === 'modo_desembolso' &&
                e.target.value === 'BANCO' &&
                _.isUndefined(data.solicitud.cuentas_bancarias)
            ) {
                newData = {...data, solicitud: {...data.solicitud, cuentas_bancarias: [{...getCuentasBancariasModel()}]}, grupo: {...data.grupo, [e.target.name]: e.target.value}};
                console.log('newData --> ', newData);
                setData(newData);
            } else {
                newData = {...data, grupo: {...data.grupo, [e.target.name]: e.target.value}}
                setData(newData);
            }
            
        }
    }

    const handleChangeSolicitudData = (e) => {
        let newData = {...data};
        switch (e.target.type) {
            case 'checkbox':
                setData({...data, solicitud: {...data.solicitud, [e.target.name]: e.target.checked}});
                break;
            case 'desembolso_lat':
                newData.solicitud.domicilio_geopoint.latitude = e.target.value;
                break;
            case 'desembolso_lng':
                newData.solicitud.domicilio_geopoint.longitude = e.target.value;
                break;
            default:
                setData({...data, solicitud: {...data.solicitud, [e.target.name]: e.target.value}});
                break;
        }
    }

    const handleChangeBanckPartner = (event, index) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const { cuentas_bancarias } = data.solicitud;

        switch (name) {
            case 'socio':
                cuentas_bancarias[index].clientIndex = parseInt(value);
                break;
            case 'cuenta':
                if (/^[0-9|(\s)]*$/.test(value))
                    cuentas_bancarias[index][name] = value;
                break;
            default:
                cuentas_bancarias[index][name] = value;
                break;
        }
        
        setData({...data, ...{solicitud: {...data.solicitud, cuentas_bancarias}}});
    }

    const handleChangeClient = (nextClientData, index) => {
        setData(prevData => {
          const updatedClients = [...prevData.clients];
          updatedClients[index] = { ...updatedClients[index], ...nextClientData };
          return { ...prevData, clients: updatedClients };
        });
    }

    const addItem = (preIndex) => {
        const coll = data.solicitud.cuentas_bancarias;
        const newColl = [];
        for (let index = 0; index < coll.length; index++) {
            newColl.push(coll[index]);
            if (index === preIndex) {
                newColl[index+1] = getCuentasBancariasModel();
            }
        }
        const {solicitud} = data;
        solicitud.cuentas_bancarias = newColl;
        setData({...data, solicitud});
    }

    const removeItem = (index) => {
        const coll = data.solicitud.cuentas_bancarias;
        const {solicitud} = data;
        if (!(coll.length === 1)) {
            coll.splice(index, 1)
            solicitud.cuentas_bancarias = coll;
            setData({...data, solicitud});
        } else {
            solicitud.cuentas_bancarias = [getCuentasBancariasModel()];
            setData({...data, solicitud});
        }
    }

    const myAlertNextStep = () => alert('Debes completar esta sección para poder avanzar 🤔');

    const nextBtn = (event) => {
        /**
         * Si allowNextStep es un paso mayor al actual entonces permitirá anvanzar
         */
        // if (
        //     allowNextStep > step
        // ) {
            $(event.currentTarget).tab('show').after(() => {
                setStep(step+1);
            });
        // } else {
        //     myAlertNextStep();
        // }

        /**
         * 
         */
    }

    const tabBtn = (event, step) => {
        /**
         * si el paso elegido es menor o igual al permitido permitirá navegar
         */
        // if (
        //     step <= allowNextStep
        // ) {
        //     $(event.currentTarget).tab('show').after(() => {
                setStep(step);
        //     });
        // } else {
        //     if (step !== allowNextStep) {
        //         myAlertNextStep();
        //     }
        // }
    }

    const prevBtn = (event) => {
        $(event.currentTarget).tab('show').after(() => {
            setStep(step-1);
        });
    }

    const addClient = (client = {}) => {
        const {clients, documents, solicitud} = data;
        clients.push({
            ...getPersonalDataModel(),
            ...getPledgeDataModel(),
            ...getEconomicDataModel(),
            ...client
            
        });
        documents.push({...getDocumentsModel()});
        solicitud.amounts.push(getAmountModel());
        const newData = {...data, clients, documents, solicitud};
        setData(newData);
        verifyLoadedDocumnents();
    }

    const removeClient = (index) => {
        const result = window.confirm('¿Está seguro que desea elimiar al cliente del proceso de registro?');
        if (result) {
            const { clients, documents, solicitud } = data;
            clients.splice(index, 1);
            documents.splice(index, 1);
            solicitud.amounts.splice(index, 1);
            setData({...data, clients, documents, solicitud});
        }
        verifyLoadedDocumnents();
    }

    const disassembleCURP = (curp) => {
        // Validar la longitud de la CURP
        if (curp.length !== 18) {
            console.log('La CURP ingresada no tiene la longitud correcta.');
            return;
        }
        
        // Extraer información de la CURP
        var info = {};

        // Primer letra y vocal del primer apellido
        info.primerApellido = curp.substring(0, 2);
        
        // Primera letra del segundo apellido
        info.segundoApellido = curp.charAt(2);
        
        // Primera letra del nombre
        info.nombre = curp.charAt(3);
        
        // Obtener los últimos dos dígitos del año de nacimiento
        var ultimosDosDigitosAnio = curp.substring(4, 6);
        var anio = parseInt(ultimosDosDigitosAnio, 10);
        var anioCorregido = '';
        
        // Ajustar el valor del año para que tenga 4 dígitos
        if (anio >= 0 && anio <= 29) {
            anioCorregido = '20' + ultimosDosDigitosAnio;
        } else {
            anioCorregido = '19' + ultimosDosDigitosAnio;
        }
        
        // Actualizar el valor del año en la información de la CURP
        info.anioNacimiento = anioCorregido;
        
        // Dos dígitos del mes de nacimiento
        info.mesNacimiento = curp.substring(6, 8);
        
        // Dos dígitos del día de nacimiento
        info.diaNacimiento = curp.substring(8, 10);
        
        // Carácter del sexo (Hombre o Mujer)
        info.sexo = curp.charAt(10) === 'H' ? 'HOMBRE' : 'MUJER';
        
        // Dos letras del estado de nacimiento
        info.estadoNacimiento = curp.substring(11, 13);
        
        // Imprimir la información extraída
        console.log('Información de la CURP:');
        console.log('Primer apellido:', info.primerApellido);
        console.log('Segundo apellido:', info.segundoApellido);
        console.log('Nombre:', info.nombre);
        console.log('Año de nacimiento:', info.anioNacimiento);
        console.log('Mes de nacimiento:', info.mesNacimiento);
        console.log('Día de nacimiento:', info.diaNacimiento);
        console.log('Sexo:', info.sexo);
        console.log('Estado de nacimiento:', info.estadoNacimiento);

        return info;
    }

    const getGender = (info) => {
        return info.sexo;
    }

    const getBirthdate = (info) => {
        return `${info.anioNacimiento}-${info.mesNacimiento}-${info.diaNacimiento}`;
    }

    const getFullName = (texto, primerLetraNombre, dosLetrasPrimerApellido, primerLetraSegundoApellido) => {
        const regex = new RegExp(`${primerLetraNombre}.*${dosLetrasPrimerApellido}.*${primerLetraSegundoApellido}[a-zA-Z]*`, 'g');
        return texto.match(regex);
    }

    const logData = () => {
        console.log('data --> ', data);
    }

    const setCredencialAnverso = async (info, index) => {
        
        const { documents } = data;

        setData({
            ...data,
            documents: documents.map((document, idx) => {
                if (idx === index) {
                return {
                    ...document,
                    ineFrontSide: { imageUrl: info.imageUrl, loaded: true }
                };
                }
                return document;
            })
        });

    }

    const setCredencialReverso = async (info, index) => {
        
        const { documents } = data;

        const updatedData = {
            ...data,
            documents: documents.map((document, idx) => {
                if (idx === index) {
                    return {
                    ...document,
                    ineBackSide: { imageUrl: info.imageUrl, loaded: true }
                    };
                }
                return document;
            })
        };

        setData(updatedData);

    }

    const setActaNacimiento = async (info, index) => {
        
        const { documents } = data;
        const updatedData = {
            ...data,
            documents: documents.map((document, idx) => {
                if (idx === index) {
                    return {
                    ...document,
                    birthCertificate: { imageUrl: info.imageUrl, loaded: true }
                    };
                }
                return document;
            })
        };

        setData(updatedData);

    }

    const setCurp = async (info, index) => {
        
        const { documents } = data;

        const updatedData = {
            ...data,
            documents: documents.map((document, idx) => {
                if (idx === index) {
                    return {
                    ...document,
                    curp: { imageUrl: info.imageUrl, loaded: true }
                    };
                }
                return document;
            })
        };

        setData(updatedData);
    }

    const setComprobanteDomiilio = async (info, index) => {
        const { documents } = data;
        const updatedData = {
            ...data,
            documents: documents.map((document, idx) => {
                if (idx === index) {
                    return {
                    ...document,
                    proofAddress: { imageUrl: info.imageUrl, loaded: true }
                    };
                }
                return document;
            })
        };

        setData(updatedData);
    }

    const setSignature = (imageUrl, index) => {
        const { documents } = data;
        const updatedData = {
            ...data,
            documents: documents.map((document, idx) => {
                if (idx === index) {
                    return {
                        ...document,
                        signature: { imageUrl: imageUrl, loaded: true }
                    };
                }
                return document;
            })
        };

        setData(updatedData);
    }

    const resetDocument = (keyDociment, index) => {
        const { documents } = data;
        const updatedData = {
            ...data,
            documents: documents.map((document, idx) => {
                if (idx === index) {
                    return {
                        ...document,
                        [keyDociment]: { imageUrl: null, loaded: false }
                    };
                }
                return document;
            })
        };

        setData(updatedData);
    }

    const handlePhotoGroup = async (event) => {
        const target = event.target;
        const file = target.files[0];
        const name = target.name;
        const reader = new FileReader();
        reader.onload = async (e) => {
            const imageUrl = e.target.result;
            setData({...data, grupo: {...data.grupo, [name]: imageUrl}});
        };
        reader.readAsDataURL(file);
    }

    const setLugarDesemboldo = (lat, lng) => {
        setData((prevData) => ({
          ...prevData,
          solicitud: {
            ...prevData.solicitud,
            domicilio_geopoint: {
              ...prevData.solicitud.domicilio_geopoint,
              latitude: lat,
              longitude: lng,
            },
          },
        }));
    }

    const handleAmounts = (nextAmounts) => {
        const amounts = nextAmounts;
        data.solicitud.amounts = amounts;
        setData(data);
        handleLocalStorage();
        const newValue = setRequiredWarranty(data.solicitud.amounts);
        setWarranty(newValue);
    }

    const whereValidName = () => {
        return {
            Grupo: {
                $select: {
                    query: {
                        className: 'Grupos',
                        where: {
                            nombre: data.grupo.nombre.toUpperCase().trim(),
                            Sucursal: {
                                __type: 'Pointer',
                                className: 'Sucursales',
                                objectId: data.grupo.Sucursal
                            },
                            objectId: groupObjectId ? {
                                $ne: groupObjectId
                            } : undefined,
                        }
                    },
                    key: 'objectId'
                }
            }
        }
    }

    const handleValidName = (data) => {
        if (
            !_.isEmpty(data.grupo.nombre) &&
            !_.isEmpty(data.grupo.Sucursal)
        ) {
            const params = new URLSearchParams({
                where: JSON.stringify(whereValidName()),
                count: 1,
                limit: 0
            });
            return Rest.peticion(`${config.api_url}/classes/GruposDiccionario?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (res.code) {
                    console.log('Ocurrió un error al comprobar la existencia del nombre de grupo.');
                } else {
                    if (res.count > 0) {
                        // hay un grupo con el mismo nombre, no debe permitir seguir el registro
                        setGroupNameExist(null);
                        setGroupNameExist(true);
                    } else {
                        setGroupNameExist(null);
                        setGroupNameExist(false);
                        setAllowNextStep(step+1);
                    }
                }
                return res;
            })
            .catch(error => {
                alert('Ocurrió un error al comprobar la existencia del nombre de grupo.');
                return error;
            });
        }
        
    }

    const checkExistence = async (clave, valor, notEqualObjectId) => {

        let where = {
            Socio: {
                '$select': {
                    query: {
                        className: 'Socios',
                        where: {}
                    },
                    key: 'objectId'
                }
            }
        };
        if (!_.isEmpty(notEqualObjectId)) {
            where.Socio.$select.query.where.objectId = {
                $ne: notEqualObjectId
            };
        }

        where.Socio.$select.query.where[clave] = valor.toUpperCase();

        const params = new URLSearchParams({
            where: JSON.stringify(where),
            include: 'Socio',
            count: 1,
        });
        return Rest.peticion(`${config.api_url}/classes/SociosDiccionario?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        }).catch(error => {
            return error;
        });
    }

    const handleChargeFactor  = (event) => {
        const target = event.target;
        const value = parseFloat(target.value);
        let cantidad_pagos_semanales = 10;

        cantidad_pagos_semanales = RequisitionModel[RequisitionModel.setChargeFactorType(data.solicitud.prestamo_personalizado_periodicidad, data.grupo.charge_factor_type, data.solicitud.garantia, data.solicitud.prestamo_personalizado)][value].cantidad_pagos_semanales;

        const grupo = data.grupo;
        const solicitud = data.solicitud;

        setData({
            ...data,
            grupo: {
                ...grupo, 
                charge_factor_type: RequisitionModel.setChargeFactorType(data.solicitud.prestamo_personalizado_periodicidad, data.grupo.charge_factor_type, data.solicitud.garantia, data.solicitud.prestamo_personalizado),
            },
            solicitud: {
                ...solicitud,
                charge_factor_type: RequisitionModel.setChargeFactorType(data.solicitud.prestamo_personalizado_periodicidad, data.grupo.charge_factor_type, data.solicitud.garantia, data.solicitud.prestamo_personalizado),
                factor_cobro: value,
                cantidad_pagos_semanales: cantidad_pagos_semanales,
            }
        });
        
        return true;
    }

    const saveNewAmounts = async (clientsPointer, groupId) => {

        const formData = JSON.parse( JSON.stringify(data) );

        formData.solicitud.amounts = _.map(formData.solicitud.amounts, (amount, index) => {
            amount.Socio = {objectId: clientsPointer[index].objectId}
            return amount;
        })
        const amounts = setAmounts(formData.solicitud.amounts, groupId);

        return await saveAmounts(amounts);
    }

    const setObjectIdPerClient = async (clientsPointer) => {
        let {clients} = data;
        clients = _.map(clients, (client, index) => {
            client.objectId = clientsPointer[index].objectId;
            return client;
        });
        setData({
            ...data,
            clients
        });
    }

    const saveNewGroup = async () => {
        setCurrentSection('saving');
        const currentUser = Parse.User.current();
        const promotor = await getEmpleyeeByUserId(currentUser.id);
        // console.log('promotor --> ', promotor);
        const clientsPointer = await saveClient(promotor.objectId);
        const group = await saveGroup(clientsPointer, promotor.objectId);
        // console.log('group --> ', group);
        const savedAmounts = await saveNewAmounts(clientsPointer, group.objectId);
        const solicitud = await saveSolicitud(clientsPointer, savedAmounts, group.objectId, promotor.objectId);
        // console.log('solicitud --> ', solicitud);
        await addSolicitud(solicitud.objectId, group.objectId);
        await updatePartnersStatus(_.map(clientsPointer, client => {
            return {Socio: {objectId: client.objectId}}
        }));
        await setObjectIdPerClient(clientsPointer);

        setCurrentSection('congratulation');
        setData({
            ...data,
            grupo: {
                ...data.grupo,
                saved: true,
                objectId: group.objectId,
            }
        });
    }

    const saveNewCicle = async () => {
        setCurrentSection('saving');
        let newData = JSON.parse(JSON.stringify(data));
        // console.log('newData --> ', newData);
        delete newData.grupo.Sucursal;
        delete newData.grupo.id_en_sucursal;
        // setCurrentSection('saving');
        const currentUser = Parse.User.current();
        const promotor = await getEmpleyeeByUserId(currentUser.id);
        console.log('promotor --> ', promotor);
        const clientsPointer = await saveClient(promotor.objectId);
        // console.log('clientsPointer --> ', clientsPointer);
        await updateGroup(clientsPointer, promotor.objectId);
        // console.log('group --> ', group);
        const savedAmounts = await saveNewAmounts(clientsPointer, groupObjectId);
        const solicitud = await saveSolicitud(clientsPointer, savedAmounts, groupObjectId, promotor.objectId);
        // console.log('solicitud --> ', solicitud);
        await addSolicitud(solicitud.objectId, groupObjectId);
        await updatePartnersStatus(_.map(clientsPointer, client => {
            return {Socio: {objectId: client.objectId}}
        }));
        await setObjectIdPerClient(clientsPointer);

        setCurrentSection('congratulation');
        setData({
            ...data,
            grupo: {
                ...data.grupo,
                saved: true,
                objectId: groupObjectId,
            }
        });
    }

    const checkFormComplete = () => {

        // validar que los documentos se cargaron
        for (let index = 0; index < data.documents.length; index++) {
            const documents = data.documents[index];
            if (documents.birthCertificate.imageUrl === null) {
                alert("El documento del Acta de nacimiento es obligatorio.");
                return false;
            }
            if (documents.curp.imageUrl === null) {
                alert("El documento de la CURP es obligatorio.");
                return false;
            }
            if (documents.ineBackSide.imageUrl === null) {
                alert("El documento de la credencial (parte trasera) es obligatorio.");
                return false;
            }
            if (documents.ineFrontSide.imageUrl === null) {
                alert("El documento de la credencial (el frente) es obligatorio.");
                return false;
            }
            if (documents.proofAddress.imageUrl === null) {
                alert("El documento el comprobante de domicilio es obligatorio.");
                return false;
            }
            if (documents.signature.imageUrl === null) {
                alert("Falta la firma de un cliente.");
                return false;
            }
        }

        // Obtiene todos los campos del formulario
        const formElements = document.querySelectorAll("#form-wizard input[required], #form-wizard select[required]");

        // validar los campos del formulario
        for (const formElement of formElements) {
            // Verifica si el campo está vacío
            if (formElement.value === "") {
                // Muestra un mensaje de error
                alert("El campo " + ($(formElement).attr('label') || formElement.name) + " es obligatorio.");
                return false;
            }
        }

        // validar si se especificó los montos solicitados
        for (let index = 0; index < data.solicitud.amounts.length; index++) {
            const amount = data.solicitud.amounts[index];
            if (amount.monto_solicitado === 0) {
                alert('Especifique un monto de préstamo para el cliente.');
                return false;
            }
        }

        // validar que se especificó un lugar para el desembolso
        if (
            data.solicitud.domicilio_geopoint.latitude === 0 ||
            data.solicitud.domicilio_geopoint.longitude === 0
        ) {
            alert("Especifique una ubicación para el desembolso");
            return false;
        }



        // El formulario está completo
        return true;
    }

    const onSubmit = () => {

        if(data.grupo.grupo_reducido !== true && data.clients.length < 3) {
            alert('Se requiere por lo menos 3 clientas para conformar el grupo.');
            return;
        }

        if (!checkFormComplete()) {
            return;
        }

        const r = window.confirm('¿Todos los datos son correctos?');

        if (r === false) {
            return;
        }

        // console.log('data --> ', data);
        
        try {
            
            if (!!groupObjectId) {
                if (groupNameExist) {
                    alert('El nombre del grupo se encuentra en uso.')
                    return;
                }
                saveNewCicle();
            } else {
                saveNewGroup();
            }

        } catch (error) {
            console.error('Error el proceso de guardado: ', error);
        }

    }

    const saveClient = () => {
        return new Promise((resolve, reject) => {

            let clientsPointer = [];

            const clients = JSON.parse(JSON.stringify(data.clients));

            _.map(clients, async (data) => {
                /**
                 * en caso de que se tenga un objectId no se debe
                 * hacer el guardado, la edición ocurrirá en otra función
                 */
                if (_.isEmpty(data.objectId)) {
                    // formatear datos
                    _.map(data, (value, key) => {
                        let newValue;
                        
                            if (!_.isEmpty(value) && _.isString(value)) {
                                newValue = value.toUpperCase().trim();
                            } else {
                                newValue = value;
                            }
        
                            data[key] = newValue;
                    });
    
                    if (Parse.User.current().toJSON().Sucursal) {
                        data.lugar_registro = {
                            __type: 'Pointer',
                            className: 'Sucursales',
                            objectId: Parse.User.current().toJSON().Sucursal.objectId
                        };
                    }
    
                    data.status = 'ACTIVO';
        
                    data.fecha_registro = {
                        __type: 'Date',
                        iso: new Date().toISOString()
                    };
        
                    // // Establecer usuario quien registró el socio
                    const currentUser = Parse.User.current();
                    data.registeredBy = {
                        __type: 'Pointer',
                        className: '_User',
                        objectId: currentUser.id
                    }
        
                    let en_moderacion = false;
        
                    let id_busqueda =   textUtils.setPlainText(data.nombre+data.apellido_paterno+data.apellido_materno).toLowerCase() +
                                        data.curp +
                                        data.numero_ocr;
                        id_busqueda =   id_busqueda.toLowerCase();
    
                    /**
                     * eliminamos el objectId, ha espe punto
                     * no deber existir, pero en desarrollo
                     * se puede dar el caso
                     */
                    try {
                        delete data.objectId;
                    } catch (error) {
                        console.log(error);
                    }
        
                    const data_POST = {
                        requests: [{
                            method: 'POST',
                            path: `${config.api_path2}/classes/Socios`,
                            body: data
                        }]
                    };
        
                    let id_partner = '';
                    
                    Rest.peticion(`${config.api_url}/batch`, {
                        method: 'POST',
                        body: JSON.stringify(data_POST)
                    })
                    .then(res => {
                        return res.json();
                    })
                    .then(res => {
                        if (
                            !_.isUndefined(res[0].success)
                        ) {
                            id_partner = res[0].success.objectId;
                        } else {
                            throw new Error(`No se pudo registrar el usuario: ${data.nombre}`);
                        }
                    })
                    .then(() => {
                        return checkExistence('domicilio_numero_servicio', data.domicilio_numero_servicio, id_partner);
                    })
                    .then(res => {
                        if (!(res.code||res.error)) {
                            if (res.results.length >= 3) {
                                en_moderacion = true;
                            }
                        }
                    })
                    .then(() => {
        
                        let data_PUT = {
                            requests: [{
                                method: 'POST',
                                path: `${config.api_path2}/classes/SociosDiccionario`,
                                body: {
                                    id_busqueda: id_busqueda,
                                    Socio: {
                                        __type: 'Pointer',
                                        className: 'Socios',
                                        objectId: id_partner
                                    },
                                    en_moderacion: en_moderacion
                                }
                            }]
                        };
                        return Rest.peticion(`${config.api_url}/batch`, {
                            method: 'POST',
                            body: JSON.stringify(data_PUT)
                        });
                        
                    })
                    .then(res => {
                        // localStorage.removeItem(this.data_loca_key);
                        // alert('La nueva clienta ha sido registrada.');
                        clientsPointer.push({
                            __type: 'Pointer',
                            className: 'Socios',
                            objectId: id_partner
                        });
                        if (clientsPointer.length === clients.length) {
                            resolve(clientsPointer);
                        }
                    })
                    .catch(error => {
                        const msg = 'Ha ocurrido un error al intentar guardar los datos. Intente más tarde o contacte a soporte.';
                        alert(msg);
                        reject(msg)
                        console.log('error al registrar cliente --> ', error);
                    });
                /**
                 * si se trata de un cliente con creado previamente 
                 * entonces solo agregamos el pointer del cliente.
                 * validamos también si se tiene completo la lista 
                 * de pointers
                 */
                } else {
                    clientsPointer.push({
                        __type: 'Pointer',
                        className: 'Socios',
                        objectId: data.objectId
                    });
                    if (clientsPointer.length === clients.length) {
                        resolve(clientsPointer);
                    }
                }
            });
        })
    }

    const saveGroup = async (clientsPointer, promotorId) => {
        return new Promise(async (resolve, reject) => {

            const currentUser = Parse.User.current();

            const dataForm = JSON.parse(JSON.stringify(data.grupo));

            let newData = {};

            newData.nombre = dataForm.nombre;

            if (currentUser.toJSON().Sucursal) {
                newData.Sucursal = currentUser.toJSON().Sucursal.objectId;
            }

            newData.id_busqueda = textUtils.setIdBusqueda(dataForm.nombre);
        
            newData.fecha_registro = {
                __type: 'Date',
                iso: new Date().toISOString()
            };

            newData.responsable = {
                __type: 'Pointer',
                className: 'Socios',
                objectId: clientsPointer[ parseInt(dataForm.responsable) ].objectId,
            };

            newData.modo_desembolso = dataForm.modo_desembolso;
            newData.foto_grupal_comentario = dataForm.foto_grupal_comentario;

            // Establecer usuario quien registró el socio
            newData.registeredBy = {
                __type: 'Pointer',
                className: '_User',
                objectId: currentUser.id
            };

            newData.Socios = clientsPointer;

            const toDay = new Date();

            newData.fecha_elaboracion = {
                __type: 'Date',
                iso: toDay.toISOString()
            };

            newData.Sucursal = {
                __type: 'Pointer',
                className: 'Sucursales',
                objectId: Parse.User.current().toJSON().Sucursal.objectId
            };

            newData.dia_pago = new Date().getDay();

            newData.status = 'ACTIVO';

            console.log('newData --> ', newData);

            newData.promotor = {
                __type: 'Pointer',
                className: 'Empleados',
                objectId: promotorId,
            }

            let savedGroup = {};
            return Rest.peticion(`${config.api_url}/classes/Grupos`, {
                method: 'POST',
                body: JSON.stringify(newData)
            }).then(res => {

                return res.json();

            }).then(async res => {
                savedGroup = res;
                return await saveIdBusqueda(res.objectId, textUtils.setIdBusqueda(newData.nombre));
            }).then(res => {
                console.log('guardado en el diccionairo --> ', res);
                resolve(savedGroup);
            })
            .catch(error => {
                console.log('error en el guardo del grupo/diccionario --> ', error);
                reject('El grupo no ha podido ser registrado');
                alert('No se ha podido guardar el grupo. Intente más tarde.');
            })
        });

    }

    const updateGroup = async (clientsPointer, promotorId) => {
        return new Promise(async (resolve, reject) => {

            const dataForm = JSON.parse(JSON.stringify(data.grupo));

            let newData = {};

            newData.nombre = dataForm.nombre;

            newData.grupo_reducido = dataForm.grupo_reducido ? dataForm.grupo_reducido : {__op: "Delete"}

            newData.id_busqueda = textUtils.setIdBusqueda(dataForm.nombre);

            newData.responsable = {
                __type: 'Pointer',
                className: 'Socios',
                objectId: clientsPointer[ parseInt(dataForm.responsable) ].objectId,
            };

            newData.modo_desembolso = dataForm.modo_desembolso;

            newData.Socios = clientsPointer;

            newData.dia_pago = new Date().getDay();

            newData.status = 'autorizacion';

            newData.promotor = {
                __type: 'Pointer',
                className: 'Empleados',
                objectId: promotorId,
            }

            console.log('updateGroup --> ', newData);

            let savedGroup = {};
            return Rest.peticion(`${config.api_url}/classes/Grupos/${groupObjectId}`, {
                method: 'PUT',
                body: JSON.stringify(newData)
            }).then(res => {

                return res.json();

            }).then(async res => {
                savedGroup = res;
                return await updateIdBusqueda(diccionarioId, newData.id_busqueda);
            }).then(res => {
                console.log('actualizado en el diccionairo --> ', res);
                resolve(savedGroup);
            })
            .catch(error => {
                console.log('error en el guardo del grupo/diccionario --> ', error);
                reject('El grupo no ha podido ser registrado');
                alert('No se ha podido guardar el grupo. Intente más tarde.');
            })
        });

    }

    const validCuentasBancarias = (cuentas_bancarias, clientsPointer) => {
        const result = _.map(cuentas_bancarias, cuenta_bancaria => {
            cuenta_bancaria.Socio = {
                __type: 'Pointer',
                className: 'Socios',
                objectId: clientsPointer[cuenta_bancaria.clientIndex].objectId
            };
            delete cuenta_bancaria.clientIndex;
            return cuenta_bancaria;
        })
        if (_.isEmpty(result)) {
            return false;
        }
        return result;
    }

    const saveSolicitud = async (clientsPointer, savedAmounts, groupId, promotorId) => {
        
        return new Promise(async (resolve, reject) => {

            const formData = JSON.parse( JSON.stringify(data) );

            console.log('savedAmounts --> ', savedAmounts);

            const currentUser = Parse.User.current();

            let newData = {};

            let amountsPluckSuccess = _.pluck(savedAmounts, 'success');

            newData.registeredBy = {
                __type: 'Pointer',
                className: '_User',
                objectId: currentUser.id
            };

            newData.montos = _.map(amountsPluckSuccess, amout => {
                return {
                    __type: 'Pointer',
                    className: 'SolicitudesMontos',
                    objectId: amout.objectId
                }
            });

            newData.promotor = {
                __type: 'Pointer',
                className: 'Empleados',
                objectId: promotorId
            };

            newData.responsable = {
                __type: 'Pointer',
                className: 'Socios',
                objectId: clientsPointer[ parseInt(formData.grupo.responsable) ].objectId,
            };

            newData.Grupo = {
                __type: 'Pointer',
                className: 'Grupos',
                objectId: groupId
            };

            newData.Sucursal = {
                __type: 'Pointer',
                className: 'Sucursales',
                objectId: currentUser.toJSON().Sucursal.objectId,
            };

            newData.status = 'autorizacion';

            newData.ciclo = formData.solicitud.ciclo;
            newData.dia_pago = new Date().getDay();
            newData.fecha_elaboracion = {
                __type: 'Date',
                iso: new Date().toISOString(),
            };
            newData.factor_cobro = formData.solicitud.factor_cobro;
            newData.prestamo_personalizado_periodicidad = parseInt(formData.solicitud.prestamo_personalizado_periodicidad);
            newData.cuentas_bancarias = (formData.grupo.modo_desembolso === 'BANCO' && validCuentasBancarias(formData.solicitud.cuentas_bancarias, clientsPointer) )|| {__op:'Delete'};
            newData.charge_factor_type = formData.grupo.charge_factor_type;
            newData.cantidad_pagos_semanales = formData.solicitud.cantidad_pagos_semanales

            newData.fecha_registro = {
                __type: 'Date',
                iso: new Date().toISOString()
            }

            Rest.peticion(`${config.api_url}/classes/Solicitudes`, {
                method: 'POST',
                body: JSON.stringify(newData)
            })
            .then(res => res.json())
            .then(res => resolve(res))
            .catch(error => reject(error));
        });
        
        
    }

    const setAmountTotal_0 = (montos) => {
        let sum = 0;
        _.map(montos, (item) => {
            sum += parseInt(item.monto_solicitado);
        });
        return sum;
    }

    const setRequiredWarranty = (amounts) => {
        return (
            data.prestamo_personalizado && (
                setAmountTotal_0(amounts) >= 9500
            )
        ) || (
            setAmountTotal_0(amounts) >= 25000
        );
    }

    const handleAddPartner = (partner) => {
        if (_.isEmpty(_.findWhere(data.clients, {objectId: partner.objectId}))) {
            addClient(partner);
        } else {
            alert('La clienta ya fue agregada.');
        }
    }

    const handleChangeDirectState = (value, key) => {
        setData({
            ...data,
            solicitud: {
                ...data.solicitud,
                [key]: value
            }
        })
    }

    return <div className="row bg-white -mt-3 h-100">
        <Spinner loading={loading} />
        <form id="form-wizard" className={`w-100 ${currentSection === 'wizard' ? '' : 'd-none'}`}>
            <div id="steps" className="w-100 sticky-top d-flex align-items-center" style={styles.steps}>
                <div className="container h-100">
                    <div className="row h-100 align-items-center d-md-flex flex-md-row-reverse">
                        <div className="col-sm-6 d-flex justify-content-center justify-content-sm-end">
                            <div className="mb-md-3">
                                <div className="d-none d-sm-block d-md-block d-lg-block"><small>Navegación:</small></div>
                                <ul id="navigation-steps" className="list-unstyled nav nav-tabs d-flex m-0 border-0">
                                    <li className="pl-0 pt-1 pb-1 pr-1">
                                        <button className={`d-flex align-items-center justify-content-center btn btn-light border-0 ${(step === 0) ? 'bg-primary text-white' : ''}`} style={styles.btnNavWizard} data-target={`#step-${0}`} onClick={(e) => tabBtn(e, 0)} type="button">1</button>
                                    </li>
                                    <li className="p-1">
                                        <button className={`d-flex align-items-center justify-content-center btn btn-light border-0 ${(step === 1) ? 'bg-primary text-white' : ''}`} style={styles.btnNavWizard} data-target={`#step-${1}`} onClick={(e) => tabBtn(e, 1)} type="button">2</button>
                                    </li>
                                    <li className="p-1">
                                        <button className={`d-flex align-items-center justify-content-center btn btn-light border-0 ${(step === 2) ? 'bg-primary text-white' : ''}`} style={styles.btnNavWizard} data-target={`#step-${2}`} onClick={(e) => tabBtn(e, 2)} type="button">3</button>
                                    </li>
                                    <li className="p-1">
                                        <button className={`d-flex align-items-center justify-content-center btn btn-light border-0 ${(step === 3) ? 'bg-primary text-white' : ''}`} style={styles.btnNavWizard} data-target={`#step-${3}`} onClick={(e) => tabBtn(e, 3)} type="button">4</button>
                                    </li>
                                    <li className="pl-1 pt-1 pb-1 pr-0">
                                        <button className={`d-flex align-items-center justify-content-center btn btn-light border-0 ${(step === 4) ? 'bg-primary text-white' : ''}`} style={styles.btnNavWizard} data-target={`#step-${4}`} onClick={(e) => tabBtn(e, 4)} type="button">5</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <h6 className="text-primary m-0 d-none d-md-block" onClick={logData}><b>Paso {step+1}:</b> {metaDataSteps[step] ? metaDataSteps[step].titleStep : ''}</h6>
                            {groupObjectId !== '' && <div className="d-flex justify-content-between mt-2 mt-sm-0 row">
                                <div className="text-primary col-5 text-center text-md-left">
                                    <b>Ciclo nuevo:</b> {data.solicitud.ciclo}
                                </div>
                                <div className="text-primary col-7 text-center text-md-left">
                                    <b>Id de grupo:</b> {groupOriginal.Sucursal.sigla}-{data.grupo.id_en_sucursal}
                                </div>
                            </div>}
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="tab-content">
                <fieldset id="step-0" aria-labelledby="nav-step-0" role="tabpanel" className={`tab-pane fade container ${(step === 0) ? 'show active' : ''}`} style={styles.fieldset}>
                    <h2>Foto del grupo</h2>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="rounded p-4 bg-light" style={{...styles.photoGroup, ...{
                                backgroundImage: `${data.grupo.foto_grupal_url ? `url("${data.grupo.foto_grupal_url}")` : `url(${require('../../../assets/static/placeholder-group.webp')})`}`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}}>
                                <label className={`btn btn-primary btn-sm`} htmlFor="foto_grupal_url">
                                    Hacer foto
                                    &nbsp;
                                    <i className="fas fa-camera"></i>
                                    <input type="file" className="d-none" id="foto_grupal_url" name="foto_grupal_url" onChange={handlePhotoGroup}/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <br/><br/>

                    <div className="forms-group">
                        <h2 className=" mt-4 mb-3">Nombre del nuevo grupo</h2>
                        <input className={`form-control form-control-lg  text-primary ${groupNameExist === false && 'is-valid'}`} value={data.grupo.nombre} placeholder="Nombre" name="nombre" label="Nombre del grupo" onChange={handleChangeGroupData} onBlur={() => handleValidName(data)} required={true}/>
                        <small className="form-text text-muted ">Se realizará una comprobación para determinar si el nombre ingresado en el campo no está presente en la base de datos de la sucursal actual.</small>
                    </div>
                    {groupNameExist === true && <div className="alert alert-warning mt-3 animate__animated animate__shakeX" role="alert">
                        <i className="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;&nbsp;El nombre ya está en uso.
                    </div>}

                    <div className="form-group mt-4">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="grupo_reducido" name="grupo_reducido" value={data.grupo.grupo_reducido} checked={data.grupo.grupo_reducido} onChange={handleChangeGroupData} />
                            <label className="form-check-label" htmlFor="grupo_reducido">
                                Permitir grupo reducido (menos de 3 clientas)
                            </label>
                        </div>
                    </div>

                </fieldset>
                <fieldset id="step-1" aria-labelledby="nav-step-1" role="tabpanel" className={`tab-pane fade ${(step === 1) ? 'show active' : ''}`} style={styles.fieldset}>
                    <div className="container">
                        <p>Haga clic en cada botón para cargar el documento correspondiente</p>
                        <p>Por favor, tome fotos de documentos con claridad. Use el flash si es necesario y encuadre adecuadamente. Recorte la imagen si es necesario.</p>
                        <div className="row">
                            <div className="col-md-4 mb-5">
                                <h6><b>Clientes:</b></h6>
                                <hr/>
                                <div className="nav flex-column nav-pills" id="v-pills-tab-documents" role="tablist" aria-orientation="vertical">
                                    {
                                        data.clients.map((client, index) => <button key={index} type="button" className={`btn nav-link text-uppercase ${index === 0 && 'active'}`} id={`v-pills-${index}-tab`} data-toggle="pill" data-target={`#v-pills-${index}`} aria-controls={`#v-pills-${index}-tab`} aria-selected="true">{`${client.nombre ? client.nombre : `Cliente ${index+1}`}`}</button>)
                                    }
                                </div>
                                
                                {groupObjectId ? <PartnerSearchPopup title="+" handleAddPartner={addClient} searchClient={handleAddPartner} /> : <button type="button" className="btn btn-secondary btn-block mt-3" onClick={addClient}>+</button>}
                                
                            </div>
                            <div className="col-md-8">
                                <div className="tab-content" id="v-pills-tabContent-documents">
                                    {
                                        data.clients.map((client, index) => <div key={index} className={`tab-pane fade show ${index === 0 && 'active'}`} id={`v-pills-${index}`} role="tabpanel" aria-labelledby={`v-pills-${index}-tab`}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h6 className="m-0"><b>Cargar los documentos para {`${client.nombre ? client.nombre : `el cliente ${index+1}`}`}</b></h6>
                                                <button type="button" className="btn btn-danger" onClick={() => removeClient(index)}>
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                            <hr/>
                                            <InputDocument label="INE (frente)" id={`credencial-elector-anverso-${index}`} name={`credencial-elector-anverso-${index}`} handleChange={info => setCredencialAnverso(info, index)} reset={() => resetDocument('ineFrontSide', index)} value={data.documents[index].ineFrontSide} />
                                            <InputDocument label="INE (reverso)" id={`credencial-elector-reverso-${index}`} name={`credencial-elector-reverso-${index}`} handleChange={info => setCredencialReverso(info, index)} reset={() => resetDocument('ineBackSide', index)} value={data.documents[index].ineBackSide} />
                                            <InputDocument label="Acta de nacimiento" id={`acta-nacimiento-${index}`} name={`acta-nacimiento-${index}`} handleChange={info => setActaNacimiento(info, index)} reset={() => resetDocument('birthCertificate', index)} value={data.documents[index].birthCertificate} />
                                            <InputDocument label="CURP" id={`curp-${index}`} name={`curp-${index}`} handleChange={info => setCurp(info, index)} reset={() => resetDocument('curp', index)} value={data.documents[index].curp} />
                                            <InputDocument label="Comprobante domicilio" id={`comprobante-domicilio-${index}`} name={`comprobante-domicilio-${index}`} handleChange={info => setComprobanteDomiilio(info, index)} reset={() => resetDocument('proofAddress', index)} value={data.documents[index].proofAddress} />

                                            <Signature index={index} handleChange={(imageUrl) => setSignature(imageUrl, index)} imageUrl={data.documents[index].signature.imageUrl}/>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset id="step-2" aria-labelledby="nav-step-2" role="tabpanel" className={`tab-pane fade ${(step === 2) ? 'show active' : ''}`} style={styles.fieldset}>
                    <nav className="sticky-top d-flex w-100" style={styles.clientTabs}>
                        <div className="container align-self-end overflow-hidden h-100 p-0 pl-3 pr-3">
                            <div className="nav nav-tabs border-0 d-flex flex-nowrap h-100" style={styles.navTabClients} id="nav-tab-clients" role="tablist">
                                {
                                    data.clients.map((client, index) => <a href="#" key={index} className={`btn btn-outline-primary rounded-0 border-0 border-bottom text-primary border-primary text-uppercase flex-shrink-0 h-100 d-flex align-items-end ${index === 0 ? 'active' : ''}`} id={`nav-tab-client-${index}`} data-toggle="tab" data-target={`#nav-content-client-${index}`} role="tab" aria-controls="nav-client" aria-selected="true">{client.nombre ? client.nombre : `Cliente ${index+1}`}</a>)
                                }
                                
                            </div>
                        </div>
                    </nav>
                    <div id="nav-tabContent-clients" className="tab-content">
                        {
                            data.clients.map((client, index) => <div key={index} className={`tab-pane fade show ${index === 0 ? 'active' : ''}`} id={`nav-content-client-${index}`} role="tabpanel" aria-labelledby="av-content-client"><Tabs index={index} client={client} handleChangeClient={handleChangeClient}/></div>)
                        }
                    </div>
                </fieldset>
                <fieldset id="step-3" aria-labelledby="nav-step-3" role="tabpanel" className={`tab-pane fade container ${(step === 3) ? 'show active' : ''}`} style={styles.fieldset}>
                    
                    <Amounts clients={data.clients} defaultAmounts={data.solicitud.amounts} handleChange={handleAmounts}/>

                    <br/><br/><br/><br/><br/>
                    
                    <ChargeFactor
                    handleChangeSolicitudData={handleChangeSolicitudData}
                    handleChangeDirectState={handleChangeDirectState}
                    handleChargeFactor={handleChargeFactor}
                    factor_cobro={data.solicitud.factor_cobro}
                    charge_factor_type={data.solicitud.charge_factor_type}
                    cantidad_pagos_semanales={data.solicitud.cantidad_pagos_semanales}
                    garantia={data.solicitud.garantia}
                    garantia_valor={data.solicitud.garantia_valor}
                    warranty={warranty}
                    ciclo={data.solicitud.ciclo}
                    prestamo_personalizado={data.solicitud.prestamo_personalizado}
                    prestamo_personalizado_periodicidad={data.solicitud.prestamo_personalizado_periodicidad}
                    prestamo_personalizado_interes={data.solicitud.prestamo_personalizado_interes}
                    prestamo_personalizado_cantidad_pagos={data.prestamo_personalizado_cantidad_pagos}
                    />
                    <br/><br/><br/><br/><br/>

                    <h2>Desembolso</h2>
                    <p>
                        Selecciona el modo de desembolso para tu préstamo. Si eliges la opción bancaria, proporciona los datos necesarios para realizar el desembolso bancario. Ten a mano la información bancaria requerida.
                    </p>
                    <br/>
                    <div className="row">
                        <div className="form-group col-md-5">
                            <label htmlFor="modo_desembolso">Modo de desembolso</label>
                            <select className="form-control text-uppercase" id="modo_desembolso" name="modo_desembolso" label="Modo de desembolso" value={data.grupo.modo_desembolso} onChange={handleChangeGroupData} required={true}>
                                <option value="">Elige una opción</option>
                                <option value="EFECTIVO">
                                    En efectivo
                                </option>
                                <option value="BANCO">
                                    Banco
                                </option>
                                {/* <option value="TARJETA_DEBITO">
                                    Tarjeta de débito
                                </option>
                                <option value="CLABE">
                                    Clabe interbancaria
                                </option> */}
                            </select>
                        </div>
                        <div className="col-md-7">
                            {data.grupo.modo_desembolso === 'BANCO' && <>
                                <label>Datos bancarios</label>
                                <legend>Desembolso bancario</legend>
                                {data.solicitud.cuentas_bancarias && data.solicitud.cuentas_bancarias.map((cuenta_bancaria, index) => <div className="mb-4 d-flex" key={index}>
                                    <div>
                                        <div className="btn-toolbar mr-2" role="toolbar" aria-label="Toolbar with button groups">
                                            <div className="btn-group-vertical" role="group" aria-label="Add/Remove">
                                                <button type="button" className="btn btn-secondary" onClick={() => addItem(index)} disabled={data.solicitud.cuentas_bancarias.length > 1}>
                                                    <i className="fas fa-plus"></i>
                                                </button>
                                                <button type="button" className="btn btn-secondary" onClick={() => removeItem(index)}>
                                                    <i className="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card bg-light w-100">
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="form-group">
                                                        <label htmlFor={`socio-item${index}`}>Clienta</label>
                                                        <select className="form-control text-uppercase" id={`socio-item${index}`} name="socio" label="Cliente asociado a la cuenta" value={cuenta_bancaria.clientIndex} required={true} onChange={(event) => handleChangeBanckPartner(event, index)}>
                                                            <option value="">Elige una opción</option>
                                                            {data.clients.map((socio, index) => <option value={index} key={index}>
                                                                {`${socio.nombre} ${socio.apellido_paterno} ${socio.apellido_materno}`}
                                                            </option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label htmlFor={`tipo-item${index}`}>Transferir a</label>
                                                        <select className="form-control text-uppercase" id={`tipo-item${index}`} name="tipo" label="Cuenta a transferir" value={cuenta_bancaria.tipo} required={true} onChange={(event) => handleChangeBanckPartner(event, index)}>
                                                            <option value="">Elige una opción</option>
                                                            <option value="tarjeta">
                                                                TARJETA DE DÉBITO
                                                            </option>
                                                            <option value="clabe">
                                                                CLABE INTERBANCARIA
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label htmlFor={`banco-item${index}`}>Banco</label>
                                                        <input type="text" className="form-control" id={`banco-item${index}`} name="banco" label="Banco a transferir" value={cuenta_bancaria.banco} required={true} onChange={(event) => handleChangeBanckPartner(event, index)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <div className="form-group">
                                                        <label htmlFor={`cuenta-item${index}`}>Número de {(cuenta_bancaria.tipo === 'tarjeta' && 'tarjeta') || (cuenta_bancaria.tipo === 'clabe' && 'clabe interbancaria')}</label>
                                                        <input type="text" className="form-control" id={`cuenta-item${index}`} name="cuenta" label="Número de cuenta" value={cuenta_bancaria.cuenta} required={true} onChange={(event) => handleChangeBanckPartner(event, index)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor={`titular-item${index}`}>Titular de la {(cuenta_bancaria.tipo === 'tarjeta' && 'tarjeta') || (cuenta_bancaria.tipo === 'clabe' && 'clabe interbancaria')}</label>
                                                <input type="text" className="form-control" id={`titular-item${index}`} name="titular" label="Titular de la cuenta" value={cuenta_bancaria.titular} required={true} onChange={(event) => handleChangeBanckPartner(event, index)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                                }
                            </>}
                        </div>
                    </div>
                </fieldset>
                <fieldset id="step-4" aria-labelledby="nav-step-4" role="tabpanel" className={`tab-pane fade container ${(step === 4) ? 'show active' : ''}`} style={styles.fieldset}>
                    <h2>Responsable del grupo</h2>
                    <div className="form-group">
                        <label htmlFor="responsable">Responsable</label>
                        <select className="form-control text-uppercase" id="responsable" name="responsable" label="Responsable del grupo" value={data.grupo.responsable} onChange={handleChangeGroupData} required={true}>
                            <option value="">Elige una opción</option>
                            {data.clients.map((socio, index) => <option value={index} key={index}>
                            {`${socio.nombre} ${socio.apellido_paterno} ${socio.apellido_materno}`}
                            </option>)}
                        </select>
                    </div>
                    <br/>
                    <h2>Lugar del desembolso</h2>
                    <div className="rounded bg-light">
                        {step === 4 && <MyMaps handleChange={setLugarDesemboldo} point={[data.solicitud.domicilio_geopoint.latitude, data.solicitud.domicilio_geopoint.longitude]}/>}
                    </div>
                    <div className="mt-3">
                        <label>Coordenadas en el mapa</label>
                        <div className="row">
                            <div className="col-6 col-md-4">
                                <div className="form-group">
                                    <label className="col-form-label">Latitud</label>
                                    <input type="text" name="desembolso_lat" label="Ubicación del desembolso" value={data.solicitud.domicilio_geopoint.latitude} onChange={handleChangeSolicitudData} className="form-control" placeholder="Longitud" required={true} />
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="form-group">
                                    <label className="col-form-label">Longitud</label>
                                    <input type="text" name="desembolso_lng" label="Ubicación del desembolso" value={data.solicitud.domicilio_geopoint.longitude} onChange={handleChangeSolicitudData} className="form-control" placeholder="Latitud" required={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className="fixed-bottom p-1" style={styles.wrapperNextBtn}>
                <div className="row justify-content-center">
                    {/* {console.log('errors --> ', errors)} */}
                    <div className="col-5 col-md-4">
                        <button type="button" id="next-btn" className="btn btn-primary btn-block" data-target={`#step-${step-1}`} onClick={prevBtn} disabled={step === 0}>Regresar</button>
                    </div>
                    <div className="col-5 col-md-4">
                        {step < 4 && <button type="button" id="next-btn" className="btn btn-primary btn-block" data-target={`#step-${step+1}`} onClick={nextBtn}>
                            Siguiente
                        </button>}
                        {step === 4 && <button type="button" id="submit" className="btn btn-primary btn-block" onClick={onSubmit}>
                            Finalizar
                        </button>}
                    </div>
                </div>
            </div>
        </form>
        <div className={`w-100 ${currentSection === 'saving' ? '' : 'd-none'}`}>
            <div className="container">
                <h2 className="text-primary text-center" style={styles.textSaving}>Guardando información…</h2>
            </div>
        </div>
        <div className={`w-100 ${currentSection === 'congratulation' ? '' : 'd-none'}`} style={styles.bgCongratulation}>
            <div className="container d-flex flex-column">
                <h1 className="text-center animate__animated animate__fadeInUp" style={styles.textCongratulation}>¡Felicidades!</h1>
                <div className="m-auto shadow animate__animated animate__fadeIn animate__delay-1s" style={{...styles.photoGroupSuccess,
                    backgroundImage: `${data.grupo.foto_grupal_url ? `url("${data.grupo.foto_grupal_url}")` : `url(${require('../../../assets/static/placeholder-group.webp')})`}`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}></div>
                {(groupObjectId === null && 
                <h3 className="text-primary text-center animate__animated animate__fadeInUp animate__delay-1s" style={styles.textGroupInfo}>El grupo <b className="text-uppercase">{data.grupo.nombre}</b> ha sido creado</h3>) || 
                <h3 className="text-primary text-center animate__animated animate__fadeInUp animate__delay-1s" style={styles.textGroupInfo}>El nuevo ciclo para <b className="text-uppercase">{data.grupo.nombre}</b> ha sido creado</h3>}
                
                {(groupObjectId === null && 
                <Link to="/grupos/grupos-por-guardar" className="btn btn-primary m-auto d-block col-md-3 animate__animated animate__fadeInUp animate__delay-2s">
                    Terminar
                </Link>) || 
                <Link to="/grupos" className="btn btn-primary m-auto d-block col-md-3 animate__animated animate__fadeInUp animate__delay-2s">
                    Terminar
                </Link>}
            </div>
        </div>
    </div>
});

export default GroupRegisterWizard;

const styles = {
    steps: {
        minHeight: 100,
        background: '#E3D3DE',
        top: 50
    },
    btnNavWizard: {
        width: 30,
        height: 30,
        borderRadius: 15
    },
    fieldset: {
        minHeight: HEIGHT - 150,
        paddingTop: 50,
        paddingBottom: 100
    },
    clientTabs: {
        marginTop: -50,
        background: '#dcc5d4',
        height: 50,
        top: 150,
    },
    navTabClients: {
        overflowX: 'auto',
        scrollBehavior: 'smooth',
    },
    photoGroup: {
        width: '100%',
        minHeight: 400,
    },
    wrapperNextBtn: {
        background: '#E3D3DE',
        zIndex: 0,
    },
    textSaving: {
        marginTop: HEIGHT * .2
    },
    textCongratulation: {
        marginTop: HEIGHT * .10,
        marginBottom: HEIGHT * .08,
        fontSize: WIDTH * (50 / WIDTH),
        color: '#9BC83F',
        fontWeight: 'bold',
    },
    photoGroupSuccess: {
        width: HEIGHT * 0.4,
        height: HEIGHT * 0.4,
        background: '#F1F1F1',
        borderRadius: '100%'
    },
    textGroupInfo: {
        marginTop: HEIGHT * 0.06,
        marginBottom: HEIGHT * 0.06
    },
    bgCongratulation: {
        backgroundImage: `url(${require('../../../assets/static/bg-congratulation-a.png')}), url(${require('../../../assets/static/bg-congratulation-b.png')})`,
        backgroundSize: '100% auto',
        backgroundPosition: 'center top, center bottom',
        backgroundRepeatY: 'no-repeat',
    },
}