export const getPersonalDataModel = () => {
    return {
        apellido_materno: '',
        apellido_paterno: '',
        clave_elector: '',
        conyugue_lugar_trabajo: '',
        conyugue_nombre: '',
        curp: '',
        domicilio_colonia: '',
        domicilio_cp: '',
        domicilio_direccion: '',
        domicilio_entidad: '',
        domicilio_numero_servicio_tipo: '',
        domicilio_numero_servicio: '',
        domicilio_poblacion: '',
        domicilio_tiempo_habitado: '',
        domicilio_tipo: '',
        estado_civil: '',
        nacimiento_entidad: '',
        nacimiento_fecha: '',
        nacimiento_pais: '',
        lugar_registro: '',
        nombre: '',
        numero_dependientes: '',
        numero_ocr: '',
        ocupacion: '',
        referencia_1_direccion: '',
        referencia_1_nombre_completo: '',
        referencia_1_telefono: '',
        referencia_2_direccion: '',
        referencia_2_nombre_completo: '',
        referencia_2_telefono: '',
        sexo: '',
        telefono: '',
        domicilio_geopoint: {
            __type: 'GeoPoint',
            latitude: 0,
            longitude: 0
        }
    }
}

export const getPledgeDataModel = () => {
    return {
        garantia_prendaria_1_nombre: '',
        garantia_prendaria_1_marca: '',
        garantia_prendaria_1_estado: '',
        garantia_prendaria_2_nombre: '',
        garantia_prendaria_2_marca: '',
        garantia_prendaria_2_estado: '',
        garantia_prendaria_3_nombre: '',
        garantia_prendaria_3_marca: '',
        garantia_prendaria_3_estado: '',
    }
}

export const getEconomicDataModel = () => {
    return {
        negocio_tipo: '',
        negocio_tiempo_activo: '',
        destino_credito: '',
        negocio_venta: 0,
        gastos_negocio: 0,
        sueldo_conyugue: 0,
        ingreso_prospera: 0,
        ingreso_beca: 0,
        ingreso_otros: 0,
        ingreso_otros_ventas_totales: 0,
        ingreso_otros_trabajo: 0,
        gastos_vivienda_renta_casa: 0,
        gastos_vivienda_telefono: 0,
        gastos_vivienda_agua: 0,
        gastos_vivienda_luz: 0,
        gastos_vivienda_transporte: 0,
        gastos_vivienda_otros: 0,
        gastos_prestamo_personal: 0,
        gastos_prestamo_financiera: 0,
        gastos_prestamo_banco: 0,
        gastos_prestamo_tdd: 0,
        gastos_prestamo_departamental: 0,
        gastos_prestamo_otro: 0,
        observacion_vivienda: '',
    }
}

export const getDocumentsModel = () => {
    return {
        // ine anverso
        ineFrontSide: {
            imageUrl: null,
            loaded: false,
        },
        // ine reverso
        ineBackSide: {
            imageUrl: null,
            loaded: false,
        },
        // acta de nacimiento
        birthCertificate: {
            imageUrl: null,
            loaded: false,
        },
        // curp
        curp: {
            imageUrl: null,
            loaded: false,
        },
        // comprobante de comicilio
        proofAddress: {
            imageUrl: null,
            loaded: false,
        },
        signature: {
            imageUrl: null,
            loaded: false,
        }
    }
}

export const getAmountModel = () => {
    return {
        monto_solicitado: 0
    }
}

export const getCuentasBancariasModel = () => {
    return {
        clientIndex: undefined,
        tipo: '',
        banco: '',
        cuenta: '',
        titular: ''
    }
}