import React, { Component } from 'react';
import Spinner from "../others/Spinner";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import formatString from '../../utilities/format-string';
import TableCountInfo from '../others/TableCountInfo';
import TablePagination from '../others/TablePagination';
import { NavLink } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import _ from 'underscore';
import ReportDayPreviewPopup from './ReportDayPreviewPopup';
import SelectBranchOffice from '../forms/SelectBranchOffice';
import user from '../../settings/user';

export default class ReportDayHistory extends Component {

    constructor(props){
        super(props)
        localStorage.removeItem('bitacora_report');

        this.handleFilter = this.handleFilter.bind(this);
    }

    state = {
        loading: false,
        items: [],
        total: 0,
        index: 0,
        limit: 100,

        dateStart: null,
        dateEnd: null,
        Sucursal: ''
    }

    componentDidMount = () => {
        this.getResources();
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        
        return true;
    }

    handleChangeDate = (date, name) => {
        this.setState({
            [name]: date
        })
    }

    getResources = (index = 0, where = {}) => {

        this.setState({
            loading: true
        })

        let rawParams = {
            limit: this.state.limit,
            skip: index,
            count: 1,
            include: 'Sucursal',
            order: '-fecha_consulta'
        }

        if (!_.isEmpty(where)) {
            where = JSON.stringify(where);
            rawParams.where = where;
        }

        const params = new URLSearchParams(rawParams);
        
        Rest.peticion(`${config.api_url}/classes/ReporteBitacora?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .then(async res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                
                this.setState({
                    items: res.results,
                    total: res.count,
                })
                
            }
        })
        .catch(error => {
            console.log(error);
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        })
        .finally(() => {
            this.setState({
                loading: false
            })
        });
    }

    handleFilter = (event) => {
        if (event) {
            event.preventDefault();
        }

        let fecha_consulta = {};

        let dateStart = null;
        if (this.state.dateStart) {
            dateStart = new Date(this.state.dateStart);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);

            fecha_consulta.$gte = {
                __type: 'Date',
                iso: dateStart.toISOString()
            }
        }
        
        let dateEnd = null;
        if (this.state.dateEnd) {
            dateEnd = new Date(this.state.dateEnd);
            dateEnd.setHours(0);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);

            fecha_consulta.$lte = {
                __type: 'Date',
                iso: dateEnd.toISOString()
            }
        }

        if (dateStart === null && dateEnd === null) {
            fecha_consulta = undefined;
        }

        let Sucursal = undefined;

        if (this.state.Sucursal) {
            Sucursal = {
                __type: 'Pointer',
                className: 'Sucursales',
                objectId: this.state.Sucursal
            }
        }

        const where = {
            fecha_consulta,
            Sucursal,
        };

        this.getResources(0, where)
    }

    render() {

        return (
        <>
            <Spinner loading={this.state.loading} />
            <h3 className="text-uppercase text-pink pt-3 pb-3">
                <i className="fas fa-money-bill-wave mr-2"></i>
                Bitácora de arqueos
            </h3>
            <div className="card">
                <div className="card-body">

                    <div className='card mb-4'>
                        <div className='card-header'>Filtro</div>
                        <form className='card-body' onSubmit={this.handleFilter}>
                            <div className='row align-items-end'>
                                <div className="col-md-4 form-group m-0 d-flex flex-column">
                                    <label htmlFor="fecha">Fecha</label>
                                    <div className="input-group">
                                        <div className="d-flex">
                                            <DatePicker
                                            className="form-control mr-1"
                                            locale="es"
                                            selected={this.state.dateStart}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={date => this.handleChangeDate(date, 'dateStart')}
                                            selectsStart
                                            startDate={this.state.dateStart}
                                            endDate={this.state.dateEnd}
                                            showDisabledMonthNavigation
                                            shouldCloseOnSelect={false}
                                            placeholderText="Inicio" />
                                            <DatePicker
                                            className="form-control ml-1"
                                            locale="es"
                                            selected={this.state.dateEnd}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={date => this.handleChangeDate(date, 'dateEnd')}
                                            selectsEnd
                                            startDate={this.state.dateStart}
                                            endDate={this.state.dateEnd}
                                            minDate={this.state.dateStart}
                                            showDisabledMonthNavigation
                                            shouldCloseOnSelect={false}
                                            placeholderText="Final" />
                                        </div>
                                        {/* <div className="form-check form-group mt-3 w-100">
                                            <input className="form-check-input" type="checkbox" defaultValue={false} value={this.state.fintrar_por_fecha_finalizacion} name="fintrar_por_fecha_finalizacion" onChange={this.handleChange} id="fintrar_por_fecha_finalizacion" />
                                            <label className="form-check-label" htmlFor="fintrar_por_fecha_finalizacion">
                                                Finalizado al
                                            </label>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-md-4 form-group m-0">
                                    <label htmlFor="Sucursal">Sucursal</label>
                                    
                                    <SelectBranchOffice id="Sucursal" name="Sucursal" value={this.state.Sucursal} onChange={this.handleChange} />
                                </div>
                                <div className='col-md-4'>
                                    <button type="submit" className='btn btn-primary btn-block'>Filtrar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                    <TableCountInfo total={this.state.total}/>
                    <br/>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Reporte al</th>
                                <th>Sucursal</th>
                                <th>Sobrante de día</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.items.map((item, index) => <tr key={index} id={`bitacora-${item.objectId}`}>
                                <td>{formatString.setLegibleDate(item.fecha_consulta.iso)}</td>
                                <td>{item.Sucursal.nombre}</td>
                                <td>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.sobrante_del_dia)}</td>
                                <td className='text-right p-0'>
                                    {(item.Sucursal.candado_edicion_bitacora !== true && (!_.isEmpty(user.rol.permisos.Reportes) && user.rol.permisos.Reportes.editar_bitacora)) && <NavLink to={`/bitacora/edicion/${item.objectId}`} className="btn btn-primary btn-sm">Editar</NavLink>}
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        {/* <button type="button" className="btn btn-primary btn-sm" onClick={() => this.printReport('bitacora-reporte', item.objectId)}>Imprimir</button> */}
                                        {/* <button type="button" onClick={(event) => this.printReportPreview(event, 'bitacora-reporte', item.objectId)} className="btn btn-primary btn-sm">Vista previa</button> */}                                        
                                        {/* <ReportDayPreviewPopup key={index} index={index} className="btn-sm" data={item} callback={() => this.reportPreview('bitacora-reporte', item)} callbackPrint={this.reportPrint}/> */}
                                        {/* <ReportDayPreviewPopup key={index} index={index} className="btn-sm" callback={() => this.reportPreview(item)} callbackPrint={this.reportPrint} callbackPreview={this.reportPrintPreview}/> */}
                                    </div>
                                    <NavLink to={`/bitacora/visualizar/${item.objectId}`} className="btn btn-link btn-lg" title="Ver">
                                        <i className="fas fa-chevron-right"></i>
                                    </NavLink>

                                </td>
                            </tr>)}
                        </tbody>
                    </table>

                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <TableCountInfo total={this.state.total}/>
                        </div>
                        <div className="col-md-6">
                            <TablePagination index={this.state.index} align="end" limit={this.state.limit} rows={this.state.total} getData={this.getResources} where={{}} />
                        </div>
                    </div>
                </div>
            </div>
            {!_.isEmpty(this.state.printData) && <iframe id="bitacora-reporte" name="bitacora-reporte" width="0" height="0" src={`${this.props.match.url}/bitacora-reporte?no_margin=true`}></iframe>}
            
        </>
        );
    }
}
