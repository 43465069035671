import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";

import './styles/css/custom.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import 'react-image-lightbox-rotate-latest/style.css';

import './index.css';
import App from './routes/App';
import * as serviceWorker from './serviceWorker';
import config from './settings/config';

import './Server/Server';

ReactDOM.render(
<Router>
    {config.environment === 'dev' && <span className="badge badge-pill badge-warning shadow" style={{top: 40, zIndex: 9999, position: 'fixed', left: '50%', transform: 'translateX(-50%)'}}>DESARROLLO</span>}
    {config.environment === 'qa' && <span className="badge badge-pill badge-warning shadow" style={{top: 40, zIndex: 9999, position: 'fixed', left: '50%', transform: 'translateX(-50%)'}}>AMBIENTE DE PRUEBAS</span>}
    <App />
</Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
