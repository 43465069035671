import React, { Component } from 'react';
import InputTel from './../forms/InputTel';
import SelectBranchOffice from './../forms/SelectBranchOffice';
import InputDate from './../forms/InputDate';
import entidadesMexico from './../../colecciones/entidades-mexico.json';
import paises from './../../colecciones/paises.json';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import _ from 'underscore';
import TextPhone from './../others/TextPhone';
import Parse from 'parse';
import formatString from '../../utilities/format-string';

class EmployeeForm extends Component {

    constructor(props){
        super(props);
        this.state = this.state_init;

        const d = new Date();
        this.fecha_registro = d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCP   = this.handleChangeCP.bind(this);
    }

    state_init = {
        cargo: '',
        Sucursal: '', // Pointer
        status: '',
        status_comentario: '',
        fecha_ingreso: '',

        apellido_materno: '',
        apellido_paterno: '',
        clave_elector: '',
        conyugue_lugar_trabajo: '',
        conyugue_nombre: '',
        curp: '',
        estado_civil: '',
        nacimiento_entidad: '',
        nacimiento_fecha: '',
        nacimiento_pais: '',
        nombre: '',
        numero_dependientes: 0,
        numero_ocr: '',
        // ocupacion: '',
        sexo: '',
        telefono: '',

        domicilio_colonia: '',
        domicilio_cp: '',
        domicilio_direccion: '',
        domicilio_entidad: '',
        domicilio_numero_servicio_tipo: '',
        domicilio_numero_servicio: '',
        domicilio_poblacion: '',
        domicilio_tiempo_habitado: '',
        domicilio_tipo: '',
    }

    fecha_registro = '';

    settlements = [];

    setRegisterDate = (fecha) => {
        if (fecha) {
            const d = new Date(fecha);
            this.fecha_registro = formatString.setLegibleDate(d.toISOString());
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // para resetear el formulario
        if (nextProps.resetform === true) {
            this.setState(this.state_init);
        }
        // sirve para la edición de socios
        if (!_.isEmpty(nextProps.data)) {
            // Establecemos la fecha de registro
            this.setRegisterDate(nextProps.data.fecha_registro);
            this.setState(nextProps.data);

            if (nextProps.readOnly === false)
            {
                this.getDataByCP(nextProps.data.domicilio_cp);
            }
        }
    }

    UNSAFE_componentWillUpdate = (nextProps, nextState) => {
        this.props.setData(nextState);
    }

    handleChangeCP = (event) => {
        
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });

        this.getDataByCP(value);

    }

    getDataByCP = async (cp) => {
        if (cp.length === 5) {
                
            this.settlements = [];
            this.setState({
                domicilio_poblacion: '',
                domicilio_entidad: ''
            });
            if (!_.isEmpty(cp)) {
                
                const params = new URLSearchParams({
                    where: JSON.stringify({
                        cp: cp
                    }),
                    limit: 1000,
                    count: 1
                });

                const solfinCP = await Rest.peticion(`${config.api_url}/classes/ColoniasBarrios?${params.toString()}`, {
                    method: 'GET'
                }).then(res => {return res.json()}).then(res => {return res}).catch(error => console.log(error));

                if (!solfinCP.code) {

                    if (solfinCP.results.length) {

                        _.each(_.pluck(solfinCP.results, 'nombre'), (asentamiento) => {
                            this.settlements.push( asentamiento.toUpperCase() );
                        });

                    }
                }

                const solfin = await Rest.peticion(`${config.api_url}/classes/CodigoPostal?${params.toString()}`, {
                    method: 'GET'
                }).then(res => {return res.json()}).then(res => {return res}).catch(error => console.log(error));
                
                if (!solfin.code) {
                    
                    if (solfin.results.length) {

                        _.each(solfin.results[0].asentamiento, (asentamiento) => {
                            
                            this.settlements.push( asentamiento.toUpperCase() );
                            
                        });
                        
                        this.settlements = this.settlements.sort();
                        this.settlements = _.uniq(this.settlements);

                                        
                        this.setState({
                            domicilio_poblacion: solfin.results[0].municipio,
                            domicilio_entidad: solfin.results[0].estado
                        });

                        return;
                    }
                }


                
                const sepomex = await fetch('https://api.copomex.com/query/info_cp/'+cp+'?type=simplified&token=bfc2983b-6538-4c83-9655-6e2db67d4090', {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {

                    if (res.error) {
                        return false
                    } else {
                        return res;
                    }

                })
                .catch(error => {
                    console.log(error);
                });

                if (sepomex) {
                    
                    _.each(sepomex.response.asentamiento, (asentamiento) => {
                        
                        this.settlements.push(asentamiento.toUpperCase());

                    });
                    
                    this.settlements = this.settlements.sort();
                    this.settlements = _.uniq(this.settlements);
                    
                    this.setState({
                        domicilio_poblacion: sepomex.response.municipio,
                        domicilio_entidad: sepomex.response.estado
                    });
 
                    Rest.peticion(`${config.api_url}/classes/CodigoPostal?`, {
                        method: 'POST',
                        body: JSON.stringify(sepomex.response),
                    }).catch(error => console.log(error));

                }
                
            }
        }
        
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        if (!_.isEmpty(value) && target.type === "number") {
            value = parseInt(value);
        }

        this.setState({
            [name]: value
        });
    }

    handleChangeSettlement = () => {
        this.settlements = [];
        
        this.setState({
            domicilio_colonia: ''
        });
    }

    setCargo = (cargo) => {
        let _cargo = '';
        switch (cargo) {

            case 'ANALISTA':
                _cargo = 'ANALISTA'
                break;
            case 'AUDITOR':
                _cargo = 'AUDITOR'
                break;
            case 'GERENCIA_REGIONAL':
                _cargo = 'GERENCIA REGIONAL'
                break;
            case 'GERENCIA':
                _cargo = 'GERENCIA'
                break;
            case 'JURIDICO':
                _cargo = 'JURIDICO'
                break;
            case 'PROMOTOR':
                _cargo = 'PROMOTOR'
                break;
            case 'PROMOTOR C.I.':
                _cargo = 'PROMOTOR C.I.'
                break;
            case 'SUPERVISOR':
                _cargo = 'SUPERVISOR'
                break;

            default:
                _cargo = cargo;
                break;
        }

        return _cargo;
    }

    setEstadoCivil = (value) => {
        let text = '';

        switch (value) {
            case 'SOLTERO':
                text = 'SOLTERA';
                break;
            case 'CASADO':
                text = 'CASADA';
                break;
            case 'DIVORCIADO':
                text = 'DIVORCIADA';
                break;
            case 'UNIÓN LIBRE':
                text = 'UNIÓN LIBRE';
                break;
            case 'VIUDO':
                text = 'VIUDA';
                break;
        
            default:
                text = value;
                break;
        }
        return text;
    }

    render() {
        return (
            <>
                <fieldset className="card mb-3">
                    <div className="card-body">
                        <legend className="card-title">Datos de empleo</legend>
                        <div className="row">
                            {!this.props.readOnly && <div className="form-group col-md-4">
                                <label htmlFor="fecha-registro">Fecha de registro</label>
                                <span className="form-control text-muted" id="fecha-registro">{this.fecha_registro}</span>
                            </div> || <div className="form-group col-md-4">
                                <small className="text-muted">Fecha de registro</small>
                                <p className="text-info">{this.fecha_registro}</p>
                            </div>}
                            <div className="col-md-8">
                                {!this.props.readOnly && <>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Status</label>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name="status" id="status_activo" value="ACTIVO" checked={this.state.status === 'ACTIVO'} onChange={this.handleChange} required={this.props.required} />
                                                <label className="form-check-label" htmlFor="status_activo">
                                                    Activo
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name="status" id="status_baja" value="BAJA" checked={this.state.status === 'BAJA'} onChange={this.handleChange} required={this.props.required} />
                                                <label className="form-check-label" htmlFor="status_baja">
                                                    Baja
                                                </label>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-8">
                                            <label htmlFor="status_comentario">Comentario del status
                                                
                                                {(this.state.status === 'BAJA') && <span>*</span>}
                                                
                                            </label>
                                            <textarea className="form-control text-uppercase" id="status_comentario" name="status_comentario" value={this.state.status_comentario} onChange={this.handleChange} rows="3" required={
                                                this.state.status === 'BAJA'
                                            } ></textarea>
                                            <br />
                                        </div>
                                    </div>
                                </> || <div className="row">
                                    <div className="col-md-3">
                                        <small className="text-muted">Status</small>
                                        <p>
                                            {this.state.status}
                                        </p>
                                    </div>
                                    <div className="col-md-9">
                                        <small className="text-muted">Comentario del status</small>
                                        <p>
                                            {this.state.status_comentario}
                                        </p>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-4">
                                <div className={this.props.readOnly?'d-none':''}>
                                    <label htmlFor="fecha_ingreso">Fecha de ingreso</label>
                                    <InputDate resetform={this.props.resetform} handleChange={this.handleChange} name="fecha_ingreso" hasDate={this.state.fecha_ingreso} required={this.props.required} />
                                </div>
                                <div className={!this.props.readOnly?'d-none':''}>
                                    <small className="text-muted">Fecha de ingreso</small>
                                    <p>
                                        {this.state.fecha_ingreso && <>{formatString.setLegibleDate(new Date(new Date(this.state.fecha_ingreso).getTime()+(1000*60*60*24)).toISOString())}</>}
                                    </p>
                                </div>
                            
                            </div>
                            {!this.props.readOnly && <div className="form-group col-md-4">
                                <label htmlFor="cargo">Cargo</label>
                                <select className="form-control" id="cargo" name="cargo" value={this.state.cargo} onChange={this.handleChange} required={true}>
                                    <option value="">Elija una opción</option>
                                    <option value="ANALISTA">ANALÍSTA</option>
                                    <option value="AUDITOR">AUDITOR</option>
                                    <option value="GERENCIA_REGIONAL">GERENCIA REGIONAL</option>
                                    <option value="GERENCIA">GERENCIA</option>
                                    <option value="JURIDICO">JURÍDICO</option>
                                    <option value="PROMOTOR">PROMOTOR</option>
                                    <option value="PROMOTOR C.I.">PROMOTOR C.I.</option>
                                    <option value="SUPERVISOR">SUPERVISOR</option>
                                </select>
                            </div> || <div className="form-group col-md-4">
                                <small className="text-muted">Cargo</small>
                                <p>{this.setCargo(this.state.cargo)}</p>
                            </div>}
                            <div className="form-group col-md-4">
                                {!this.props.readOnly && <label htmlFor="Sucursal">
                                    Sucursal
                                </label> || <small className="text-muted">
                                    Sucursal
                                </small>}
                                {Parse.User.current().toJSON().Sucursal && 
                                <SelectBranchOffice disabled={true} name="Sucursal" id="Sucursal" resetform={this.props.resetform} readOnly={this.props.readOnly} value={this.state.Sucursal} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleChange} required={true} /> || 
                                <SelectBranchOffice name="Sucursal" id="Sucursal" resetform={this.props.resetform} readOnly={this.props.readOnly} value={this.state.Sucursal} onChange={this.handleChange} required={true} />
                                }
                                
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="card mb-3">
                    <div className="card-body">
                        <legend className="card-title">Datos personales</legend>
                        <div className="row">
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="apellido_paterno">Apellido paterno</label>
                                        <input type="text" className="form-control text-uppercase" id="apellido_paterno" name="apellido_paterno" value={this.state.apellido_paterno} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Apellido paterno</small>
                                        <p>
                                            {this.state.apellido_paterno}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="apellido_materno">Apellido materno</label>
                                        <input type="text" className="form-control text-uppercase" id="apellido_materno" name="apellido_materno" value={this.state.apellido_materno} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Apellido materno</small>
                                        <p>
                                            {this.state.apellido_materno}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="nombre_completo">Nombre(s)</label>
                                        <input type="text" className="form-control text-uppercase" id="nombre_completo" name="nombre" value={this.state.nombre} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Nombre(s)</small>
                                        <p>
                                            {this.state.nombre}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="Sexo">Sexo</label>
                                        <div className="d-flex">
                                            <div className="form-check col">
                                                <input className="form-check-input" type="radio" id="sexo_hombre" name="sexo" value="HOMBRE" checked={this.state.sexo === 'HOMBRE'} onChange={this.handleChange} required={this.props.required} />
                                                <label className="form-check-label" htmlFor="sexo_hombre">
                                                Hombre
                                                </label>
                                            </div>
                                            <div className="form-check col">
                                                <input className="form-check-input" type="radio" id="sexo_mujer" name="sexo" value="MUJER" checked={this.state.sexo === 'MUJER'} onChange={this.handleChange} required={this.props.required} />
                                                <label className="form-check-label" htmlFor="sexo_mujer">
                                                Mujer
                                                </label>
                                            </div>
                                        </div>
                                    </> || <>
                                        <small className="text-muted">Sexo</small>
                                        <p>
                                            {this.state.sexo}
                                        </p>
                                    </>
                                }
                                        
                            </div>
                            <div className="col-12"></div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="clave_elector">Clave de elector</label>
                                        <input type="text" className="form-control text-uppercase" maxLength="18" minLength="18" id="clave_elector" name="clave_elector" value={this.state.clave_elector} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Clave de elector</small>
                                        <p>
                                            {this.state.clave_elector}
                                        </p>
                                    </>
                                }
                                
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="numero_ocr">Número de OCR</label>
                                        <input type="text" className="form-control text-uppercase" maxLength="13" minLength="13" id="numero_ocr" name="numero_ocr" value={this.state.numero_ocr} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Número de OCR</small>
                                        <p>
                                            {this.state.numero_ocr}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="pais_nacimiento">País de nacimiento</label>
                                        <select className="form-control text-uppercase" id="pais_nacimiento" name="nacimiento_pais" value={this.state.nacimiento_pais} onChange={this.handleChange} required={this.props.required} >
                                            <option value="" disabled>Elija una opción...</option>
                                            {paises.map((pais, index) => <option value={pais} key={index}>{pais}</option>)}
                                        </select> 
                                    </> || <>
                                        <small className="text-muted">País de nacimiento</small>
                                        <p>
                                            {this.state.nacimiento_pais}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="entidad_nacimiento">Entidad de nacimiento</label>
                                        {
                                            (
                                                this.state.nacimiento_pais === 'MÉXICO'
                                            ) && <select className="form-control text-uppercase" id="entidad_nacimiento" name="nacimiento_entidad" value={this.state.nacimiento_entidad} onChange={this.handleChange} required={this.props.required} >
                                                <option value="" disabled>Elija una opción...</option>
                                                {entidadesMexico.map((entidad, index) => <option value={entidad} key={index}>{entidad}</option>)}
                                            </select> ||
                                            <input type="text" className="form-control text-uppercase" id="entidad_nacimiento" name="nacimiento_entidad" value={this.state.nacimiento_entidad} onChange={this.handleChange} required={this.props.required} />
                                        }
                                    </> || <>
                                        <small className="text-muted">Entidad de nacimiento</small>
                                        <p>
                                            {this.state.nacimiento_entidad}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                            
                                <div className={this.props.readOnly?'d-none':''}>
                                    <label htmlFor="fecha_nacimiento">Fecha de nacimiento</label>
                                    <InputDate resetform={this.props.resetform} handleChange={this.handleChange} name="nacimiento_fecha" hasDate={this.state.nacimiento_fecha} required={this.props.required} />
                                </div>
                                <div className={!this.props.readOnly?'d-none':''}>
                                    <small className="text-muted">Fecha de nacimiento</small>
                                    <p>
                                        {this.state.nacimiento_fecha && <>{formatString.setLegibleDate(new Date(new Date(this.state.nacimiento_fecha).getTime()+(1000*60*60*24)).toISOString())}</>}
                                    </p>
                                </div>
                            
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="curp">CURP</label>
                                        <input type="text" className="form-control text-uppercase" id="curp" name="curp" value={this.state.curp} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">CURP</small>
                                        <p>
                                            {this.state.curp}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="estado_civil">Estado civil</label>
                                        <select className="form-control text-uppercase" id="estado_civil" name="estado_civil" value={this.state.estado_civil} onChange={this.handleChange} required={this.props.required} >
                                            <option value="" disabled>Elija una opción...</option>
                                            <option value="SOLTERO">SOLTERA</option>
                                            <option value="CASADO">CASADA</option>
                                            <option value="DIVORCIADO">DIVORCIADA</option>
                                            <option value="UNIÓN LIBRE">UNIÓN LIBRE</option>
                                            <option value="VIUDO">VIUDA</option>
                                        </select>
                                    </> || <>
                                        <small className="text-muted">Estado civil</small>
                                        <p>
                                            {this.setEstadoCivil(this.state.estado_civil)}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="numero_dependientes">Número de dependientes</label>
                                        <input type="number" className="form-control" id="numero_dependientes" name="numero_dependientes" value={this.state.numero_dependientes} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Número de dependientes</small>
                                        <p>
                                            {this.state.numero_dependientes}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4 mb-3">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="telefono">Teléfono</label>
                                        <InputTel id="telefono" name="telefono" value={this.state.telefono} handleChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Teléfono</small>
                                        <p>
                                            <TextPhone phone={this.state.telefono} />
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="col-12"></div>
                            <div className="form-group col-md-8">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="conyugue_nombre">Nombre del conyugue</label>
                                        <input type="text" 
                                        className="form-control text-uppercase" 
                                        id="conyugue_nombre" 
                                        name="conyugue_nombre" 
                                        value={this.state.conyugue_nombre} onChange={this.handleChange} 
                                        disabled={(
                                            this.state.estado_civil === 'SOLTERO' || 
                                            this.state.estado_civil === 'DIVORCIADO' || 
                                            this.state.estado_civil === 'VIUDO'
                                        )} required={(
                                            this.state.estado_civil === 'CASADO' ||
                                            this.state.estado_civil === 'UNIÓN LIBRE'
                                        )} />
                                    </> || <>
                                        <small className="text-muted">Nombre del conyugue</small>
                                        <p>
                                            {this.state.conyugue_nombre}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="conyugue_lugar_trabajo">Lugar de trabajo del conyugue</label>
                                        <input type="text" 
                                        className="form-control text-uppercase" 
                                        id="conyugue_lugar_trabajo" 
                                        name="conyugue_lugar_trabajo" 
                                        value={this.state.conyugue_lugar_trabajo} 
                                        onChange={this.handleChange} 
                                        disabled={(
                                            this.state.estado_civil === 'SOLTERO' || 
                                            this.state.estado_civil === 'DIVORCIADO' || 
                                            this.state.estado_civil === 'VIUDO'
                                        )} required={(
                                            this.state.estado_civil === 'CASADO' ||
                                            this.state.estado_civil === 'UNIÓN LIBRE'
                                        )} />
                                    </> || <>
                                        <small className="text-muted">Lugar de trabajo del conyugue</small>
                                        <p>
                                            {this.state.conyugue_lugar_trabajo}
                                        </p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </fieldset>
                
                <fieldset className="card mb-3">
                    <div className="card-body">
                        <legend className="card-title">Datos del domicilio</legend>
                        <div className="row">
                            <div className="form-group col-md-8">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="domicilio_direccion">Dirección</label>
                                        <input type="text" className="form-control text-uppercase" id="domicilio_direccion" name="domicilio_direccion" value={this.state.domicilio_direccion} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Dirección</small>
                                        <p>
                                            {this.state.domicilio_direccion}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="domicilio_cp">C.P.</label>
                                        <input type="text" className="form-control text-uppercase" id="cp" name="domicilio_cp" value={this.state.domicilio_cp} onChange={this.handleChangeCP} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">C.P.</small>
                                        <p>
                                            {this.state.domicilio_cp}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="domicilio_entidad">Estado</label>
                                        <input type="text" className="form-control text-uppercase" id="domicilio_entidad" name="domicilio_entidad" value={this.state.domicilio_entidad} onChange={this.handleChange} required={this.props.required} />    
                                    </> || <>
                                        <small className="text-muted">Estado</small>
                                        <p>
                                            {this.state.domicilio_entidad}
                                        </p>
                                    </>
                                }
                                
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="domicilio_poblacion">Población</label>
                                        <input type="text" className="form-control text-uppercase" id="domicilio_poblacion" name="domicilio_poblacion" value={this.state.domicilio_poblacion} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Población</small>
                                        <p>
                                            {this.state.domicilio_poblacion}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="domicilio_colonia">Fraccionamiento o Colonia</label>
                                        {
                                            (this.settlements.length && <div className="input-group">
                                                <select className="custom-select text-uppercase" id="domicilio_colonia" name="domicilio_colonia" value={this.state.domicilio_colonia} onChange={this.handleChange} required={this.props.required} >
                                                    <option value="" disabled>Elija una opción...</option>
                                                    {this.settlements.map((asentamiento, index) => <option value={asentamiento} key={index}>{asentamiento}</option>)}
                                                </select>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" onClick={this.handleChangeSettlement}>Otro</button>
                                                </div>
                                            </div>) || (
                                                <>
                                                    <input type="text" className="form-control text-uppercase" id="domicilio_colonia" name="domicilio_colonia" value={this.state.domicilio_colonia} onChange={this.handleChange} required={this.props.required} />
                                                    <small className="form-text">Escriba un C.P. para obtener una lista</small>
                                                </>
                                            )
                                        }
                                    </> || <>
                                        <small className="text-muted">Fraccionamiento o Colonia</small>
                                        <p>
                                            {this.state.domicilio_colonia}
                                        </p>
                                    </>
                                }
                                
                                
                            </div>
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="domicilio_tiempo_habitado">Tiempo habitado en ella</label>
                                        <input type="text" className="form-control text-uppercase" id="domicilio_tiempo_habitado" name="domicilio_tiempo_habitado" value={this.state.domicilio_tiempo_habitado} onChange={this.handleChange} required={this.props.required} />
                                    </> || <>
                                        <small className="text-muted">Tiempo habitado en ella</small>
                                        <p>
                                            {this.state.domicilio_tiempo_habitado}
                                        </p>
                                    </>
                                }
                            </div>
                            
                            <div className="form-group col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="Sexo">Domicilio</label>
                                        <div className="d-flex flex-column flex-sm-row">
                                            <div className="form-check col">
                                                <input className="form-check-input" type="radio" name="domicilio_tipo" id="domicilio_propio" value="PROPIO" checked={this.state.domicilio_tipo === 'PROPIO'} onChange={this.handleChange} required={this.props.required} />
                                                <label className="form-check-label" htmlFor="domicilio_propio">
                                                Propio
                                                </label>
                                            </div>
                                            <div className="form-check col">
                                                <input className="form-check-input" type="radio" name="domicilio_tipo" id="domicilio_rentado" value="RENTADO" checked={this.state.domicilio_tipo === 'RENTADO'} onChange={this.handleChange} required={this.props.required} />
                                                <label className="form-check-label" htmlFor="domicilio_rentado">
                                                Rentado
                                                </label>
                                            </div>
                                            <div className="form-check col">
                                                <input className="form-check-input" type="radio" name="domicilio_tipo" id="domicilio_familiar" value="FAMILIAR" checked={this.state.domicilio_tipo === 'FAMILIAR'} onChange={this.handleChange} required={this.props.required} />
                                                <label className="form-check-label" htmlFor="domicilio_familiar">
                                                Familiar
                                                </label>
                                            </div>
                                        </div>
                                    </> || <>
                                        <small className="text-muted">Domicilio</small>
                                        <p>
                                            {this.state.domicilio_tipo}
                                        </p>
                                    </>
                                }
                            </div>

                            <div className="col-md-4">
                                
                                {
                                    !this.props.readOnly && <div className="row">
                                        <div className="form-group col-7">
                                            <label htmlFor="domicilio_numero_servicio">Número de servicio</label>
                                            <input type="text" className="form-control text-uppercase"  id="domicilio_numero_servicio" name="domicilio_numero_servicio" value={this.state.domicilio_numero_servicio} onChange={this.handleChange} required={this.props.required} />
                                        </div>
                                        <div className="form-group col-5 pl-0">
                                            <label htmlFor="domicilio_numero_servicio_tipo">Tipo servicio</label>
                                            <select className="custom-select" id="domicilio_numero_servicio_tipo" name="domicilio_numero_servicio_tipo" value={this.state.domicilio_numero_servicio_tipo} onChange={this.handleChange} required={this.props.required}>
                                                <option value="" disabled>Elija una opción...</option>
                                                <option value="AGUA">Agua</option>
                                                <option value="CABLE">Cable</option>
                                                <option value="LUZ">Luz</option>
                                            </select>
                                        </div>
                                    </div> || <div className="row">
                                        <div className="form-group col-7">
                                            <small className="text-muted">Número de servicio</small>
                                            <p>
                                                {this.state.domicilio_numero_servicio}
                                            </p>
                                        </div>
                                        <div className="form-group col-5 pl-0">
                                            <small className="text-muted">Tipo servicio</small>
                                            <p>
                                                {this.state.domicilio_numero_servicio_tipo}
                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </fieldset>
                {!this.props.readOnly && <button type="submit" className="btn btn-primary btn-lg mr-2 btns-form-submit" data-loading-text="Loading...">
                    Guardar
                </button>}
            </>
        );
    }
}

export default EmployeeForm;