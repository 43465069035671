import React, { Component } from 'react';
import Header from './navigation/Header';
import Nav from './navigation/Nav';
import NotFound from './pages/NotFound';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './../App.css';
import './../styles/css/partner-form.css';
import _routes from './navigation/routes';
import BackBtn from './navigation/BackBtn';

const location = {
    pathname: '/escritorio'
}


class NF extends Component {
    render() {
        return (<div className="h-100 d-flex align-items-center justify-content-center"><NotFound/></div>);
    }
}

function RouteWithSubRoutes(route) {

    return (
      <Route
        path={route.path}
        render={props => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    );
  }

  export default function RouteConfigExample() {
    return (
        <div id="main">
            <div className="container-fluid min-vh-100 d-flex flex-column justify-content-between">
                <Header />
                <div className="row min-vh-100">
                    <Nav />
                    <div className="col -pt-3 -pb-3 d-flex flex-column">
                        {/* <BackBtn/> */}
                        <Switch>
                            {_routes.map((route, i) => (
                                <RouteWithSubRoutes key={i} {...route} />
                            ))}

                            <Route exact path="/" component={
                                () => {
                                    window.location = '/escritorio'
                                }
                            } />
                            <Route component={NF} />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
}