import React, {Component} from "react";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import _ from 'underscore';
import Spinner from "../others/Spinner";
import TableCountInfo from "../others/TableCountInfo";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import formatString from '../../utilities/format-string';
import moment from "moment";
import PoliciesSearchForm from "../forms/PoliciesSearchForm";
import TablePagination from "../others/TablePagination";
import ContalinkForm from "./sendContalinkForm";
moment.locale('es');
class PoliciesList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            collection: [],
            index: 0,
            total: 0,
            policyId: ''
        }
    }

    limit = 50;
    rows = 0;
    where = {};

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }
    
    componentDidMount = () => {
        this.getResource();
    }

    getResource = (index = 0, _where = {}) =>{

        this.handleSpinner();
        this.where = _where;

        this.setState(({
            index: index
        }));

        let params;
        let where = {};

        where = JSON.stringify(_where);//si aplicamos filtros viene en _where

        params = new URLSearchParams({
            keys: 'fecha_emision,fecha,numero,concepto,cargos,abonos,periodo,ejercicio,status,envios,activo,type_transaction.tipo,type_transaction.descripcion,enviosContalink',
            limit: this.limit,
            count: 1,
            order: '-createdAt',
            skip: index,
            where,
            include: 'TypesTransactions'

        });

        // Rest.peticion(`${config.api_url}/classes/GLPoliza?${params.toString()}`, {
        Rest.peticion(`${config.api_url}/classes/GLPoliza?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {

            this.handleSpinner();
            return res.json();

        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                // console.log("Respuesta => ", res.results)
                
                this.rows = res.count;
                this.setState({
                    collection: _.map(res.results, (item, index) => { 
                        item.index = index + 1 + parseInt(this.state.index);
                        return item; 
                    }),
                    total: res.count
                });

            }
            
        })
        .catch(error => {
            console.log("Error: ", error)
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
            
        });


        
    }

    setDate = (date) => {
        const d = formatString.setLegibleDate(date.iso);
        return  d;
    }

    //metodo para ejecutar los filtros y busqueda 
    handleFilter = (where) => {
        this.rows = 0;
        this.getResource(0, where);
    }

    fuctionDelete = async(className, id) => {
        
        let requests = []

        requests.push({
            method: 'DELETE',
            path: `${config.api_path2}/classes/${className}/${id}`
        });


        const data = {
            requests: requests
        }

        return Rest.peticion(`${config.api_url}/batch`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
    }

    deletePolicie = async (id_poliza) => {

        const confirm = window.confirm('¿Está seguro de eliminar la póliza y sus detalles? \r Esta acción no es irreversible');
        if (confirm) {

            this.handleSpinner();

            let params = new URLSearchParams({
                limit: 100000,
                where: JSON.stringify({
                    poliza_id: id_poliza
                })
            })
    
            Rest.peticion(`${config.api_url}/classes/GLPolizaDetalle?${params.toString()}`, {
                method: 'GET',
            }).then(response =>{
                return response.json();
            }).then( async(res) => {

                // console.log("Detalles de póliza => ", res.results);

                //procedemos a eliminar los detalles 
                // if(res.results.length > 0){
                    
                //     for(let i=0; i<res.results.length; i++){

                //         const detalle = res.results[i];

                //         let requests = [];
                //         requests.push({
                //             method: 'DELETE',
                //             path: `${config.api_path2}/classes/GLPolizaDetalle/${detalle.objectId}`
                //         });

                //         const data = {
                //             requests: requests
                //         }

                //         Rest.peticion(`${config.api_url}/batch`, {
                //             method: 'POST',
                //             body: JSON.stringify(data)
                //         }).then(result => {
                //             // console.log("Result =>", result);
                //             // console.log("Detalle eliminado")
                //         }).catch(error => {
                //             console.log('Ocurrio un error al eliminar los detalles.');
                //         });
                //     }
                // }

                // //Eliminamos la póliza
                // let requests = []

                // requests.push({
                //     method: 'DELETE',
                //     path: `${config.api_path2}/classes/GLPoliza/${id_poliza}`
                // });


                // const data = {
                //     requests: requests
                // }

                // Rest.peticion(`${config.api_url}/batch`, {
                //     method: 'POST',
                //     body: JSON.stringify(data)
                // }).then(res => {
                //     if (res.code) {
                //         alert('Error');
                //     } else {

                //         // console.log("Res poliza => ", res)
                //         this.getResource();
                //         this.handleSpinner();

                //         alert('Póliza eliminada exitosamente');
                //     }
                    
                // }).catch(error => {

                //     this.handleSpinner();
                //     alert('Ha ocurrido un error inesperado al eliminar la póliza. Intente más tarde.');
                // });

                if(res.results.length > 0){
                    for(let i=0; i<res.results.length; i++){

                        const detalle = res.results[i];
                        await this.fuctionDelete('GLPolizaDetalle', detalle.objectId);
                        // console.log(eliminarDetalle);
                    }
                }

                //eliminamos la póliza
                const eliminarPoliza = await this.fuctionDelete('GLPoliza', id_poliza);
                // console.log(eliminarPoliza[0]);

                if (eliminarPoliza[0].error) {
                    alert('Ocurrió un error al eliminar la póliza. Intente más tarde o cantacte a soporte');
                    this.handleSpinner();

                } else {

                    this.getResource();
                    this.handleSpinner();

                    alert('Póliza eliminada exitosamente');
                }

            }).catch(err =>{
                console.log("Error => ", err)
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
                this.handleSpinner();
            })

        }
    }

    render () {

        return (
            <>

                <Spinner loading={this.state.loading}/>

                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-file-alt mr-2"></i>
                    Listado de Pólizas
                </h3>


                <div className="card">
                    <div className="card-body">

                        <div className="row">
                            <div className="col">
                                <PoliciesSearchForm handleFilter={this.handleFilter}/>
                            </div>
                        </div>
                        <br />

                        <TableCountInfo total={this.state.total}/>
                        <div className="table-responsive">
                            <table className="table table-hover">

                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Concepto</th>
                                        <th scope="col">Tipo de transacción</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Cargos</th>
                                        <th scope="col">Abonos</th>
                                        <th scope="col text-center">No. de póliza</th>
                                        <th scope="col text-center">Periodo</th>
                                        <th scope="col text-center">Ejercicio</th>
                                        <th scope="col">Status</th>
                                        <th scope="col text-center">Envíos</th>
                                        <th scope="col">Detalles de la póliza</th>
                                        <th scope="col">Acciones</th>
                                        {/* <th scope="col"></th> */}
                                    </tr>
                                </thead>

                                <tbody className="">
                                    {
                                        this.state.collection.length ? this.state.collection.map((item, idx) => 
                                            <tr key={idx}>
                                                <td>{item.index}</td>
                                                <td className="td-responsive-text">{item.type_transaction.tipo}</td>
                                                <td className="td-responsive-text">{item.type_transaction.descripcion}</td>
                                                <td className="td-responsive-text">{moment.utc(item.fecha.iso).format("DD/MM/YYYY")}</td>
                                                <td className="td-responsive-text">{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.cargos )}</td>
                                                <td className="td-responsive-text">{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.abonos )}</td>
                                                <td className="td-responsive-text text-center">{item.numero.toString().padStart(2, '0')}</td>
                                                <td className="td-responsive-text text-center">{item.periodo.toString().padStart(2, '0')}</td>
                                                <td className="td-responsive-text text-center">{item.ejercicio}</td>
                                                <td className="td-responsive-text">{item.status}</td>
                                                {/* <td className="td-responsive-text text-center">{item.envios}</td> */}
                                                <td className="td-responsive-text text-center">{item.enviosContalink ? item.enviosContalink.length : 0}</td>
                                                <td className="td-responsive-text text-center">
                                                    <Link to={`/polizaDetalles/${item.objectId}/${item.numero}/${item.periodo}`} title="Ver detalles" className="btn btn-link">
                                                        {/* <i className="fas fa-chevron-right"></i> */}
                                                        Ir a detalles
                                                    </Link>
                                                </td>
                                                <td className="td-responsive-text text-center">
                                                    <button className="btn btn-link btn-lg data-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fas fa-bars"></i>
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-left">
                                                        {/* <li>
                                                            <Link to={`/polizaDetalles/${item.objectId}/${item.numero}`} title="Ver detalles" className="btn btn-link btn-md">
                                                                <i className="fas fa-chevron-right mr-2"></i>
                                                                <span>Ver detalles</span>
                                                            </Link>
                                                        </li> */}
                                                        {/* <li>
                                                            <button type="button" className="btn btn-link btn-md" data-toggle="modal" data-target="#modal-sendContalink-form" data-backdrop="static" data-keyboard="false" onClick={() => this.setState({policyId: item.objectId}) }>
                                                                <i className="fas fa-paper-plane mr-2"></i>
                                                                <span>Enviar a ContaLink</span>
                                                            </button>
                                                        </li> */}
                                                        <li>
                                                            {/* <Link to={`#`} title="Eliminar" className="btn btn-link btn-md">
                                                                <i className="fas fa-trash-alt mr-2"></i>
                                                                <span>Eliminar</span>
                                                            </Link> */}
                                                            <button className="btn btn-link btn-md" onClick={() => this.deletePolicie(item.objectId)}>
                                                                <i className="fas fa-trash-alt mr-2"></i>
                                                                <span>Eliminar</span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        ) :
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="alert alert-warning m-0" role="alert">
                                                    No se hallaron registros
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>

                            </table>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where}/>
                            </div>
                        </div>

                        <ContalinkForm
                            id="modal-sendContalink-form"
                            idPolicy= {this.state.policyId}
                        />

                    </div>
                </div> 
            </>
        )
    }
}

export default PoliciesList;