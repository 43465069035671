import React, { Component } from 'react';

class TablePagination extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0
        }
        this.setPage = this.setPage.bind(this);
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.rows === 0) {
            this.setState({
                page: 0
            })
        }
    }

    setPage = (event) => {
            
        let target = event.target;
        let value = target.value;

        let name = target.name;
    
        this.setState({
            [name]: value
        });
        
        this.props.getData(value, this.props.where);
    }

    setPages = () => {
        let btns = [];
        for (let count = 0; count < Math.ceil(this.props.rows / this.props.limit); count++) {

            btns.push(<option value={count*this.props.limit} key={count}>
                {count+1}
            </option>);
            
        }

        return <select className="form-control" name="page" value={this.state.page} onChange={this.setPage} style={{borderRadius: 0, marginLeft: -1}}>
            {btns}
        </select>
    }

    getData = (index) => {
        this.setState({
            page: index
        })
        this.props.getData(index, this.props.where)
    }

    render() {
        const total_pages = Math.ceil(this.props.rows / this.props.limit);
        return (
            <>
                {
                    this.props.rows > 0 && this.props.rows > this.props.limit && <>
                        <nav className="mb-3 ml-0 mr-0 position-relative float-right text-center" aria-label="navigation table" style={{zIndex:0}}>
                            <small className="text-muted">Paginación</small>
                            <div className="pagination">
                                <li className={`page-item ${(parseInt(this.state.page) === 0) && 'disabled'}`}>
                                    <button className="page-link" aria-label="Previous" onClick={() => this.getData(parseInt(this.state.page) - this.props.limit)}>
                                        <i className="fas fa-angle-double-left"></i>
                                    </button>
                                </li>
                                
                                <li className="page-item">
                                {this.setPages()}
                                </li>
                                
                                <li  style={{marginLeft: -1}} className={`page-item ${((parseInt(this.state.page)+this.props.limit) >= this.props.rows) && 'disabled'}`}>
                                    <button className="page-link" aria-label="Next" onClick={() => this.getData(parseInt(this.state.page) + this.props.limit)}>
                                        <i className="fas fa-angle-double-right"></i>
                                    </button>
                                </li>
                            </div>
                            <small className="text-muted">{total_pages} {total_pages === 1?'página':'páginas'}</small>
                        </nav>
                    </>
                }
            </>
        );
    }
}

export default TablePagination;