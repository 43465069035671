
import React from 'react';
import textUtils from '../../utilities/format-string';
import _ from 'underscore';
import SearchForm from './../forms/SearchForm';
import SelectBranchOffice from './../forms/SelectBranchOffice';
import SelectEmpleyee from '../forms/SelectEmpleyee';
import Parse from 'parse';

class GroupSearch extends SearchForm {
    state = {
        searchValue: '',
        searchKey: '',
        lugar_registro: Parse.User.current().toJSON().Sucursal ? Parse.User.current().toJSON().Sucursal.objectId : '',
        status: '',
        promotor: ''
    }
    handleSearchSubmit = (event) => {
        if (event) event.preventDefault();

        let searchValue = textUtils.setPlainText(this.state.searchValue);
        searchValue = searchValue.toLowerCase();
        searchValue = searchValue.replace(' ','');

        let where = {};

        where = {
            id_busqueda: !_.isEmpty(searchValue) ? {
                $regex: searchValue
            } : undefined,
            Grupo: (
                !_.isEmpty(this.state.status)||
                !_.isEmpty(this.state.lugar_registro)||
                !_.isEmpty(this.state.promotor)
            ) ? {
                $select: {
                    query: {
                        className: 'Grupos',
                        where: {
                            status: !_.isEmpty(this.state.status) ? this.state.status : undefined,
                            Sucursal: !_.isEmpty(this.state.lugar_registro) ? {
                                __type: 'Pointer',
                                className: 'Sucursales',
                                objectId: this.state.lugar_registro
                            }: undefined,
                            promotor: !_.isEmpty(this.state.promotor) ? {
                                __type: 'Pointer',
                                className: 'Empleados',
                                objectId: this.state.promotor
                            }: undefined,
                            Empleado: {
                                $exists: false
                            },
                            Solicitudes: {
                                $inQuery: {
                                    className: 'Solicitudes',
                                    where: {
                                        prestamo_personalizado_interes: {
                                            $exists: false
                                        },
                                        prestamo_personalizado: {
                                            $ne: true
                                        },
                                        factor_cobro: {
                                            $exists: true
                                        }, 
         
                                    }
                                }
                            }
                        }
                    },
                    key: 'objectId'
                }
            } : undefined
        };
        
        this.props.handleFilter(where);
    }

    resetSearchForm = () => {
        this.setState({
            searchValue: '',
            searchKey: '',
            lugar_registro: '',
            status: '',
            promotor: ''
        });
        this.props.handleFilter();
    }

    render() {
        return (
            <>
            <div className="card">
                <div className="card-header">
                    
                        <i className="fas fa-filter mr-2"></i>
                        Filtro
                    
                </div>
                <div className="card-body">
                    
                    <form className="row" onSubmit={this.handleSearchSubmit}>
                        <div className="col-md-12">
                            <div className="row align-items-start">
                                <div className="col-md-3">
                                    <label className="sr-only" htmlFor="search-input">Nombre del grupo</label>
                                    <div className="input-group mb-3 mb-md-0">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>
                                        <span className={`btn ${!this.state.searchValue.length && 'd-none'}`} onClick={() => this.resetSearchForm()} style={styles.searchInputClose}>
                                            <i className="fas fa-times"></i>
                                        </span>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="search-input" 
                                        placeholder="Nombre del grupo"
                                        style={styles.searchInput}
                                        onChange={this.handleSearchChange}
                                        value={this.state.searchValue}
                                        name="searchValue" />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                {Parse.User.current().toJSON().Sucursal && <SelectBranchOffice name="lugar_registro" placeholder="Lugar de registro" id="lugar_registro" value={Parse.User.current().toJSON().Sucursal.objectId} disabled={Parse.User.current().toJSON().Sucursal} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleSearchChange} /> ||
                                    <SelectBranchOffice name="lugar_registro" placeholder="Lugar de registro" id="lugar_registro" value={this.state.lugar_registro} onChange={this.handleSearchChange} />}
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                    <select className="form-control" name="status" value={this.state.status} onChange={this.handleSearchChange}>
                                        {/* <optgroup label="---"> */}
                                            <option value="">STATUS</option>
                                            <option value="activo">ACTIVO</option>
                                            <option value="autorizacion">AUTORIZACIÓN</option>
                                            <option value="autorizado">AUTORIZADO</option>
                                            <option value="baja">BAJA</option>
                                            <option value="rechazado">RECHAZADO</option>
                                            <option value="morosidad">MOROSIDAD</option>
                                        {/* </optgroup> */}
                                        {/* <optgroup label="STATUS DE MORA">
                                            <option value="fnc_mora">MORA</option>
                                            <option value="fnc_cartera_vencida">CARTERA VENCIDA</option>
                                            <option value="fnc_cartera_incobrable">CARTERA INCOBRABLE</option>
                                            <option value="cartera_extra_judicial">CARTERA EXTRA JUDICIAL</option>
                                        </optgroup> */}
                                    </select>
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                    <SelectEmpleyee name="promotor" id="promotor" value={this.state.promotor} onChange={this.handleSearchChange} placeholder="Promotor" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 mt-0 mt-md-3">
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Buscar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </>
        );
    }
}

export default GroupSearch;

const styles = {
    searchInputClose: {
        position: 'absolute',
        right: 0,
        zIndex: 4,
    },
    searchInput: {
        paddingRight: 36
    }
}