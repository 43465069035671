import React, { Component } from 'react';
import config from './../../settings/config';
import './../../styles/css/partnerlist.css';
import { Link } from "react-router-dom";
import Spinner from './../others/Spinner';
import _ from 'underscore';
import TablePagination from './../others/TablePagination';
import TableCountInfo from './../others/TableCountInfo';
import Rest from './../../Server/Rest';
import SearchForm from './../forms/SearchForm';
import TextPhone from './../others/TextPhone';
import Parse from 'parse';
import formatString from '../../utilities/format-string';
import user from '../../settings/user';

class PartnerList extends Component {

    constructor (props) {
        super(props);

        this.state = {
            collection: [],
            loading: false,
            index: 0,
            total: 0,
            screenWidth: window.innerWidth
        }
    }

    
    limit   = 50;
    rows    = 0;
    where   = {};
    title   = 'Lista de clientas';
    timeout_innerWidth = null;

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    resize = (e) => {
        clearTimeout(this.timeout_innerWidth);
        this.timeout_innerWidth = setTimeout(() => {
            this.setState({
                screenWidth: window.innerWidth
            })
        }, 300);
    }

    componentDidMount = () => {
        this.getResource();

        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.resize);
        clearTimeout(this.timeout_innerWidth);
        this.timeout_innerWidth = null;
    }

    getResource = (index = 0, _where = {}) => {

        this.where = _where;

        this.setState(state => ({
            index: index
        }));
        
        this.handleSpinner();

        let params;
        let where = {};

        if (Parse.User.current().toJSON().Sucursal && _.isEmpty(_where)) {
            _where.Socio = {
                $inQuery: {
                    where: {
                        lugar_registro: Parse.User.current().toJSON().Sucursal
                    },
                    className: 'Socios'
                }
            }
        }
        
        // establecer el valor para listar socios en validación o normales
        if (this.props.match.path === '/validacion') {
            _where.en_moderacion = true;
        }

        // obtener socios sin id de busqueda
        // _where.id_busqueda = '';
        
        where = JSON.stringify(_where);

        params = new URLSearchParams({
            keys: 'en_moderacion,Socio.id_en_sucursal,Socio.nombre,Socio.apellido_paterno,Socio.apellido_materno,Socio.telefono,Socio.lugar_registro,Socio.status',
            limit: this.limit,
            skip: index,
            where: where,
            count: 1,
            order: '-fecha_registro,-createdAt',
            include: 'Socios,Socio.lugar_registro'
        });
        
        Rest.peticion(`${config.api_url}/classes/SociosDiccionario?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {

            this.handleSpinner();
            return res.json();

        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                
                this.rows = res.count;
                this.setState({
                    collection: _.map(res.results, (item, index) => { item.index = index +1 + parseInt(this.state.index); return item; }),
                    total: res.count
                });
            }
            
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
            
        });
    }

    // dar formato a fecha
    setDate = (fecha) => {
        return  formatString.setLegibleDate(fecha)
    } 
    
    establecerStatus = (status) => {
        if (status === 'ACTIVO') return 'ACTIVA';
        if (status === 'BAJA_VOLUNTARIA') return 'BAJA VOLUNTARIA';
        if (status === 'BAJA_CASTIGADA') return 'BAJA CASTIGADA';
        if (status === 'RECHAZADO') return 'RECHAZADA';
        if (status === 'MOROSIDAD') return 'MOROSIDAD';
        if (status === 'FALLECIDA') return 'FALLECIDA';
    }

    setLugarRegistro = (lugar) => {
        if (!_.isEmpty(lugar)) {
            return lugar.nombre
        } else {
            return '';
        }
    }

    setUrl = (item) => {
        return <Link to={`/clientas/${item.objectId}/${item.Socio.objectId}/${item.Socio.id_en_sucursal}/datos-personales`} title="Ver" className="btn btn-link btn-lg">
            <i className="fas fa-chevron-right"></i>
        </Link>
    }

    setID = (id, sucursal) => {
        return `${id}-${sucursal.sigla}`
    }

    handleFilter = (where) => {
        this.rows = 0;
        this.getResource(0, where);
    }

    setPartnerTable = () => {
        return <>
            <div className="table-responsive">
                <table id="table-partner-list" className="table table-hover position-relative">
                    <thead id="puntero1"></thead>
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre completo</th>
                        <th scope="col">ID</th>
                        <th scope="col" width="150">Teléfono</th>
                        <th scope="col">Lugar de registro</th>
                        <th scope="col">Status</th>
                        <th scope="col">Fecha de registro</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <thead id="puntero2"></thead>
                    <tbody>
                        {
                            this.state.collection.length && this.state.collection.map((item, index) =>
                                <tr key={index} className={(item.en_moderacion && !(this.props.match.path === '/validacion')) && 'text-muted' || ''}>
                                    <td>{item.index}</td>
                                    <td className="td-responsive-text">
                                        
                                        {item.Socio.nombre +' '+ item.Socio.apellido_paterno +' '+ item.Socio.apellido_materno }
                                        
                                    </td>
                                    <td style={{whiteSpace: 'nowrap'}}>
                                        <code className={((!item.en_moderacion || (item.en_moderacion && this.props.match.path === '/validacion'))? 'text-body': 'text-muted')}>{this.setID(item.Socio.id_en_sucursal, item.Socio.lugar_registro)}</code>
                                    </td>
                                    <td nowrap="true"><TextPhone phone={item.Socio.telefono} /></td>
                                    <td>{this.setLugarRegistro(item.Socio.lugar_registro)}</td>
                                    <td nowrap="true">{this.establecerStatus(item.Socio.status)}</td>
                                    <td>{this.setDate((item.Socio.fecha_registro && item.Socio.fecha_registro.iso)||item.Socio.createdAt)}</td>
                                    {(user.rol.permisos.Socios && !(user.rol.permisos.Socios.ocultar_boton_ver_detalles === true)) && <td className="p-1 text-right">
                                        {(!item.en_moderacion || (item.en_moderacion && this.props.match.path === '/validacion')) && this.setUrl(item) || <label className="badge badge-primary mt-2">Validación</label>}
                                    </td>}
                                </tr>
                            ) ||
                            <tr>
                                <td colSpan="8" className="text-center">
                                    <div className="alert alert-warning m-0" role="alert">
                                        No se hallaron registros
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    }

    setPartnerCard = () => {
        
        return this.state.collection.length && this.state.collection.map((item, index) => 
        <div className="position-relative">
            <span className="badge badge-secondary list-partner-item-counter ml-4" style={{zIndex: 1}}>{item.index}</span>
            <div key={index} className="card mb-4 mt-3 shadow" style={{overflow: 'hidden'}}> 
                <div className="card-body bg-light">
                    <div className="p-1 container">
                        <div className="row">
                            <div className="col">
                                <h5 className="font-weight-bold text-primary">
                                    {item.Socio.nombre +' '+ item.Socio.apellido_paterno +' '+ item.Socio.apellido_materno }
                                </h5>
                            </div>
                            <div className="col-5 text-right">
                                <span className="text-muted">ID:</span> {this.setID(item.Socio.id_en_sucursal, item.Socio.lugar_registro)}
                            </div>
                        </div>
                    </div>
                    <table className="table table-borderless">               
                        <tbody>
                            <tr>
                            <td className="p-1 text-muted">Teléfono:</td>
                            <td className="p-1 text-right"><TextPhone phone={item.Socio.telefono} /></td>
                            </tr>
                            <tr>
                            <td className="p-1 text-muted">Lugar de registro:</td>
                            <td className="p-1 text-right">{this.setLugarRegistro(item.Socio.lugar_registro)}</td>
                            </tr>
                            <tr>
                            <td className="p-1 text-muted">Status:</td>
                            <td className="p-1 text-right">{this.establecerStatus(item.Socio.status)}</td>
                            </tr>
                            <tr>
                            <td className="p-1 text-muted">Fecha de registro:</td>
                            <td className="p-1 text-right">{this.setDate((item.Socio.fecha_registro && item.Socio.fecha_registro.iso)||item.Socio.createdAt)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <Link to={`/clientas/${item.objectId}/${item.Socio.objectId}/${item.Socio.id_en_sucursal}/datos-personales`} title="Ver" className="btn btn-secondary btn-lg btn-block">
                        Ver detalles
                    </Link>
                </div>
            </div>
        </div>
        
    ) || <div className="alert alert-warning m-0" role="alert">
        No se hallaron registros
    </div>}

    setHead = <h3 className="text-uppercase text-pink pt-3 pb-3">
        <i className="fas fa-address-book mr-2"></i>
        {this.title}
    </h3>

    render() {
        return (    
            <>
                <Spinner loading={this.state.loading} />
                
                {this.setHead}
                
                <div className="card">
                    <div className="card-body">
                        <SearchForm handleFilter={this.handleFilter} />
                        
                        <br />
                        
                        <TableCountInfo total={this.state.total} />

                        <br/>

                        {/* <TableHeaderFixed> */}
                        {
                            (this.state.screenWidth >= 768) && 
                            this.setPartnerTable() || 
                            this.setPartnerCard()
                        }
                        {/* </TableHeaderFixed> */}

                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-5"></div>
            </>
        );
    }
}

export default PartnerList;

