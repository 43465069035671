import React from 'react';
import DocumentPaperLetter from './DocumentPaperLetter';
import GroupPaymentFormSingle from '../groups/GroupPaymentFormSingle';
import GroupPaymentForm from '../groups/GroupPaymentForm';
import GroupPaymentsCheck from '../groups/GroupPaymentsCheck';
import formatString from '../../utilities/format-string';
import _ from 'underscore';

class DocumentAccountStatement extends GroupPaymentForm {

    constructor(props) {
        super(props);

        this.state = this.state_init();
        this.margin = this.getParamValue('no_margin');
        this.is_print = this.getParamValue('is_print');
        this.state.requisition = JSON.parse(localStorage.getItem('account_statement_requisition'));
        this.pago_total_periodo = parseFloat(localStorage.getItem('pago_total_periodo'));
        let paymentsMade = [];
        for (let index = 0; index < this.state.requisition.montos.length; index++) {
            const amount = this.state.requisition.montos[index];
            paymentsMade[amount.objectId] = amount.objectId;
        }
        this.state.paymentsMade = paymentsMade;

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    margin = '';
    is_print = false;

    getParamValue = (paramName) => {
        var url = window.location.search.substring(1); //get rid of "?" in querystring
        var qArray = url.split('&'); //get key-value pairs
        for (var i = 0; i < qArray.length; i++) 
        {
            var pArr = qArray[i].split('='); //split key and value
            if (pArr[0] === paramName) 
                return pArr[1]; //return value
        }
    }

    render() {
        const {pago_total_periodo} = this;
        const {requisition} = this.state;
        const totalAmountPayable = _.reduce(requisition.Pagos, function(memo, item){ return memo + item.monto; }, 0);
        return (
            <div className="w-100">
                {!_.isEmpty(this.state) && <DocumentPaperLetter className="d-flex flex-column pt-5 pb-5" margin={this.margin}>
                    <div style={{...styles.docSection}} className="doc-section d-flex flex-column justify-content-around pt-3">
                        {!_.isEmpty(requisition) === true && <div className="modal-body">
                            <h1 className="mb-3">Estado de cuenta</h1>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <small className="text-muted">Grupo</small>
                                            <p>{requisition.Grupo.nombre}</p>
                                        </td>
                                        <td>
                                            <small className="text-muted">Fecha actual</small>
                                            <p>{formatString.setLegibleDate(new Date().toISOString())}</p>
                                        </td>
                                        {!(this.is_print === 'true') && <td style={{width: '20%'}}>
                                            <small className="text-muted">Ingreso total</small>
                                            <p>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( this.state.total_1 + this.state.total_2 )}</p>
                                        </td>}
                                    </tr>
                                    <tr>
                                        <td __colSpan={6}>
                                            <small className="text-muted">Monto de pago</small>
                                            <p>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( pago_total_periodo )}</p>
                                        </td>
                                        {this.is_print === 'true' && <td>
                                            <small className="text-muted">Monto total a pagar</small>
                                            <p>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( totalAmountPayable )}</p>
                                        </td>}
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Clienta</th>
                                        <th>Fecha de último pago</th>
                                        <th>Pago fijo</th>
                                        <th>Pago requerido</th>
                                        {this.is_print === 'true' && <th>Monto total a pagar</th>}
                                        {this.is_print === 'true' && <th>Monto pagado</th>}
                                        {this.is_print === 'true' && <th>Saldo</th>}
                                        <th>{!(this.is_print === 'true') && 'Abono'}</th>
                                        <th>{!(this.is_print === 'true') && 'Pagos adelantados'}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {requisition.montos.length > 0 && requisition.montos.map((amount, index) => <tbody key={index} className="tbody-multiple" id={`solicitud-monto-${amount.objectId}`}>
                                    <tr>
                                        <td>{index+1}</td>
                                        <td className="pl-0">{amount.Socio.nombre} {amount.Socio.apellido_paterno} {amount.Socio.apellido_materno}</td>
                                        
                                        <GroupPaymentFormSingle 
                                        id={`conjunto_${index}`}
                                        is_print={this.is_print}
                                        paymentsMade={_.filter(requisition.PagosRealizados, (paymentsMade) => {
                                            return amount.objectId === paymentsMade.SolicitudMonto.objectId
                                        })}
                                        amount={amount} 
                                        payments={_.filter(requisition.Pagos, (pago) => {
                                            return amount.objectId === pago.SolicitudMonto.objectId
                                        })}
                                        handleAmount={this.handleAmount} 
                                        handleAmountDeposit={this.handleAmountDeposit}
                                        resetform={this.state.resetform}/>

                                        <td>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td className="p-0 border-0"></td>
                                        <td colSpan="9" className="p-0 border-0">
                                            
                                            <div className="collapse show mb-3" id={`collapse_${amount.objectId}`}>
                                                <GroupPaymentsCheck
                                                is_print={this.is_print}
                                                amountId={this.state.paymentsMade[amount.objectId]}
                                                pagosRealizados={_.filter(requisition.PagosRealizados, (item) => {
                                                    return _.isEqual(item.SolicitudMonto, {__type: 'Pointer', className: 'SolicitudesMontos', objectId: amount.objectId})
                                                })} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>)}
                            </table>
                        </div>}
                    </div>
                </DocumentPaperLetter>}
            </div>
        );
    }
}

export default DocumentAccountStatement;

const styles = {
    docSection: {
        // height: (17.85+2.929)+'cm',
    },
    
}

