import React, { useEffect, useRef, useState } from "react";
import Lightbox from 'react-image-lightbox-rotate-latest';

process.env.TESSDATA_PREFIX = './tessdata';

const InputDocument = (props) => {

    const [percent, setPercent] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [isOpenPhoto, setIsOpenPhoto] = useState(false);

    const fileInputRef = useRef(null);

    const resetInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
            setPercent(null);
            setImageUrl('');
            if (props.reset) {
                props.reset();
            }
        }
    };

    const recognizeImage = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
        
                reader.onload = async (e) => {
                    setImageUrl(e.target.result);
                    /**
                     * anteriorment se tenía un script para 
                     * recuperar datos de imagenes. Se quitó
                     * y ahora se envia un string vacio
                    */
                    resolve({text: '', imageUrl: e.target.result});
                };
            
                reader.readAsDataURL(file);
            } catch (error) {
                reject(error);
            }
        })
    }

    const handleChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const text = await recognizeImage(file);
            props.handleChange(text);
        }
    };

    useEffect(() => {
        if (props.value) {
            if (props.value.loaded === true) {
                setPercent(1);
                setImageUrl(props.value.imageUrl);
            }
        }
    }, [props.value]);

    return (<div className="mb-4">
        <div className="row">
            <div className="col-8">
                <label className={`btn btn-secondary btn-block btn-lg text-left ${!(percent === null || percent === 1) ? 'disabled' : ''}`} htmlFor={props.id}>
                    {props.label}
                    {(percent === null || percent === 1) ? <i className="fas fa-cloud-upload-alt float-right mt-1"></i> : <div className="spinner-border text-dark float-right" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>}
                    <input type="file" label={props.label} className="d-none" ref={fileInputRef} id={props.id} name={props.name} onChange={(e) => handleChange(e)} disabled={!(percent === null || percent === 1)}/>
                </label>
            </div>
            <div className="col-2 pl-0">
                <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={resetInput} disabled={percent !== 1}>
                    <i className="fas fa-redo-alt"></i>
                </button>
            </div>
            <div className="col-2 pl-0">
                <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={() => setIsOpenPhoto(true)} disabled={percent !== 1}>
                    <i className="fas fa-search"></i>
                </button>
            </div>
        </div>
        <div className="progress">
            <div className={`progress-bar ${percent === 1 ? 'bg-success' : ''}`} role="progressbar" style={{width: `${percent*100}%`}} aria-valuenow={`${percent*100}`} aria-valuemin={0} aria-valuemax={100} />
        </div>

        {isOpenPhoto && (
          <Lightbox
            mainSrc={imageUrl}
            onCloseRequest={() => setIsOpenPhoto(false)}
            imageTitle={props.label}
            // imageCaption={props.label}
          />
        )}

    </div>)
}

export default InputDocument;