import React, { Component } from 'react';
import config from './../../settings/config';

class StatusOnline extends Component {
    state = {
        online: 'load'
    }
    baseUrl = 'https://sistema.solfindelsureste.com/';
    onlineCheck = () => {
        
        let xhr = new XMLHttpRequest();
        return new Promise((resolve, reject)=>{
            xhr.onload = (res) => {
                this.setState(state => ({
                    online: res.type
                }));
                resolve(res);
            };
            xhr.onerror = (res) => {
                this.setState(state => ({
                    online: res.type
                }));
                reject(res);
            };
            xhr.open('GET', this.baseUrl, true);
            xhr.send();
        });
    }

    clickHandler = () => {
        // if (config.environment === 'prod') {
            // this.onlineCheck();
        // }
    }
    
    render() {
        return (
            <div onClick={() => this.clickHandler()} className={(this.state.online === 'load') ? 'online':'offline'}>
                {(this.state.online !== 'load') && <div className="alert alert-danger position-fixed w-100 text-center rounded-0" style={{zIndex:9}} role="alert">
                <i className="fas fa-times-circle"></i> Se perdió la conexión a Internet.
                </div>}
                {this.props.children}
            </div>
        );
    }
}

export default StatusOnline;