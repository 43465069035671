import React, { Component } from "react";

export default class InputSlectOtherOption extends Component {
    constructor(props){
        super(props);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    state = {
        value: '',
        toggleInput: false,
        defaultValue: []
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.state.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            })
        }
        
        if (this.state.defaultValue !== nextProps.defaultValue) {
            this.setState({
                defaultValue: nextProps.defaultValue
            })
        }
    };

    handleOnChange = (event) => {
        const target = event.target;
        const value = target.value;

        if (value === 'other') {
            target.value = '';
            this.toggleInput();
            this.props.onChange(event);
        } else if (/^[0-9|(\s)]*$/.test(value)) {
            this.props.onChange(event);
        }
    };

    toggleInput = () => {
        this.setState((state) => ({
            toggleInput: !state.toggleInput
        }))
    }

    render() {
        return (
            <div>
                {(this.state.toggleInput === false && <select
                    className="form-control"
                    name={this.props.name}
                    value={this.state.value}
                    onChange={this.handleOnChange}
                    required={this.props.required}
                >
                    {/* <option selected value={this.props.defaultValue}>{this.props.defaultValue}</option> */}
                    <option value="">Opciones...</option>
                    {
                        this.state.defaultValue.length && this.state.defaultValue.map((item, key) => <option key={key} value={item.cuenta}>{item.cuenta} ({item.sucursal})</option>)
                    }
                    <option value="other">Otro</option>
                </select>) ||
                <div className="input-group mb-3">
                    <input 
                        type="text"
                        className="form-control"
                        name={this.props.name}
                        value={this.state.value}
                        onChange={this.handleOnChange}
                        required={this.props.required}
                    />
                    <div className="input-group-append">
                        <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-return"
                        onClick={() => this.toggleInput()}>
                            <i className="fas fa-undo-alt" />
                        </button>
                    </div>
                </div>

                }
            </div>
        );
    }
}
