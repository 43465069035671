import React, { Component } from 'react';
import _ from 'underscore';

class TextPhone extends Component {

    // dar formato a teléfono
    establecerTelefono = (telefono) => {
        if (!_.isEmpty(telefono)) {
            const nuevoNumero = _.map(telefono, (digito, i) => {
                let nuevoDigito = '';
                switch (i) {
                    case 0:
                        nuevoDigito = '('+digito;
                        break;
                    case 2:
                        nuevoDigito = digito+') ';
                        break;
                    case 5:
                        nuevoDigito = digito+' ';
                        break;
                    default:
                        nuevoDigito = digito;
                        break;
                }

                return nuevoDigito;
            });
            
            return nuevoNumero.join('');
        } else {
            return '';
        }
    }

    render() {
        return (
            <span>
                {this.establecerTelefono(this.props.phone)}
            </span>
        );
    }
}

export default TextPhone;