import _ from 'underscore';
const _inPaymentPeriod = (status, fecha_elaboracion) => {
    if (status === 'autorizado') {
        if (fecha_elaboracion) {
            fecha_elaboracion.setHours(0);
            fecha_elaboracion.setMinutes(0);
            fecha_elaboracion.setMilliseconds(0);
            fecha_elaboracion = fecha_elaboracion.getTime();
            let   fecha_inicio_pago = fecha_elaboracion + (1000*60*60*24*7);
            let hoy = new Date();
            hoy.setHours(0);
            hoy.setMinutes(0);
            hoy.setMilliseconds(0);
            hoy = hoy.getTime();
            return hoy >= fecha_inicio_pago;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

const _getTotalPayment = (payments) => {
    let debt = 0.0;
    let 
    toDay = new Date();
    toDay.setHours(24);
    toDay.setMinutes(0);
    toDay.setSeconds(0);
    toDay.setMilliseconds(0);
    toDay = toDay.getTime();
    _.each(payments, payment => {
        if (new Date(payment.fecha_pago.iso).getTime() <= toDay) {
            debt += payment.monto;
        } else {
            debt += 0;
        }
    });
    return debt;
}

export default {
    inPaymentPeriod: _inPaymentPeriod,
    getTotalPayment: _getTotalPayment
}