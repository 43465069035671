import React, { useState } from 'react';
import PartnerBuro from './PartnerBuro';
import PartnerHistory from './PartnerHistory';
import PartnerEditDataPersonal from './PartnerEditDataPersonal';
import PartnerEditDataEconomic from './PartnerEditDataEconomic';
import PartnerCredit from './PartnerCredit';
import {
    NavLink,
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import Spinner from './../others/Spinner';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import user from './../../settings/user';
import Parse from 'parse';
import './../../styles/css/partner.css';
import NotFound from './../pages/NotFound';

function Partner (props) {

    const [loading, handleSpinner] = useState(null);
    const [en_moderacion, setModeracion] = useState(null);
    const [name_socio, setNameSocio] = useState(null);
    const [Sucursal, setSucursal] = useState(null);

    const setFirstText = (text) => {
        const textArray = text.split(' ');
        return textArray[0];
    }

    const getSocio = () => {
        const params = new URLSearchParams({
            keys: 'en_moderacion,Socio.nombre,Socio.apellido_paterno,Socio.lugar_registro',
            include: 'Socio,Socio.lugar_registro'
        });
        Rest.peticion(`${config.api_url}/classes/SociosDiccionario/${props.match.params.id_diccionario}?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            setSucursal(res.Socio.lugar_registro.sigla);
            setModeracion(res.en_moderacion);
            setNameSocio((setFirstText(res.Socio.nombre)+' '+res.Socio.apellido_paterno).toLowerCase());
            return res;
            
        })
        .catch(error => {
            return error;
        });
    }

    getSocio();
    
    const saveIdBusqueda = async (historial) => {

        const data = {
            historial: {
                __op: 'AddRelation',
                objects: [{
                    __type: 'Pointer',
                    className: 'SociosHistorial',
                    objectId: historial.objectId
                }]
            }
        }
        
        return Rest.peticion(`${config.api_url}/classes/SociosDiccionario/${props.match.params.id_diccionario}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
    }
    
    const saveSocioHistorial = async (data) => {

        const currentUser = Parse.User.current().toJSON();
        const body = {
            datos_anteriores: data,
            afectado_por: currentUser.nombre+' '+currentUser.apellidos,
            usuario_email: currentUser.email,
            usuario_telefono: currentUser.telefono,
            Rol: {
                __type: 'Pointer',
                className: 'Roles',
                objectId: user.rol.objectId
            },
            User: {
                __type: 'Pointer',
                className: '_User',
                objectId: currentUser.objectId
            },
            Socio: {
                __type: 'Pointer',
                className: 'Socios',
                objectId: props.match.params.id_socio
            }
        }
        return Rest.peticion(`${config.api_url}/classes/SociosHistorial`, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then(res => {return res.json()}).then(res => {return res}).catch(error => {return error});
    }

    const changeModerationValue = async () => {

        const r = window.confirm('¿Confirma que desea aprobar a la clienta?');

        if (r === true) {
            handleSpinner(true);
            const change = await Rest.peticion(`${config.api_url}/classes/SociosDiccionario/${props.match.params.id_diccionario}`,{
                method: 'PUT',
                body: JSON.stringify({
                    en_moderacion: false
                })
            }).then(res => {return res.json()}).then(res => {return res}).catch(error => {return error});
            
            if (change.code || change.error) {
                handleSpinner(false);
                alert('No se ha podido actualizar el asesor');
            } else {
                const historial = await saveSocioHistorial({en_moderacion: false});
                if (historial.code || historial.error) {
                    handleSpinner(false);
                    alert('No se ha podido guardar el historial');
                } else {
                    const socio_diccionario = await saveIdBusqueda(historial);
                    if (socio_diccionario.code || socio_diccionario.error) {
                        handleSpinner(false);
                        alert('No se ha podido actualizar el asesor');
                    } else {
                        handleSpinner(false);
                        alert('La clienta ha sido aprobada.');
                    }
                }
            }
        }
    }
    
    let { path, url } = useRouteMatch();
    
    return (
        <>
            <Spinner loading={loading} />
            <div className="row align-items-center justify-content-between">
                <div className="col-md-6">
                    <h3 className="text-uppercase text-pink pt-3 pb-3 m-0">
                        <i className="fas fa-edit mr-2"></i>
                        Edición
                    </h3>
                </div>
                <div className="col col-md-auto pt-3 pb-3">
                    <small>
                        <nav aria-label="breadcrumb">
                            <div className="breadcrumb m-0 d-flex justify-content-end justify-content-md-start">
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/escritorio">Escritorio</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/escritorio">Escritorio</NavLink>

                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/clientas">Clientas</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted text-capitalize" to={`/clientas/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}`}>{name_socio? name_socio:'Edición'}</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/clientas/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/datos-personales`}>Datos personales</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/clientas/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/datos-economicos`}>Datos económicos</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/clientas/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/buro`}>Buró</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/clientas/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/creditos`}>Datos de crédito</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/clientas/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/historial`}>Historial</NavLink>
                            
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/validacion">Clientas</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted text-capitalize" to={`/validacion/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}`}>{name_socio? name_socio:'Edición'}</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/validacion/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/datos-personales`}>Datos personales</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/validacion/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/datos-economicos`}>Datos económicos</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/validacion/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/buro`}>Buró</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/validacion/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/creditos`}>Datos de crédito</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/validacion/${props.match.params.id_diccionario}/${props.match.params.id_socio}/${props.match.params.id_en_sucursal}/historial`}>Historial</NavLink>
                            </div>
                        </nav>
                    </small>
                </div>
            </div>
            
            <div className="card solfin-card">
                <div className="card-body">

                    <div className="d-flex align-items-start justify-content-between">
                        <div className="h3 mb-4">
                            <span className="badge badge-light shadow">
                                <code>ID: {Sucursal && (`${props.match.params.id_en_sucursal}-${Sucursal}`)}</code>
                            </span>
                        </div>
                        {
                            (
                                en_moderacion &&
                                props.match.path === '/validacion/:id_diccionario/:id_socio/:id_en_sucursal'
                            ) &&
                            <button 
                            className="btn btn-secondary btn-sm" 
                            onClick={() => changeModerationValue()}>
                                <i className="fas fa-user-check"></i> Aprobar
                            </button>
                        }
                    </div>

                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink title="Datos personales" className="nav-link" to={`${url}/datos-personales`} activeClassName="active">
                                <i className="fas fa-id-card mr-md-2"></i>
                                <span className="d-none d-sm-inline">Datos personales</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink title="Datos económicos" className="nav-link" to={`${url}/datos-economicos`} activeClassName="active">
                                <i className="fas fa-money-check-alt mr-md-2"></i>
                                <span className="d-none d-sm-inline">Datos económicos</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink title="Buró" className="nav-link" to={`${url}/buro`} activeClassName="active">
                                <i className="fas fa-wallet mr-md-2"></i>
                                <span className="d-none d-sm-inline">Buró</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink title="Buró" className="nav-link" to={`${url}/creditos`} activeClassName="active">
                                <i className="fas fa-hand-holding-usd mr-md-2"></i>
                                <span className="d-none d-sm-inline">Datos de crédito</span>
                            </NavLink>
                        </li>
                        
                        {(user.rol.permisos.SociosHistorial && user.rol.permisos.SociosHistorial.consultar) && <li className="nav-item">
                            <NavLink title="Historial" className="nav-link" to={`${url}/historial`} activeClassName="active">
                                <i className="fas fa-history mr-md-2"></i>
                                <span className="d-none d-sm-inline">Historial</span>
                            </NavLink>
                        </li>}
                    </ul>
                        
                    <br />

                    <Switch>
                        <Route exact path={`${path}/datos-personales`} component={PartnerEditDataPersonal} />
                        <Route exact path={`${path}/datos-economicos`} component={PartnerEditDataEconomic} />
                        <Route exact path={`${path}/buro`} component={PartnerBuro} />
                        <Route exact path={`${path}/creditos`} component={PartnerCredit} />
                        {(user.rol.permisos.SociosHistorial && user.rol.permisos.SociosHistorial.consultar) && <Route exact path={`${path}/historial`} component={PartnerHistory} />}
                        <Route exact path={`${path}/`} component={
                            () => (
                                <div className="alert alert-info" role="alert">
                                    <big><i className="fas fa-hand-point-up"></i></big> Navega en las secciones de arriba
                                </div>
                            )
                        } />
                        <Route component={NotFound} />
                    </Switch>
                    
                    
                </div>
            </div>
            <div className="p-5"></div>
        </>
    );

}

export default Partner;