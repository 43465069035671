import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'underscore';
import Login from './../components/pages/Login';
import RememberPassword from './../components/pages/RememberPassword';
import Main from './../components/Main';
import WrapperDiv from './../components/others/StatusOnline';
import './../App.css';
import Parse from 'parse';
import DocumentView from '../components/documents/DocumentView';

const App = () => (
    <WrapperDiv id="app">
        <Switch>
            <Route exact path="/acceso" component={Login} />
            <Route exact path="/recuperar-contrasena" component={RememberPassword} />
            <Route exact path="/grupos/:id_diccionario/:id_grupo/solicitud-actual/:id_solicitud_actual/:document_type" component={DocumentView} />
            <Route exact path="/grupos/:id_diccionario/:id_grupo/historial/:id_solicitud_actual/:document_type" component={DocumentView} />
            <Route exact path="/creditos-individuales/:id_grupo/solicitud-actual/:id_solicitud_actual/:document_type" component={DocumentView} />
            <Route exact path="/creditos-individuales/:id_grupo/historial/:id_solicitud_actual/:document_type" component={DocumentView} />
            <Route exact path="/creditos-empleados/:id_grupo/solicitud-actual/:id_solicitud_actual/:document_type" component={DocumentView} />
            <Route exact path="/creditos-empleados/:id_grupo/historial/:id_solicitud_actual/:document_type" component={DocumentView} />
            <Route exact path="/autorizaciones/:id_diccionario/:id_grupo/solicitud-actual/:id_solicitud_actual/:document_type" component={DocumentView} />
            <Route exact path="/autorizados/:id_diccionario/:id_grupo/solicitud-actual/:id_solicitud_actual/:document_type" component={DocumentView} />

            <Route exact path="/pagos/:document_type" component={DocumentView} />
            <Route exact path="/pagos-creditos-individuales/:document_type" component={DocumentView} />
            <Route exact path="/pagos-creditos-empleados/:document_type" component={DocumentView} />
            <Route exact path="/bitacora/visualizar/:id_bitacora/:document_type" component={DocumentView} />
            <PrivateRoute path="/">
                <Main />
            </PrivateRoute>
        </Switch>
                
    </WrapperDiv>
);

export default App;

function PrivateRoute({ children, ...rest }) {

    return (
      <Route
        {...rest}
        render={({ location }) =>
          !_.isNull(Parse.User.current()) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/acceso",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}