import React, {Component}  from "react";
import Spinner from "../others/Spinner";
import _ from 'underscore';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import formatString from '../../utilities/format-string';
import AccountTypeHistory from "./AccountTypeHistory";


class AccountHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            collection: []
        }

    }

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    getAccountHistory = async() => {

        this.handleSpinner();

        const params = new URLSearchParams({
            where: JSON.stringify({
                Account: {
                    __type: 'Pointer',
                    className: 'CatalogoCuentas',
                    objectId: this.props.match.params.id_account
                }
            }),
            order: '-createdAt'
        });

        const res = await Rest.peticion(`${config.api_url}/classes/AccountHistorial?${params.toString()}`, {
            method: 'GET',
        });

        const response = await res.json();

        // console.log("Respuesta historial => ", response.results);

        if (_.isEmpty(response.code) && _.isEmpty(response.error)) {
            
            this.setState({
                collection: response.results
            });
            
        } else {
            
            alert('No se ha podido obtener el historial');
        
        }
        
        this.handleSpinner();

    }

    UNSAFE_componentWillMount = () => {
        this.getAccountHistory();
    }

    titles = {
        codigo: 'Código',
        nombre: 'Nombre',
        descripcion: 'Descripción',
        id_tipo_cuenta: 'Tipo de cuenta'
    }

    setValue = (value, key) => {
        let res;

        switch(key) {
            case 'id_tipo_cuenta':
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: <AccountTypeHistory id_type={value}/></small>
            break;
            case 'id_busqueda': 
            break;
            case 'Tipo_Cuenta': 
            break;
            default:
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: {value}</small>
            break;
        }

        return res;
    }

    setValues = (data) => {
        if (!_.isEmpty(data)) {
            return _.map(data, (value, key) => this.setValue(value, key));
        } else {
            return <small className="badge-secondary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2">Sin datos previos</small>
        }
        
    
    }

    setCreatedAt = (fecha) => {
        let result = '';
        if (fecha) {
            const d = new Date(fecha);
            let hours = d.getHours();
            hours = hours < 10 ? `0${hours}` : hours;
            let minutes = d.getMinutes();
            minutes = minutes < 10 ? `0${minutes}` : minutes;
            result = formatString.setLegibleDate(d.toISOString());
            result += ` ${hours}:${minutes}`
        }
        return result;
    }

    render() {

        // console.log("Collection => ", this.state.collection);
        return (
            <>
                <Spinner loading={this.state.loading} />

                <table className="table">
                    <caption>La tabla muestra los datos anteriores que fueron modificados.</caption>
                    <thead>
                        <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Modificado por</th>
                            <th scope="col">Datos anteriores</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (this.state.collection.length > 0 && this.state.collection.map((item, index) => <tr key={index}>
                                <td style={{whiteSpace:'nowrap'}}>{this.setCreatedAt(item.createdAt)}</td>
                                <td style={{whiteSpace:'nowrap'}}>{item.afectado_por}</td>
                                {/* <td style={{whiteSpace:'nowrap'}}>Algo</td> */}
                                <td>{item.datos_anteriores && this.setValues(item.datos_anteriores)}</td>
                            </tr>))
                        }
                    </tbody>
                </table>
            </>
        )
    }
}

export default AccountHistory;