import React, { Component } from 'react';
import _ from 'underscore';
import GroupPaymentFormSingle from './GroupPaymentFormSingle';
import RequisitionModel from './../../models/RequisitionModel';
import $ from 'jquery';
import Rest from '../../Server/Rest';
import Parse from 'parse';
import config from './../../settings/config';
import Spinner from '../others/Spinner';
import GroupPaymentsCheck from './GroupPaymentsCheck';
import Popover from './../others/Popover';
import GroupStatusForm from './GroupStatusForm';
import formatString from '../../utilities/format-string';

class GroupPaymentForm extends Component {
    constructor(props) {
        super(props);

        this.state = this.state_init();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    state_init = () => {
        return {
            pagos: [],
            total_1: 0.0,
            total_2: 0.0,
            requisition: {},
            loading: false,
            paymentsMade: {},
            resetform: false,
            saving: false
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            requisition: nextProps.requisition
        });
    }

    componentDidMount = () => {
        $(`#${this.props.id}`).on('hide.bs.modal', () => {
            this.setState(this.state_init());
        });
    }

    getRequisition = async () => {
        const params = new URLSearchParams({
            include: this.props.include
        });
        await Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.props.requisition.objectId}?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.error || res.code) {
                console.log('error', res);
            } else {
                this.setState({
                    requisition: res
                });
                this.showWindowChangeStatus(res);

            }
        })
    }

    updateMora = (solicitudId) => {
        console.log('setAllStatus');
        Rest.peticion(`${config.api_url}/functions/changeStatusReqById`, {
            method: 'POST',
            body: JSON.stringify({
                solicitudId: solicitudId
            })
        }).then(res => {
            console.log(res);
        }).catch(error => {
            console.log(error);
        })
    }

    handleAmount = () => {
        const items1 = $('input.input-receipt-amount');
        let total1 = _.reduce(items1, (memo, item) => {
            if (item.checked === true) {
                return memo + parseFloat(item.dataset.amount);
            } else {
                return memo + 0.0;
            }
        }, 0);

        const items2 = $('input[name="abono_cantidad"]');
        let total2 = _.reduce(items2, (memo, item) => {
            if (!_.isEmpty(item.value)) {
                return memo + parseFloat(item.value);
            } else {
                return memo + 0.0;
            }
        }, 0);

        this.setState({
            total_1: total1,
            total_2: total2
        });
    }

    collapseContent = (objectId) => {
        $(`#collapse_${objectId}`).collapse('toggle');
        let {state} = this;
        state.paymentsMade[objectId] = objectId;
        this.setState({
            paymentsMade: state.paymentsMade
        });
    }

    collapseContent2 = (objectId) => {
        $(`#collapse_${objectId}`).collapse('toggle');
        let {state} = this;
        state.paymentsMade[objectId] = undefined;
        this.setState({
            paymentsMade: state.paymentsMade
        });
    }

    setReset = () => {
        this.setState({
            resetform: true
        });
        setTimeout(() => {
            this.setState({
                resetform: false
            });
        }, 1000);
    }

    // controla la aparición de la ventana para cambiar
    // el estatus del grupo y de los socios
    showWindowChangeStatus = (requisition) => {
        
        if (!_.isEmpty(requisition)) {
            const {pago_total_periodo} = this.props;
            if (RequisitionModel.validateCompletedPayment(requisition, pago_total_periodo)) {
                // mostrar la ventana para cambiar status
                $('#modaEndCycle').modal('show');
            }
        }
    }

    handleSubmit = async (event) => {
        if (event) event.preventDefault();
        const items = $('input[name="payment"]');
        let values = _.map(items, item => {
            // return [SolicitudPagoId, Pago Recibo o Abono, Pago Recibo (número), Abono (número)]
            return $(item).val().split('_');
        });

        // comprobar si se ha eliegudo una opción
        if (_.some(_.map(values, value => {return value[2] === ""}))) {
            alert('Elija las opciones de pago para cada clienta.');
            return;
        }

        let requests = [];

        const pagado_al = {
            __type: 'Date',
            iso: new Date().toISOString()
        };

        const currentUser = Parse.User.current().toJSON();

        let monto_pagado_grupal = 0.0;
        let monto_acumulado_grupal = 0.0;
        
        _.each(values, value => {
            const solicitudMontoId = value[0];
            const solicitudPagoId = value[1];
            const tipoPago = value[2];
            const pagos_adelantados = parseInt(value[5]);
            let monto_acumulado = 0.0;
            let monto_pagado = 0.0;

            if (tipoPago === 'pagorecibo') {
                monto_pagado = parseFloat(value[3]).toFixed(2);
                monto_pagado = parseFloat(monto_pagado);
            }
            if (tipoPago === 'abono') {
                monto_pagado = parseFloat(value[4]);
            }

            // establecer nuevo monto acumulado
                // buscar monto asociado al pago actual de la solicitud
                const monto = _.find(this.state.requisition.montos, item => {
                    return item.objectId === solicitudMontoId
                });
                
                // establecer monto actual
                if (monto.monto_acumulado) {
                    monto_acumulado = monto.monto_acumulado + monto_pagado;
                } else {
                    monto_acumulado = monto_pagado;
                }
                monto_acumulado_grupal += monto_acumulado; 

            monto_pagado_grupal += monto_pagado;
            
            if (monto_pagado > 0) {
                requests.push({
                    method: 'POST',
                    path: `${config.api_path2}/classes/SolicitudesPagosRealizados`,
                    body: {
                        SolicitudMonto: {
                            __type: 'Pointer',
                            className: 'SolicitudesMontos',
                            objectId: solicitudMontoId
                        },
                        SolicitudPago: {
                            __type: 'Pointer',
                            className: 'SolicitudesPagos',
                            objectId: solicitudPagoId
                        },
                        registrado_por: {
                            __type: 'Pointer',
                            className: '_User',
                            objectId: currentUser.objectId
                        },
                        Solicitud: {
                            __type: 'Pointer',
                            className: 'Solicitudes',
                            objectId: this.state.requisition.objectId
                        },
                        monto_pagado: monto_pagado,
                        pagado_al: pagado_al,
                        pagos_adelantados: pagos_adelantados,

                    }
                });
                requests.push({
                    method: 'PUT',
                    path: `${config.api_path2}/classes/SolicitudesMontos/${solicitudMontoId}`,
                    body: {
                        ultima_fecha_pago: pagado_al,
                        monto_acumulado: monto_acumulado,
                    }
                });
            }
        });

        // Evitar que el usuario registre más del monto total esperado
        const {pago_total_periodo} = this.props;
        const monto_total = RequisitionModel.getPagoTotal(this.state.requisition, pago_total_periodo);
        if (monto_acumulado_grupal > monto_total) {
            alert('El monto a pagar es mayor al total');
            return;
        }

        this.setState({
            saving: true
        })

        // verificar que el pago a registrar cumpla con los terminos de fecha minima
        // para cerrar el ciclo
        const cycle_is_allowed_to_end = await Rest.peticion(`${config.api_url}/functions/checkDateToEndCycle`, {
            method: 'POST',
            body: JSON.stringify({
                nuevo_pago: monto_pagado_grupal,
                solicitudId: this.state.requisition.objectId
            })
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res.result;
        });

        if (cycle_is_allowed_to_end === true) {
            // comprobar de que hay abonos para guardar
            if (requests.length) {
                const data = {
                    requests: requests
                };
    
                this.setState({
                    loading: true
                });
    
                Rest.peticion(`${config.api_url}/batch`, {
                    method: 'POST',
                    body: JSON.stringify(data)
                }).then(res => {
                    return res.json();
                }).then(res => {
                    $(`#${this.props.id}`).on('hidden.bs.modal', (e) => {
                        this.props.handleFilter();
                        $(`#${this.props.id}`).off('hidden.bs.modal');
                    });

                    this.setReset();
                    this.getRequisition();
                    // ejecutar la función cloud para acutalizar los status de los grupos (activo a mora y viceversa)
                    this.updateMora(this.state.requisition.objectId);
                    
                    alert('Los pagos han sido registrados.');
                }).catch(error => {
                    alert('Los pagos NO fueron registrados. Intente más tarde.');
                }).then(() => {
                    this.setState({
                        loading: false,
                        saving: false
                    })
                });
            } else {
                this.setState({
                    saving: false
                })
                alert('Especifique algunos montos a registrar.');
            }
        } else {
            this.setState({
                saving: false
            })
            alert('No se pudo registrar los pagos. Intente de nuevo en la fecha mínima permitida para cerrar el ciclo del grupo actual o intente con otra cantidad menor.');
        }

    }

    render() {
        const {id, pago_total_periodo} = this.props;
        const {requisition} = this.state;
        this.showWindowChangeStatus(requisition);
        return (<>
            <div className="modal fade" tabIndex={-1} id={id}>
                <Spinner loading={this.state.loading} />
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <form onSubmit={this.handleSubmit} className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Registrar pagos</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        {!_.isEmpty(requisition) === true && <div className="modal-body">
                            <div className="d-flex">
                                <div className="col-md-4">
                                    <small className="text-muted">Grupo</small>
                                    <p>{requisition.Grupo.nombre}</p>
                                </div>
                                <div className="col-md-4">
                                    <small className="text-muted">Fecha actual</small>
                                    <p>{formatString.setLegibleDate(new Date().toISOString())}</p>
                                </div>
                                <div className="col-md-4">
                                    <small className="text-muted">Ingreso total</small>
                                    <p>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( this.state.total_1 + this.state.total_2 )}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-md-4">
                                    <small className="text-muted">Monto de pago</small>
                                    <p>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( pago_total_periodo )}</p>
                                </div>
                                <div className="col-md-4">
                                    <small className="text-muted">No. pagos</small>
                                    <p>{requisition.cantidad_pagos_semanales || requisition.prestamo_personalizado_cantidad_pagos}</p>
                                </div>
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Clienta</th>
                                        <th>Fecha de último pago</th>
                                        <th>Pago fijo</th>
                                        <th>Pago requerido <Popover title="Pago requerido" content="Es el pago semanal o catorcenal según sea el caso. Si el monto está en color rojo representa la cantidad total pendiente de pago. Si el monto está en negrita representa el total a favor del cliente." /></th>
                                        <th>Abono</th>
                                        <th>Pagos adelantados <Popover title="Pagos adelantados" content="Es la cantidad de pagos adelantados de acuerdo a la cantidad abonada."/></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {requisition.montos.length > 0 && requisition.montos.map((amount, index) => <tbody key={index} className="tbody-multiple" id={`solicitud-monto-${amount.objectId}`}>
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{amount.Socio.nombre} {amount.Socio.apellido_paterno} {amount.Socio.apellido_materno}</td>
                                        
                                        <GroupPaymentFormSingle 
                                        id={`conjunto_${index}`}
                                        amount={amount} 
                                        payments={_.filter(requisition.Pagos, (pago) => {
                                            return amount.objectId === pago.SolicitudMonto.objectId
                                        })}
                                        handleAmount={this.handleAmount} 
                                        handleAmountDeposit={this.handleAmountDeposit}
                                        resetform={this.state.resetform}/>

                                        <td>
                                            <button className={`btn btn-link ${this.state.paymentsMade[amount.objectId]?'d-none':''}`} type="button" onClick={() => this.collapseContent(`${amount.objectId}`)}>
                                                <i className="fas fa-chevron-down"></i>
                                            </button>
                                            <button className={`btn btn-link ${this.state.paymentsMade[amount.objectId]?'':'d-none'}`} type="button" onClick={() => this.collapseContent2(`${amount.objectId}`)}>
                                                <i className="fas fa-chevron-up"></i>
                                            </button>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td className="p-0 border-0"></td>
                                        <td colSpan="6" className="p-0 border-0">
                                            <div className="collapse mb-3" id={`collapse_${amount.objectId}`}>
                                                <GroupPaymentsCheck amountId={this.state.paymentsMade[amount.objectId]} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>)}
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Totales</td>
                                        <td className="text-center">{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( this.state.total_1 )}</td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text text-white border-0">Abonar</div>
                                                </div>
                                                <span className="form-control border-0 pt-0">
                                                    {new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( this.state.total_2 )}
                                                </span>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.saving}>
                                
                                {(this.state.saving === true && <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span>Guardando...</span>
                                </>) || 'Guardar'}
                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <GroupStatusForm requisition={requisition} handleFilter={this.props.handleFilter} />
        </>);
    }
}

export default GroupPaymentForm;