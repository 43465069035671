import React, { Component } from 'react';
import PartnerFormDataEconomic from '../../components/partners/PartnerFormDataEconomic';
import _ from 'underscore';
import config from '../../settings/config';
import Spinner from './../others/Spinner';
import Rest from './../../Server/Rest';
import Parse from 'parse';
import user from './../../settings/user';

class PartnerEditDataEconomic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resetform: false,
            data: {},
            loading: false,
            readOnly: true
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeEdit = this.handleChangeEdit.bind(this);
        
    }

    data = {};
    data_original = {};
    socio_objectId = '';
    id_diccionario = '';

    setData = (data, file, nota = '') => {
        this.data = data;
    }

    // consultar datos del socio
    UNSAFE_componentWillMount = () => {
        this.handleSpinner();
        const params = new URLSearchParams({
            keys: 'en_moderacion,Socio.destino_credito,Socio.gastos_negocio,Socio.gastos_prestamo_banco,Socio.gastos_prestamo_departamental,Socio.gastos_prestamo_financiera,Socio.gastos_prestamo_otro,Socio.gastos_prestamo_personal,Socio.gastos_prestamo_tdd,Socio.gastos_vivienda_agua,Socio.gastos_vivienda_luz,Socio.gastos_vivienda_otros,Socio.gastos_vivienda_renta_casa,Socio.gastos_vivienda_telefono,Socio.gastos_vivienda_transporte,Socio.ingreso_beca,Socio.ingreso_otros_trabajo,Socio.ingreso_otros_ventas_totales,Socio.ingreso_otros,Socio.ingreso_prospera,Socio.negocio_tiempo_activo,Socio.negocio_tipo,Socio.negocio_venta,Socio.observacion_vivienda,Socio.sueldo_conyugue,Socio.datos_mercado_pregunta_1,Socio.datos_mercado_pregunta_2,Socio.datos_mercado_pregunta_3,Socio.total_ingresos',
            excludeKeys: 'Socio.Buro,Socio.registeredBy,Socio.id_en_sucursal'
        });
        
        Rest.peticion(`${config.api_url}/classes/SociosDiccionario/${this.props.match.params.id_diccionario}?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            this.handleSpinner();
            return res.json();
        })
        .then(res => {
            if (res.code) {
                alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
            } else {

                if (res.en_moderacion === true && !(user.rol.permisos.Validacion && user.rol.permisos.Validacion.editar === true)) {
                    alert('No tienes accesos a estos datos.');
                    window.location = '/escritorio';
                    return false;
                }

                this.data_original = _.clone(res.Socio);
                this.data = _.clone(res.Socio);
                this.socio_objectId = res.Socio.objectId;
                this.id_diccionario = res.objectId;
                this.setState({
                    resetform: true
                });
            }
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
        });
    }

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    handleChangeEdit = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        
        this.setState((state) => ({
            [name]: !state[name]
        }));
    }

    handleChangeBtnSubmit = () => {
        let btn_submit = document.getElementsByClassName('btns-form-submit');

        btn_submit[0].disabled = !btn_submit[0].disabled;

        if (btn_submit[0].disabled === true) {
            btn_submit[0].innerHTML = 'Guardando...';
        } else {
            btn_submit[0].innerHTML = 'Guardar';
        }
    }

    saveSocioBusqueda = async (historial) => {

        if (historial) {
            const data = {
                historial: {
                    __op: 'AddRelation',
                    objects: [{
                        __type: 'Pointer',
                        className: 'SociosHistorial',
                        objectId: historial.objectId
                    }]
                }
            }
            return Rest.peticion(`${config.api_url}/classes/SociosDiccionario/${this.id_diccionario}`, {
                method: 'PUT',
                body: JSON.stringify(data)
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                return res;
            })
            .catch(error => {
                return error;
            });
        } else {
            return false;
        }
    }

    saveData = async (data) => {
        return Rest.peticion(`${config.api_url}/classes/Socios/${this.socio_objectId}`, {
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(res => {return res.json()}).then(res => {return res}).catch(error => {return error});
    }

    saveSocioHistorial = async (_data) => {

        if (!_.isEqual(this.data_original, _data)) {
            
            let data = {};
            _.each(this.data_original, (value, key) => {
                if(!_.isEqual(this.data_original[key], _data[key])) {
                    data[key] = value;
                };
            });
            _.each(_data, (value, key) => {
                if (this.data_original[key] === undefined) {
                    data[key] = 'SIN DATO PREVIO';
                }
            });

            const currentUser = Parse.User.current().toJSON();
            const body = {
                datos_anteriores: data,
                afectado_por: currentUser.nombre+' '+currentUser.apellidos,
                usuario_email: currentUser.email,
                usuario_telefono: currentUser.telefono,
                grupo_datos: 'datos-economicos',
                Rol: {
                    __type: 'Pointer',
                    className: 'Roles',
                    objectId: user.rol.objectId
                },
                User: {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: currentUser.objectId
                },
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: this.socio_objectId
                }
            }
            return Rest.peticion(`${config.api_url}/classes/SociosHistorial`, {
                method: 'POST',
                body: JSON.stringify(body)
            }).then(res => {return res.json()}).then(res => {return res}).catch(error => {return error});
        } else {
            return false;
        }
    }

    async handleSubmit (event) {

        if (event) event.preventDefault();

        const r = window.confirm('¿Todos los datos son correctos?');

        if (r === true) {
        
            let data = this.data;

            // textos a mayúsculas
            _.map(data, (value, key) => {
                let newValue;
                if (!_.isEmpty(value) && _.isString(value)) {
                    newValue = value.toUpperCase().trim();
                } else {
                    newValue = value;
                }
                data[key] = newValue;
            });

            if (!_.isEmpty(data.curp) && !this.validCURP(data.curp)) {

                alert('La CURP no parece cumplir con el formato válido.');
                return;
            }

            const newData = _.omit(data, 'className','registeredBy','__type','Buro','objectId','createdAt','updatedAt');
            this.data_original = _.omit(this.data_original, 'className','registeredBy','__type','Buro','objectId','createdAt','updatedAt');
            
            this.handleChangeBtnSubmit();
            this.handleSpinner();

            const acualizacion = await this.saveData(newData);

            const historial = await this.saveSocioHistorial(newData);

            await this.saveSocioBusqueda(historial);

            if (acualizacion.code || acualizacion.error) {
                alert('Ha ocurrido un error al intentar actualizar los datos. Intente más tarde o contacte a soporte.');
            } else {
                this.data_original = _.clone(newData);
                alert('Los datos han sido actualizados.');
            }

            this.handleSpinner();
            this.handleChangeBtnSubmit();
        }
        
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />

                {(user.rol.permisos.Socios && user.rol.permisos.Socios.editar === true) &&
                <div className="row">
                    <div className="col-8">
                        {!this.state.readOnly && <p className="text-info">
                            Todos los campos son obligatorios.
                        </p>}
                    </div>
                    <div className="text-right col-4 mb-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitch1" value={!this.state.readOnly} defaultChecked={!this.state.readOnly} name="readOnly" onChange={this.handleChangeEdit} />
                            <label className="custom-control-label text-primary" htmlFor="customSwitch1">Editar</label>
                        </div>
                    </div>
                </div>}
                
                <form onSubmit={this.handleSubmit}>

                    <PartnerFormDataEconomic readOnly={this.state.readOnly} required={true} resetform={this.state.resetform} data={this.data} setData={this.setData} />
                    
                    {!this.state.readOnly && <button type="submit" className="btn btn-primary btn-lg mr-2 btns-form-submit" data-loading-text="Loading...">
                        Guardar
                    </button>}
                
                </form>
            </>
        );
    }
}

export default PartnerEditDataEconomic;