// Este formulario de busqueda solo cambia en cuanto a la propiedad Sucursal en lugar de lugar_regustor para las busquedas por Sucursal

import textUtils from '../../utilities/format-string';


import _ from 'underscore';
import SearchForm from './SearchForm';

class SearchFormEmployees extends SearchForm {

    



    handleSearchSubmit = (event) => {
        if (event) event.preventDefault();

        let searchValue = textUtils.setPlainText(this.state.searchValue);
        searchValue = searchValue.toLowerCase();
        searchValue = searchValue.replace(' ','');

        let where = {};

        if (!_.isEmpty(this.state.lugar_registro)) {
            where = {
                id_busqueda: {
                    $regex: searchValue
                },
                Empleado: {
                    $inQuery: {
                        where: {
                            Sucursal: {
                                __type: 'Pointer',
                                className: 'Sucursales',
                                objectId: this.state.lugar_registro
                            },
                            status: this.state.status.length? this.state.status : undefined
                        },
                        className: 'Empleados'
                    }
                }
            }
        } else {
            where = {
                id_busqueda: {
                    $regex: searchValue
                },
                Empleado: {
                    $inQuery: {
                        where: {
                            status: this.state.status.length? this.state.status : undefined
                        },
                        className: 'Empleados'
                    }
                }

            };
        }
        
        this.props.handleFilter(where)
    }

    handleSearchSubmitAdvenced = (event) => {
        if (event) event.preventDefault();

        let searchValue = textUtils.setPlainText(this.state.searchValue);
        searchValue = searchValue.toUpperCase();

        let where = {};

        if (!_.isEmpty(this.state.lugar_registro)) {
            where = {
                Empleado: {
                    $inQuery: {
                        where: {
                            [this.state.searchKey]: {
                                $regex: searchValue
                            },
                            Sucursal: {
                                __type: 'Pointer',
                                className: 'Sucursales',
                                objectId: this.state.lugar_registro
                            }
                        },
                        className: 'Empleados'
                    }
                }
            }
        } else {
            where = {
                Empleado: {
                    $inQuery: {
                        where: {
                            [this.state.searchKey]: {
                                $regex: searchValue
                            }
                        },
                        className: 'Empleados'
                    }
                }
            };
        }
        
        this.props.getResource(undefined, where);
    }
}

export default SearchFormEmployees;