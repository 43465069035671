import React, { Component } from 'react';
import './../../styles/css/paper.css';

class DocumentPaperLetter extends Component {
    render() {
        return (
            <div id={this.props.id || 'paper'} className={this.props.className} style={this.props.margin === 'true' ? {padding:'0', display:'block'} : styles.preview}>
                {this.props.children}
            </div>
        );
    }
}

export default DocumentPaperLetter;

const styles = {
    preview: {
        backgroundColor: 'white',
        fontSize: '0.38805555555556cm',
        width: (21.59+(2*4))+'cm',
        minHeight: (27.94+(2*4))+'cm',
        padding: '0 2cm',
        margin: '3rem auto',
        boxShadow: '0px 8px 15px -10px black'
    }
}