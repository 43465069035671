import React from 'react';
import SelectBranchOffice from './../forms/SelectBranchOffice';

class SelectBranchOfficeGroup extends SelectBranchOffice {

    handleChange = async (event) => {
        await this.props.onChange(event);
        this.props.handleValidName();
    };
}

export default SelectBranchOfficeGroup;