import React, { useEffect, useState } from "react";
import _ from 'underscore';
import InputDate from "../../forms/InputDate";
import InputTel from "../../forms/InputTel";
import MyMaps from "./MyMaps";
import { getPersonalDataModel } from "./models";
import Rest from "../../../Server/Rest";
import config from "../../../settings/config";

const PersonalData = React.memo((props) => {
    
    const paises = require('../../../colecciones/paises.json');
    const entidadesMexico = require('../../../colecciones/entidades-mexico.json');

    const [data, setData] = useState(getPersonalDataModel());
    const [showMap, setShowMap] = useState(false);
    const [settlements, setSettlements] = useState([]);
    const [toDay] = useState(new Date().getTime());
    const [verification, setVerification] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(props.data)) {
            setData(props.data);
        }
    }, [props.data]);

    const handleChange = (e) => {
        let target = e.target;
        let value = target.value;
        let name = target.name;

        if (!_.isEmpty(value) && target.type === "number") {
            value = parseInt(value);
        }

        let nextData = {};
        if (name === 'ubicacion_lat') {
            nextData = {...data};
            nextData.domicilio_geopoint.latitude = value;
        } else if (name === 'ubicacion_lng') {
            nextData = {...data};
            nextData.domicilio_geopoint.longitude = value;
        } else {
            nextData = {...data, [name]: value };
        }

        
        // setData(nextData);
        props.handleChange && props.handleChange(nextData, props.index);
    }

    const setUbicacion = (lat, lng) => {
        const newData = {...data};
        newData.domicilio_geopoint.latitude = lat;
        newData.domicilio_geopoint.longitude = lng;
        setData(newData);
        props.handleChange && props.handleChange(newData, props.index);
    }

    const handleChangeSettlement = () => {
        setSettlements([]);
        
        setData({
            ...data,
            domicilio_colonia: ''
        });
    }

    const getDataByCP = async (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
    
        const nextData = {...data, [name]: value };

        const cp = value;
        if (cp.length === 5) {
                
            if (!_.isEmpty(cp)) {
                
                const params = new URLSearchParams({
                    where: JSON.stringify({
                        cp: cp
                    }),
                    limit: 1,
                    count: 1
                });

                /**
                 * Buscar las localidades en la base de datos
                 */
                const solfin = await Rest.peticion(`${config.api_url}/classes/CodigoPostal?${params.toString()}`, {
                    method: 'GET'
                }).then(res => {return res.json()}).then(res => {return res}).catch(error => console.log(error));

                if (!solfin.code) {
                    
                    if (solfin.count) {

                        let newSettlements = [];
                        
                        _.each(solfin.results[0].asentamiento, (asentamiento) => {
                            
                            newSettlements.push( asentamiento.toUpperCase() );
                            
                        });
                        
                        newSettlements = newSettlements.sort();

                        setSettlements(newSettlements);

                        const nextData = {
                            ...data,
                            domicilio_cp: cp,
                            domicilio_poblacion: solfin.results[0].municipio,
                            domicilio_entidad: solfin.results[0].estado,
                        }
                        setData(nextData);

                        props.handleChange && props.handleChange(nextData, props.index);

                        /**
                         * romper el proceso si se obtuvo el código postal en la base de datos
                         */
                        return;
                    }
                }

                /**
                 * en caso de que no exista el código postal en la base de datos
                 * entonces consultar a copomex
                 */
                const sepomex = await fetch('https://api.copomex.com/query/info_cp/'+cp+'?type=simplified&token=bfc2983b-6538-4c83-9655-6e2db67d4090', {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {

                    if (res.error) {
                        return false
                    } else {
                        return res;
                    }

                })
                .catch(error => {
                    console.log('sepomex error --> ', error);
                });

                if (sepomex) {
                    let settlements = [];
                    
                    _.each(sepomex.response.asentamiento, (asentamiento) => {
                        
                        settlements.push(asentamiento.toUpperCase());

                    });
                    
                    settlements.push(data.domicilio_colonia);
                    
                    settlements = settlements.sort();
                    settlements = _.uniq(settlements);

                    setSettlements(settlements);
                    
                   const nextData = {
                       ...data,
                       domicilio_cp: cp,
                       domicilio_poblacion: sepomex.response.municipio,
                       domicilio_entidad: sepomex.response.estado,
                   }

                   setData(nextData);

                   props.handleChange && props.handleChange(nextData, props.index);

                    Rest.peticion(`${config.api_url}/classes/CodigoPostal?`, {
                        method: 'POST',
                        body: JSON.stringify(sepomex.response),
                    }).catch(error => console.log(error));
                }
                
            }
        } else {
            setData(nextData);
        }
        
    }

    return (
      <div>
        <h2>Datos personales</h2>
        <div className="row">
            <div className="form-group col-md-6 col-xl-4">
                <label>Nombre/s</label>
                <input
                    type="text"
                    className="form-control text-uppercase"
                    id={`nombre_completo_${toDay}`}
                    name="nombre"
                    label="Nombre/s"
                    value={data.nombre}
                    onChange={handleChange}
                    required={true}
                />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Apellido paterno</label>
                <input
                    type="text"
                    className="form-control text-uppercase"
                    id={`apellido_paterno_${toDay}`}
                    name="apellido_paterno"
                    label="Apellido paterno"
                    value={data.apellido_paterno}
                    onChange={handleChange}
                    required={true}
                />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Apellido materno</label>
                <input
                    type="text"
                    className="form-control text-uppercase"
                    id={`apellido_materno_${toDay}`}
                    name="apellido_materno"
                    label="Apellido materno"
                    value={data.apellido_materno}
                    onChange={handleChange}
                    required={true}
                />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Sexo</label>
                <select className="form-control" id={`sexo_${toDay}`} name="sexo" label="Sexo" value={data.sexo} required={true} onChange={handleChange}>
                    <option value="">Opciones...</option>
                    <option value="HOMBRE">HOMBRE</option>
                    <option value="MUJER">MUJER</option>
                </select>
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Clave de elector</label>
                <input type="text" className="form-control text-uppercase" maxLength="18" minLength="18" id={`clave_elector_${toDay}`} name="clave_elector" label="Clave elector" value={data.clave_elector} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Número de OCR</label>
                <input type="text" className="form-control text-uppercase" maxLength="13" minLength="13" id={`numero_ocr_${toDay}`} name="numero_ocr" label="Número OCR" value={data.numero_ocr} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>País de nacimiento</label>
                <select className="form-control text-uppercase" id={`pais_nacimiento_${toDay}`} name="nacimiento_pais" label="País de nacimiento" value={data.nacimiento_pais} onChange={handleChange} required={true} >
                <option value="" disabled>Elija una opción...</option>
                    {paises.map((pais, index) => <option value={pais} key={index}>{pais}</option>)}
                </select> 
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Entidad de nacimiento</label>
                {
                    (data.nacimiento_pais === 'MÉXICO' && <select className="form-control text-uppercase" id={`entidad_nacimiento_${toDay}`} name="nacimiento_entidad" label="Entidad de nacimiento" value={data.nacimiento_entidad} onChange={handleChange} required={true} >
                        <option value="" disabled>Elija una opción...</option>
                        {entidadesMexico.map((entidad, index) => <option value={entidad} key={index}>{entidad}</option>)}
                    </select>) ||
                    <input type="text" className="form-control text-uppercase" id={`entidad_nacimiento_${toDay}`} name="nacimiento_entidad" label="Entidad de nacimiento" value={data.nacimiento_entidad} onChange={handleChange} required={true} />
                }
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Fecha de nacimiento</label>
                <InputDate handleChange={handleChange} name="nacimiento_fecha" label="Fecha de nacimiento" hasDate={data.nacimiento_fecha} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>CURP</label>
                <input type="text" className="form-control text-uppercase" id={`curp_${toDay}`} name="curp" label="CURP" value={data.curp} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Estado civil</label>
                <select className="form-control text-uppercase" id={`estado_civil_${toDay}`} name="estado_civil" label="Estado civil" value={data.estado_civil} onChange={handleChange} required={true} >
                    <option value="" disabled>Elija una opción...</option>
                    <option value="SOLTERO">SOLTERA</option>
                    <option value="CASADO">CASADA</option>
                    <option value="DIVORCIADO">DIVORCIADA</option>
                    <option value="UNIÓN LIBRE">UNIÓN LIBRE</option>
                    <option value="VIUDO">VIUDA</option>
                </select>
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Número de dependientes</label>
                <input type="number" className="form-control" id={`numero_dependientes_${toDay}`} name="numero_dependientes" label="Número dependientes" value={data.numero_dependientes} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Ocupación</label>
                <input type="text" className="form-control text-uppercase" id={`ocupacion_${toDay}`} name="ocupacion" label="Ocupación" value={data.ocupacion} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Teléfono</label>
                <InputTel id={`telefono_${toDay}`} name="telefono" label="Teléfono" value={data.telefono} handleChange={handleChange} required={true} />
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="form-group col-md-12 col-xl-8">
                        <label>Nombre del conyugue</label>
                        <input type="text" 
                        className="form-control text-uppercase" 
                        id={`conyugue_nombre_${toDay}`} 
                        name="conyugue_nombre" label="Nombre conyugue" 
                        value={data.conyugue_nombre}
                        onChange={handleChange} 
                        disabled={(
                            data.estado_civil === 'SOLTERO' || 
                            data.estado_civil === 'DIVORCIADO' || 
                            data.estado_civil === 'VIUDO'
                        )} required={(
                            data.estado_civil === 'CASADO' ||
                            data.estado_civil === 'UNIÓN LIBRE'
                        )} />
                    </div>
                    <div className="col-md-6 col-xl-4">
                        <label>Lugar de trabajo del conyugue</label>
                        <input type="text" 
                        className="form-control text-uppercase" 
                        id={`conyugue_lugar_trabajo_${toDay}`} 
                        name="conyugue_lugar_trabajo" label="Lugar trabajo conyugue" 
                        value={data.conyugue_lugar_trabajo} 
                        onChange={handleChange} 
                        disabled={(
                            data.estado_civil === 'SOLTERO' || 
                            data.estado_civil === 'DIVORCIADO' || 
                            data.estado_civil === 'VIUDO'
                        )} required={(
                            data.estado_civil === 'CASADO' ||
                            data.estado_civil === 'UNIÓN LIBRE'
                        )} />
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>

        <h2>Datos del domicilio</h2>
        <div className="row">
            <div className="form-group col-md-9 col-xl-9">
                <label>Dirección</label>
                <input type="text" className="form-control text-uppercase" id={`domicilio_direccion_${toDay}`} name="domicilio_direccion" label="Dirección del domicilio" value={data.domicilio_direccion} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-3 col-xl-3">
                <label>C.P.</label>
                <input type="text" className="form-control text-uppercase" id={`cp_${toDay}`} name="domicilio_cp" label="C.P. del domicilio" value={data.domicilio_cp} onChange={getDataByCP} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Estado</label>
                <input type="text" className="form-control text-uppercase" id={`domicilio_entidad_${toDay}`} name="domicilio_entidad" label="Entidad del domicilio" value={data.domicilio_entidad} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Población</label>
                <input type="text" className="form-control text-uppercase" id={`domicilio_poblacion_${toDay}`} name="domicilio_poblacion" label="Población del domicilio" value={data.domicilio_poblacion} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                        <label>Fraccionamiento o Colonia</label>
                        {
                            (settlements.length && <div className="input-group">
                                <select className="custom-select text-uppercase" id={`domicilio_colonia_${toDay}`} name="domicilio_colonia" label="Colonia del domicilio" value={data.domicilio_colonia} onChange={handleChange} required={true} >
                                    <option value="">Elija una opción...</option>
                                    {settlements.map((asentamiento, index) => <option value={asentamiento} key={index}>{asentamiento}</option>)}
                                </select>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={handleChangeSettlement}>Otro</button>
                                </div>
                            </div>) || (
                                <>
                                    <input type="text" className="form-control text-uppercase" id={`domicilio_colonia_${toDay}`} name="domicilio_colonia" label="Colonia del domicilio" value={data.domicilio_colonia} onChange={handleChange} required={true} />
                                    <small className="form-text">Escriba un C.P. para obtener una lista</small>
                                </>
                            )
                        }
                        
                    </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Tiempo habitado en ella</label>
                <input type="text" className="form-control text-uppercase" id={`domicilio_tiempo_habitado_${toDay}`} name="domicilio_tiempo_habitado" label="Tiempo habitado en el domicilio" value={data.domicilio_tiempo_habitado} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Domicilio</label>
                <div className="d-flex flex-column flex-sm-row">
                    <select className="form-control" name="domicilio_tipo" label="Tipo domicilio" id={`domicilio_tipo_${toDay}`} value={data.domicilio_tipo} onChange={handleChange}>
                        <option value="">Opciones...</option>
                        <option value="PROPIO">Propio</option>
                        <option value="RENTADO">Rentado</option>
                        <option value="FAMILIAR">Familiar</option>
                    </select>
                </div>
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Número de servicio</label>
                <input type="text" className="form-control text-uppercase"  id={`domicilio_numero_servicio_${toDay}`} name="domicilio_numero_servicio" label="Número del comprobante de domicilio" value={data.domicilio_numero_servicio} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Tipo servicio</label>
                <select className="custom-select" id={`domicilio_numero_servicio_tipo_${toDay}`} name="domicilio_numero_servicio_tipo" label="Tipo de comprobante de domicilio" value={data.domicilio_numero_servicio_tipo} onChange={handleChange} required={true}>
                    <option value="" disabled>Elija una opción...</option>
                    <option value="AGUA">Agua</option>
                    <option value="CABLE">Cable</option>
                    <option value="LUZ">Luz</option>
                    <option value="TELÉFONO">Teléfono</option>
                    <option value="CONSTANCIA DE RESIDENCIA">Constancia de residencia</option>
                </select>
            </div>
            {verification === true && <>
                {showMap === false && <div className="form-group col-md-6 col-xl-4 d-flex align-items-end">
                    <button type="button" className="btn btn-secondary btn-block" onClick={() => setShowMap(!showMap)}>
                        <i className="fas fa-map-marker-alt"></i> Ver mapa
                    </button>
                </div>}
            </>}
        </div>
        {verification === true && <>
            {showMap === true && <>
                <br/>
                <div className="location-client-map">
                    <label>Ubicación geográfica del cliente</label>
                    <MyMaps handleChange={setUbicacion} point={[data.domicilio_geopoint.latitude, data.domicilio_geopoint.longitude]}/>
                </div>
            </>}
            <div className="mt-3">
                <label>Ubicación</label>
                <div className="row">
                    <div className="col-md-6 col-xl-4">
                        <div className="form-group">
                            <label className="col-form-label">Latitud</label>
                            <input type="text" name="ubicacion_lat" label="Ubicación" value={data.domicilio_geopoint.latitude} onChange={handleChange} className="form-control" placeholder="Longitud" required={true} />
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                        <div className="form-group">
                            <label className="col-form-label">Longitud</label>
                            <input type="text" name="ubicacion_lng" label="Ubicación" value={data.domicilio_geopoint.longitude} onChange={handleChange} className="form-control" placeholder="Latitud" required={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>}
        
        <br/>
        <br/>
        <h2>Referencias personales</h2>
        <div className="row">
            <div className="form-group col-md-12">
                <label>Nombre completo</label>
                <input type="text" className="form-control text-uppercase" id={`referencia_1_nombre_completo_${toDay}`} name="referencia_1_nombre_completo" label="Nombre completo de la referencia" value={data.referencia_1_nombre_completo} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-8">
                <label>Dirección</label>
                <input type="text" className="form-control text-uppercase" id={`referencia_1_direccion_${toDay}`} name="referencia_1_direccion" label="Dirección de la referencia" value={data.referencia_1_direccion} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-4">
                <label>Teléfono</label>
                <InputTel id={`referencia_1_telefono_${toDay}`} name="referencia_1_telefono" label="Teléfono de la referencia" value={data.referencia_1_telefono} handleChange={handleChange} required={true} />
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="form-group col-md-12">
                <label>Nombre completo</label>
                <input type="text" className="form-control text-uppercase" id={`referencia_2_nombre_completo_${toDay}`} name="referencia_2_nombre_completo" label="Nombre completo de la referencia" value={data.referencia_2_nombre_completo} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-8">
                <label>Dirección</label>
                <input type="text" className="form-control text-uppercase" id={`referencia_2_direccion_${toDay}`} name="referencia_2_direccion" label="Dirección de la referencia" value={data.referencia_2_direccion} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-4">
                <label>Teléfono</label>
                <InputTel id={`referencia_2_telefono_${toDay}`} name="referencia_2_telefono" label="Teléfono de la referencia" value={data.referencia_2_telefono} handleChange={handleChange} required={true} />
            </div>
        </div>
      </div>
    );
})

export default PersonalData;