import React from "react";
import { Component } from "react";
import _ from "underscore";
// import TypesAccounts from '../../models/enumTypeAccounts';
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import textUtils from './../../utilities/format-string';


class AccountSearch extends Component{

    constructor(props) {
        super(props);

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    state = {
        busqueda: '',
        tipo: '',
        id_tipo_cuenta: '',
        collection: [],
    }

    handleSearchChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    esetSearchForm = () => {
        this.setState({
            searchValue: '',
            searchKey: '',
            status: '',
            lugar_registro: ''
        });
        this.props.handleFilter();
    }

    handleSearchSubmit = (event) => {

        if (event) event.preventDefault();

        let searchValue = textUtils.setPlainText(this.state.busqueda);
        searchValue = searchValue.toUpperCase().trim();
        searchValue = searchValue.replace(' ','');

        let where = {};

        if (!_.isEmpty(this.state.busqueda) && !_.isEmpty(this.state.id_tipo_cuenta)) {

            where = {
                id_busqueda : {
                    // $regex : this.state.busqueda.toUpperCase().trim()
                    $regex : searchValue
                },
                id_tipo_cuenta : this.state.id_tipo_cuenta
            }
        } else if (!_.isEmpty(this.state.busqueda)) {
            where = {
                id_busqueda : {
                    // $regex : this.state.busqueda.toUpperCase().trim()
                    $regex : searchValue
                },
            }
        } else if (!_.isEmpty(this.state.id_tipo_cuenta)) {
            where = {
                id_tipo_cuenta : this.state.id_tipo_cuenta
            }
        }


        this.props.handleFilter(where)
    }

    UNSAFE_componentWillMount = async() => {
        let params = new URLSearchParams({
            limit: 50,
            count: 1,
        });

        Rest.peticion(`${config.api_url}/classes/TypesAccounts?${params.toString()}`, {
            method: 'GET',
        }).then((response) => {
            return response.json();
        }).then((res) => {
            
            if (res.code) {
                alert('Ha ocurrido un error inesperado al recuperar los tipos de cuenta para los filtros.\nIntente más tarde.');
            } else {
                // console.log("Respuesta Tipos de cuenta en filtros => ", res);

                this.setState({
                    collection: res.results
                })
            }

            

        }).catch((err) => {
            console.log("Error: ", err)
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });

    }

    render() {
        
        return (
            <>
            <div className="card">

                <div className="card-header">
                    <i className="fas fa-filter mr-2"></i>
                    Filtros
                </div>

                <div className="card-body">
                    
                    <form className="row" onSubmit={this.handleSearchSubmit}>
                        <div className="col-md-12">
                            <div className="row align-items-start">
                                <div className="col-md-6">
                                    <label htmlFor="search-input">Código de cuenta</label>
                                    <div className="input-group mb-3 mb-md-0">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>
                                        {/* <span className={`btn ${!this.state.busqueda.length && 'd-none'}`} onClick={() => this.resetSearchForm()} style={styles.searchInputClose}>
                                            <i className="fas fa-times"></i>
                                        </span> */}
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="search-input" 
                                            placeholder="Código de la cuenta"
                                            style={styles.searchInput}
                                            onChange={this.handleSearchChange}
                                            value={this.state.busqueda}
                                            name="busqueda"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-md-3">
                                    <label htmlFor="cuenta_tipo">Tipo de cuenta</label>
                                    <select className="form-control text-uppercase" id="tipo" name="tipo" value={this.state.tipo} onChange={this.handleSearchChange}>
                                        <option value="">Tipo de cuenta</option>
                                        {   (TypesAccounts.EnumTypeAccount).map((item, idx) =>
                                            <option value={item.key} key={idx}>{(TypesAccounts.TypeAccount)[item.value]}</option>
                                        )}
                                    </select>
                                </div> */}

                                <div className="col-md-3">
                                    <label htmlFor="cuenta_tipo">Tipo de cuenta</label>
                                    <select className="form-control text-uppercase" id="id_tipo_cuenta" name="id_tipo_cuenta" value={this.state.id_tipo_cuenta} onChange={this.handleSearchChange}>
                                        <option value="">Tipo de cuenta</option>
                                        {   (this.state.collection && this.state.collection.length) && this.state.collection.map((item, idx) =>
                                            <option value={item.objectId} key={idx}>{item.value}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 mt-0 mt-md-3">
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Buscar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </>
        );
    }


}

export default AccountSearch;

const styles = {
    searchInputClose: {
        position: 'absolute',
        right: 0,
        zIndex: 4,
    },
    searchInput: {
        paddingRight: 36
    }
}