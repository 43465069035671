import React, { Component } from 'react';
import DocumentPaperLetter from './DocumentPaperLetter';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import _ from 'underscore';

function Unidades(num){

    switch(num)
    {
        case 1: return "UN";
        case 2: return "DOS";
        case 3: return "TRES";
        case 4: return "CUATRO";
        case 5: return "CINCO";
        case 6: return "SEIS";
        case 7: return "SIETE";
        case 8: return "OCHO";
        case 9: return "NUEVE";
    }

    return "";
}//Unidades()

function Decenas(num){

    let decena = Math.floor(num/10);
    let unidad = num - (decena * 10);

    switch(decena)
    {
        case 1:
            switch(unidad)
            {
                case 0: return "DIEZ";
                case 1: return "ONCE";
                case 2: return "DOCE";
                case 3: return "TRECE";
                case 4: return "CATORCE";
                case 5: return "QUINCE";
                default: return "DIECI" + Unidades(unidad);
            }
        case 2:
            switch(unidad)
            {
                case 0: return "VEINTE";
                default: return "VEINTI" + Unidades(unidad);
            }
        case 3: return DecenasY("TREINTA", unidad);
        case 4: return DecenasY("CUARENTA", unidad);
        case 5: return DecenasY("CINCUENTA", unidad);
        case 6: return DecenasY("SESENTA", unidad);
        case 7: return DecenasY("SETENTA", unidad);
        case 8: return DecenasY("OCHENTA", unidad);
        case 9: return DecenasY("NOVENTA", unidad);
        case 0: return Unidades(unidad);
    }
}//Unidades()

function DecenasY(strSin, numUnidades) {
    if (numUnidades > 0)
    return strSin + " Y " + Unidades(numUnidades)

    return strSin;
}//DecenasY()

function Centenas(num) {
    let centenas = Math.floor(num / 100);
    let decenas = num - (centenas * 100);

    switch(centenas)
    {
        case 1:
            if (decenas > 0)
                return "CIENTO " + Decenas(decenas);
            return "CIEN";
        case 2: return "DOSCIENTOS " + Decenas(decenas);
        case 3: return "TRESCIENTOS " + Decenas(decenas);
        case 4: return "CUATROCIENTOS " + Decenas(decenas);
        case 5: return "QUINIENTOS " + Decenas(decenas);
        case 6: return "SEISCIENTOS " + Decenas(decenas);
        case 7: return "SETECIENTOS " + Decenas(decenas);
        case 8: return "OCHOCIENTOS " + Decenas(decenas);
        case 9: return "NOVECIENTOS " + Decenas(decenas);
    }

    return Decenas(decenas);
}//Centenas()

function Seccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let letras = "";

    if (cientos > 0)
        if (cientos > 1)
            letras = Centenas(cientos) + " " + strPlural;
        else
            letras = strSingular;

    if (resto > 0)
        letras += "";

    return letras;
}//Seccion()

function Miles(num) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
    let strCentenas = Centenas(resto);

    if(strMiles === "")
        return strCentenas;

    return strMiles + " " + strCentenas;
}//Miles()

function Millones(num) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMillones = Seccion(num, divisor, "UN MILLON DE", "MILLONES DE");
    let strMiles = Miles(resto);

    if(strMillones === "")
        return strMiles;

    return strMillones + " " + strMiles;
}//Millones()

function NumeroALetras(num) {
    var data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letrasCentavos: "",
        letrasMonedaPlural: 'PESOS',//"PESOS", 'Dólares', 'Bolívares', 'etcs'
        letrasMonedaSingular: 'PESO', //"PESO", 'Dólar', 'Bolivar', 'etc'

        letrasMonedaCentavoPlural: "CENTAVOS",
        letrasMonedaCentavoSingular: "CENTAVO"
    };

    if (data.centavos > 0) {
        data.letrasCentavos = "CON " + (function (){
            if (data.centavos === 1)
                return Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular;
            else
                return Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural;
            })();
    };

    if(data.enteros === 0)
        return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    if (data.enteros === 1)
        return Millones(data.enteros) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
    else
        return Millones(data.enteros) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;
}

class DocumentIndividualPromissoryNote extends Component {

    constructor (props) {
        super(props)
        this.margin = this.getParamValue('no_margin');
        this.state = JSON.parse(localStorage.getItem('requisition'));
        if (!_.isEmpty(this.state.fecha_elaboracion)) {
            this.fecha = new Date(this.state.fecha_elaboracion.iso);
        }
    }
    margin = '';

    fecha = new Date();

    months = [
        'ENERO',
        'FEBRERO',
        'MARZO',
        'ABRIL',
        'MAYO',
        'JUNIO',
        'JULIO',
        'AGOSTO',
        'SEPTIEMBRE',
        'OCTUBRE',
        'NOVIEMBRE',
        'DICIEMBRE'
    ];

    getParamValue = (paramName) => {
        var url = window.location.search.substring(1); //get rid of "?" in querystring
        var qArray = url.split('&'); //get key-value pairs
        for (var i = 0; i < qArray.length; i++) 
        {
            var pArr = qArray[i].split('='); //split key and value
            if (pArr[0] === paramName) 
                return pArr[1]; //return value
        }
     }

    setGroupTables = () => {
        let newAmount = [];
        let amount = _.clone(this.state.montos);
        do{
            newAmount.push( amount.splice(0, 2) );
        } while (amount.length > 0);
        return newAmount;
    }

    setPaymentsNumberString = (number) => {
        return number < 10 ? `0${number}` : number
    }

    setPayText = (number) => {
        let text = '';
        switch (number) {
            case 7:
                text = 'SEMANAL';
                break;
            case 14:
                text = 'CATORCENAL';
                break;
            case 28:
                text = 'BICATORCENAL';
                break;                
            default:
                text = `SOBRE UN PERIODO DE ${number} DÍAS REGULARES`;
                break;
        }
        return text;
    }

    render() {
        return (
            <div className="w-100">
                {!_.isEmpty(this.state) && <DocumentPaperLetter id="paper-individual-promissory-note" className="d-flex flex-column" margin={this.margin}>

                    {this.state.montos.map((amount, index) => <div className={`${(index%2)===0?'-bg-danger pt-3':'-bg-warning pb-3'} d-flex flex-column justify-content-center`} style={{...styles.docSection}} key={index}>
                        <div className="d-flex align-items-center justify-content-between mb-3 pl-0">
                            <div className="col-3 pl-0">
                                <span className="text-center badge-primary border-0 d-block rounded-0" style={{fontSize: '12.5pt'}}>PAGARÉ</span>
                            </div>
                            <div className="col-6 pr-0">
                                <div className="d-flex align-items-center">
                                    <div className="col-2 text-right">No.</div>
                                    <div className="col-3 pl-0">
                                        {/* 1/10 significa 1 de 10 pagos. */}
                                        <span className="border border-black d-block text-center">1/{this.state.prestamo_personalizado_cantidad_pagos ? this.state.prestamo_personalizado_cantidad_pagos : this.state.cantidad_pagos_semanales}</span>
                                    </div>
                                    <div className="col-4 text-right">BUENO POR</div>
                                    <div className="col-3 p-0">
                                        <span className="border border-black d-block text-center">{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( amount.monto_autorizado )}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br/>

                        <p className="text-right">
                            EN {this.state.Sucursal.nombre}, {this.state.Sucursal.entidad_federativa} A {this.fecha.getDate()} DE {this.months[this.fecha.getMonth()]} DEL {this.fecha.getFullYear()}.
                        </p>

                        <p className="text-justify">
                            DEBO Y PAGARÉ INCONDICIONALMENTE SIN PRETEXTO ESTE PAGARE A LA ORDEN DE SOLFIN DEL SURESTE S.A.S. DE C.V., EN LA FECHA CITADA DONDE ELIJA EL TENOR EL DÍA DE SU VENCIMIENTO A LA ORDEN DE LA EMPRESA ANTES CITADA, EN EL LUGAR UBICADO EN {this.state.Sucursal.direccion}; {this.state.Sucursal.nombre}, {this.state.Sucursal.entidad_federativa} EL DÍA {this.fecha.getDate()} DE {this.months[this.fecha.getMonth()]} DEL {this.fecha.getFullYear()}.
                        </p>

                        <p>
                            LA CANTIDAD DE <br/>
                            {NumeroALetras(amount.monto_autorizado)} 00/100 M.N.
                        </p>

                        <p className="text-justify">
                            VALOR RECIBIDO A MI ENTERA SATISFACCIÓN. ESTE PAGARE FORMA PARTE DE UNA SERIE NUMERADA DEL 01 AL {this.setPaymentsNumberString(this.state.prestamo_personalizado_cantidad_pagos ? this.state.prestamo_personalizado_cantidad_pagos : this.state.cantidad_pagos_semanales)} Y TODOS ESTÁN SUJETOS A LA CONDICIÓN DE QUE, AL NO PAGARSE CUALQUIERA DE ELLOS A SU VENCIMIENTO, SERÁN EXIGIBLES TODOS LOS QUE LE SIGAN EN NÚMERO, ADEMÁS DE LOS YA VENCIDOS DE ACUERDO A LO ESTABLECIDO EN EL ARTÍCULO 79 DE LA LEY GENERAL DE TÍTULOS Y OPERACIONES DE CRÉDITOS DESDE LA FECHA DE VENCIMIENTO DE ESTE DOCUMENTO HASTA EL DÍA DE SU LIQUIDACIÓN, CAUSARÁ INTERESES MORATORIOS AL TIPO DE 10% {(this.state.prestamo_personalizado_periodicidad && this.setPayText(this.state.prestamo_personalizado_periodicidad)) || (this.state.cantidad_pagos_semanales && 'SEMANAL')}, PAGADERO JUNTAMENTE CON EL PRINCIPAL.
                        </p>

                        <table className="table table-borderless m-0 p-0">
                            <tbody>
                                <tr className="d-flex">
                                    <td className="d-flex flex-column w-50 justify-content-between pl-3 pt-3 pr-5 pb-0">
                                        <div className="text-center mb-2">
                                            DEUDOR
                                        </div>
                                        <div className="row">
                                            <div className="col-4">NOMBRE</div>
                                            <div className="col-8">{`${amount.Socio.nombre} ${amount.Socio.apellido_paterno} ${amount.Socio.apellido_materno}`}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">DIRECCIÓN</div>
                                            <div className="col-8">
                                            {amount.Socio.domicilio_direccion} <br/>
                                            {amount.Socio.domicilio_colonia} <br/>
                                            {amount.Socio.domicilio_poblacion}, {amount.Socio.domicilio_entidad} C.P. {amount.Socio.domicilio_cp}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 align-self-end">ACEPTO</div>
                                            <div className="col-8 col-6 align-self-end">
                                                <br/>
                                                <br/>
                                                <hr className="m-0"></hr>
                                            </div>
                                        </div>
                                        
                                    </td>

                                    
                                    {this.state.montos.length > 1 && <>
                                        {this.state.montos[index+1] && <td className="d-flex flex-column w-50 justify-content-between pl-5 pt-3 pr-3 pb-0">
                                            <div className="text-center mb-2">
                                                AVAL
                                            </div>
                                            <div className="row">
                                                <div className="col-4">NOMBRE</div>
                                                <div className="col-8">{`${this.state.montos[index+1].Socio.nombre} ${this.state.montos[index+1].Socio.apellido_paterno} ${this.state.montos[index+1].Socio.apellido_materno}`}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">DIRECCIÓN</div>
                                                <div className="col-8">
                                                {this.state.montos[index+1].Socio.domicilio_direccion} <br/>
                                                {this.state.montos[index+1].Socio.domicilio_colonia} <br/>
                                                {this.state.montos[index+1].Socio.domicilio_poblacion}, {this.state.montos[index+1].Socio.domicilio_entidad} C.P. {this.state.montos[index+1].Socio.domicilio_cp}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 align-self-end">ACEPTO</div>
                                                <div className="col-8 col-6 align-self-end">
                                                    <br/>
                                                    <br/>
                                                    <hr className="m-0"></hr>
                                                </div>
                                            </div>
                                                
                                        </td> || <td className="d-flex flex-column w-50 justify-content-between pl-5 pt-3 pr-3 pb-0">
                                            <div className="text-center mb-2">
                                                AVAL
                                            </div>
                                            <div className="row">
                                                <div className="col-4">NOMBRE</div>
                                                <div className="col-8">{`${this.state.montos[0].Socio.nombre} ${this.state.montos[0].Socio.apellido_paterno} ${this.state.montos[0].Socio.apellido_materno}`}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">DIRECCIÓN</div>
                                                <div className="col-8">
                                                {this.state.montos[0].Socio.domicilio_direccion} <br/>
                                                {this.state.montos[0].Socio.domicilio_colonia} <br/>
                                                {this.state.montos[0].Socio.domicilio_poblacion}, {this.state.montos[0].Socio.domicilio_entidad} C.P. {this.state.montos[0].Socio.domicilio_cp}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 align-self-end">ACEPTO</div>
                                                <div className="col-8 col-6 align-self-end">
                                                    <br/>
                                                    <br/>
                                                    <hr className="m-0"></hr>
                                                </div>
                                            </div>
                                                
                                        </td>}
                                    </>}
                                    {this.state.montos.length === 1 && <td className="d-flex flex-column w-50 justify-content-between pl-5 pt-3 pr-3 pb-0">
                                        {!_.isEmpty(this.state.Grupo.aval) && <><div className="text-center mb-2">
                                            AVAL
                                        </div>
                                        <div className="row">
                                            <div className="col-4">NOMBRE</div>
                                            <div className="col-8">{`${this.state.Grupo.aval.nombre} ${this.state.Grupo.aval.apellido_paterno} ${this.state.Grupo.aval.apellido_materno}`}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">DIRECCIÓN</div>
                                            <div className="col-8">
                                            {this.state.Grupo.aval.domicilio_direccion} <br/>
                                            {this.state.Grupo.aval.domicilio_colonia} <br/>
                                            {this.state.Grupo.aval.domicilio_poblacion}, {this.state.Grupo.aval.domicilio_entidad} C.P. {this.state.Grupo.aval.domicilio_cp}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 align-self-end">ACEPTO</div>
                                            <div className="col-8 col-6 align-self-end">
                                                <br/>
                                                <br/>
                                                <hr className="m-0"></hr>
                                            </div>
                                        </div></> || <p className="alert alert-danger">
                                        <i className="fas fa-exclamation-triangle"></i> No se ha establecido un aval. Proporcione los datos en la sección <b>Datos generales</b> del grupo
                                        </p>}
                                            
                                    </td>}
                                </tr>
                            </tbody>
                        </table>
                    </div>)}
                
                </DocumentPaperLetter>}
            </div>
        );
    }
}

export default DocumentIndividualPromissoryNote;

const styles = {
    docSection: {
        height: (17.97 + 2.81)+'cm',
        // margin: '1cm 0'
    }
}