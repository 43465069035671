import React, { useState } from 'react';
import GroupEdit from './GroupEdit';
import GroupHistory from './GroupHistory';
import GroupRequisition from './GroupRequisition';
import {
    NavLink,
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import user from './../../settings/user';
import './../../styles/css/partner.css';
import NotFound from './../pages/NotFound';
import GroupInfoIDs from './GroupInfoIDs';
import _ from 'underscore';
import GroupDocuments from './GroupDocuments';

function Group (props) {

    const [name_group, setNombre] = useState(null);
    const [Sucursal, setSucursal] = useState(null);
    const [id_en_sucursal, setIdEnSucursal] = useState(null);
    const [id_solicitud_actual, setIdSolicitudActual] = useState(null);
    const [id_en_sucursal_solicitud, setIdEnSucursalSolicitud] = useState(null);

    const getGroup = () => {
        const params = new URLSearchParams({
            keys: 'id_en_sucursal,nombre,Sucursal,Solicitudes',
            include: 'Sucursal,Solicitudes'
        });
        Rest.peticion(`${config.api_url}/classes/Grupos/${props.match.params.id_grupo}?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            setSucursal(res.Sucursal.sigla);
            setIdEnSucursal(res.id_en_sucursal);
            setNombre(res.nombre);
            const requisition = _.findWhere(res.Solicitudes, {status:'autorizacion'}) || _.findWhere(res.Solicitudes, {status:'autorizado'});
            if (requisition) {
                setIdSolicitudActual(requisition.objectId);
                setIdEnSucursalSolicitud(requisition.id_en_sucursal);
            }
            
            return res;
        })
        .catch(error => {
            return error;
        });
        
    }

    getGroup();
    
    let { path, url } = useRouteMatch();
    
    return (
        <>
            <div className="row align-items-center justify-content-between">
                <div className="col-md-6">
                    <h3 className="text-uppercase text-pink pt-3 pb-3 m-0">
                        <i className="fas fa-edit mr-2"></i>
                        Edición
                    </h3>
                </div>
                <div className="col col-md-auto pt-3 pb-3">
                    <small>
                        <nav aria-label="breadcrumb">
                            <div className="breadcrumb m-0 d-flex justify-content-end justify-content-md-start">
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/escritorio">Escritorio</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/escritorio">Escritorio</NavLink>

                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/grupos">Grupos</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted text-capitalize" to={`/grupos/${id_en_sucursal}`}>{name_group? name_group:'Edición'}</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/grupos/${id_en_sucursal}/datos-generales`}>Datos generales</NavLink>
                                {/* <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/grupos/${id_en_sucursal}/historial`}>Historial</NavLink> */}
                            </div>
                        </nav>
                    </small>
                </div>
            </div>
            
            <div className="card solfin-card">
                <div className="card-body">

                    <GroupInfoIDs id_en_sucursal={id_en_sucursal} Sucursal={Sucursal} id_en_sucursal_solicitud={id_en_sucursal_solicitud}/>

                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink title="Datos personales" className="nav-link" to={`${url}/datos-generales`} activeClassName="active">
                                <i className="fas fa-id-card mr-md-2"></i>
                                <span className="d-none d-sm-inline">Datos generales</span>
                            </NavLink>
                        </li>
                        {(
                            (user.rol.permisos.Autorizaciones && user.rol.permisos.Autorizaciones.consultar) ||
                            (user.rol.permisos.Autorizados && user.rol.permisos.Autorizados.consultar)
                         ) && !_.isNull(id_solicitud_actual) && <li className="nav-item">
                            <NavLink title="Historial" className="nav-link" exact to={`${url}/solicitud-actual/${id_solicitud_actual}`} activeClassName="active">
                                <i className="fas fa-tasks mr-md-2"></i>
                                <span className="d-none d-sm-inline">Solicitud actual</span>
                            </NavLink>
                        </li>}
                        {(user.rol.permisos.GruposHistorial && user.rol.permisos.GruposHistorial.consultar) && <li className="nav-item">
                            <NavLink title="Historial" className="nav-link" to={`${url}/historial`} activeClassName="active">
                                <i className="fas fa-history mr-md-2"></i>
                                <span className="d-none d-sm-inline">Historial</span>
                            </NavLink>
                        </li>}
                        {(user.rol.permisos.Grupos && user.rol.permisos.Grupos.consultar) && <li className="nav-item">
                            <NavLink title="Documentos" className="nav-link" to={`${url}/documentos`} activeClassName="active">
                                <i className="fas fa-folder-open mr-md-2"></i>
                                <span className="d-none d-sm-inline">Documentos</span>
                            </NavLink>
                        </li>}
                    </ul>
                    
                    <div className="pt-3">
                        <Switch>
                            <Route exact path={`${path}/datos-generales`} component={GroupEdit} />
                            {(user.rol.permisos.GruposHistorial && user.rol.permisos.GruposHistorial.consultar) && <Route exact path={`${path}/historial`} component={GroupHistory} />}
                            {(
                                (user.rol.permisos.Autorizaciones && user.rol.permisos.Autorizaciones.consultar) ||
                                (user.rol.permisos.Autorizados && user.rol.permisos.Autorizados.consultar)
                            ) && <Route exact path={`${path}/solicitud-actual/:id_solicitud`} component={GroupRequisition} />}
                            {(user.rol.permisos.GruposHistorial && user.rol.permisos.GruposHistorial.consultar) && <Route exact path={`${path}/historial/:id_solicitud`} component={GroupRequisition} />}
                            {(user.rol.permisos.Grupos && user.rol.permisos.Grupos.consultar) && <Route exact path={`${path}/documentos`} component={GroupDocuments} />}
                            <Route exact path={`${path}/`} component={
                                () => (
                                    <div className="alert alert-info" role="alert">
                                        <big><i className="fas fa-hand-point-up"></i></big> Navega en las secciones de arriba
                                    </div>
                                )
                            } />
                            <Route component={NotFound} />
                        </Switch>
                    </div>
                    
                    
                </div>
            </div>
            <div className="p-5"></div>
        </>
    );

}

export default Group;