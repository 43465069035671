import React from 'react';
import formatString from '../../utilities/format-string';
import GroupListAutorization from './GroupListAutorization';

class GroupListAuthorized extends GroupListAutorization {
    title = 'Autorizados';

    status = 'autorizado';
    
    order = '-fecha_autorizacion';

    columnas = ['#', 'Nombre', 'ID', 'Sucursal', 'Status', 'Autorizado al'];

    setDate = (requisition) => {
        try {
            const d = new Date(requisition.fecha_autorizacion.iso);
            return  formatString.setLegibleDate(d.toISOString());
        } catch (error) {
            console.log(error);
            return 'Sin especificar';
        }
    }

    setHead = <h3 className="text-uppercase text-pink pt-3 pb-3 d-flex">
        <i className="fas fa-users d-flex align-items-start mr-2">
            <small><small><i className="fas fa-check"></i></small></small>
        </i>
        {this.title}
    </h3>
}

export default GroupListAuthorized;