import React, { Component } from 'react';
import './../../styles/css/paper-policy.css';

class DocumentPaperLetterCheckPolicy extends Component {
    render() {
        return (
            <div id="paper-policy" className={this.props.className} style={this.props.margin === 'true'?{padding:'2cm 0'}:styles.preview}>
                {this.props.children}
            </div>
        );
    }
}

export default DocumentPaperLetterCheckPolicy;

const styles = {
    preview: {
        backgroundColor: 'white',
        fontSize: '0.38805555555556cm',
        width: (21.59+(2*4))+'cm',
        minHeight: (27.94+(2*6))+'cm',
        padding: '2cm',
        margin: '3rem auto',
        boxShadow: '0px 8px 15px -10px black'
    }
}