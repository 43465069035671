import React, { useState } from 'react';
import EmployeeHistory from './EmployeeHistory';
import EmployeeEdit from './EmployeeEdit';
import {
    NavLink,
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import Spinner from './../others/Spinner';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import user from './../../settings/user';
import './../../styles/css/partner.css';
import NotFound from './../pages/NotFound';
import EmployeeCredits from './EmployeeCredits';

function Employee (props) {

    const [loading] = useState(null);
    const [name_socio, setNameSocio] = useState(null);
    const [Sucursal, setSucursal] = useState(null);
    const [id_en_sucursal, setIdEnSucursal] = useState(null);

    const setFirstText = (text) => {
        const textArray = text.split(' ');
        return textArray[0];
    }

    const getEmployee = () => {
        const params = new URLSearchParams({
            keys: 'Empleado.id_en_sucursal,Empleado.nombre,Empleado.apellido_paterno,Empleado.Sucursal',
            include: 'Empleado,Empleado.Sucursal'
        });
        Rest.peticion(`${config.api_url}/classes/EmpleadosDiccionario/${props.match.params.id_diccionario}?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            setSucursal(res.Empleado.Sucursal.sigla);
            setIdEnSucursal(res.Empleado.id_en_sucursal);
            setNameSocio((setFirstText(res.Empleado.nombre)+' '+res.Empleado.apellido_paterno).toLowerCase());
            return res;
            
        })
        .catch(error => {
            return error;
        });
    }

    getEmployee();
    
    let { path, url } = useRouteMatch();
    
    return (
        <>
            <Spinner loading={loading} />
            <div className="row align-items-center justify-content-between">
                <div className="col-md-6">
                    <h3 className="text-uppercase text-pink pt-3 pb-3 m-0">
                        <i className="fas fa-user-edit mr-2"></i>
                        Edición
                    </h3>
                </div>
                <div className="col col-md-auto pt-3 pb-3">
                    <small>
                        <nav aria-label="breadcrumb">
                            <div className="breadcrumb m-0 d-flex justify-content-end justify-content-md-start">
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/escritorio">Escritorio</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/escritorio">Escritorio</NavLink>

                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to="/empleados">Empleados</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted text-capitalize" to={`/empleados/${props.match.params.id_diccionario}/${props.match.params.id_empleado}`}>{name_socio? name_socio:'Edición'}</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/empleados/${props.match.params.id_diccionario}/${props.match.params.id_empleado}/datos-generales`}>Datos generales</NavLink>
                                <NavLink className="breadcrumb-item" activeClassName="active text-muted" to={`/empleados/${props.match.params.id_diccionario}/${props.match.params.id_empleado}/historial`}>Historial</NavLink>
                            </div>
                        </nav>
                    </small>
                </div>
            </div>
            
            <div className="card solfin-card">
                <div className="card-body">

                    <div className="d-flex align-items-start justify-content-between">
                        <div className="h3 mb-4">
                            <span className="badge badge-light shadow">
                                <code>ID: {Sucursal && (`${id_en_sucursal}-${Sucursal}`)}</code>
                            </span>
                        </div>
                    </div>

                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink title="Datos personales" className="nav-link" to={`${url}/datos-generales`} activeClassName="active">
                                <i className="fas fa-id-card mr-md-2"></i>
                                <span className="d-none d-sm-inline">Datos generales</span>
                            </NavLink>
                        </li>
                        {(user.rol.permisos.EmpleadosHistorial && user.rol.permisos.EmpleadosHistorial.consultar) && <li className="nav-item">
                            <NavLink title="Historial" className="nav-link" to={`${url}/historial`} activeClassName="active">
                                <i className="fas fa-history mr-md-2"></i>
                                <span className="d-none d-sm-inline">Historial</span>
                            </NavLink>
                        </li>}
                        {(user.rol.permisos.Empleados && user.rol.permisos.Empleados.editar) && <li className="nav-item">
                            <NavLink title="Lista de créditos" className="nav-link" to={`${url}/lista-creditos`} activeClassName="active">
                                <i className="fas fa-money-bill-wave mr-md-2"></i>
                                <span className="d-none d-sm-inline">Lista de créditos</span>
                            </NavLink>
                        </li>}
                    </ul>
                        
                    <br />

                    <Switch>
                        {(user.rol.permisos.Empleados && user.rol.permisos.Empleados.consultar) && <Route exact path={`${path}/datos-generales`} component={EmployeeEdit} />}
                        {(user.rol.permisos.EmpleadosHistorial && user.rol.permisos.EmpleadosHistorial.consultar) && <Route exact path={`${path}/historial`} component={EmployeeHistory} />}
                        {(user.rol.permisos.Empleados && user.rol.permisos.Empleados.editar) && <Route exact path={`${path}/lista-creditos`} component={EmployeeCredits} />}
                        <Route exact path={`${path}/`} component={
                            () => (
                                <div className="alert alert-info" role="alert">
                                    <big><i className="fas fa-hand-point-up"></i></big> Navega en las secciones de arriba
                                </div>
                            )
                        } />
                        <Route component={NotFound} />
                    </Switch>
                    
                    
                </div>
            </div>
            <div className="p-5"></div>
        </>
    );

}

export default Employee;