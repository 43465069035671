import React, { Component } from 'react';
import InputDate from './../forms/InputDate';
import entidadesMexico from './../../colecciones/entidades-mexico.json';
import paises from './../../colecciones/paises.json';
import _ from 'underscore';
import InputFile from '../forms/InputFile';
import InputTel from '../forms/InputTel';
import Rest from './../../Server/Rest';
import config from './../../settings/config';
import SelectBranchOffice from './../forms/SelectBranchOffice';
import Parse from 'parse';

class PartnerForm extends Component {
    constructor(props) {
        super(props);

        this.state = this.state_init();

        const d = new Date();
        this.fecha_registro = d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();

        this.handleChange     = this.handleChange.bind(this);
        this.handleChangeCP   = this.handleChangeCP.bind(this);
        this.fileInput              = React.createRef();
        this.nota                   = React.createRef();
    }

    fecha_registro = '';

    settlements = [];

    state_init = () => {
        return {
            // (fieldset) datos preliminares
            lugar_registro: Parse.User.current().toJSON().Sucursal ? Parse.User.current().toJSON().Sucursal.objectId : '', // Pointer,
            status: '',
            status_comentario: '',
    
            // (fieldset) datos personales
            apellido_paterno: '',
            apellido_materno: '',
            nombre: '',
            sexo: '',
            clave_elector: '',
            numero_ocr: '',
            nacimiento_pais: '',
            nacimiento_entidad: '',
            nacimiento_fecha: '',
            curp: '',
            estado_civil: '',
            numero_dependientes: 0,
            ocupacion: '',
            telefono: '',
            conyugue_nombre: '',
            conyugue_lugar_trabajo: '',
    
            // datos domiciliarios
            domicilio_direccion: '',
            domicilio_colonia: '',
            domicilio_cp: '',
            domicilio_poblacion: '',
            domicilio_entidad: '',
            domicilio_tiempo_habitado: '',
            domicilio_tipo: '',
            domicilio_numero_servicio: '',
            domicilio_numero_servicio_tipo: '',
    
            // datos de garantia prendaria
            garantia_prendaria_1_nombre: '',
            garantia_prendaria_1_marca: '',
            garantia_prendaria_1_estado: '',
            garantia_prendaria_2_nombre: '',
            garantia_prendaria_2_marca: '',
            garantia_prendaria_2_estado: '',
            garantia_prendaria_3_nombre: '',
            garantia_prendaria_3_marca: '',
            garantia_prendaria_3_estado: '',
    
            // referencias personales
            referencia_1_nombre_completo: '',
            referencia_1_direccion: '',
            referencia_1_telefono: '',
            referencia_2_nombre_completo: '',
            referencia_2_direccion: '',
            referencia_2_telefono: '',
    
            // datos económicos
            destino_credito: '',
            gastos_negocio: 0,
            gastos_prestamo_banco: 0,
            gastos_prestamo_departamental: 0,
            gastos_prestamo_financiera: 0,
            gastos_prestamo_otro: 0,
            gastos_prestamo_personal: 0,
            gastos_prestamo_tdd: 0,
            gastos_vivienda_agua: 0,
            gastos_vivienda_luz: 0,
            gastos_vivienda_otros: 0,
            gastos_vivienda_renta_casa: 0,
            gastos_vivienda_telefono: 0,
            gastos_vivienda_transporte: 0,
            ingreso_beca: 0,
            ingreso_otros_trabajo: 0,
            ingreso_otros_ventas_totales: 0,
            ingreso_otros: 0,
            ingreso_prospera: 0,
            negocio_tiempo_activo: '',
            negocio_tipo: '',
            negocio_venta: 0,
            observacion_vivienda: '',
            sueldo_conyugue: 0,
    
            // datos mercado
            datos_mercado_pregunta_1: '',
            datos_mercado_pregunta_2: '',
            datos_mercado_pregunta_3: '',
    
            // totales
            total_ingresos: 0
            
        }
    };

    setRegisterDate = (fecha) => {
        let d = new Date();
        if (_.isString(fecha)) {
            d = new Date(fecha);
            this.fecha_registro = d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();
        } else {
            this.fecha_registro = d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();
        }
        
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // para resetear el formulario
        if (nextProps.resetform === true) {
            this.setRegisterDate();
            this.setState(this.state_init());
        }
        // sirve para la edición de socios
        if (!_.isEmpty(nextProps.data) && nextProps.resetform !== true) {
            // Establecemos la fecha de registro
            this.setRegisterDate(nextProps.data.fecha_registro);
            this.setState(nextProps.data);
            this.getDataByCP(nextProps.data.domicilio_cp);
        }
    }

    componentWillUpdate = (nextProps, nextState) => {
        this.props.setData(nextState, this.fileInput, this.nota.current.value);
    }

    setTotalIncome = () => {

        const negocio_venta =                parseInt(this.state.negocio_venta) || 0;
        const sueldo_conyugue =              parseInt(this.state.sueldo_conyugue) || 0;
        const ingreso_prospera =             parseInt(this.state.ingreso_prospera) || 0;
        const ingreso_beca =                 parseInt(this.state.ingreso_beca) || 0;
        const ingreso_otros =                parseInt(this.state.ingreso_otros) || 0;
        const ingreso_otros_ventas_totales = parseInt(this.state.ingreso_otros_ventas_totales) || 0;
        const ingreso_otros_trabajo =        parseInt(this.state.ingreso_otros_trabajo) || 0;

        let total = 
        negocio_venta +
        sueldo_conyugue +
        ingreso_prospera +
        ingreso_beca +
        ingreso_otros +
        ingreso_otros_ventas_totales +
        ingreso_otros_trabajo;

        return total;

    }

    setTotalExpenses = () => {

        const gastos_negocio =                  parseInt(this.state.gastos_negocio) || 0;
        const gastos_prestamo_banco =           parseInt(this.state.gastos_prestamo_banco) || 0;
        const gastos_prestamo_departamental =   parseInt(this.state.gastos_prestamo_departamental) || 0;
        const gastos_prestamo_financiera =      parseInt(this.state.gastos_prestamo_financiera) || 0;
        const gastos_prestamo_otro =            parseInt(this.state.gastos_prestamo_otro) || 0;
        const gastos_prestamo_personal =        parseInt(this.state.gastos_prestamo_personal) || 0;
        const gastos_prestamo_tdd =             parseInt(this.state.gastos_prestamo_tdd) || 0;
        const gastos_vivienda_agua =            parseInt(this.state.gastos_vivienda_agua) || 0;
        const gastos_vivienda_luz =             parseInt(this.state.gastos_vivienda_luz) || 0;
        const gastos_vivienda_otros =           parseInt(this.state.gastos_vivienda_otros) || 0;
        const gastos_vivienda_renta_casa =      parseInt(this.state.gastos_vivienda_renta_casa) || 0;
        const gastos_vivienda_telefono =        parseInt(this.state.gastos_vivienda_telefono) || 0;
        const gastos_vivienda_transporte =      parseInt(this.state.gastos_vivienda_transporte) || 0;

        let total = 
        gastos_negocio +
        gastos_prestamo_banco +
        gastos_prestamo_departamental +
        gastos_prestamo_financiera +
        gastos_prestamo_otro +
        gastos_prestamo_personal +
        gastos_prestamo_tdd +
        gastos_vivienda_agua +
        gastos_vivienda_luz +
        gastos_vivienda_otros +
        gastos_vivienda_renta_casa +
        gastos_vivienda_telefono +
        gastos_vivienda_transporte;

        return total;
    }

    setMonthlyTotal = () => (this.setTotalIncome() - this.setTotalExpenses());
    
    handleChange = (event) => {
            
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if (!_.isEmpty(value) && target.type === "number") {
            value = parseInt(value);
        }
    
        this.setState({
            [name]: value
        });

    }

    handleChangeCP = (event) => {
        
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });

        this.getDataByCP(value);

    }

    getDataByCP = async (cp) => {
        if (cp.length === 5) {
                
            this.settlements = [];
            this.setState({
                domicilio_poblacion: '',
                domicilio_entidad: ''
            });
            if (!_.isEmpty(cp)) {
                
                const params = new URLSearchParams({
                    where: JSON.stringify({
                        cp: cp
                    }),
                    limit: 1000,
                    count: 1
                });

                const solfinCP = await Rest.peticion(`${config.api_url}/classes/ColoniasBarrios?${params.toString()}`, {
                    method: 'GET'
                }).then(res => {return res.json()}).then(res => {return res}).catch(error => console.log(error));

                if (!solfinCP.code) {

                    if (solfinCP.results.length) {

                        _.each(_.pluck(solfinCP.results, 'nombre'), (asentamiento) => {
                            this.settlements.push( asentamiento.toUpperCase() );
                        });

                    }
                }


                const solfin = await Rest.peticion(`${config.api_url}/classes/CodigoPostal?${params.toString()}`, {
                    method: 'GET'
                }).then(res => {return res.json()}).then(res => {return res}).catch(error => console.log(error));
                
                if (!solfin.code) {
                    
                    if (solfin.results.length) {

                        _.each(solfin.results[0].asentamiento, (asentamiento) => {
                            
                            this.settlements.push( asentamiento.toUpperCase() );
                            
                        });
                        
                        this.settlements = this.settlements.sort();
                        this.settlements = _.uniq(this.settlements);

                                        
                        this.setState({
                            domicilio_poblacion: solfin.results[0].municipio,
                            domicilio_entidad: solfin.results[0].estado
                        });
 
                        return;
                    }
                }

                
               const sepomex = await fetch('https://api.copomex.com/query/info_cp/'+cp+'?type=simplified&token=bfc2983b-6538-4c83-9655-6e2db67d4090', {
                   method: 'GET'
               })
               .then(res => {
                   return res.json();
               })
               .then(res => {

               if (res.error) {
                        return false
                    } else {
                        return res;
                }

               })
                .catch(error => {
                    console.log(error);
               });


                if (sepomex) {
                    
                    _.each(sepomex.response.asentamiento, (asentamiento) => {
                        
                        this.settlements.push(asentamiento.toUpperCase());

                    });
                    
                    //this.settlements.push(this.state.domicilio_colonia);
                    
                    this.settlements = this.settlements.sort();
                    this.settlements = _.uniq(this.settlements);
                    
                    this.setState({
                        domicilio_poblacion: sepomex.response.municipio,
                        domicilio_entidad: sepomex.response.estado
                    });
 
                    Rest.peticion(`${config.api_url}/classes/CodigoPostal?`, {
                        method: 'POST',
                        body: JSON.stringify(sepomex.response),
                    }).catch(error => console.log(error));

                }
                
            }
        }
        
    }

    handleChangeSettlement = () => {
        this.settlements = [];
        
        this.setState({
            domicilio_colonia: ''
        });
    }

    render() {
        return (
            <>
                {this.html_datosPreliminares()}
                
                {this.html_datosPersonales()}

                {this.html_datosDomicilio()}

                {this.html_garantiaPrendaria()}

                {this.html_referenciasPersonales()}

                {this.html_datosEconomicos()}

                {this.html_datosMercado()}

                {this.html_buro()}
            </>
        );
    }

    html_datosPreliminares = () => (
        <div className="card mb-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="fecha-registro">Fecha de registro</label>
                            <span className="form-control text-muted" id="fecha-registro">{this.fecha_registro}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="lugar-registro">Lugar de registro</label>
                            {(Parse.User.current().toJSON().Sucursal && 
                            <SelectBranchOffice disabled={true} name="lugar_registro" id="lugar-registro" handleValidName={this.props.handleChange} value={Parse.User.current().toJSON().Sucursal.objectId} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleChange} required={true} />)
                            || <SelectBranchOffice name="lugar_registro" id="lugar-registro" value={this.state.lugar_registro} onChange={this.handleChange} required={this.props.required} />}
                        </div>
                    </div>
                    <div className="col-md-8">
                        <label>Status</label>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="status" id="status_activo" value="ACTIVO" checked={this.state.status === 'ACTIVO'} onChange={this.handleChange} required={this.props.required} />
                                    <label className="form-check-label" htmlFor="status_activo">
                                        Activa
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="status" id="status_baja_voluntaria" value="BAJA_VOLUNTARIA" checked={this.state.status === 'BAJA_VOLUNTARIA'} onChange={this.handleChange} required={this.props.required} />
                                    <label className="form-check-label" htmlFor="status_baja_voluntaria">
                                        Baja voluntaria
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="status" id="status_baja_castigada" value="BAJA_CASTIGADA" checked={this.state.status === 'BAJA_CASTIGADA'} onChange={this.handleChange} required={this.props.required} />
                                    <label className="form-check-label" htmlFor="status_baja_castigada">
                                        Baja castigada
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="status" id="status_baja_rechazada" value="RECHAZADO" checked={this.state.status === 'RECHAZADO'} onChange={this.handleChange} required={this.props.required} />
                                    <label className="form-check-label" htmlFor="status_baja_rechazada">
                                        Rechazada
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="status" id="status_baja_morocidad" value="MOROSIDAD" checked={this.state.status === 'MOROSIDAD'} onChange={this.handleChange} required={this.props.required} />
                                    <label className="form-check-label" htmlFor="status_baja_morocidad">
                                        Morosidad
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="status" id="status_baja_fallecida" value="FALLECIDA" checked={this.state.status === 'FALLECIDA'} onChange={this.handleChange} required={this.props.required} />
                                    <label className="form-check-label" htmlFor="status_baja_fallecida">
                                        Fallecida
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <label htmlFor="status_comentario">Comentario del status
                                    {(this.state.status === 'BAJA_VOLUNTARIA') && <span>*</span>}
                                    {(this.state.status === 'BAJA_CASTIGADA') && <span>*</span>}
                                    {(this.state.status === 'RECHAZADO') && <span>*</span>}
                                    {(this.state.status === 'MOROSIDAD') && <span>*</span>}
                                </label>
                                <textarea className="form-control text-uppercase" id="status_comentario" name="status_comentario" value={this.state.status_comentario} onChange={this.handleChange} rows="3" required={
                                    (this.state.status === 'BAJA_VOLUNTARIA') ||
                                    (this.state.status === 'BAJA_CASTIGADA') ||
                                    (this.state.status === 'RECHAZADO') ||
                                    (this.state.status === 'MOROSIDAD')
                                } ></textarea>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );

    html_datosPersonales = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Datos personales</legend>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="apellido_paterno">Apellido paterno</label>
                        <input type="text" className="form-control text-uppercase" id="apellido_paterno" name="apellido_paterno" value={this.state.apellido_paterno} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="apellido_materno">Apellido materno</label>
                        <input type="text" className="form-control text-uppercase" id="apellido_materno" name="apellido_materno" value={this.state.apellido_materno} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="nombre_completo">Nombre(s)</label>
                        <input type="text" className="form-control text-uppercase" id="nombre_completo" name="nombre" value={this.state.nombre} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="Sexo">Sexo</label>
                        <div className="d-flex">
                            <div className="form-check col">
                                <input className="form-check-input" type="radio" id="sexo_hombre" name="sexo" value="HOMBRE" checked={this.state.sexo === 'HOMBRE'} onChange={this.handleChange} required={this.props.required} />
                                <label className="form-check-label" htmlFor="sexo_hombre">
                                Hombre
                                </label>
                            </div>
                            <div className="form-check col">
                                <input className="form-check-input" type="radio" id="sexo_mujer" name="sexo" value="MUJER" checked={this.state.sexo === 'MUJER'} onChange={this.handleChange} required={this.props.required} />
                                <label className="form-check-label" htmlFor="sexo_mujer">
                                Mujer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12"></div>
                    <div className="form-group col-md-4">
                        <label htmlFor="clave_elector">Clave de elector</label>
                        <input type="text" className="form-control text-uppercase" maxLength="18" minLength="18" id="clave_elector" name="clave_elector" value={this.state.clave_elector} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="numero_ocr">Número de OCR</label>
                        <input type="text" className="form-control text-uppercase" maxLength="13" minLength="13" id="numero_ocr" name="numero_ocr" value={this.state.numero_ocr} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="pais_nacimiento">País de nacimiento</label>
                        <select className="form-control text-uppercase" id="pais_nacimiento" name="nacimiento_pais" value={this.state.nacimiento_pais} onChange={this.handleChange} required={this.props.required} >
                            <option value="" disabled>Elija una opción...</option>
                            {paises.map((pais, index) => <option value={pais} key={index}>{pais}</option>)}
                        </select> 
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="entidad_nacimiento">Entidad de nacimiento</label>
                        {
                            (
                                this.state.nacimiento_pais === 'MÉXICO'
                            ) && <select className="form-control text-uppercase" id="entidad_nacimiento" name="nacimiento_entidad" value={this.state.nacimiento_entidad} onChange={this.handleChange} required={this.props.required} >
                                <option value="" disabled>Elija una opción...</option>
                                {entidadesMexico.map((entidad, index) => <option value={entidad} key={index}>{entidad}</option>)}
                            </select> ||
                            <input type="text" className="form-control text-uppercase" id="entidad_nacimiento" name="nacimiento_entidad" value={this.state.nacimiento_entidad} onChange={this.handleChange} required={this.props.required} />
                        }
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="fecha_nacimiento">Fecha de nacimiento</label>
                        <InputDate handleChange={this.handleChange} name="nacimiento_fecha" hasDate={this.state.nacimiento_fecha} resetform={this.props.resetform} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="curp">CURP</label>
                        <input type="text" className="form-control text-uppercase" id="curp" name="curp" value={this.state.curp} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="estado_civil">Estado civil</label>
                        <select className="form-control text-uppercase" id="estado_civil" name="estado_civil" value={this.state.estado_civil} onChange={this.handleChange} required={this.props.required} >
                            <option value="" disabled>Elija una opción...</option>
                            <option value="SOLTERO">SOLTERA</option>
                            <option value="CASADO">CASADA</option>
                            <option value="DIVORCIADO">DIVORCIADA</option>
                            <option value="UNIÓN LIBRE">UNIÓN LIBRE</option>
                            <option value="VIUDO">VIUDA</option>
                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="numero_dependientes">Número de dependientes</label>
                        <input type="number" className="form-control" id="numero_dependientes" name="numero_dependientes" value={this.state.numero_dependientes} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="ocupacion">Ocupación</label>
                        <input type="text" className="form-control text-uppercase" id="ocupacion" name="ocupacion" value={this.state.ocupacion} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4 mb-3">
                        <label htmlFor="telefono">Teléfono</label>
                        <InputTel id="telefono" name="telefono" value={this.state.telefono} handleChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="col-12"></div>
                    <div className="form-group col-md-8">
                        <label htmlFor="conyugue_nombre">Nombre del conyugue</label>
                        <input type="text" 
                        className="form-control text-uppercase" 
                        id="conyugue_nombre" 
                        name="conyugue_nombre" 
                        value={this.state.conyugue_nombre} onChange={this.handleChange} 
                        disabled={(
                            this.state.estado_civil === 'SOLTERO' || 
                            this.state.estado_civil === 'DIVORCIADO' || 
                            this.state.estado_civil === 'VIUDO'
                        )} required={(
                            this.state.estado_civil === 'CASADO' ||
                            this.state.estado_civil === 'UNIÓN LIBRE'
                        )} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="conyugue_lugar_trabajo">Lugar de trabajo del conyugue</label>
                        <input type="text" 
                        className="form-control text-uppercase" 
                        id="conyugue_lugar_trabajo" 
                        name="conyugue_lugar_trabajo" 
                        value={this.state.conyugue_lugar_trabajo} 
                        onChange={this.handleChange} 
                        disabled={(
                            this.state.estado_civil === 'SOLTERO' || 
                            this.state.estado_civil === 'DIVORCIADO' || 
                            this.state.estado_civil === 'VIUDO'
                        )} required={(
                            this.state.estado_civil === 'CASADO' ||
                            this.state.estado_civil === 'UNIÓN LIBRE'
                        )} />
                    </div>
                </div>
            </div>
        </fieldset>
    );

    html_datosDomicilio = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Datos del domicilio</legend>
                <div className="row">
                    <div className="form-group col-md-8">
                        <label htmlFor="domicilio_direccion">Dirección</label>
                        <input type="text" className="form-control text-uppercase" id="domicilio_direccion" name="domicilio_direccion" value={this.state.domicilio_direccion} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="domicilio_cp">C.P.</label>
                        <input type="text" className="form-control text-uppercase" id="cp" name="domicilio_cp" value={this.state.domicilio_cp} onChange={this.handleChangeCP} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="domicilio_entidad">Estado</label>
                        <input type="text" className="form-control text-uppercase" id="domicilio_entidad" name="domicilio_entidad" value={this.state.domicilio_entidad} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="domicilio_poblacion">Población</label>
                        <input type="text" className="form-control text-uppercase" id="domicilio_poblacion" name="domicilio_poblacion" value={this.state.domicilio_poblacion} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="domicilio_colonia">Fraccionamiento o Colonia</label>
                        {
                            (this.settlements.length && <div className="input-group">
                                <select className="custom-select text-uppercase" id="domicilio_colonia" name="domicilio_colonia" value={this.state.domicilio_colonia} onChange={this.handleChange} required={this.props.required} >
                                    <option value="">Elija una opción...</option>
                                    {this.settlements.map((asentamiento, index) => <option value={asentamiento} key={index}>{asentamiento}</option>)}
                                </select>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={this.handleChangeSettlement}>Otro</button>
                                </div>
                            </div>) || (
                                <>
                                    <input type="text" className="form-control text-uppercase" id="domicilio_colonia" name="domicilio_colonia" value={this.state.domicilio_colonia} onChange={this.handleChange} required={this.props.required} />
                                    <small className="form-text">Escriba un C.P. para obtener una lista</small>
                                </>
                            )
                        }
                        
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="domicilio_tiempo_habitado">Tiempo habitado en ella</label>
                        <input type="text" className="form-control text-uppercase" id="domicilio_tiempo_habitado" name="domicilio_tiempo_habitado" value={this.state.domicilio_tiempo_habitado} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    
                    <div className="form-group col-md-4">
                        <label htmlFor="Sexo">Domicilio</label>
                        <div className="d-flex flex-column flex-sm-row">
                            <div className="form-check col">
                                <input className="form-check-input" type="radio" name="domicilio_tipo" id="domicilio_propio" value="PROPIO" checked={this.state.domicilio_tipo === 'PROPIO'} onChange={this.handleChange} required={this.props.required} />
                                <label className="form-check-label" htmlFor="domicilio_propio">
                                Propio
                                </label>
                            </div>
                            <div className="form-check col">
                                <input className="form-check-input" type="radio" name="domicilio_tipo" id="domicilio_rentado" value="RENTADO" checked={this.state.domicilio_tipo === 'RENTADO'} onChange={this.handleChange} required={this.props.required} />
                                <label className="form-check-label" htmlFor="domicilio_rentado">
                                Rentado
                                </label>
                            </div>
                            <div className="form-check col">
                                <input className="form-check-input" type="radio" name="domicilio_tipo" id="domicilio_familiar" value="FAMILIAR" checked={this.state.domicilio_tipo === 'FAMILIAR'} onChange={this.handleChange} required={this.props.required} />
                                <label className="form-check-label" htmlFor="domicilio_familiar">
                                Familiar
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="row">
                            <div className="form-group col-7">
                                <label htmlFor="domicilio_numero_servicio">Número de servicio</label>
                                <input type="text" className="form-control text-uppercase"  id="domicilio_numero_servicio" name="domicilio_numero_servicio" value={this.state.domicilio_numero_servicio} onChange={this.handleChange} required={this.props.required} />
                            </div>
                            
                            <div className="form-group col-5 pl-0">
                                <label htmlFor="domicilio_numero_servicio_tipo">Tipo servicio</label>
                                <select className="custom-select" id="domicilio_numero_servicio_tipo" name="domicilio_numero_servicio_tipo" value={this.state.domicilio_numero_servicio_tipo} onChange={this.handleChange} required={this.props.required}>
                                    <option value="" disabled>Elija una opción...</option>
                                    <option value="AGUA">Agua</option>
                                    <option value="CABLE">Cable</option>
                                    <option value="LUZ">Luz</option>
                                    <option value="TELÉFONO">Teléfono</option>
                                    <option value="CONSTANCIA DE RESIDENCIA">Constancia de residencia</option>
                                </select>
                            </div>
                                
                                
                            
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </fieldset>
    );

    html_garantiaPrendaria = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Garantías prendarias</legend>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="articulo_1_nombre">Artículo 1</label>
                        <input type="text" className="form-control text-uppercase" id="articulo_1_nombre" name="garantia_prendaria_1_nombre" value={this.state.garantia_prendaria_1_nombre} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="articulo_1_marca">Marca</label>
                        <input type="text" className="form-control text-uppercase" id="articulo_1_marca" name="garantia_prendaria_1_marca" value={this.state.garantia_prendaria_1_marca} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="articulo_1_marca">Estado del artículo</label>
                        <select className="form-control text-uppercase" id="articulo_1_marca" name="garantia_prendaria_1_estado" value={this.state.garantia_prendaria_1_estado} onChange={this.handleChange} required={this.props.required} >
                            <option value="" disabled>Elija una opción...</option>
                            <option value="BUENO">Bueno</option>
                            <option value="MALO">Malo</option>
                            <option value="REGULAR">Regular</option>
                        </select>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="articulo_2_nombre">Artículo 2</label>
                        <input type="text" className="form-control text-uppercase" id="articulo_2_nombre" name="garantia_prendaria_2_nombre" value={this.state.garantia_prendaria_2_nombre} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="articulo_2_marca">Marca</label>
                        <input type="text" className="form-control text-uppercase" id="articulo_2_marca" name="garantia_prendaria_2_marca" value={this.state.garantia_prendaria_2_marca} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="articulo_2_marca">Estado del artículo</label>
                        <select className="form-control text-uppercase" id="articulo_2_marca" name="garantia_prendaria_2_estado" value={this.state.garantia_prendaria_2_estado} onChange={this.handleChange} required={this.props.required} >
                            <option value="" disabled>Elija una opción...</option>
                            <option value="BUENO">Bueno</option>
                            <option value="MALO">Malo</option>
                            <option value="REGULAR">Regular</option>
                        </select>
                    </div>
                </div>
                <hr/>
                <div className="row">
                <div className="form-group col-md-6">
                        <label htmlFor="articulo_3_nombre">Artículo 3</label>
                        <input type="text" className="form-control text-uppercase" id="articulo_3_nombre" name="garantia_prendaria_3_nombre" value={this.state.garantia_prendaria_3_nombre} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="articulo_3_marca">Marca</label>
                        <input type="text" className="form-control text-uppercase" id="articulo_3_marca" name="garantia_prendaria_3_marca" value={this.state.garantia_prendaria_3_marca} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="articulo_3_marca">Estado del artículo</label>
                        <select className="form-control text-uppercase" id="articulo_3_marca" name="garantia_prendaria_3_estado" value={this.state.garantia_prendaria_3_estado} onChange={this.handleChange} required={this.props.required} >
                            <option value="" disabled>Elija una opción...</option>
                            <option value="BUENO">Bueno</option>
                            <option value="MALO">Malo</option>
                            <option value="REGULAR">Regular</option>
                        </select>
                    </div>
                </div>
            </div>
        </fieldset>
    );

    html_referenciasPersonales = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Referencias personales</legend>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label htmlFor="referencia_1_nombre-completo">Nombre completo</label>
                        <input type="text" className="form-control text-uppercase" id="referencia_1_nombre_completo" name="referencia_1_nombre_completo" value={this.state.referencia_1_nombre_completo} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-8">
                        <label htmlFor="referencia_1_direccion">Dirección</label>
                        <input type="text" className="form-control text-uppercase" id="referencia_1_direccion" name="referencia_1_direccion" value={this.state.referencia_1_direccion} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                    <label htmlFor="referencia_1_telefono">Teléfono</label>
                        <InputTel id="referencia_1_telefono" name="referencia_1_telefono" value={this.state.referencia_1_telefono} handleChange={this.handleChange} required={this.props.required} />
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label htmlFor="referencia_2_nombre-completo">Nombre completo</label>
                        <input type="text" className="form-control text-uppercase" id="referencia_2_nombre_completo" name="referencia_2_nombre_completo" value={this.state.referencia_2_nombre_completo} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-8">
                        <label htmlFor="referencia_2_direccion">Dirección</label>
                        <input type="text" className="form-control text-uppercase" id="referencia_2_direccion" name="referencia_2_direccion" value={this.state.referencia_2_direccion} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="referencia_2_telefono">Teléfono</label>
                        <InputTel id="referencia_2_telefono" name="referencia_2_telefono" value={this.state.referencia_2_telefono} handleChange={this.handleChange} required={this.props.required} />
                    </div>
                </div>
            </div>
        </fieldset>
    );

    html_datosEconomicos = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Datos económicos</legend>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="tipo-negocio">Tipo de negocio</label>
                        <input type="text" className="form-control text-uppercase" id="negocio_tipo" name="negocio_tipo" value={this.state.negocio_tipo} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="tiempo-actividad">Tiempo en la actividad</label>
                        <input type="text" className="form-control text-uppercase" id="negocio_tiempo_activo" name="negocio_tiempo_activo" value={this.state.negocio_tiempo_activo} onChange={this.handleChange} required={this.props.required} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="destino-credito">Destino del crédito</label>
                        <select className="form-control text-uppercase" id="destino_credito" name="destino_credito" value={this.state.destino_credito} onChange={this.handleChange} required={this.props.required} >
                            <option value="" disabled>Elija una opción...</option>
                            <option>Inversión</option>
                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="venta-negocio">Venta del negocio</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                            </div>
                            <input type="number" className="form-control" id="negocio_venta" name="negocio_venta" value={this.state.negocio_venta} onChange={this.handleChange} required={this.props.required} />
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="gastos_negocio">Gastos</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                            </div>
                            <input type="number" className="form-control" id="gastos_negocio" name="gastos_negocio" value={this.state.gastos_negocio} onChange={this.handleChange} required={this.props.required} />
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="sueldo-conyugue">Sueldo del conyugue</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                            </div>
                            <input type="number" className="form-control" id="sueldo_conyugue" name="sueldo_conyugue" value={this.state.sueldo_conyugue} onChange={this.handleChange} required={this.props.required} />
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                        <h5>Ingresos familiares</h5> 
                        <div className="card">
                            <div className="card-body">
                                <h6>Subsidio del gobierno</h6> 
                                <br/>
                                <div className="form-group row">
                                    <label htmlFor="ingreso-prospera" className="col-sm-4 col-form-label">Prospera</label>
                                    <div className="input-group col-sm-8 mb-2 align-items-start">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div>
                                        <input type="number" min="0" className="form-control" id="ingreso_prospera" name="ingreso_prospera" value={this.state.ingreso_prospera} onChange={this.handleChange} required={this.props.required} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="ingreso-beca" className="col-sm-4 col-form-label">Beca</label>
                                    <div className="input-group col-sm-8 mb-2 align-items-start">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div>
                                        <input type="number" min="0" className="form-control" id="ingreso_beca" name="ingreso_beca" value={this.state.ingreso_beca} onChange={this.handleChange} required={this.props.required} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="ingreso-otros" className="col-sm-4 col-form-label">Otros</label>
                                    <div className="input-group col-sm-8 mb-2 align-items-start">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div>
                                        <input type="number" min="0" className="form-control" id="ingreso_otros" name="ingreso_otros" value={this.state.ingreso_otros} onChange={this.handleChange} required={this.props.required} />
                                    </div>
                                </div>
                                <br/>
                                <h6>Otros ingresos</h6> 
                                <br/>
                                <div className="form-group row">
                                    <label htmlFor="otros-ingresos-ventas-totales" className="col-sm-4 col-form-label">Ventas totales</label>
                                    <div className="input-group col-sm-8 mb-2 align-items-start">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div>
                                        <input type="number" min="0" className="form-control" id="ingreso_otros_ventas_totales" name="ingreso_otros_ventas_totales" value={this.state.ingreso_otros_ventas_totales} onChange={this.handleChange} required={this.props.required} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="ingreso-otro-trabajo" className="col-sm-4 col-form-label">Otro trabajo</label>
                                    <div className="input-group col-sm-8 mb-2 align-items-start">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div>
                                        <input type="number" min="0" className="form-control" id="ingreso_otros_trabajo" name="ingreso_otros_trabajo" value={this.state.ingreso_otros_trabajo} onChange={this.handleChange} required={this.props.required} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="col-md-8">
                        <h5>Gastos familiares</h5> 
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 border-right">
                                        <h6>Vivienda</h6>
                                        <br/>
                                        
                                        <div className="form-group row">
                                            <label htmlFor="gasto-renta-casa" className="col-sm-5 col-form-label">Renta de casa</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_vivienda_renta_casa" name="gastos_vivienda_renta_casa" value={this.state.gastos_vivienda_renta_casa} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="gasto-telefono" className="col-sm-5 col-form-label">Teléfono</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_vivienda_telefono" name="gastos_vivienda_telefono" value={this.state.gastos_vivienda_telefono} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="gasto-agua" className="col-sm-5 col-form-label">Agua</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_vivienda_agua" name="gastos_vivienda_agua" value={this.state.gastos_vivienda_agua} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="gasto-luz" className="col-sm-5 col-form-label">Luz</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_vivienda_luz" name="gastos_vivienda_luz" value={this.state.gastos_vivienda_luz} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="gasto-transporte" className="col-sm-5 col-form-label">Transporte</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_vivienda_transporte" name="gastos_vivienda_transporte" value={this.state.gastos_vivienda_transporte} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="gasto-otros" className="col-sm-5 col-form-label">Otros</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_vivienda_otros" name="gastos_vivienda_otros" value={this.state.gastos_vivienda_otros} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                            
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Préstamos</h6>
                                        <br/>
                                        
                                        <div className="form-group row">
                                            <label htmlFor="prestamo-personal" className="col-sm-5 col-form-label">Personal</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_prestamo_personal" name="gastos_prestamo_personal" value={this.state.gastos_prestamo_personal} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="prestamo-financiera" className="col-sm-5 col-form-label">Financieras</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_prestamo_financiera" name="gastos_prestamo_financiera" value={this.state.gastos_prestamo_financiera} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="prestamo-banco" className="col-sm-5 col-form-label">Bancos</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_prestamo_banco" name="gastos_prestamo_banco" value={this.state.gastos_prestamo_banco} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="prestamo-tdd" className="col-sm-5 col-form-label">Trarjetas de crédito</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_prestamo_tdd" name="gastos_prestamo_tdd" value={this.state.gastos_prestamo_tdd} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="prestamo-departamental" className="col-sm-5 col-form-label">Departamentales</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_prestamo_departamental" name="gastos_prestamo_departamental" value={this.state.gastos_prestamo_departamental} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="prestamo-otro" className="col-sm-5 col-form-label">Otros</label>
                                            <div className="input-group col-sm-7 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="gastos_prestamo_otro" name="gastos_prestamo_otro" value={this.state.gastos_prestamo_otro} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    
                    <div className="form-group col-md-4">
                            <label>Total ingresos</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                            </div>
                            <span className="form-control text-muted">{this.setTotalIncome()}</span>
                        </div>
                    </div>
                    
                    
                    <div className="form-group col-md-4">
                            <label>Total egreso</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                            </div>
                            <span className="form-control text-muted">{this.setTotalExpenses()}</span>
                        </div>
                    </div>
                    
                    
                    <div className="form-group col-md-4">
                        <label>Total mensual</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                            </div>
                            <span className="form-control text-muted" >{this.setMonthlyTotal()}</span>
                        </div>
                    </div>
                    
                    <div className="form-group col-md-12">
                        <label htmlFor="observacion-vivienda">Observación de la vivienda</label>
                        <textarea className="form-control text-uppercase" id="observacion_vivienda" name="observacion_vivienda" value={this.state.observacion_vivienda} onChange={this.handleChange} rows="3" required={this.props.required} ></textarea>
                    </div>
                </div>
                
            </div>
        </fieldset>
    );

    html_datosMercado = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Datos de mercado</legend>
                <div className="form-group">
                    <label htmlFor="datos_mercado_pregunta_1">1. ¿Cómo se enteró de SOLFIN DEL SURESTE S.A.S. DE C.V.?</label>
                    <textarea className="form-control text-uppercase" id="datos_mercado_pregunta_1" name="datos_mercado_pregunta_1" value={this.state.datos_mercado_pregunta_1} onChange={this.handleChange} rows="3" required={this.props.required} ></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="datos-mercado-pregunta-2">2. ¿Por qué decidió solicitar crédito con SOLFIN DEL SURESTE S.A.S. DE C.V.?</label>
                    <textarea className="form-control text-uppercase" id="datos_mercado_pregunta_2" name="datos_mercado_pregunta_2" value={this.state.datos_mercado_pregunta_2} onChange={this.handleChange} rows="3" required={this.props.required} ></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="datos-mercado-pregunta-3">3. ¿Qué beneficios le llama más la atención de SOLFIN DEL SURESTE S.A.S. DE C.V.?</label>
                    <textarea className="form-control text-uppercase" id="datos_mercado_pregunta_3" name="datos_mercado_pregunta_3" value={this.state.datos_mercado_pregunta_3} onChange={this.handleChange} rows="3" required={this.props.required} ></textarea>
                </div>
            </div>
        </fieldset>
    );

    html_buro = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Buró de crédito</legend>
                <p className="text-info">
                    Suba el buró de la clienta en formato PDF. De lo contrario, suba una captura de pantalla que refiera la problemática y escriba una nota.
                </p>
                <InputFile fileInput={this.fileInput} nota={this.nota} resetform={this.props.resetform} required={this.props.required} />
                
            </div>
        </fieldset>
    );
}

export default PartnerForm;