import React, { Component } from 'react';
import _ from 'underscore';

class InputTel extends Component {

    constructor (props) {
        super(props);
        this.state = {
            valid: true,
            tel: ''
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        
        if (/^\d{10}$/g.test( nextProps.value )) {
            this.setState({
                isInvalid: false
            })
        } else {
            this.setState({
                isInvalid: true
            })
        }

        let res = '';
        if (!_.isEmpty(nextProps.value)) {
            res = _.map(nextProps.value, (digito) => {
                
                if (
                    (digito === '0') ||
                    (digito === '1') ||
                    (digito === '2') ||
                    (digito === '3') ||
                    (digito === '4') ||
                    (digito === '5') ||
                    (digito === '6') ||
                    (digito === '7') ||
                    (digito === '8') ||
                    (digito === '9')
                ) {
                    return digito;
                }
            })
            
            this.setState({
                tel: res.join('')
            })
        } else {
            this.setState({
                tel: ''
            })
        }
        
    }

    render() {
        return (
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-phone-alt"></i>
                    </span>
                </div>
                <input type="text" ref={this.myRef} className={'form-control' + ' ' + (this.state.isInvalid ? 'is-invalid' : '') } id={this.props.id} name={this.props.name} value={this.state.tel} maxLength="10" minLength="10" onChange={this.props.handleChange} required={this.props.required} />
                {
                    this.state.isInvalid && <div className="invalid-feedback">
                        El teléfono no es valido
                    </div>
                }
            </div>
            
        );
    }
}

export default InputTel;