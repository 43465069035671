import React, { Component } from 'react';
import Spinner from './../others/Spinner';

class GroupSpinner extends Spinner {

    setSpinner () {
        return <div className="row">
            <div className="col">
                <div className="card shadow border-0">
                    <div className="card-body">
                        <div className="row justify-content-between flex-nowrap">
                            <div className="col-auto pr-0" style={{width:40}}>
                                {
                                    !this.props.savedGroup && <i className="fas fa-spinner fa-spin fa-fw text-info"></i>
                                }
                                {
                                    this.props.savedGroup && <i className="fas fa-check-circle text-success"></i>
                                }
                            </div>
                            <div className="col">Guardando datos de grupo</div>
                        </div>
                        <div className="p-2"></div>
                        <div className="row justify-content-between flex-nowrap">
                            <div className="col-auto pr-0" style={{width:40}}>
                                {
                                    !this.props.savedMontos && <i className="fas fa-spinner fa-spin fa-fw text-info"></i>
                                }
                                {
                                    this.props.savedMontos && <i className="fas fa-check-circle text-success"></i>
                                }
                            </div>
                            <div className="col">Guardando montos</div>
                        </div>
                        <div className="p-2"></div>
                        <div className="row justify-content-between flex-nowrap">
                            <div className="col-auto pr-0" style={{width:40}}>
                                {
                                    !this.props.finish && <i className="fas fa-spinner fa-spin fa-fw text-info"></i>
                                }
                                {
                                    this.props.finish && <i className="fas fa-check-circle text-success"></i>
                                }
                            </div>
                            <div className="col">Finalizando</div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    } 
}

export default GroupSpinner;