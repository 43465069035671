import React, { Component } from 'react';
import RequisitionModel from '../../models/RequisitionModel';

class DocumentPaymentControlTable extends Component {

    totaPayWeek = 0;

    setPayWeekCustom = (requisition, monto_autorizado) => {
        let payWeek = RequisitionModel.setPayWeekCustom(requisition, monto_autorizado);
        this.totaPayWeek += payWeek;
        return payWeek;
    }

    setPayWeek = (requisition, monto_autorizado) => {
        let payWeek = 0.0;
        payWeek = (monto_autorizado / 1000) * requisition.factor_cobro;
        
        payWeek = parseFloat(payWeek);
        // payWeek = payWeek.toFixed(2);
        // payWeek = parseFloat(payWeek);

        let decimal = payWeek - Math.trunc(payWeek);
        if (decimal > 0 && decimal < 0.5) {
            payWeek = Math.trunc(payWeek) + 0.5;
        } else if (decimal > 0.5) {
            payWeek = Math.trunc(payWeek) + 1;
        }
        this.totaPayWeek += payWeek;

        return payWeek;
    }

    setPay = (requisition, monto_autorizado) => {
        let pay = 0.0;
        if (requisition.prestamo_personalizado_cantidad_pagos) {
            pay = this.setPayWeekCustom(requisition, monto_autorizado);
        } else {
            pay = this.setPayWeek(requisition, monto_autorizado);
        }
        return pay;
    }

    render() {
        return (<>
            <table className="table-bordered table-payment-control w-100">
                <tbody>
                    <tr>
                        <th rowSpan="2"></th>
                        <th rowSpan="2" style={styles.td}>NOMBRE</th>
                        <th rowSpan="2" style={styles.td} className="text-center">
                            {(this.props.requisition.prestamo_personalizado_periodicidad && this.props.requisition.prestamo_personalizado_periodicidad !== 7)? 'PAGO DEL PERIODO':'PAGO SEMANAL'}
                        </th>
                        <th className="text-center" style={{maxWidth:'100px',minWidth:'100px'}}>{this.props.newNumWeekArray[0]? ((this.props.requisition.prestamo_personalizado_periodicidad && this.props.requisition.prestamo_personalizado_periodicidad !== 7)?'PAGO ':'SEMANA ')+this.props.newNumWeekArray[0] : ''}</th>
                        <th className="text-center" style={{maxWidth:'100px',minWidth:'100px'}}>{this.props.newNumWeekArray[1]? ((this.props.requisition.prestamo_personalizado_periodicidad && this.props.requisition.prestamo_personalizado_periodicidad !== 7)?'PAGO ':'SEMANA ')+this.props.newNumWeekArray[1] : ''}</th>
                        <th className="text-center" style={{maxWidth:'100px',minWidth:'100px'}}>{this.props.newNumWeekArray[2]? ((this.props.requisition.prestamo_personalizado_periodicidad && this.props.requisition.prestamo_personalizado_periodicidad !== 7)?'PAGO ':'SEMANA ')+this.props.newNumWeekArray[2] : ''}</th>
                        <th className="text-center" style={{maxWidth:'100px',minWidth:'100px'}}>{this.props.newNumWeekArray[3]? ((this.props.requisition.prestamo_personalizado_periodicidad && this.props.requisition.prestamo_personalizado_periodicidad !== 7)?'PAGO ':'SEMANA ')+this.props.newNumWeekArray[3] : ''}</th>
                        <th className="text-center" style={{maxWidth:'100px',minWidth:'100px'}}>{this.props.newNumWeekArray[4]? ((this.props.requisition.prestamo_personalizado_periodicidad && this.props.requisition.prestamo_personalizado_periodicidad !== 7)?'PAGO ':'SEMANA ')+this.props.newNumWeekArray[4] : ''}</th>
                    </tr>
                    <tr>
                        <th className="text-center">{this.props.paymentDays[0]}</th>
                        <th className="text-center">{this.props.paymentDays[1]}</th>
                        <th className="text-center">{this.props.paymentDays[2]}</th>
                        <th className="text-center">{this.props.paymentDays[3]}</th>
                        <th className="text-center">{this.props.paymentDays[4]}</th>
                    </tr>
                    {this.props.amounts.map((amount, index) => <tr key={index}>
                        <td>{index+1}</td>
                        <td>{amount.Socio.nombre} {amount.Socio.apellido_paterno} {amount.Socio.apellido_materno}</td>
                        <td>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( this.setPay(this.props.requisition, amount.monto_autorizado) )}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>)}
                    <tr>
                        <td></td>
                        <th>TOTAL</th>
                        <th>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( this.totaPayWeek.toFixed(2) )}</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{`${this.props.promoter.nombre} ${this.props.promoter.apellido_paterno} ${this.props.promoter.apellido_materno}`}</td>
                        <td colSpan="6"></td>
                    </tr>
                </tbody>
            </table>
        </>);
    }
}

export default DocumentPaymentControlTable;

const styles = {
    td : {
        verticalAlign: 'middle',
        minWidth: '100px',
        maxWidth: '100px',
    }
}