import React, { Component } from 'react';
import Spinner from './../others/Spinner';
import _ from 'underscore';
import TextPhone from './../others/TextPhone';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import formatString from '../../utilities/format-string';

class PartnerHistory extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: false,
            collection: []
        }
    }

    titles = {
        en_moderacion: 'Revisión',
        // (fieldset) datos preliminares
        lugar_registro: 'Lugar de registro',
        status: 'Status',
        status_comentario: 'Comentarios del status',

        // (fieldset) datos personales
        apellido_paterno: 'Apellido paterno',
        apellido_materno: 'Apellido materno',
        nombre: 'Nombres(s)',
        sexo: 'Sexo',
        clave_elector: 'Clave de elector',
        numero_ocr: 'Número de OCR',
        nacimiento_pais: 'País de nacimiento',
        nacimiento_entidad: 'Entidad de nacimiento',
        nacimiento_fecha: 'Fecha de nacimiento',
        curp: 'CURP',
        estado_civil: 'Estado civil',
        numero_dependientes: 'Número de dependientes',
        ocupacion: 'Ocupación',
        telefono: 'Teléfono',
        conyugue_nombre: 'Nombre del conyugue',
        conyugue_lugar_trabajo: 'Lugar de trabajo del conyugue',

        // datos domiciliarios
        domicilio_direccion: 'Dirección',
        domicilio_colonia: 'Fraccionamiento o Colonia',
        domicilio_cp: 'Código postal',
        domicilio_poblacion: 'Población',
        domicilio_entidad: 'Estado',
        domicilio_tiempo_habitado: 'Tiempo habitado en ella',
        domicilio_tipo: 'Domicilio',
        domicilio_numero_servicio: 'Número de servicio',
        domicilio_numero_servicio_tipo: 'Tipo servicio',

        // datos de garantia prendaria
        garantia_prendaria_1_nombre: 'Artículo 1',
        garantia_prendaria_1_marca: 'Marca del artículo 1',
        garantia_prendaria_1_estado: 'Estado del artículo 1',
        garantia_prendaria_2_nombre: 'Artículo 2',
        garantia_prendaria_2_marca: 'Marca del artículo 2',
        garantia_prendaria_2_estado: 'Estado del artículo 2',
        garantia_prendaria_3_nombre: 'Artículo 3',
        garantia_prendaria_3_marca: 'Marca del artículo 3',
        garantia_prendaria_3_estado: 'Estado del artículo 3',

        // referencias personales
        referencia_1_nombre_completo: <>Nombre (<small>Referencia personal 1</small>)</>,
        referencia_1_direccion: <>Dirección (<small>Referencia personal 1</small>)</>,
        referencia_1_telefono: <>Teléfono (<small>Referencia personal 1</small>)</>,
        referencia_2_nombre_completo: <>Nombre (<small>Referencia personal 2</small>)</>,
        referencia_2_direccion: <>Dirección (<small>Referencia personal 2</small>)</>,
        referencia_2_telefono: <>Teléfono (<small>Referencia personal 2</small>)</>,

        // datos económicos
        destino_credito: 'Destino del crédito',
        gastos_negocio: 'Gastos',
        gastos_prestamo_banco: <>Préstamo (<small>Banco</small>)</>,
        gastos_prestamo_departamental: <>Préstamo (<small>Departamentales</small>)</>,
        gastos_prestamo_financiera: <>Préstamo (<small>Financieras</small>)</>,
        gastos_prestamo_otro: <>Préstamo (<small>Otros</small>)</>,
        gastos_prestamo_personal: <>Préstamo (<small>Personal</small>)</>,
        gastos_prestamo_tdd: <>Préstamo (<small>Tarjetas de crédito</small>)</>,
        gastos_vivienda_agua: <>Gasto vivienda (<small>Renta de casa</small>)</>,
        gastos_vivienda_luz: <>Gasto vivienda (<small>Teléfono</small>)</>,
        gastos_vivienda_otros: <>Gasto vivienda (<small>Agua</small>)</>,
        gastos_vivienda_renta_casa: <>Gasto vivienda (<small>Luz</small>)</>,
        gastos_vivienda_telefono: <>Gasto vivienda (<small>Transporte</small>)</>,
        gastos_vivienda_transporte: <>Gasto vivienda (<small>Otros</small>)</>,
        ingreso_beca: <>Ingreso (<small>Beca</small>)</>,
        ingreso_otros_trabajo: <>Ingreso (<small>Otro trabajo</small>)</>,
        ingreso_otros_ventas_totales: <>Ingreso (<small>Ventas totales</small>)</>,
        ingreso_otros: <>Ingreso (<small>Otros</small>)</>,
        ingreso_prospera: <>Ingreso (<small>Prospera</small>)</>,
        negocio_tiempo_activo: 'Tiempo en la actividad',
        negocio_tipo: 'Tipo de negocio',
        negocio_venta: 'Venta del negocio',
        observacion_vivienda: 'Observación de la vivienda',
        sueldo_conyugue: 'Sueldo del conyugue',

        // datos mercado
        datos_mercado_pregunta_1: '¿Cómo se enteró de SOLFIN DEL SURESTE S.A.S. DE C.V.?',
        datos_mercado_pregunta_2: '¿Por qué decidió solicitar crédito con SOLFIN DEL SURESTE S.A.S. DE C.V.?',
        datos_mercado_pregunta_3: '¿Qué beneficios le llama más la atención de SOLFIN DEL SURESTE S.A.S. DE C.V.?',
    }

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    UNSAFE_componentWillMount = () => {
        this.getHistory();
    }

    getHistory = async () => {
    
        this.handleSpinner();

        const params = new URLSearchParams({
            where: JSON.stringify({
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: this.props.match.params.id_socio
                }
            }),
            order: '-createdAt'
        });

        const res = await Rest.peticion(`${config.api_url}/classes/SociosHistorial?${params.toString()}`, {
            method: 'GET'
        }).then(res => {return res.json()}).then(res => {return res;}).catch(error => {return error});
        
        if (_.isEmpty(res.code) && _.isEmpty(res.error)) {
            
            this.setState({
                collection: res.results
            })
            
        } else {
            
            alert('No se ha podido obtener el historial');
        
        }
        
        this.handleSpinner();
        
    }

    setRegisterDate = (fecha) => {
        if (fecha) {
            
        }
        const d = new Date(fecha);
        this.fecha_registro = formatString.setLegibleDate(d.toISOString());
    }

    setStatus = (value) => {
        let status;
        
        switch (value) {
            // case 'ACTIVO':
            //     status = 'ACTIVO';
            //     break;
            case 'BAJA_VOLUNTARIA':
                status = 'BAJA VOLUNTARIA';
                break;
            case 'BAJA_CASTIGADA':
                status = 'BAJA CASTIGADA';
                break;
            // case 'RECHAZADO':
            //     status = 'RECHAZADO';
            //     break;
            // case 'MOROSIDAD':
            //     status = 'MOROSIDAD';
            //     break;
            // case 'FALLECIDA':
            //     status = 'FALLECIDA';
            //     break;
            default:
                status = value;
                break;
        }
        return status;
    }

    setBirthday = (date) => {
        if (date) {
            
            return formatString.setLegibleDate(new Date(new Date(date).getTime()+(1000*60*60*24)).toISOString());
        }
    }

    setCreatedAt = (fecha) => {
        let result = '';
        if (fecha) {
            const d = new Date(fecha);
            let hours = d.getHours();
            hours = hours < 10 ? `0${hours}` : hours;
            let minutes = d.getMinutes();
            minutes = minutes < 10 ? `0${minutes}` : minutes;
            result = formatString.setLegibleDate(d.toISOString());
            result += ` ${hours}:${minutes}`
        }
        return result;
    }

    setFormatDivisa = (number) => {
        return new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format(number);
    }

    setValue = (value, key) => {
        
        let res;
        
        switch (key) {
            case 'telefono':
            case 'referencia_1_telefono':
            case 'referencia_2_telefono':
            case 'gastos_vivienda_telefono':
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: <TextPhone phone={value} /></small>
                break;
            case 'nacimiento_fecha':
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: {this.setBirthday(value)}</small>
                break;
            case 'status':
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: {this.setStatus(value)}</small>
                break;
            case 'gastos_negocio':
            case 'gastos_prestamo_banco':
            case 'gastos_prestamo_departamental':
            case 'gastos_prestamo_financiera':
            case 'gastos_prestamo_otro':
            case 'gastos_prestamo_personal':
            case 'gastos_prestamo_tdd':
            case 'gastos_vivienda_agua':
            case 'gastos_vivienda_luz':
            case 'gastos_vivienda_otros':
            case 'gastos_vivienda_renta_casa':
            case 'gastos_vivienda_telefono':
            case 'gastos_vivienda_transporte':
            case 'ingreso_beca':
            case 'ingreso_otros_trabajo':
            case 'ingreso_otros_ventas_totales':
            case 'ingreso_otros':
            case 'ingreso_prospera':
            case 'negocio_venta':
            case 'sueldo_conyugue':
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: ${this.setFormatDivisa(value)}</small>
                break;
            default:
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: {value}</small>
                break;
        }

        return res;
    
    }

    setValues = (data) => {
        if (!_.isEmpty(data)) {
            return _.map(data, (value, key) => this.setValue(value, key));    
        } else {
            return <small className="badge-secondary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2">Sin datos previos</small>
        }
        
    
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />
                <table className="table">
                    <caption>La tabla muestra los datos anteriores que fueron modificados.</caption>
                    <thead>
                        <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Modificado por</th>
                            <th scope="col">Datos anteriores</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (this.state.collection.length > 0 && this.state.collection.map((change, index) => <tr key={index}>
                                <td style={{whiteSpace:'nowrap'}}>{this.setCreatedAt(change.createdAt)}</td>
                                <td style={{whiteSpace:'nowrap'}}>{change.afectado_por}</td>
                                <td>{change.datos_anteriores && this.setValues(change.datos_anteriores)}</td>
                            </tr>))
                        }
                    </tbody>
                </table>
            </>
        );
    }
}

export default PartnerHistory;