import React from 'react';
import Rest from '../../Server/Rest';
import config from '../../settings/config';
import GroupListAutorization from './GroupListAutorization';
import TableCountInfo from './../others/TableCountInfo';
import TablePagination from './../others/TablePagination';
import Spinner from '../others/Spinner';
import _ from 'underscore';
import GroupPaymentForm from './GroupPaymentForm';
import textUtils from '../../utilities/format-string';
import Popover from './../others/Popover';
import { jsPDF } from 'jspdf';
import user from '../../settings/user';
import './../../styles/css/group.css';
import GroupListPaymentFilter from './GroupListPaymentFilter';
import Parse from 'parse';
import $ from 'jquery';
import RequisitionModel from '../../models/RequisitionModel';
import SyncLoader from "react-spinners/SyncLoader";
import { Link, NavLink, withRouter } from 'react-router-dom';

class GroupListPayment extends GroupListAutorization {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);

        this.ref = React.createRef();
    }

    state = {
        loading: false,

        // datos de filtro
        nombre: '',
        promotor: '',
        dateStart: null,
        dateEnd: null,
        Sucursal: !_.isEmpty(Parse.User.current().toJSON().Sucursal) ? Parse.User.current().toJSON().Sucursal.objectId : '',
        includeDowns: false,
        fintrar_por_fecha_finalizacion: false,

        // datos de la tabla
        total: 0,
        collection: [],
        requisitionSelected: {},
        requisitionPrint: false,

        monto_desembolsado_total: 0.0,
        monto_pago_total: 0.0,
        monto_pagado_total: 0.0,
        balanceDue_total: 0.0,

        // status spinner para sumatoria
        loadSumary: false,

        where: {}
    }

    collection = [];
    limit = 50;
    title = 'Pagos grupales';
    order = 'updatedAt,-ciclo'; // orden ascendente, el objetivo es mandar al ultimo los que se pagaron recientemente.
    include =   'Grupo' +
                ',Grupo.Socios' +
                ',Sucursal' +
                ',promotor' +
                ',montos' +
                ',montos.Socio' +
                ',Pagos' +
                ',Pagos.Socio' +
                ',PagosRealizados' +
                ',PagosRealizados.registrado_por';
    // keys =   'Grupo' +
    // ',Grupo.Socios' +
    // ',Sucursal' +
    // ',promotor' +
    // ',montos' +
    // ',montos.Socio' +
    // ',Pagos' +
    // ',Pagos.fecha_pago' +
    // ',Pagos.monto' +
    // ',Pagos.numero_pago' +
    // ',Pagos.Socio.status' +
    // ',PagosRealizados' +
    // ',PagosRealizados.registrado_por';

    setWhere = (data = {}) => {
        const where_fecha_pago = {
            where: {
                fecha_pago: {}
            },
            className: 'SolicitudesPagos'
        };
        if (data.dateStart) {
            where_fecha_pago.where.fecha_pago.$gte = data.dateStart;
        }    
        if (data.dateEnd) {
            where_fecha_pago.where.fecha_pago.$lte = data.dateEnd;
        }

        const config = user.config();
        const filter_from = config && config.filter_from ? {
            $gte: {
                __type: 'Date',
                iso: config.filter_from
                
            }
        } : undefined;


        let promotor = undefined;
        if (user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) {
            const currentUser = Parse.User.current();
            promotor = {
                $select: {
                    query: {
                        where: {
                            User: {
                                __type: 'Pointer',
                                className: '_User',
                                objectId: currentUser.id
                    
                            }
                        },
                        className: 'Empleados'
                    },
                    key: 'objectId'
                }
            } 
        }

        if (!_.isEmpty(data.promotor)) {
            promotor = !_.isEmpty(data.promotor)? data.promotor : undefined;
        }

        let where = {
            status: data.includeDowns === false ? {
                $ne: 'baja'
            } : undefined,
            Grupo: (!_.isEmpty(data.id_busqueda) || !_.isEmpty(data.Sucursal)) ? {
                $inQuery: {
                    where: {
                        id_busqueda: !_.isEmpty(data.id_busqueda) ? data.id_busqueda : undefined,
                        Sucursal: !_.isEmpty(data.Sucursal) ? data.Sucursal : undefined,
                        Empleado: {
                            $exists: false
                        }
                    },
                    className: 'Grupos'
                }
            }: 
            {
                $inQuery: {
                    where: {
                        Empleado: {
                            $exists: false
                        }
                    },
                    className: 'Grupos'
                }
            },
            createdAt: filter_from,
            promotor: promotor,
            prestamo_personalizado: {
                $ne: true
            },
            factor_cobro:{
                $exists: true    
            },
            prestamo_personalizado_interes: {
               $exists: false
            }
        }

        if (this.state.fintrar_por_fecha_finalizacion) {
            where.fecha_finalizacion = !_.isEmpty(where_fecha_pago.where.fecha_pago)? where_fecha_pago.where.fecha_pago : undefined;
            where.status = 'baja';
            // where.PagosRealizados = !_.isEmpty(where_fecha_pago.where.fecha_pago)? {
            //     $inQuery: {
            //         where: {
            //             pagado_al: where_fecha_pago.where.fecha_pago
            //         },
            //         className: 'SolicitudesPagosRealizados'
            //     }
            // }: {
            //     $exists: true
            // }
        } 
        // else {

            where.Pagos = !_.isEmpty(where_fecha_pago.where.fecha_pago)? {
                $inQuery: where_fecha_pago
            }: {
                $exists: true
            }
        // }
        
        return where;
    }
    
    componentDidMount = () => {
        $('#modal-payment-form').on('hide.bs.modal', () => {
            this.setPaymentInfo();
        });
        // if (this.props.location.search) {
        //     this.getResource(undefined, undefined, this.props.location.search)
        // }
    } 

    downloadXLS = (xls) => {
        const s2ab = (s) => {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        var bin = atob(xls);
        var ab = s2ab(bin); // from example above
        var blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (this.state.filter_type) {
            link.download = `reporte-${this.state.filter_type}.xlsx`;    
        } else {
            link.download = 'pagos.xlsx';
        }

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    getBigLotTotals = async (where) => {
        let promise = new Promise(async (resolve, reject) => {
            let requisitions = [];
            let totalPaid = 0.0;
            const query = async (where, skip = 0) => {
                const params = new URLSearchParams({
                    limit: 1000,
                    skip: skip,
                    where: JSON.stringify(where),
                    count: 1,
                    order: this.order,
                    include: this.include,
                    keys: 'montos,Pagos,PagosRealizados,montos.monto_autorizado,prestamo_personalizado_interes,prestamo_personalizado_cantidad_pagos,prestamo_personalizado_periodicidad,prestamo_personalizado_cantidad_pagos,factor_cobro,prestamo_personalizado,cantidad_pagos_semanales,prestamo_personalizado_cantidad_pagos'
                });
                
                Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(async res => {
                    requisitions = requisitions.concat(res.results);
                    if (res.count === requisitions.length) {
                        let
                        montos = _.pluck(_.clone(requisitions), 'montos');
                        montos = _.flatten(montos);
                        const total_desembolsos = _.reduce(montos, function(memo, item){ return memo + item.monto_autorizado; }, 0);
                        let total_montopagos = 0;
                        _.each(requisitions, item => {
                            total_montopagos += this.setPay(item);
                        });
                        totalPaid = await this.getPaymentsMade(where);
                        let balanceDue_total = 0;
                        _.each(requisitions, item => {
                            balanceDue_total += RequisitionModel.balanceDue(item, this.setPay(item));
                        });
                        resolve({
                            total_desembolsos: total_desembolsos,
                            total_montopagos: total_montopagos,
                            totalPaid: totalPaid,
                            balanceDue_total: balanceDue_total

                        });
                    } else {
                        await query(where, requisitions.length);
                    }
                }).catch(error => {
                    reject(error);
                });
            };
            
            await query(where, 0);
        });

        return promise;
    }

    getXLS = () => {
        if (this.state.collection.length) {
            this.handleSpinner();
            
            Rest.peticion(`${config.api_url}/functions/getXLS`, {
                method: 'POST',
                body: JSON.stringify({
                    className: this.className || 'Solicitudes',
                    where: this.state.where,
                    order: this.order,
                    include: this.include,
                    type: this.state.filter_type || 'pagos_programados',
                    format: 'xlsx'
                })
            })
            .then(res => {
                return res.json();
            })
            .then(res => {

                if (res.code || res.error) {
                    alert(res.error);
                } else {
                    this.downloadXLS(res.result);
                }
                
            })
            .catch(error => {
                alert('Ha ocurrido un error al intentar descargar el archivo Excel');
            })
            .then(() => {
                this.handleSpinner();
            });
        } else {
            alert('Realice un filtro para descargar el archivo Excel');
        }
    }

    getPaymentsMade = (_where = {}) => {
        let promise = new Promise(async (resolve, reject) => {
            let requisitions = [];
            let pagado_al = undefined;
        
            if (!_.isEmpty(_where.Pagos) ) {
                try {
                    if (
                        !_.isEmpty(_where.Pagos.$inQuery.where.fecha_pago)
                    ) {
                        pagado_al = _where.Pagos.$inQuery.where.fecha_pago;
                        // delete where.Pagos.$inQuery.where.fecha_pago;
                    }
                } catch (error) {console.log(error);}
            }
    
            let payWhere = JSON.stringify({
                pagado_al: pagado_al,
                Solicitud: {
                    $inQuery: {
                        where: _where,
                        className: 'Solicitudes'
                    }
                }
            });

            const query = async (skip = 0) => {
                let params;
                
        
                params = new URLSearchParams({
                    limit: 1000,
                    skip: skip,
                    where: payWhere,
                    count: 1,
                    order: this.order,
                    include: this.include
                });
                
                return Rest.peticion(`${config.api_url}/classes/SolicitudesPagosRealizados?${params.toString()}`, {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    return res;
                })
                .then(async res => {
                    requisitions = requisitions.concat(res.results);
                    if (res.count === requisitions.length) {
                        let total = 0.0;
                        for (let index = 0; index < res.results.length; index++) {
                            const item = res.results[index];
                            total += item.monto_pagado;
                        }
                        resolve(total);
                    } else {
                        await query(requisitions.length);
                    }
                }).catch((error) => {
                    reject(0.0);
                });
            }

            await query(0);
        });
        return promise;
        
    }

    getResource = (index = 0, _where = {}, search = undefined) => {
        
        this.handleSpinner();
        
        let params;

        if (search) {
            params = search;
        } else {
            this.setState({
                index: index,
                loadSumary: null,
                monto_desembolsado_total: 0.0,
                monto_pago_total: 0.0,
                monto_pagado_total: 0.0,
                where: _where
            });
        
            let where = JSON.stringify(_where);
        
            params = new URLSearchParams({
                
                limit: this.limit,
                skip: index,
                where: where,
                count: 1,
                order: this.order,
                include: this.include,
                // keys: this.keys,
            });

            params = `?${params.toString()}`;
        }
        
        Rest.peticion(`${config.api_url}/classes/Solicitudes${params}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .then(async res => {
            
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                this.rows = res.count;
                let collection = _.map(res.results, (item, index) => {
                    const monto_pago = this.setPay(item);
                    // ultima fecha de pago
                    const pagos = _.sortBy(item.Pagos, item => {
                        return item.numero_pago
                    })
                    const fecha_termino = pagos[pagos.length - 1].fecha_pago.iso;
                    return {
                        objectId: item.objectId,
                        groupId: item.Grupo.objectId,
                        index: index + 1 + parseInt(this.state.index),
                        nombre_grupo: item.Grupo.nombre,
                        ciclo: item.ciclo,
                        nombre_promotor: this.setPromotor(item),
                        monto_desembolsado: this.setAuthorizedAmount(item),
                        monto_pago: monto_pago,
                        pago_actual: this.setCurrentPatmentNumber(item),
                        pagos_totales: this.setNumberPayments(item),
                        fecha_termino: textUtils.setLegibleDate(fecha_termino),
                        saldo: RequisitionModel.balanceDue(item, monto_pago),
                        pago_realizado_status: this.setPaymentMade(item, monto_pago),
                        status: item.status
                    }
                });
                this.collection = _.groupBy(res.results,'objectId');
                
                this.handleSpinner();
                this.setState({
                    collection: collection,
                    total: res.count,
                });
    
            }
            return true;
        })
        .then( async () => {
            let totalPaid = 0.0;
            let monto_desembolsado_total = 0.0;
            let monto_pago_total = 0.0;
            let balanceDue_total = 0.0;
            if (this.state.total > 100) {
                const totals = await this.getBigLotTotals(_where);
                totalPaid = totals.totalPaid;
                monto_desembolsado_total = totals.total_desembolsos;
                monto_pago_total = totals.total_montopagos;
                balanceDue_total = totals.balanceDue_total;
            } else {
                totalPaid = await this.getPaymentsMade(_where);
                monto_desembolsado_total = this.setTotalAuthorizedAmount(this.state.collection);
                monto_pago_total = this.setTotalWeeklyPayment(this.state.collection);
                balanceDue_total = this.setbalanceDue_total(this.state.collection);
            }
            
            this.setState({
                monto_pagado_total: totalPaid,
                monto_desembolsado_total: monto_desembolsado_total,
                monto_pago_total: monto_pago_total,
                balanceDue_total: balanceDue_total
            });
        })
        .then(() => {
            this.setState({
                loadSumary: false
            });
        })
        .catch(error => {
            console.log(error);
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    setPaymentMade = (requisition, monto_pago) => {

        // 1. obtener el numero de pago actual 
        // 2. averiguar la fecha que realmente le corresponde pagar
        //     3. pintar de gris si la fecha inicial del filtro es menor o igual a la fecha real de pago
        //     4. si hay fecha final en el filtro, pintar de gris si dicha fecha es menor o igual a la fecha real de pago

        let total_pagado = 0.0;
        let pagos_realizados = 0;
        let result = false;

        if (!_.isEmpty(requisition.PagosRealizados)) {

            // 1
            for (let index = 0; index < requisition.PagosRealizados.length; index++) {
                const pago_realizado_status = requisition.PagosRealizados[index];
                total_pagado += pago_realizado_status.monto_pagado;
            }
            total_pagado = total_pagado.toFixed(2);
            total_pagado = parseFloat(total_pagado);
            monto_pago = monto_pago.toFixed(2);
            monto_pago = parseFloat(monto_pago);
            
            pagos_realizados = Math.floor(total_pagado/monto_pago);
            
            // 2
            let pago = _.findWhere(requisition.Pagos, {numero_pago: pagos_realizados})
            
            let fecha_pago = pago ? pago.fecha_pago : null;

            if (fecha_pago && (this.state.dateStart || this.state.dateEnd)) {
                let dateStart = (this.state.dateStart && this.state.dateStart) || false;
                if (dateStart) {
                    dateStart = dateStart.getTime();
                }
                let dateEnd = (this.state.dateEnd && this.state.dateEnd) || false;
                if (dateEnd) {
                    dateEnd = dateEnd.getTime();
                }
                fecha_pago = new Date(fecha_pago.iso);
                fecha_pago.setHours(23);
                fecha_pago.setHours(59);
                fecha_pago.setMinutes(59);
                fecha_pago = fecha_pago.getTime();
                // 3
                if (dateEnd && (dateEnd <= fecha_pago)) {
                    result = true;
                // 4
                } else if (dateStart && (dateStart <= fecha_pago)) {
                    result = true;
                } else {
                    result = false;
                }
            }
        }
        return result;
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        
        return true;
    }

    handleChangeDate = (date, name) => {
        this.setState({
            [name]: date
        })
    }

    setPromotor = (solicitud) => {
        const promotor = solicitud.promotor;
        return `${promotor.nombre} ${promotor.apellido_paterno} ${promotor.apellido_materno}`;
    }

    setNumberPayments = (solicitud) => {
        if (solicitud.prestamo_personalizado_cantidad_pagos) {
            return solicitud.prestamo_personalizado_cantidad_pagos;
        } else {
            return solicitud.cantidad_pagos_semanales;
        }
    }

    /**
     * Establece el número del pago grupal completado.
     * Comprueba si el pago acumulado es mayor o igual
     * al pago que se debería recibir. La validación
     * se realiza con cantidades enteras por practicidad.
     * @param {*} solicitud 
     * @returns integer
     */
    setCurrentPatmentNumber = (solicitud) => {
        
        let monto_acumulado = 0.0;
        _.each(solicitud.montos, monto => {
            if (monto.monto_acumulado) {
                monto_acumulado += monto.monto_acumulado;
            }
        });

        if (solicitud.prestamo_personalizado_cantidad_pagos) {
            monto_acumulado = monto_acumulado.toFixed(1);
            monto_acumulado = parseFloat(monto_acumulado);
        }
        
        const n_pagos = this.setNumberPayments(solicitud);
        const pago_recurrente = this.setPay2(solicitud);
        let pago_actual = 0;
        for (let index = 0; index <= n_pagos; index++) {
            let monto_n = (pago_recurrente * index).toFixed(1);
            monto_n = parseFloat( monto_n );
            if (monto_acumulado >= monto_n) {
                pago_actual = index;
                continue;
            }
        }

        return pago_actual;
    }

    // calcular el pago periodico
    setPay2 = RequisitionModel.setPay2Exacto
    setPay = (solicitud) => {
        
        let pay = this.setPay2(solicitud);
        
        return pay;
    }

    setTotalAuthorizedAmount = (collection) => {
        let total = 0.0;
        _.each(collection, item => {
            total += item.monto_desembolsado;
        });
        return total;
    }
    setTotalWeeklyPayment = (collection) => {
        let total = 0.0;
        _.each(collection, item => {
            total += item.monto_pago;
        });
        return total;
    }
    setbalanceDue_total = (collection) => {
        let total = 0.0;
        _.each(collection, item => {
            total += item.saldo;
        });
        return total;
    }

    setAuthorizedAmount = (solicitud) => {
        let amount = 0.0;
        for (let index = 0; index < solicitud.montos.length; index++) {
            amount += solicitud.montos[index].monto_autorizado;
        }
        return amount;
    }

    setPaymentInfo = (objectId) => {
        if (objectId) {
            this.setState({
                requisitionSelected: this.collection[objectId][0]
            });
        } else {
            this.setState({
                requisitionSelected: ''
            });
        }
    }

    handlePrintDocument = () => {

        if (this.state.collection.length === 0) {
            alert('Sin datos para descargar');
            return;
        }

        this.handleSpinner();

        Rest.peticion(`${config.api_url}/functions/getXLS`, {
            method: 'POST',
            body: JSON.stringify({
                className: this.className || 'Solicitudes',
                where: this.state.where,
                order: this.order,
                include: this.include,
                type: this.state.filter_type || 'pagos_programados',
                format: 'json'
            })
        })
        .then(res => {
            return res.json();
        })
        .then(res => {

            let generateData = function(collection) {
                return _.map(collection, item => {
                    let data = {};
                    data['#'] = ''+`${item.index?item.index:''}`;
                    data['Grupo'] = ''+`${item.nombre_grupo?item.nombre_grupo:''}`;
                    data['Promotor'] = ''+`${item.nombre_promotor?item.nombre_promotor:''}`;
                    data['Monto desem.'] = ''+(new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.monto_desembolsado));
                    data['Monto de pago'] = ''+(new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.monto_pago));
                    data['Pago actual'] = ''+`${item.pago_actual?item.pago_actual:''}`;
                    data['Saldo'] = ''+(new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.saldo));
                    data['Fecha término'] = ''+`${item.fecha_termino?item.fecha_termino:''}`;
                    return Object.assign({}, data);
                })
              };
              
              function createHeaders(keys) {
                var result = [];
                for (var i = 0; i < keys.length; i += 1) {
                  result.push({
                    id: keys[i],
                    name: keys[i],
                    prompt: keys[i],
                    padding: 0
                  });
                }
                return result;
              }
              
              var headers = createHeaders([
                '#',
                'Grupo',
                'Promotor',
                'Monto desem.',
                'Monto de pago',
                'Pago actual',
                'Saldo',
                'Fecha término',
              ]);
    
            let doc = new jsPDF({
                orientation: 'landscape',
            });
            doc.setFontSize(20);
            doc.setTextColor(199, 137, 177);
            doc.text('Pagos', 7, 10);
            doc.setTextColor(0, 0, 0);
            doc.table(
                7, 15, 
                generateData(res.result), 
                headers, 
                { autoSize: true, fontSize: 10 }
            );
            doc.save("pagos.pdf");

            return true;
            
        })
        .catch(error => {
            alert('Ha ocurrido un error al intentar descargar el archivo Excel');
        })
        .then(() => {
            this.handleSpinner();
        });

    }

    handleFilter = (event) => {
        if (event) event.preventDefault();

        const state = this.state;  
    
        let dateStart = undefined;
        if (_.isDate(state.dateStart)) {
            dateStart = new Date(state.dateStart);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);
        }
    
        let dateEnd = undefined;
        if (_.isDate(state.dateEnd)) {
            dateEnd = new Date(state.dateEnd);
            dateEnd.setHours(24);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);
        }

        const name_group = textUtils.setIdBusqueda(state.nombre);

        let _where = {
            dateStart: _.isUndefined(dateStart) ? undefined : {
                __type: 'Date',
                iso: dateStart.toISOString()
            },
            dateEnd: _.isUndefined(dateEnd) ? undefined : {
                __type: 'Date',
                iso: dateEnd.toISOString()
            },
            id_busqueda: _.isEmpty(name_group)? undefined : {
                $regex: name_group
            },
            Sucursal: _.isEmpty(state.Sucursal)? undefined : {
                __type: 'Pointer',
                className: 'Sucursales',
                objectId: state.Sucursal
            },
            promotor: _.isEmpty(state.promotor)? undefined : {
                __type: 'Pointer',
                className: 'Empleados',
                objectId: state.promotor
            },
            includeDowns: state.includeDowns,
            fintrar_por_fecha_finalizacion: state.fintrar_por_fecha_finalizacion
        };

        const where = this.setWhere(_where);
        this.rows = 0;

        this.getResource(0, where);
        // const params = new URLSearchParams({
            
        //     limit: this.limit,
        //     // skip: index,
        //     where: JSON.stringify(where),
        //     count: 1,
        //     order: this.order,
        //     include: this.include
        // });
        // this.props.history.push(`/pagos?${params}`);
    }

    printAccountStatement = (documentName = undefined, _requisition = {}) => {
        this.setState({
            requisitionPrint: false,
            loading: true
        })
        setTimeout(() => {
            const requisition = this.collection[_requisition.objectId][0]
            this.setState({
                requisitionPrint: requisition,
            })
            setTimeout(() => {
                this.setState({
                    requisitionPrint: requisition,
                    loading: false
                })
            }, 500);
            localStorage.setItem('account_statement_requisition', JSON.stringify(_.clone(requisition)));
            localStorage.setItem('pago_total_periodo', JSON.stringify(this.setPay2(requisition)));
            window.frames[documentName].focus();
            window.frames[documentName].print();
        }, 500);
        
    }

    render() {
        const {state} = this;
        return (
            <>
                <Spinner loading={state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-money-bill-wave mr-2"></i>
                    {this.title}
                </h3>
                <div className="card">
                    <div className="card-body">
                        
                        <GroupListPaymentFilter
                        handleChange={this.handleChange}
                        handleChangeDate={this.handleChangeDate}
                        handleFilter={this.handleFilter}
                        state={state}
                        inputGroupEnabled={!user.rol.permisos.Pagos.filtro_nombre}
                        inputDateEnabled={!user.rol.permisos.Pagos.filtro_fecha}
                        inputSucursalEnabled={!user.rol.permisos.Pagos.filtro_fecha}
                        inputPrometerEnabled={!user.rol.permisos.Pagos.filtro_promotor}
                        inputincludeDownsEnabled={false}
                        />
                        
                        <div className="text-right">
                            <div className="btn-group" role="group" aria-label="Descargas">
                                {user.rol.permisos.Pagos.descargar_excel === true && <button className="btn btn-secondary" onClick={() => this.getXLS()}>Descargar Excel</button>}
                                {user.rol.permisos.Pagos.descargar_pdf === true && <button className="btn btn-secondary" onClick={() => this.handlePrintDocument()}>Descargar PDF</button>}
                            </div>
                        </div>

                        <TableCountInfo total={state.total} />

                        <div id="table-pdf" className={`${window.innerWidth > 768 && 'table-responsive'}`} ref={this.ref}>
                            {(window.innerWidth > 768 && <>
                                {(state.collection.length > 0 && <table className="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Grupo</th>
                                            <th>Ciclo</th>
                                            {!(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) && <th>Promotor</th>}
                                            <th className="text-right">Monto desem.</th>
                                            <th className="text-right"><span className="no-show-pdf"><Popover title="Monto de pago" content="Es el pago semanal o catorcenal según sea el caso." /></span>Monto pago</th>
                                            <th>Pago actual</th>
                                            {(user.rol.nombre === 'Administrador' || user.rol.nombre === 'Contador') && <th className="text-right">Saldo</th>}
                                            <th>Fecha de término</th>
                                            <th className="no-show-pdf"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.collection.map((item, index) => <tr key={index} id={`solicitud-${item.objectId}`} id-grupo={item.groupId} className={item.pago_realizado_status?'table-success':''}>
                                            <td>{item.index}</td>
                                            <td className="td-responsive-text">{item.nombre_grupo}</td>
                                            <td className="td-responsive-text">{item.ciclo}</td>
                                            {!(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) && <td className="td-responsive-text">{item.nombre_promotor}</td>}
                                            <td className="text-right" style={{whiteSpace: 'nowrap'}}>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( item.monto_desembolsado )}</td>
                                            <td className="text-right" style={{whiteSpace: 'nowrap'}}>
                                                {item.pago_realizado_status && <sup><Popover title="Pago efectuado" size="normal" className="text-success" content="De acuerdo a la fecha del filtro, el pago ya fue realizado." /></sup>}
                                                {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( item.monto_pago )}
                                            </td>
                                            <td>{!_.isUndefined(item.pago_actual) && !_.isUndefined(item.pagos_totales) && `${item.pago_actual}/${item.pagos_totales}`}</td>
                                            {(user.rol.nombre === 'Administrador' || user.rol.nombre === 'Contador') && <td className="text-right">{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.saldo)}</td>}
                                            <td>{item.fecha_termino}</td>
                                            <td className="p-2 text-right no-show-pdf" style={{whiteSpace: 'nowrap'}}>
                                                <div className="btn-group" role="group">
                                                    {user.rol.permisos.Pagos.pagar === true && (!_.isEmpty(item.objectId) === true && (item.status !== 'baja' || item.pago_actual !== item.pagos_totales)) && <button type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#modal-payment-form" onClick={() => this.setPaymentInfo(item.objectId)}>Pagar</button>}
                                                    {(user.rol.permisos.Pagos.imprimir_estadocuenta === true && item.objectId) && <button type="button" className="btn btn-primary btn-sm" onClick={() => this.printAccountStatement('estado-cuenta', item)}>Imprimir</button>}
                                                    {(user.rol.permisos.Pagos.editar_pagos === true && item.objectId) && <NavLink to={`/pagos/edicion/${item.objectId}`} className="btn btn-primary btn-sm">Editar pagos</NavLink>}
                                                </div>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={`${(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) ? 3:4}`}/>
                                            <td className="text-right">{
                                                (this.state.loadSumary === null && <SyncLoader
                                                size={8}
                                                color={"#23A1D3"}
                                                loading={true}
                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_desembolsado_total)
                                            }</td>
                                            <td className="text-right">{
                                                (this.state.loadSumary === null && <SyncLoader
                                                size={8}
                                                color={"#23A1D3"}
                                                loading={true}
                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pago_total)
                                            }</td>
                                            <td></td>
                                            {((user.rol.nombre === 'Administrador' || user.rol.nombre === 'Contador') && <><td className="text-right">{
                                                (this.state.loadSumary === null && <SyncLoader
                                                size={8}
                                                color={"#23A1D3"}
                                                loading={true}
                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.balanceDue_total)
                                            }</td>
                                            <td colSpan="2"/></>) || <td colSpan="3"/>}
                                        </tr>
                                        <tr>
                                            <td className="border-top-0" colSpan={`${(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) ? 3:4}`}/>
                                            <td className="text-success text-right text-uppercase" style={{whiteSpace: 'nowrap'}}>Total pagado</td>
                                            <td className="text-success text-right">
                                                {
                                                    (this.state.loadSumary === null && <SyncLoader
                                                    size={8}
                                                    color={"#23A1D3"}
                                                    loading={true}
                                                    />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pagado_total)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-top-0" colSpan={`${(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) ? 3:4}`}/>
                                            <td className={`text-right text-uppercase ${(this.state.monto_pagado_total - this.state.monto_pago_total) < 0 ? 'text-danger' : 'text-info'}`}>Saldo (+/-)</td>
                                            <td className={`text-right ${(this.state.monto_pagado_total - this.state.monto_pago_total) < 0 ? 'text-danger' : 'text-info'}`}>
                                                {
                                                    (this.state.loadSumary === null && <SyncLoader
                                                    size={9}
                                                    color={"#23A1D3"}
                                                    loading={true}
                                                    />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pagado_total - this.state.monto_pago_total)
                                                }
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>) || <div className="text-center">
                                    <div className="alert alert-warning m-0 mt-3 mb-3" role="alert">
                                    <i className="fas fa-filter"></i> Realice un filtro
                                    </div>
                                </div>}
                            </>) || <>
                                <br/>
                                {(state.collection.length > 0 && <>
                                    {state.collection.map((item, index) => <div key={index} className="position-relative">
                                            <span className="badge badge-secondary list-partner-item-counter ml-4" style={{zIndex:1}}>{item.index}</span>
                                            <div key={index} className="card mb-4 mt-3 shadow" style={{overflow: 'hidden'}}> 
                                                <div className={`card-body ${item.pago_realizado_status === false && 'bg-light'}`} style={(item.pago_realizado_status && {backgroundColor: '#83cd93'}) || {}}>
                                                    <div className="p-1 container">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="" style={{color: 'rgba(0,0,0,0.6)'}}>Grupo:</span>
                                                                <h5 className="font-weight-bold text-primary">
                                                                    {item.nombre_grupo}
                                                                </h5>
                                                            </div>
                                                            <div className="col-5 text-right">
                                                                <span className="" style={{color: 'rgba(0,0,0,0.6)'}}>Ciclo:</span> <b>{item.ciclo}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) && <table className="table table-borderless m-0">
                                                        <tbody>
                                                            <tr>
                                                                <td  colSpan="2" className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Promotor:</td>
                                                                <th  colSpan="2" className="p-1 text-right">
                                                                    {item.nombre_promotor}
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>}
                                                    <table className="table table-borderless">     
                                                        <tbody>
                                                            <tr>

                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Monto desembolsado:</td>
                                                            <th className="p-1 text-right">
                                                                {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( item.monto_desembolsado )}
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Monto pago:</td>
                                                            <th className="p-1 text-right">
                                                                {item.pago_realizado_status && <sup><Popover title="Pago efectuado" size="normal" className="text-success" content="De acuerdo a la fecha del filtro, el pago ya fue realizado." /></sup>}
                                                                {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( item.monto_pago )}
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Pago actual:</td>
                                                            <th className="p-1 text-right">
                                                                {!_.isUndefined(item.pago_actual) && !_.isUndefined(item.pagos_totales) && `${item.pago_actual}/${item.pagos_totales}`}
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Fecha de término:</td>
                                                            <th className="p-1 text-right">
                                                                {item.fecha_termino}
                                                            </th>
                                                            </tr>
                                                            {(user.rol.nombre === 'Administrador' || user.rol.nombre === 'Contador') && <tr>
                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Saldo:</td>
                                                            <th className="p-1 text-right text-primary">
                                                                {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.saldo)}
                                                            </th>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                    <div className="btn-group btn-block" role="group">
                                                        {user.rol.permisos.Pagos.pagar === true && (!_.isEmpty(item.objectId) === true && (item.status !== 'baja' || item.pago_actual !== item.pagos_totales)) && <button type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#modal-payment-form" onClick={() => this.setPaymentInfo(item.objectId)}>Pagar</button>}
                                                        {(user.rol.permisos.Pagos.imprimir_estadocuenta === true && item.objectId) && <button type="button" className="btn btn-primary btn-sm" onClick={() => this.printAccountStatement('estado-cuenta', item)}>Imprimir</button>}
                                                        {(user.rol.permisos.Pagos.editar_pagos === true && item.objectId) && <NavLink to={`/pagos/edicion/${item.objectId}`} className="btn btn-primary btn-sm">Editar pagos</NavLink>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <h5><b>Totales</b></h5>
                                            <table className="table table-borderless m-0">
                                                <tbody>
                                                    <tr>
                                                        <td className=" pl-0 ">Monto desembolsado</td>
                                                        <td className="text-right pr-0">{
                                                            (this.state.loadSumary === null && <SyncLoader
                                                            size={8}
                                                            color={"#23A1D3"}
                                                            loading={true}
                                                            />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_desembolsado_total)
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td className=" pl-0 ">Monto pago</td>
                                                        <td className="text-right pr-0">{
                                                            (this.state.loadSumary === null && <SyncLoader
                                                            size={8}
                                                            color={"#23A1D3"}
                                                            loading={true}
                                                            />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pago_total)
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-success pl-0 " style={{whiteSpace: 'nowrap'}}>Total pagado</td>
                                                        <td className="text-success text-right pr-0">
                                                            {
                                                                (this.state.loadSumary === null && <SyncLoader
                                                                size={8}
                                                                color={"#23A1D3"}
                                                                loading={true}
                                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pagado_total)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={` pl-0 ${(this.state.monto_pagado_total - this.state.monto_pago_total) < 0 ? 'text-danger' : 'text-info'}`}>Saldo (+/-)</td>
                                                        <td className={`text-right pr-0 ${(this.state.monto_pagado_total - this.state.monto_pago_total) < 0 ? 'text-danger' : 'text-info'}`}>
                                                            {
                                                                (this.state.loadSumary === null && <SyncLoader
                                                                size={9}
                                                                color={"#23A1D3"}
                                                                loading={true}
                                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pagado_total - this.state.monto_pago_total)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>) || <div className="text-center">
                                    <div className="alert alert-warning m-0 mt-3 mb-3" role="alert">
                                    <i className="fas fa-filter"></i> Realice un filtro
                                    </div>
                                </div>}
                            </>}
                            
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.state.where} />
                            </div>
                        </div>
                    </div>

                    <GroupPaymentForm id="modal-payment-form" 
                    requisition={state.requisitionSelected} 
                    handleFilter={this.handleFilter} 
                    pago_total_periodo={this.setPay2(state.requisitionSelected)}
                    setPaymentInfo={this.setPaymentInfo}
                    include={this.include} />

                    {!_.isEmpty(state.requisitionPrint) && <iframe id="estado-cuenta" name="estado-cuenta" width="0" height="0" src={`${this.props.match.url}/estado-cuenta?no_margin=true&is_print=true`}></iframe>}

                </div>
            </>
        );
    }
}

export default GroupListPayment;