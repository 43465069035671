import React, { Component } from 'react';

class GroupInfoIDs extends Component {
    render() {
        return (
            <div className="d-flex flex-wrap flex-lg-nowrap">
                <div className="h3 mb-2 mb-lg-5 mr-3">
                    <span className="badge badge-light shadow">
                        <code>ID GRUPO: {this.props.Sucursal && (`${this.props.id_en_sucursal}-${this.props.Sucursal}`)}</code>
                    </span>
                </div>
                <div className="h3 mb-5">
                    <span className="badge badge-light shadow">
                        {this.props.id_en_sucursal_solicitud && <code>ID SOL<span className="d-none d-md-inline">ICITUD</span><span className="d-inline d-md-none">.</span> ACTUAL: {this.props.id_en_sucursal_solicitud}</code>}
                    </span>
                </div>
            </div>
        );
    }
}

export default GroupInfoIDs;