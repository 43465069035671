import React from 'react';
import Parse from 'parse';
import config from '../../settings/config';
import _ from 'underscore';
import Spinner from '../others/Spinner';
import GroupListLocal, { openDB } from './GroupListLocal';
import { resolve } from 'promise';

export const getLocalGroups = async () => {
    return new Promise(async (reslv, rejct) => {
        try {
            const db = await openDB();
            const tx = db.transaction('myStore', 'readonly');
            const store = tx.objectStore('myStore');
            const prefix = `${Parse.User.current().toJSON().objectId}-local-new-cicle-by-group-`;
            // Definimos el rango de claves que comienza con el prefijo especificado
            const range = IDBKeyRange.bound(prefix, prefix + '\uffff');
      
            const request = store.openCursor(range);
            const matchingData = [];
      
            request.onsuccess = (event) => {
              const cursor = event.target.result;
              if (cursor) {
                if (cursor.value.grupo.objectId) {
                    matchingData.push({
                        localId: cursor.key.replace(`${Parse.User.current().toJSON().objectId}-`, ''),
                        ...cursor.value
                    });
                }
                cursor.continue();
              } else {
                reslv(matchingData);
              }
            };
      
            await new Promise((resolve) => {
              tx.oncomplete = resolve;
            });
        } catch (error) {
            console.error('Error retrieving matching data from IndexedDB:', error);
            rejct(error);
        }
    });
}

class GroupListLocalNewCycle extends GroupListLocal {

    title = "Documentos de nuevos ciclos";

    UNSAFE_componentWillMount = async () => {
        const matchingData = await getLocalGroups();
        const prevState = this.state;
        this.setState({
            ...prevState,
            loading: false,
            localGroups: matchingData
        });
    }

    saveDocuments = (data) => {
        
        let requests = [];
        _.map(data.clients, (client, index) => {
            let formData1 = new FormData();
            formData1.append('base64', data.documents[index].birthCertificate.imageUrl);
            formData1.append('file_name', 'actanacimiento');
            formData1.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData1,
                })
            );

            let formData2 = new FormData();
            formData2.append('base64', data.documents[index].curp.imageUrl);
            formData2.append('file_name', 'curp');
            formData2.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData2,
                })
            );

            let formData3 = new FormData();
            formData3.append('base64', data.documents[index].ineBackSide.imageUrl);
            formData3.append('file_name', 'credencialreverso');
            formData3.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData3,
                })
            );

            let formData4 = new FormData();
            formData4.append('base64', data.documents[index].ineFrontSide.imageUrl);
            formData4.append('file_name', 'credencialanverso');
            formData4.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData4,
                })
            );

            let formData5 = new FormData();
            formData5.append('base64', data.documents[index].proofAddress.imageUrl);
            formData5.append('file_name', 'comprobantedomicilio');
            formData5.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData5,
                })
            );

            let formData6 = new FormData();
            formData6.append('base64', data.documents[index].signature.imageUrl);
            formData6.append('file_name', 'firma');
            formData6.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData6,
                })
            );
            
            if (data.grupo.foto_grupal_url) {
                let formData7 = new FormData();
                formData7.append('base64', data.grupo.foto_grupal_url);
                formData7.append('file_name', 'foto_grupal');
                formData7.append('objectId', data.grupo.objectId);
                requests.push(
                    fetch(`${config.domain}/upload_photo.php`, {
                        method: 'POST',
                        body: formData7,
                    })
                );
            }
        });
        
        let prevState1 = this.state;
        this.setState({
            ...prevState1,
            loading: true
        });
        getLocalGroups();

        Promise.all(requests)
        .then(responses => Promise.all(responses.map(response => response.json())))
        .then(async (responses) => {
            // separamos la respuesta de la foto grupal
            let groupResponse = {};
            if (data.grupo.foto_grupal_url) {
                groupResponse = responses.pop();
            }
            // guardamos los documentos en la DB
            const savedDocuments = await this.saveDocumentsInDB(responses, data.grupo.objectId);
            console.log('savedDocuments --> ', savedDocuments);
            const PointerDocs = _.map(savedDocuments, document => {
                return {
                    __type: 'Pointer',
                    className: 'Documentos',
                    objectId: document.success.objectId
                }
            })
            // guardamos la foto grupal en la DB
            await this.addPhotoGroup(data.grupo.objectId, groupResponse.ruta, PointerDocs);
            await this.deleteLocalData(data.localId);
            let prevState2 = this.state;
            this.setState({
                ...prevState2,
                loading: false
            });

            alert('Los documentos han sido enviados correctamente.');
        })
        .catch((error) => {
            // Manejar cualquier error en la solicitud
            console.error('Error al almacenar la imagen:', error);
            alert('Se produjo un error al intentar almacenar los documentos.');
        })
        .finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading}/>
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-users mr-2"></i>
                    {this.title}
                </h3>
                
                <div className="card">
                    <div className="card-body">
                            {
                                this.state.localGroups !== undefined && this.state.localGroups.map((data) =>
                                <div className="card w-100 mb-3" key={data.localId}>
                                    <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
                                        <div>
                                            <h6 className="card-subtitle mb-2 text-muted">Grupo</h6>
                                            <h5 className="card-title text-primary">{data.grupo.nombre}</h5>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button type="button" className="btn btn-secondary mr-3" disabled={!data.grupo.saved} onClick={() => this.saveDocuments(data)}>
                                                Subir archivos <i className="fas fa-upload"></i>
                                            </button>
                                            {/* <Link to={`/grupos/nuevo-grupo/${data.localId}`} title="Ver" className={`btn btn-secondary ${data.grupo.saved && 'disabled'}`}>
                                                Seguir editando <i className="fas fa-chevron-right"></i>
                                            </Link> */}
                                            {/* <button type="button" className="btn btn-danger ml-3" onClick={() => this.deleteLocalData(data.localId)}>Delete</button> */}
                                        </div>
                                    </div>
                                </div>)
                            }
                    </div>
                </div>

                <div className="p-5"></div>
            </>
        );
    }
}

export default GroupListLocalNewCycle;