import React, { Component } from 'react';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import _ from 'underscore';

class SelectTypeAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collection: [],
            value: props.defaultValue || '',
        }

        if(this.props.placeholder) {
            this.placeholder = this.props.placeholder;
        }

        this.handleChange = this.handleChange.bind(this);
    }

    placeholder = 'Elija una opción...';


    UNSAFE_componentWillMount = async() => {
        let params = new URLSearchParams({
            limit: 50,
            count: 1,
        });

        // const peticion = Rest.peticion(`${config.api_url}/classes/TypesAccounts?${params.toString()}`, {
        Rest.peticion(`${config.api_url}/classes/TypesAccounts?${params.toString()}`, {
            method: 'GET',
        }).then((response) => {
            return response.json();
        }).then((res) => {
            
            if (res.code) {
                alert('Ha ocurrido un error inesperado al recuperar los tipos de cuenta para el select.\nIntente más tarde.');
            } else {
                // console.log("Respuesta Tipos de cuenta en select => ", res);

                this.setState({
                    collection: res.results
                })
            }

            

        }).catch((err) => {
            console.log("Error: ", err)
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });

    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        // console.log("Es edicion");

        if (nextProps.defaultValue) {
            this.setState({
                value: nextProps.defaultValue || ''
            });
        } else {
            if (_.isObject(nextProps.value) && !_.isEmpty(nextProps.value)) {
                this.setState({
                    value: nextProps.value.objectId
                })
            } else if (!_.isEmpty(nextProps.value)) {
                this.setState({
                    value: nextProps.value
                })
            } else {
                this.setState({
                    value: ''
                })
            }
        }
        
    }

    setLabel = () => {
        const TypeAccount = _.findWhere(this.state.collection, {
            objectId: this.state.value
        });
        if (!_.isEmpty(TypeAccount)) {
            return TypeAccount.value;
        } else {
            return '';
        }
        
    }

    handleChange = !this.props.disabled ? this.props.onChange : (() => {console.log('sin permiso');});

    setOption = (item, index) => <option key={index} value={item.objectId}>{item.value}</option>;

    render() {
        return (<>
            {!this.props.readOnly && (this.props.disabled === true 
                ? <div className="form-control text-uppercase" style={{backgroundColor: '#e9ecef'}}>
                    {this.setLabel()}
                </div> 
                : <select className="form-control text-uppercase" name={this.props.name} id={this.props.id} value={this.state.value} onChange={this.handleChange} disabled={this.props.disabled} required={this.props.required}>
                    <option value="">{this.placeholder}</option>
                    {
                        (this.state.collection && this.state.collection.length) && this.state.collection.map((item, index) => 
                            this.setOption(item, index)
                        )
                    }
                </select>
            )}
            {this.props.readOnly && <p>{this.setLabel()}</p>}
        </>);
    }

}

export default SelectTypeAccount;