import React, { Component } from 'react';
import $ from 'jquery';

class PopOver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title || 'Defina un título',
            content: this.props.content || 'Escriba un texto breve.'
        }
    }
    componentDidMount = () => {
        $('[data-toggle="popover"]').popover();
    } 
    render() {
        if (this.props.size === 'normal') {
            return <i className={`fas fa-question-circle ${this.props.className}`} tabIndex="0" data-toggle="popover" data-trigger="focus" title={this.state.title} data-content={this.state.content}></i>
        }
        return (
            <small>
                <i className={`fas fa-question-circle ${this.props.className}`} tabIndex="0" data-toggle="popover" data-trigger="focus" title={this.state.title} data-content={this.state.content}></i>
            </small>
        );
    }
}

export default PopOver;