import React, { Component } from "react";
import formatString from "../../utilities/format-string";
import ReportDayTableDetails from "./ReportDayTableDetails";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import Spinner from "../others/Spinner";
import _ from "underscore";

export default class ReportDayView extends Component {

    state = {
        data: {},
        loading: false,
        printData: null,
        showControls: true,
        printResumeStatus: false
    }

    include = 'pagos_adelantados.grupo,'+
              'recuperacion_del_dia.grupo,'+
              'pagos_pendientes.grupo,'+
              'estimacion_del_dia.grupo,'+
              'Sucursal,'+
              'hecho_por';

    UNSAFE_componentWillMount = () => {
        this.getFullData();
    }

    componentWillUnmount = () => {
        localStorage.removeItem("bitacora_report");
    }

  getFullData = () => {
    this.setState({
        loading: true
    });

    const params = new URLSearchParams({
        include: this.include,
        keys: 'fecha_consulta,'+
              'fecha_publicacion,'+
              'Sucursal,'+
              'hecho_por,'+
              'depositos_para_desembolsos,'+
              'pagos_adelantados.grupo.nombre,'+
              'recuperacion_del_dia.grupo.nombre,'+
              'pagos_pendientes.grupo.nombre,'+
              'estimacion_del_dia.grupo.nombre,'+
              'depositos_a_cuenta,'+
              'gastos_extras,'+
              'total_estimacion_del_dia,'+
              'total_depositos_para_desembolsos,'+
              'total_pagos_adelantados,'+
              'total_recuperacion_del_dia,'+
              'total_desembolsos,'+
              'total_pagos_pendientes,'+
              'total_depositos_a_cuenta,'+
              'total_gastos_extras,'+
              'sobrante_del_dia'
    });

    Rest.peticion(
        `${config.api_url}/classes/ReporteBitacora/${
        this.props.match.params.id_bitacora
        }?${params.toString()}`,
        {
        method: "GET",
        }
    )
    .then((res) => {
        return res.json();
    })
    .then((res) => {
        return res;
    })
    .then(async (res) => {
        if (res.code) {
        alert("Ha ocurrido un error inesperado. Intente más tarde.");
        } else {
        const report = res;
        console.log('report --> ', report);
        const data = {
            // form inputs
            fecha_consulta: report.fecha_consulta.iso,
            fecha_publicacion: report.fecha_publicacion.iso,
            Sucursal: report.Sucursal,
            hecho_por: report.hecho_por,
            dayEstimate: report.estimacion_del_dia,
            disbursementDeposit: report.depositos_para_desembolsos,
            prepaid: report.pagos_adelantados,
            dayRecovery: report.recuperacion_del_dia,
            pendingPayments: report.pagos_pendientes,
            depositsToAccount: report.depositos_a_cuenta,
            extraCosts: report.gastos_extras,
            totalDayEstimate: report.total_estimacion_del_dia,
            totalDisbursementDeposit: report.total_depositos_para_desembolsos,
            totalPrepaid: report.total_pagos_adelantados,
            totalDayRecovery: report.total_recuperacion_del_dia,
            totalDisbursements: report.total_desembolsos,
            totalPendingPayments: report.total_pagos_pendientes,
            totalDepositsToAccount: report.total_depositos_a_cuenta,
            totalExtraCosts: report.total_gastos_extras,
            total: report.sobrante_del_dia,
            objectId: report.objectId,
        };
        this.setState({
            data: data,
        });
        }
    })
    .catch((error) => {
        console.log(error);
        alert("Ha ocurrido un error inesperado. Intente más tarde.");
    })
    .finally(() => {
        this.setState({
        loading: false,
        });
    });

  };

  reportPrint = (data) => {
    localStorage.setItem("bitacora_report", JSON.stringify(data));
    this.setState({
      printData: data,
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });

      window.frames["bitacora-reporte"].focus();
      window.frames["bitacora-reporte"].print();

      // resolve()
    }, 500);
  };

  reportPrintResume = (data) => {
    localStorage.setItem("bitacora_report", JSON.stringify(data));
    this.setState({
      printData: data,
      showControls: false,
      loading: true,
      printResumeStatus: true
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });

      window.frames["bitacora-reporte-resume"].focus();
      window.frames["bitacora-reporte-resume"].print();
      // resolve()
    }, 1000);
  };

  reportPrintPreview = (data) => {
    localStorage.setItem("bitacora_report", JSON.stringify(data));
    this.setState({
      printData: data,
      loading: true,
    });
    setTimeout(() => {
      window.open(
        `${this.props.match.url}/bitacora-reporte`,
        "_blank",
        "no_margin=false"
      );
      this.setState({
        loading: false,
      });
    }, 500);
  };

  render() {
    return (
      <>
        <Spinner loading={this.state.loading}/>
        <h3 className="text-uppercase text-pink pt-3 pb-3">
            <i className="fas fa-money-bill-wave mr-2"></i>
            Detalles de la bitácora
        </h3>
        <div className="card">
            <div className="card-body">
                <table className="table">
                <tbody>
                    <tr>
                    <td className="border-0">Fecha de elaboración</td>
                    <td className="border-0">
                        {this.state.data.fecha_consulta &&
                        formatString.setLegibleDate(this.state.data.fecha_consulta)}
                    </td>
                    </tr>
                    <tr>
                    <td className="border-0">Sucursal</td>
                    <td className="border-0">
                        {this.state.data.Sucursal && this.state.data.Sucursal.nombre}
                    </td>
                    </tr>
                    <tr>
                    <td className="border-0">Hecho por:</td>
                    <td className="border-0 text-uppercase">
                        {this.state.data.hecho_por &&
                        this.state.data.hecho_por.nombre +
                            " " +
                            this.state.data.hecho_por.apellidos}
                    </td>
                    </tr>
                </tbody>
                </table>
                <ReportDayTableDetails data={this.state.data} showControls={true} />
            </div>
            <div className="card-footer">
            <button type="button" className="btn btn-primary mr-3" onClick={() => this.reportPrint(this.state.data)}>Imprimir reporte completo</button>
            <button type="button" className="btn btn-primary mr-3" onClick={() => this.reportPrintResume(this.state.data)}>Imprimir reporte resumido</button>
            {/* <button type="button" className="btn btn-primary" onClick={() => this.reportPrintPreview(this.state.data)}>Vista previa</button> */}
            </div>
        </div>
        <br/><br/><br/><br/><br/>
        {!_.isEmpty(this.state.printData) && <iframe id="bitacora-reporte" name="bitacora-reporte" width="0" height="0" src={`${this.props.match.url}/bitacora-reporte?no_margin=true`}></iframe>}
        {this.state.printResumeStatus === true && <iframe id="bitacora-reporte-resume" name="bitacora-reporte-resume" width="0" height="0" src={`${this.props.match.url}/bitacora-reporte?no_margin=true&is_print=true&print_resume=true`}></iframe>}
      </>
    );
  }
}
