import config from './../settings/config';
import Rest from './../Server/Rest';
import _ from 'underscore';

const _saveSuburb = async (domicilio_colonia, cp) => {
    try {
        const params = new URLSearchParams({
            where: JSON.stringify({
                nombre: domicilio_colonia,
                cp: cp
            }),
            count: 1
        });
        const existe = await Rest.peticion(`${config.api_url}/classes/ColoniasBarrios?${params.toString()}`, {
            method: 'GET'
        }).then(res => {return res.json()}).then(res => {return res}).catch(error => console.log(error));

        if (_.isUndefined(existe.code) && existe.results.length === 0) {
            Rest.peticion(`${config.api_url}/classes/ColoniasBarrios`, {
                method: 'POST',
                body: JSON.stringify({
                    nombre: domicilio_colonia,
                    cp: cp
                })
            })
        }
    } catch (error) {
        console.log(error);
        
    }
    
}

export default {
    saveSuburb: _saveSuburb
}