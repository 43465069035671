const environment = {
    prod: {
        appId: "MVhpbN9SeMw6jt3Ms7W6BNKd3j6SZW9g",
        apiKey: "7YXXdYRWevsxJpNfPXwzJCmaSHG9rLHU",
        jsKey: "D5gn8ZSjbkmDqhW99qmyh9hjHKBLHPzH",
        masterKey: "6qjZ8NE8yEFMpcJLR6vKfF5bMXLwAx6s",
        api_path1: "/parse-server",
        api_path2: "/parse",
        api_domain: "https://api2.solfindelsureste.com",
        domain: "https://crm.solfindelsureste.com",
    },
    qa: {
        appId: "pBrW3xb7thD9QfegFXMR8aSYmJs24kTj",
        apiKey: "BQZKUfaue2Tv8P4DVqtgx3NwRFMc7AHs",
        jsKey: "z7P2nC9AMkQFtybsgXGdKNDSxeJw8v4q",
        masterKey: "QS5hgc3Us4e72vFDWxBRH9XGbYuVynNp",
        api_path1: "/parse-server",
        api_path2: "/parse",
        api_domain: "https://qa.api2.solfindelsureste.com",
        domain: "https://qa.crm.solfindelsureste.com",
    },
    dev: {

        appId: 'rbP8nmQSJHjWDUHAtKnkq8sMEV3dvxtW',
        apiKey: 'sE5TR6w5aHgdEz4F8WQLtaCqdDkBGxzN',
        jsKey: '4M4z3rvtB9EmJFeZbbp8kzhC7PYyL3wh',
        masterKey: "6XZ2j9Zfnj6Ckfu6VtE4DAnJsgDhH3c5",
        api_path1: "",
        api_path2: "/parse",
        api_domain: "http://localhost:1337",
        domain: "http://localhost/solfin-crm/src",
    }
}

const key_evironment = 'prod'; // escribir 'dev' o 'prod'

export default {
    api_url: environment[key_evironment].api_domain + environment[key_evironment].api_path1 + environment[key_evironment].api_path2,
    domain: environment[key_evironment].domain,
    api_path1: environment[key_evironment].api_path1,
    api_path2: environment[key_evironment].api_path2,
    api_path: environment[key_evironment].api_path1 + environment[key_evironment].api_path2,
    api_domain:  environment[key_evironment].api_domain,
    "headers": {
        "Content-Type":             "application/json",
        "X-Parse-Application-Id":   environment[key_evironment].appId,
        "X-Parse-REST-API-Key":     environment[key_evironment].apiKey
    },
    "headersFull": {
        "Content-Type":             "application/json",
        "X-Parse-Application-Id":   environment[key_evironment].appId,
        "X-Parse-REST-API-Key":     environment[key_evironment].apiKey,
        "X-Parse-Master-Key":     environment[key_evironment].masterKey
    },
    "headersConfig": {
        "Content-Type":             "application/json",
        "X-Parse-Application-Id":   environment[key_evironment].appId,
        "X-Parse-Master-Key":     environment[key_evironment].masterKey
    },
    appId: environment[key_evironment].appId,
    apiKey: environment[key_evironment].apiKey,
    jsKey: environment[key_evironment].jsKey,
    environment: key_evironment
}