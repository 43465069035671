import React from 'react';
import { Link } from "react-router-dom";
import config from '../../settings/config';
import Rest from '../../Server/Rest';
import _ from 'underscore';
import GroupListCreditsIndividual from './GroupListCreditsIndividual';

class GroupListCreditsEmployee extends GroupListCreditsIndividual {
    
    title = 'Créditos de empleados';

    getResource = (index = 0, _where = {}) => {

        this.where = _where;

        this.setState(state => ({
            index: index
        }));
        
        this.handleSpinner();
        
        const newWhere = {
            ..._where,
            ...(_where.Grupo ? _where.Grupo.$select.query.where : {}),
            ...{
                Empleado: {
                    $exists: true
                }
            },
            Solicitudes: {
                $inQuery: {
                    className: 'Solicitudes',
                    where: {
                        //prestamo_personalizado: true,
                        prestamo_personalizado_interes: {
                            $exists: true
                        },
                        prestamo_personalizado_cantidad_pagos: {
                            $exists: true
                        }
                    }
                }
            }
        }
        delete newWhere.Grupo;
        

        const params = new URLSearchParams({
            include: 'Sucursal,Solicitudes,promotor,status',
            limit: this.limit,
            skip: parseInt(index),
            where: JSON.stringify(newWhere),
            count: 1,
            order: this.order,
            keys: 'nombre,id_busqueda,Sucursal.nombre,status,id_en_sucursal,Sucursal.sigla,Solicitudes.status,Solicitudes.ciclo,promotor,status',
        });

        let end_point = `${config.api_url}/classes/Grupos?${params.toString()}`;

        Rest.peticion(end_point, {
            method: 'GET',
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .then(res => {
            console.log(res);
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                this.rows = res.count;
                this.setState({
                    collection: _.map(res.results, (item, index) => { item.index = index +1 + parseInt(this.state.index); return item; }),
                    total: res.count
                });
            }
        })
        .then(() => {
            this.handleSpinner();
        })
        .catch(error => {
            console.log('error --> ', error);
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    setUrl = (item) => {
        return <Link to={`/creditos-empleados/${item.objectId}/datos-generales`} title="Ver" className="btn btn-link btn-lg">
            <i className="fas fa-chevron-right"></i>
        </Link>
    }

    setHead = <h3 className="text-uppercase text-pink pt-3 pb-3 d-flex">
        <i className="fas fa-user align-items-start mr-2">
            <small><small><i className="fas fa-dollar-sign"></i></small></small>
        </i>
        {this.title}

    </h3>
    
}

export default GroupListCreditsEmployee;