import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Spinner from './../others/Spinner';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import user from './../../settings/user';
import Parse from 'parse';
import $ from 'jquery';
import _ from 'underscore';
import GroupPrintDocuments from './GroupPrintDocuments';
import RequisitionModel from './../../models/RequisitionModel';
import GroupSearchPartner from './GroupSearchPartner';
import PopOver from '../others/Popover';
import "react-datepicker/dist/react-datepicker.css";
import GroupRequisitionFastUpdate from './GroupRequisitionFastUpdate';
import formatString from '../../utilities/format-string';

class GroupRequisition extends Component {

    constructor(props){
        super(props);
        this.state = this.state_init;
        
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeAmount = this.handleChangeAmount.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitActive = this.handleSubmitActive.bind(this);
        this.handleCancelAutorization = this.handleCancelAutorization.bind(this);
        this.handleLowRequisition = this.handleLowRequisition.bind(this);
        this.handleChangeStatusSocios = this.handleChangeStatusSocios.bind(this);
        this.handleChangeEdit = this.handleChangeEdit.bind(this);
    }

    partners = [];
    partnersSelected = [];
    grupo = {};
    payments = [];
    PagosRealizados = [];
    state_init = {
        readOnly: true,
        ciclo: 1,
        // nombre: '',
        promotor: {},
        responsable: {},
        dia_pago: '',
        fecha_elaboracion: false,
        factor_cobro: null,
        cantidad_pagos_semanales: 10,
        id_en_sucursal: 0,
        montos: [], // Pointers,
        fecha_registro: formatString.setLegibleDate(new Date().toISOString()),
        fecha_autorizacion: false,
        fecha_rechazo: false,
        loading: false,
        status: '',
        status_comentario: '',
        status_socios: [],
        partnerRemove: {},
        partnerRemoveStatus: '',
        partnerRemoveStatus_comentario: '',
        prestamo_personalizado: false,
        prestamo_personalizado_cantidad_pagos: 0,
        prestamo_personalizado_periodicidad: '',
        prestamo_personalizado_interes: 0,
        garantia: '',
        garantia_valor: '',
        charge_factor_type: ''
    };

    getRequisition = async () => {
        
        this.setState({
            loading: true
        });

        const params = new URLSearchParams({
            include: 'fecha_elaboracion,Grupo,Grupo.responsable,Grupo.Socios,Grupo.Sucursal,Grupo.registeredBy,montos,montos.Socio,promotor,responsable,Sucursal,registeredBy,autorizado_por,Pagos'
        });
        
        let requests = [];
        requests[0] = Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.props.match.params.id_solicitud}?${params.toString()}`, {
            method: 'GET'
        });

        let responses = [];
        return Rest.peticiones(requests)
        .then(res => {
            responses = res;
            return responses[0].json();
        })
        .then(res => {
            localStorage.setItem('requisition', JSON.stringify(_.clone(res)));
            this.partnersSelected = _.pluck(_.clone(res.montos), 'Socio');
            this.grupo = res.Grupo;
            let fecha_elaboracion = false;
            if (res.fecha_elaboracion) {
                fecha_elaboracion = new Date(res.fecha_elaboracion.iso);
                fecha_elaboracion.setHours(0);
                fecha_elaboracion.setMinutes(0);
                fecha_elaboracion.setMilliseconds(0);
            }
            const state = {
                ciclo: res.ciclo,
                promotor: res.promotor,
                responsable: res.responsable,
                dia_pago: res.dia_pago,
                fecha_elaboracion: fecha_elaboracion,
                factor_cobro: res.factor_cobro || undefined,
                cantidad_pagos_semanales: res.cantidad_pagos_semanales,
                id_en_sucursal: res.id_en_sucursal,
                montos: res.montos,
                fecha_registro: formatString.setLegibleDate(res.fecha_registro.iso),
                fecha_autorizacion: (res.fecha_autorizacion && formatString.setLegibleDate(res.fecha_autorizacion.iso)) || false,
                fecha_rechazo: (res.fecha_rechazo && formatString.setLegibleDate(res.fecha_rechazo.iso)) || false,
                fecha_finalizacion: (res.fecha_finalizacion && formatString.setLegibleDate(res.fecha_finalizacion.iso)) || false,
                status: res.status,
                status_comentario: res.status_comentario,
                status_socios: _.map(this.partnersSelected, socio => {
                    return {
                        objectId: socio.objectId,
                        status: socio.status,
                        status_comentario: ''
                    }
                }),
                prestamo_personalizado: res.prestamo_personalizado,
                prestamo_personalizado_cantidad_pagos: res.prestamo_personalizado_cantidad_pagos,
                prestamo_personalizado_periodicidad: res.prestamo_personalizado_periodicidad,
                prestamo_personalizado_interes: res.prestamo_personalizado_interes,
                garantia: res.garantia,
                garantia_valor: res.garantia_valor,
                charge_factor_type: res.charge_factor_type,


                loading: false
            }

            this.payments = res.Pagos || [];
            this.PagosRealizados = res.PagosRealizados || [];
            this.setState(state);
            return true;
        })
        .catch(error => {
            console.log('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.', error);
            alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
            this.setState({
                loading: false
            });
            return false;
        });
    }

    // consultar datos del socio
    UNSAFE_componentWillMount = () => {
        this.getRequisition();
    }

    componentWillUnmount = () => {
        localStorage.removeItem('requisition');
    }

    /**
     * Se ejecuta en el caso de que se use el mismo componente al 
     * cambiar de una pantalla a otra pero con id_solicitud 
     * diferente
     */
    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.match.params.id_solicitud, this.props.match.params.id_solicitud)) {
            this.getRequisition();
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    getResource = async (_undefined = undefined, _where = {}) => {

        // obtener socios que no esten en baja castigada, morosidad o fallecida
        // y que estén en un grupo con status baja, rechazado o baja_voluntaria
        // y que no estén en validación
        _where.en_moderacion = {
            $ne: true
        }

        _where.Socio = {
            $select: {
                query: {
                    className: 'Grupos',
                    where: {
                        Solicitudes: {
                            $inQuery: {
                                className: 'Solicitudes',
                                where: {
                                    status: {
                                        $in: ['autorizado','autorizacion']
                                    }
                                }
                            }
                        }
                    }
                },
                key: 'Socios'
            }
        }
        // obtener solo los de la sucursal que administra el usuario actual
        if (Parse.User.current().toJSON().Sucursal) {
            _where.Socio.$select.query.where.Solicitudes.$inQuery.where.lugar_registro = Parse.User.current().toJSON().Sucursal;
        }

        const params1 = new URLSearchParams({
            keys: 'Socio.id_en_sucursal,Socio.nombre,Socio.apellido_paterno,Socio.apellido_materno,Socio.telefono,Socio.lugar_registro,Socio.status,charge_factor_type',
            limit: 100,
            where: JSON.stringify(_where),
            count: 1,
            order: 'Socio.nombre,Socio.apellido_paterno,Socio.apellido_materno',
            include: 'Socios,Socio.lugar_registro'
        });
        
        const req1 = await Rest.peticion(`${config.api_url}/classes/SociosDiccionario?${params1.toString()}`, {
            method: 'GET'
        }).then(res => {return res.json()});

        _where.Socio = {
            $inQuery: {
                className: 'Socios',
                where: {
                    status: {
                        $nin: ['BAJA_CASTIGADA','MOROSIDAD','FALLECIDA']
                    }
                }
            }
        }
        // obtener solo los de la sucursal que administra el usuario actual
        if (Parse.User.current().toJSON().Sucursal) {
            _where.Socio.$inQuery.where.lugar_registro = Parse.User.current().toJSON().Sucursal;
        }

        const params2 = new URLSearchParams({
            keys: 'Socio.id_en_sucursal,Socio.nombre,Socio.apellido_paterno,Socio.apellido_materno,Socio.telefono,Socio.lugar_registro,Socio.status',
            limit: 100,
            where: JSON.stringify(_where),
            count: 1,
            order: 'Socio.nombre,Socio.apellido_paterno,Socio.apellido_materno',
            include: 'Socios,Socio.lugar_registro'
        });

        const req2 = await Rest.peticion(`${config.api_url}/classes/SociosDiccionario?${params2.toString()}`, {
            method: 'GET'
        }).then(res => {return res.json()});

        return Rest.peticiones([req1, req2])
        .then(res => {
            if (res[0].error || res[0].code || res[1].error || res[1].code) {
                return {
                    code: res[0].code || res[1].code,
                    error: res[0].error || res[1].error
                }
            } else {
                let 
                results = _.union(res[0].results, res[1].results);
                results = _.groupBy(results, 'objectId');
                results = _.map(results, (value, key) => {
                    return value.length === 1 && value[0]
                });
                results = _.compact(results);
                return { 
                    results: results,
                    count: results.length
                }
            }
        })
        .catch(err => {
            console.log(err);
            return {
                code: 'error solfin',
                error: err
            };
        });

    }

    handleChangeStatusSocios = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const name_split = name.split(',');
        let {status_socios} = this.state;
        status_socios[name_split[1]][name_split[0]] = value;
        
        this.setState({
            status_socios: status_socios
        });
    }

    handleChangeAmount = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const name_split = name.split(',');
        
        let {montos} = this.state;
        if (value <= 0 && value !== '') {
            montos[name_split[1]][name_split[0]] = 0;
        } else {
            montos[name_split[1]][name_split[0]] = value;    
        }
        

        this.setState({
            montos: montos
        });
    }

    setAmountTotal = (amount_name) => {
        
        let sum = 0;
        _.each(this.state.montos, (item) => {
            if (item[amount_name] === '') {
                sum += 0;
            } else if (!_.isUndefined(item[amount_name])) {
                sum += parseInt(item[amount_name]);
            }
            
        });
        return sum;

    }

    validateAmounts = (amount_name) => {
        let isZero = false;
        _.each(this.state.montos, (item) => {
            if (
                _.isUndefined(item[amount_name]) ||
                item[amount_name] === '' ||
                item[amount_name] === 0 ||
                item[amount_name] === '0'

            ) {
                isZero = true;
            }
        });
        return isZero;
    }

    setPayday = (number_day) => {
        const days = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
        ];
        return days[number_day];
    }

    setText = (value) => {
        let text = '';
        switch (value) {
            case 'autorizacion':
                text = 'Autorización';
                break;
            case 'autorizado':
                text = 'Autorizado';
                break;
            case 'rechazado':
                text = 'Rechazado';
                break;
            case 'baja':
                text = 'Baja';
                break;
            case 'baja_voluntaria':
                text = 'Baja voluntaria';
                break;
            case 'reestructurado':
                text = 'Reestructurado';
                break;
            case 'morosidad':
                text = 'Morosidad';
                break;
            case 'PRENDARIA':
                text = 'PRENDARIA';
                break;
            case 'LIQUIDA':
                text = 'LÍQUIDA';
                break;
            default:
                text = value;
                break;
        }
        return text;
    }

    setResponsable = () => {
        let full_name = '';
        if (!_.isEmpty(this.state.responsable)) {
            full_name = `${this.state.responsable.nombre.toLowerCase()} ${this.state.responsable.apellido_paterno.toLowerCase()} ${this.state.responsable.apellido_materno.toLowerCase()}`;
        }
        return full_name;
    }

    setPromotor = () => {
        let full_name = '';
        if (!_.isEmpty(this.state.promotor)) {
            full_name = `${this.state.promotor.nombre.toLowerCase()} ${this.state.promotor.apellido_paterno.toLowerCase()} ${this.state.promotor.apellido_materno.toLowerCase()}`;
        }
        return full_name;
    }

    setfactor_cobro = (value) => {

        let text = '';
        switch (value) {
            case 135:
                text = '$135 (10 semanas)';
                break;
            case 129:
                text = '$129 (10 semanas)';
                break;
            case 126.5:
                text = '$126.5 (10 semanas)';
                break;
            case 116:
                text = '$116 (12 semanas)';
                break;
            case 113:
                text = '$113 (12 semanas)';
                break;
            case 110:
                text = '$110 (12 semanas)';
                break;
            case 105:
                text = '$105 (14 semanas)';
                break;
            case 101:
                text = '$101 (14 semanas)';
                break;
            case 96.5:
                text = '$96.5 (14 semanas)';
                break;
            case 95:
                text = '$95 (16 semanas)';
                break;    
            case 92.5:
                text = '$92.5 (16 semanas)';
                break;
            case 89.5:
                text = '$89.5 (16 semanas)';
                break;
            default:
                text = 'Sin especificar';
                break;
        }
        return text;

    }

    saveAmounts = (amounts) => {
        const fecha_autorizacion = new Date().toISOString();
        return _.map(amounts, amount => {     
            return {
                    method: 'PUT',
                    path: `${config.api_path2}/classes/SolicitudesMontos/${amount.objectId}`,
                    body: {
                        monto_solicitado: parseInt(amount.monto_solicitado),
                        monto_sugerido: parseInt(amount.monto_sugerido),
                        monto_autorizado: parseInt(amount.monto_autorizado),
                        fecha_autorizacion: {
                            __type: 'Date',
                            iso: fecha_autorizacion
                        },
                        Solicitud: {
                            __type: 'Pointer',
                            className: 'Solicitudes',
                            objectId: this.props.match.params.id_solicitud
                        }
                    }
                }
            }
        );
    }

    saveAmountsCancel = (amounts) => {
        return _.map(amounts, amount => {     
            return {
                    method: 'PUT',
                    path: `${config.api_path2}/classes/SolicitudesMontos/${amount.objectId}`,
                    body: {
                        fecha_autorizacion: {__op:'Delete'}
                    }
                }
            }
        );
    }

    saveAmountsDisallowance = (amounts) => {
        return _.map(amounts, amount => {     
            return {
                    method: 'PUT',
                    path: `${config.api_path2}/classes/SolicitudesMontos/${amount.objectId}`,
                    body: {
                        monto_autorizado: parseInt(amount.monto_autorizado),
                        fecha_autorizacion: {__op:'Delete'}
                    }
                }
            }
        );
    }

    saveRequestsAmounts = () => {
        const pay_amounts = _.map(this.state.montos, amount => {
            return {
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: amount.Socio.objectId
                },
                Grupo: {
                    __type: 'Pointer',
                    className: 'Grupos',
                    objectId: amount.Grupo.objectId
                },
                Solicitud: {
                    __type: 'Pointer',
                    className: 'Solicitudes',
                    objectId: this.props.match.params.id_solicitud
                },
                SolicitudMonto: {
                    __type: 'Pointer',
                    className: 'SolicitudesMontos',
                    objectId: amount.objectId
                },
                monto: parseFloat(RequisitionModel.setPay(this.state, parseInt(amount.monto_autorizado))),
            };
        });

        const pay_dates = RequisitionModel.setPaymentDays(this.state.fecha_elaboracion.getTime(), this.state.prestamo_personalizado, this.state.prestamo_personalizado_cantidad_pagos, this.state.prestamo_personalizado_periodicidad, this.state.cantidad_pagos_semanales, 'iso', false);

        let data = _.map(pay_dates, (date, index) => {
            return _.map(pay_amounts, amount => {
                return _.extend({
                    fecha_pago: {
                        __type: 'Date',
                        iso: date
                    },
                    numero_pago: index + 1
                }, amount)
            });
        });

        data = _.flatten(data);

        const requests = _.map(data, item => {
            return {
                method: 'POST',
                path: `${config.api_path2}/classes/SolicitudesPagos`,
                body: item
            }
        });

        return requests;
    }

    resetAmounts = () => {
        let res = [];
        _.map(this.state.montos, amount => {
            if (amount.objectId) {
                res.push({
                    method: 'PUT',
                    path: `${config.api_path2}/classes/SolicitudesMontos/${amount.objectId}`,
                    body: {
                        monto_acumulado: {__op:'Delete'},
                        ultima_fecha_pago: {__op:'Delete'}
                    }
                });
            }
        });
        return res;
    }

    deletePaymentsMade = () => {
        let res = [];
        _.each(this.PagosRealizados, pointer => {
            if (pointer.objectId) {
                res.push({
                    method: 'DELETE',
                    path: `${config.api_path2}/classes/SolicitudesPagosRealizados/${pointer.objectId}`
                })
            }
        });
        return res;
    }

    saveAmountsPointer = (resAmouts) => {
        const amountsPointers = _.map(resAmouts, (value) => {
            return {
                __type: 'Pointer',
                className: 'SolicitudesPagos',
                objectId: value.success.objectId
            }
        });

        const data = {
            Pagos: amountsPointers
        }

        return Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.props.match.params.id_solicitud}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        });
    }

    saveAmountsPointer2 = (resAmouts) => {
        let amountsPointers = [];
        if (resAmouts.length > 0) {
            for (let index = 0; index < resAmouts.length; index++) {
                if (resAmouts[index].success && resAmouts[index].success.createdAt) {
                    amountsPointers.push({
                        __type: 'Pointer',
                        className: 'SolicitudesPagos',
                        objectId: resAmouts[index].success.objectId
                    });
                }
            }

            let monto_autorizado = 0.0;
            
            for (let index = 0; index < this.state.montos.length; index++) {
                monto_autorizado += parseFloat(this.state.montos[index].monto_autorizado);
            }

            let meses = undefined;
            let interes = undefined;
            let iva_del_interes = undefined;

            // if (!(this.state.prestamo_personalizado === true)) {
            //     meses = RequisitionModel[this.state.charge_factor_type][this.state.factor_cobro].meses;
            //     interes = RequisitionModel[this.state.charge_factor_type][this.state.factor_cobro].interes;
            //     iva_del_interes = RequisitionModel[this.state.charge_factor_type][this.state.factor_cobro].iva_del_interes;
            // }

            const data = {
                Pagos: amountsPointers,
                monto_autorizado: monto_autorizado,
                meses: meses,
                interes: interes,
                iva_del_interes: iva_del_interes,
            }
    
            return Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.props.match.params.id_solicitud}`, {
                method: 'PUT',
                body: JSON.stringify(data)
            });
        } else {
            return false;
        }
    }

    handleOpenLowRequisitionWindow = () => {
        $('#modaLowRequisition').modal('show');
    }

    handleSubmit = (event) => {
        if (event) event.preventDefault();

        if (this.validateAmounts('monto_solicitado') || this.validateAmounts('monto_sugerido') || this.validateAmounts('monto_autorizado')) {
            alert('Debe especificar todos los montos antes de guardar.');
            return;
        }

        const r = window.confirm('¿Todos los datos son correctos?');

        if (r === true) {

            const currentUser = Parse.User.current();

            const fecha_autorizacion = {
                __type: 'Date',
                iso: new Date().toISOString()
            }

            let allRequests = [];

            allRequests = this.saveAmounts(this.state.montos);

            let monto_autorizado = 0.0;
            
            for (let index = 0; index < this.state.montos.length; index++) {
                monto_autorizado += parseFloat(this.state.montos[index].monto_autorizado);
                
            }

            allRequests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Grupos/${this.props.match.params.id_grupo}`,
                body: {
                    status: 'autorizado'
                }
            });
            
            let fecha_elaboracion = new Date(this.state.fecha_elaboracion);
            let fechaFinal = RequisitionModel.getLastPaymentDay(fecha_elaboracion, this.state.prestamo_personalizado, undefined, undefined, this.state.prestamo_personalizado ? this.state.cantidad_pagos_semanales : this.state.prestamo_personalizado_periodicidad, 'iso', false);
            fechaFinal = {
                __type: 'Date',
                iso: fechaFinal.toISOString()
            };

            let meses = undefined;
            let interes = undefined;
            let iva_del_interes = undefined;
            // if (!(this.state.prestamo_personalizado === true)) {
            //     console.log('this.state.charge_factor_type --> ', this.state.charge_factor_type);
            //     meses = RequisitionModel[this.state.charge_factor_type][this.state.factor_cobro].meses;
            //     interes = RequisitionModel[this.state.charge_factor_type][this.state.factor_cobro].interes;
            //     iva_del_interes = RequisitionModel[this.state.charge_factor_type][this.state.factor_cobro].iva_del_interes;
            // }

            // actualizar Solicitud con el status Autorizado
            allRequests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Solicitudes/${this.props.match.params.id_solicitud}`,
                body: {
                    status: 'autorizado',
                    monto_autorizado: monto_autorizado,
                    fecha_autorizacion: fecha_autorizacion,
                    fecha_final_contrato: fechaFinal,
                    autorizado_por: {
                        __type: 'Pointer',
                        className: '_User',
                        objectId: currentUser.id
                    },
                    fecha_rechazo: {__op:'Delete'},
                    rechazado_por: {__op:'Delete'},
                    cancelado_por: {__op:'Delete'},
                    fecha_cancelacion: {__op:'Delete'},
                    meses: meses,
                    interes: interes,
                    iva_del_interes: iva_del_interes,
                }
            });
            
            let newRequests = [];
            do{
                newRequests.push( allRequests.splice(0, 50) );
            } while (allRequests.length > 0);

            
            let REQUESTS = _.map(newRequests, requests => {
                return Rest.peticion(`${config.api_url}/batch`, {
                    method: 'POST',
                    body: JSON.stringify({
                        requests: requests
                    })
                });
            });

            this.setState({
                loading: true
            });

            Rest.peticiones(REQUESTS).then(res => {
                const test = _.every(res, response => {
                    return response.status === 200
                });
                if (test === false) {
                    return false;
                } else {
                    return true;
                }
            }).then(res => {
                const requests_pay = this.saveRequestsAmounts();

                const reqLength = requests_pay.length;
            
                const allRequests2 = requests_pay;

                let newRequests2 = [];
                do{
                    newRequests2.push( allRequests2.splice(0, 50) );
                } while (allRequests2.length > 0);

                let resPagos = [];
                _.each(newRequests2, async (requests, index) => {
                    await Rest.peticion(`${config.api_url}/batch`, {
                        method: 'POST',
                        body: JSON.stringify({
                            requests: requests
                        })
                    }).then(res => {
                        return res.json();
                    }).then(async res => {
                        resPagos = _.union(resPagos, _.clone(res));
                        if (reqLength === resPagos.length) {
                            await this.saveAmountsPointer(resPagos).then(res => {
                                if (res.code || res.error) {
                                    this.setState({
                                        loading: false
                                    });
                                    alert('Lo sentimos, no se ha podido autorizar la solicitud. Intente más tarde.');    
                                } else {
                                    alert('La solicitud ha sido autorizada.');
                                    this.getRequisition();
                                }
                            }).catch(error => {
                                console.error('Error al guardar los pointers de SolicitudesPagos: ', error);
                                this.setState({
                                    loading: false
                                });
                                alert('Lo sentimos, no se ha podido autorizar la solicitud. Intente más tarde.');
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
                alert('Lo sentimos, no se ha podido autorizar la solicitud. Intente más tarde.');
            });
        }
    }

     handleSubmitActive = (event) => {
        if (event) event.preventDefault();
        
        const r = window.confirm(' ¿Confirma que el dinero fue desembolsado? (Al confirmar, el crédito quedará activo)');

        if (r === true) {

            const currentUser = Parse.User.current();

            let allRequests = [];

            allRequests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Grupos/${this.props.match.params.id_grupo}`,
                body: {
                    status: 'activo'
                }
            });

            allRequests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Solicitudes/${this.props.match.params.id_solicitud}`,
                body: {
                    status: 'activo'
                }
            });


            let newRequests = [];
            do{
                newRequests.push( allRequests.splice(0, 50) );
            } while (allRequests.length > 0);

            const REQUESTS = _.map(newRequests, requests => {
                return Rest.peticion(`${config.api_url}/batch`, {
                    method: 'POST',
                    body: JSON.stringify({
                        requests: requests
                    })
                });
            });

            this.setState({
                loading: true
            });

            Rest.peticiones(REQUESTS).then(res => {
                const test = _.every(res, response => {
                    return response.status === 200
                });
                if (test === false) {
                    this.setState({
                        loading: false
                    });
                    alert('Lo sentimos, no se ha podido activar el crédito. Intente más tarde.');    
                } else {
                    this.setState({
                        loading: false,
                        fecha_autorizacion: false,
                        status: 'activo'
                    })
                    alert('El crédito se ha activado.');
                }
            }).catch(error => {
                this.setState({
                    loading: false
                });
                alert('Lo sentimos, no se ha podido activar el crédito. Intente más tarde.');
            });
        }
    }

    /**/savePayments = () => {

        const requests_pay = this.saveRequestsAmounts();
        const requests_amount = this.resetAmounts();
        const requests_payment_made = this.deletePaymentsMade();

        let allRequests = [];
        let newRequests = [];
        
        allRequests = _.union(requests_pay, requests_amount, requests_payment_made);

        if (!_.isEmpty(this.payments)) {
            _.each(this.payments, payment => {
                if (payment.objectId) {
                    allRequests.push({
                        method: 'DELETE',
                        path: `${config.api_path2}/classes/SolicitudesPagos/${payment.objectId}`,
                    });
                }
            });
        }

        const reqLength = allRequests.length;
        
        do{
            newRequests.push( allRequests.splice(0, 50) );
        } while (allRequests.length > 0);

        let resPagos = [];

        this.setState({
            loading: true
        });

        _.each(newRequests, (requests, index) => {
            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify({
                    requests: requests
                })
            }).then(res => {
                return res.json();
            }).then(res => {
                resPagos = _.union(resPagos, _.clone(res));
                if (reqLength === resPagos.length) {
                    this.saveAmountsPointer2(resPagos).then(res => {
                        if (res.code || res.error) {
                            this.setState({
                                loading: false
                            });
                            alert('No se ha podido procesar los pagos.');
                        } else {
                            alert('Los pagos han sido procesados.');
                            this.getRequisition();
                        }
                    }).catch(error => {
                        this.setState({
                            loading: false
                        });
                        alert('No se ha podido procesar los pagos.');
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        });
        
    }

    handleCancelAutorization = (event) => {
        if (event) event.preventDefault();
        
        const r = window.confirm('ADVERTENCIA. ¿Confirma que desea cancelar la autorización?');

        if (r === true) {

            const currentUser = Parse.User.current();

            let allRequests = [];

            allRequests = this.saveAmountsCancel(this.state.montos);

            allRequests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Grupos/${this.props.match.params.id_grupo}`,
                body: {
                    status: 'autorizacion'
                }
            });

            allRequests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Solicitudes/${this.props.match.params.id_solicitud}`,
                body: {
                    status: 'autorizacion',
                    monto_autorizado: {__op:'Delete'},
                    fecha_autorizacion: {__op:'Delete'},
                    fecha_final_contrato: {__op:'Delete'},
                    autorizado_por: {__op:'Delete'},
                    cancelado_por: {
                        __type: 'Pointer',
                        className: '_User',
                        objectId: currentUser.id
                    },
                    Pagos: {__op:'Delete'},
                }
            });

            _.each(this.payments, payment => {
                allRequests.push({
                    method: 'DELETE',
                    path: `${config.api_path2}/classes/SolicitudesPagos/${payment.objectId}`,
                });
            });

            let newRequests = [];
            do{
                newRequests.push( allRequests.splice(0, 50) );
            } while (allRequests.length > 0);

            const REQUESTS = _.map(newRequests, requests => {
                return Rest.peticion(`${config.api_url}/batch`, {
                    method: 'POST',
                    body: JSON.stringify({
                        requests: requests
                    })
                });
            });

            this.setState({
                loading: true
            });

            Rest.peticiones(REQUESTS).then(res => {
                const test = _.every(res, response => {
                    return response.status === 200
                });
                if (test === false) {
                    this.setState({
                        loading: false
                    });
                    alert('Lo sentimos, no se ha podido cancelar la autorización. Intente más tarde.');    
                } else {
                    this.setState({
                        loading: false,
                        fecha_autorizacion: false,
                        status: 'autorizacion'
                    })
                    alert('La autorización ha sido cancelada.');
                }
            }).catch(error => {
                this.setState({
                    loading: false
                });
                alert('Lo sentimos, no se ha podido cancelar la autorización. Intente más tarde.');
            });
        }
    }
    
    handleLowRequisition = (event) => {
        
        if (event) event.preventDefault();
        
        const r = window.confirm('ADVERTENCIA. ¿Confirma que desea rechazar la solicitud?');

        if (r === true) {

            const currentUser = Parse.User.current().toJSON();

            const fecha_actual = {
                __type: 'Date',
                iso: new Date().toISOString()
            }

            let requests = [];

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Grupos/${this.props.match.params.id_grupo}`,
                body: {
                    status: 'rechazado',
                    status_comentario: this.state.status_comentario.toUpperCase(),
                }
            });

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Solicitudes/${this.props.match.params.id_solicitud}`,
                body: {
                    status: 'rechazado',
                    fecha_rechazo: fecha_actual,
                    rechazado_por: {
                        __type: 'Pointer',
                        className: '_User',
                        objectId: currentUser.objectId
                    },
                    status_comentario: this.state.status_comentario.toUpperCase(),
                    fecha_autorizacion: {__op:'Delete'},
                    autorizado_por: {__op:'Delete'},
                    cancelado_por: {__op:'Delete'},
                    fecha_cancelacion: {__op:'Delete'}
                }
            });

            _.each(this.state.status_socios, socio => {
                socio.status_comentario = socio.status_comentario.toUpperCase();
                requests.push({
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Socios/${socio.objectId}`,
                    body: {
                        status: socio.status,
                        status_comentario: socio.status_comentario
                    }
                });
                let old_socio = _.findWhere(this.partnersSelected, {objectId: socio.objectId});
                
                let _data_status = {};

                old_socio.status = old_socio.status.toUpperCase();
                if (!_.isEqual(old_socio.status, socio.status)) {
                    _data_status.status = old_socio.status;
                }

                old_socio.status_comentario = old_socio.status_comentario.toUpperCase();
                if (!_.isEqual(old_socio.status_comentario, socio.status_comentario)) {
                    _data_status.status_comentario = old_socio.status_comentario;
                }

                if (!_.isEmpty(_data_status)) {
                    requests.push({
                        method: 'POST',
                        path: `${config.api_path2}/classes/SociosHistorial`,
                        body: {
                            datos_anteriores: _data_status,
                            afectado_por: currentUser.nombre+' '+currentUser.apellidos,
                            usuario_email: currentUser.email,
                            usuario_telefono: currentUser.telefono,
                            grupo_datos: 'datos-personales',
                            Rol: {
                                __type: 'Pointer',
                                className: 'Roles',
                                objectId: user.rol.objectId
                            },
                            User: {
                                __type: 'Pointer',
                                className: '_User',
                                objectId: currentUser.objectId
                            },
                            Socio: {
                                __type: 'Pointer',
                                className: 'Socios',
                                objectId: socio.objectId
                            }
                        }
                    })
                }
                
            });
            
            this.setState({
                loading: true
            });

            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify({
                    requests: requests
                })
            }).then(res => {
                return res.json();
            }).then(res => {
                if (res.code || res.error) {
                    this.setState({
                        loading: false
                    });
                    alert('Lo sentimos, no se ha podido rechazar la solicitud. Intente más tarde.');   
                } else {
                    this.setState({
                        loading: false,
                        fecha_rechazo: formatString.setLegibleDate(fecha_actual.iso),
                        status: 'rechazado',
                        status_comentario: this.state.status_comentario.toUpperCase()
                    })
                    alert('La solicitud ha sido rechazada.');
                }
                $('#modaLowRequisition').modal('hide');
            }).catch(error => {
                this.setState({
                    loading: false
                });
                alert('Lo sentimos, no se ha podido rechazar la solicitud. Intente más tarde.');
            });
            
        }
    }

    setRemovePartner = (partner) => {
        let state = this.state;
        state.partnerRemove = partner;
        state.PartnerRemoveStatus = '';
        state.PartnerRemoveStatus_comentario = '';
        this.setState(state);
        $('#removePartnerModal').modal('show');
    }

    setBtnRemovePartner = (socio) => {
        let result;
        if (this.state.status === 'autorizado' && user.rol.permisos.Grupos && user.rol.permisos.Grupos.quitar_clientas) {
            result = <button type="button" className="btn btn-link text-danger" tabIndex="-1" onClick={() => {this.setRemovePartner(socio)}} title="Quitar del grupo">
                <i className="fas fa-user-minus"></i>
            </button>
        } else if (this.state.status === 'autorizado') {
            result = <button type="button" className="btn btn-link text-danger" tabIndex="-1" onClick={() => alert('Solo el Administrador o Gerente pueden quitar la clienta del grupo con solicitud autorizada.')} title="Quitar del grupo">
                <i className="fas fa-user-minus"></i>
            </button>
        } else if (this.state.status !== 'autorizado') {
            result = <button type="button" className="btn btn-link text-danger" tabIndex="-1" onClick={() => {this.setRemovePartner(socio)}} title="Quitar del grupo">
                <i className="fas fa-user-minus"></i>
            </button>
        }
        
        return result;
    }

    handleRemovePartner = (event) => {
        if (event) event.preventDefault();

        const r = window.confirm('¿Está seguro de quitar la clienta del grupo actual?');

        if (r === true) {

            const currentUser = Parse.User.current().toJSON();

            const data = {
                requests: [{
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Socios/${this.state.partnerRemove.objectId}`,
                    body: {
                        status: this.state.partnerRemoveStatus,
                        status_comentario: this.state.partnerRemoveStatus_comentario
                    }
                },{
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Grupos/${this.props.match.params.id_grupo}`,
                    body: {
                        Socios: {
                            __op: 'Remove',
                            objects: [{
                                __type: 'Pointer',
                                className: 'Socios',
                                objectId: this.state.partnerRemove.objectId
                            }]
                        }
                    }
                }]
            }

            _.each(this.state.montos, monto => {
                if (monto.Socio.objectId === this.state.partnerRemove.objectId) {
                    data.requests.push({
                        method: 'PUT',
                        path: `${config.api_path2}/classes/Solicitudes/${this.props.match.params.id_solicitud}`,
                        body: {
                            montos: {
                                __op: 'Remove',
                                objects: [{
                                    __type: 'Pointer',
                                    className: 'SolicitudesMontos',
                                    objectId: monto.objectId
                                }]
                            }
                        }
                    })
                }
            });

            let _data_status = {};

            if (!_.isEqual(this.state.partnerRemove.status, this.state.partnerRemoveStatus)) {
                _data_status.status = this.state.partnerRemove.status;
            }

            if (!_.isEqual(this.state.partnerRemove.status_comentario, this.state.partnerRemoveStatus_comentario)) {
                _data_status.status_comentario = this.state.partnerRemove.status_comentario;
            }

            if (!_.isEmpty(_data_status)) {
                data.requests.push({
                    method: 'POST',
                    path: `${config.api_path2}/classes/SociosHistorial`,
                    body: {
                        datos_anteriores: _data_status,
                        afectado_por: currentUser.nombre+' '+currentUser.apellidos,
                        usuario_email: currentUser.email,
                        usuario_telefono: currentUser.telefono,
                        grupo_datos: 'datos-personales',
                        Rol: {
                            __type: 'Pointer',
                            className: 'Roles',
                            objectId: user.rol.objectId
                        },
                        User: {
                            __type: 'Pointer',
                            className: '_User',
                            objectId: currentUser.objectId
                        },
                        Socio: {
                            __type: 'Pointer',
                            className: 'Socios',
                            objectId: this.state.partnerRemove.objectId
                        }
                    }
                })
            }

            this.setState({
                loading: true
            });
            
            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(res => {
                this.getRequisition();
                return res.json();
            }).then(res => {
                this.setState({
                    partnerRemove: {},
                    status: '',
                    status_comentario: ''
                });
                $('#removePartnerModal').modal('hide');
                alert('La clienta ha sido retirada del grupo.');
            }).catch(error => {
                console.log(error);
            });
        }
    }
    
    handleChangeEdit = (event) => {
        let target = event.target;
        let name = target.name;
        
        this.setState((state) => ({
            [name]: !state[name]
        }));
    }
    
    handleAddPartner = (partner) => {

        let {state} = this;

        if (_.isEmpty(_.findWhere(this.partnersSelected, {objectId: partner.objectId}))) {
            this.partnersSelected.push(_.clone(partner));
            const status_socios = _.map(this.partnersSelected, socio => {
                return {
                    objectId: socio.objectId,
                    status: socio.status,
                    status_comentario: ''
                }
            });
            state.montos.push({
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: partner.objectId
                },
                monto_actual: 0,
                monto_solicitado: 0,
                monto_sugerido: 0,
                monto_autorizado: 0
            });
            this.setState({
                Socios: state.Socios,
                // montos: state.montos,
                status_socios: status_socios
            });
        } else {
            alert('La clienta ya fue agregada.');
        }
    }

    render() {

        const {id_grupo, id_solicitud} = this.props.match.params;
        return (
            <>
                <Spinner loading={this.state.loading} />

                {this.state.status === 'autorizacion' && <h4 className="text-primary pt-4 pb-1">Autorización del crédito</h4>}
                
                <div className="card mt-3 mb-3">
                    <div className="card-body">
                        <small className="text-muted">Grupo</small>
                        <legend>{this.grupo.nombre || ''}</legend>
                        <br/>
                        <div className="row">
                            <div className="form-group col-md-4">
                                <small className="text-muted">Fecha de registro</small>
                                <p>{this.state.fecha_registro}</p>
                            </div>
                            <div className="form-group col-md-4">
                                <small className="text-muted">ID de solicitud</small>
                                <p>{this.state.id_en_sucursal}</p>
                            </div>
                            <div className="form-group col-md-4">
                                <small className="text-muted">Ciclo</small>
                                <p>{this.state.ciclo}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-4">
                                <small className="text-muted">Promotor del grupo</small>
                                <p className="text-uppercase">{this.setPromotor()}</p>
                            </div>
                            <div className="form-group col-md-4">
                                <small className="text-muted">Responsable de grupo</small>
                                <p className="text-uppercase">{this.setResponsable()}</p>
                            </div>
                            <div className="form-group col-md-4">
                                <small className="text-muted">No. pagos</small>
                                <p>{this.state.cantidad_pagos_semanales || this.state.prestamo_personalizado_cantidad_pagos}</p>
                            </div>
                            <div className="form-group col-md-4">
                                <small className="text-muted">Fecha de elaboración</small>
                                <p className="text-uppercase">{this.setPayday(this.state.dia_pago)} {this.state.fecha_elaboracion && <>({formatString.setLegibleDate(this.state.fecha_elaboracion.toISOString())})</>}</p>
                            </div>
                            {!this.state.prestamo_personalizado_interes && <div className="form-group col-md-4">
                                <small className="text-muted">Factor de cobro</small>
                                <p className="text-uppercase">
                                    {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.factor_cobro)}
                                </p>
                            </div>}
                                <div className="form-group col-md-4">
                                    <small className="text-muted">Periodicidad</small>
                                    <p className="text-uppercase">                                    
                                        {(this.state.prestamo_personalizado_periodicidad === 7) && 'SEMANAL'}
                                        {(this.state.prestamo_personalizado_periodicidad === 14) && 'CATORCENAL'}
                                        {(this.state.prestamo_personalizado_periodicidad === 28) && 'BICATORCENAL'}
                                    </p>
                                </div>
                            {this.state.prestamo_personalizado_interes && <>
                                {user.rol.es_administrador === true && <div className="form-group col-md-4">
                                    <small className="text-muted">Interés</small>
                                    <p className="text-uppercase">
                                        {this.state.prestamo_personalizado_interes} %
                                    </p>
                                </div>}
                            </>}

                            {!_.isEmpty(this.state.garantia) && <div className="form-group col-md-4">
                                <small className="text-muted">Garantía</small>
                                <p className="text-uppercase">
                                    {!_.isEmpty(this.state.garantia) && this.setText(this.state.garantia)}{`${this.state.garantia === 'LIQUIDA'? `: $ ${this.state.garantia_valor}`: `: ${this.state.garantia_valor}`}`}
                                </p>
                            </div>}
                        </div>
                        <div className="row">
                            <div className="form-group col-md-4">
                                <small className="text-muted">Status del ciclo</small>
                                <p className={`text-uppercase ${(this.state.status === 'autorizado'? 'text-success':'') +' '+ (this.state.status === 'rechazado'? 'text-danger':'')}`}>{this.setText(this.state.status)}</p>
                            </div>
                            {this.state.fecha_autorizacion && <div className="form-group col-md-4">
                                <small className="text-muted">Autorizado al</small>
                                <p>{this.state.fecha_autorizacion}</p>
                            </div>}
                            {this.state.fecha_rechazo && <div className="form-group col-md-4">
                                <small className="text-muted">Rechazado al</small>
                                <p>{this.state.fecha_rechazo}</p>
                            </div>}
                            {this.state.fecha_finalizacion && <div className="form-group col-md-4">
                                <small className="text-muted">Baja al</small>
                                <p>{this.state.fecha_finalizacion}</p>
                            </div>}
                            {!_.isEmpty(this.state.status_comentario) && <div className="form-group col-md-4">
                                <small className="text-muted">Comentarios del status</small>
                                <p>{this.state.status_comentario}</p>
                            </div>}
                        </div>
                    </div>
                </div>
                
                {((user.rol.permisos.GenerarPagos && user.rol.permisos.GenerarPagos.registrar) && this.state.status !== 'rechazado') && <GroupRequisitionFastUpdate id_solicitud={id_solicitud} requisition={this.state} getRequisition={this.getRequisition} savePayments={this.savePayments} />}

                <div className={`form-group position-relative ${this.state.readOnly?'d-none':'d-block'}`} style={{zIndex: 1}}>
                    <label>Buscar clientas</label>
                    <GroupSearchPartner
                    getResource={this.getResource}
                    closeSearch={this.closeSearch}
                    handleAddPartner={this.handleAddPartner}/>
                </div>

                <div className="card mb-5">
                    <div className="card-body">

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="border-0">#</th>
                                        <th className="border-0">Nombre</th>
                                        <th className="border-0" nowrap="true">Monto actual</th>
                                        <th className="border-0" nowrap="true">Monto solicitado</th>
                                        <th className="border-0" nowrap="true">Monto sugerido</th>
                                        {id_grupo && <th className="border-0" nowrap="true">Monto autorizado</th>}
                                        {this.state.status !== 'baja' && <th className="border-0"></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.partnersSelected.map((socio, index) => <tr key={index} id={`${this.state.montos[index].objectId}`} className={`${(socio.status === 'FALLECIDA')?'alert-dark':''}`}>
                                        <td>{index +1}</td>
                                        <td className="td-responsive-text">
                                            {socio.nombre + ' ' +socio.apellido_paterno + ' ' + socio.apellido_materno}
                                            {socio.status === 'FALLECIDA' && <PopOver title="Pagos condonados" content="La clienta se encuentra fallecida." />}
                                        </td>
                                        <td nowrap="true">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" tabIndex={`amount-input-${index*1}`} className="form-control" min="100" name={['monto_actual',index]} value={this.state.montos[index].monto_actual} disabled />
                                            </div>
                                        </td>
                                        <td nowrap="true">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" tabIndex={`amount-input-${index*2}`} className="form-control" min="100" name={['monto_solicitado',index]} value={this.state.montos[index].monto_solicitado} onChange={this.handleChangeAmount} disabled={this.state.status !== 'autorizacion' && this.state.readOnly === true} />
                                            </div>
                                        </td>
                                        <td nowrap="true">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" tabIndex={`amount-input-${index*3}`} className="form-control" min="100" name={['monto_sugerido',index]} value={this.state.montos[index].monto_sugerido} onChange={this.handleChangeAmount} disabled={this.state.status !== 'autorizacion' && this.state.readOnly === true} />
                                            </div>
                                        </td>
                                        {id_grupo && <td nowrap="true">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" tabIndex={`amount-input-${index*4}`} className="form-control" min="100" name={['monto_autorizado',index]} value={this.state.montos[index].monto_autorizado} onChange={this.handleChangeAmount} disabled={this.state.status !== 'autorizacion' && this.state.readOnly === true} />
                                            </div>
                                        </td>}
                                        {(
                                            this.state.status !== 'baja' && 
                                            this.state.status !== 'rechazado'
                                        ) && <td>
                                            {this.setBtnRemovePartner(socio)}
                                        </td>}
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="align-middle"><b>Totales</b></td>
                                        <td></td>
                                        <td nowrap="true">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text border-0">$</div>
                                                </div>
                                                <span type="text" className="form-control text-info border-0">
                                                    {new Intl.NumberFormat('en').format( this.setAmountTotal('monto_actual') )}
                                                </span>
                                            </div>
                                        </td>
                                        <td nowrap="true">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text border-0">$</div>
                                                </div>
                                                <span type="text" className="form-control text-info border-0">
                                                    {new Intl.NumberFormat('en').format( this.setAmountTotal('monto_solicitado') )}
                                                </span>
                                            </div>
                                        </td>
                                        <td nowrap="true">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text border-0">$</div>
                                                </div>
                                                <span type="text" className="form-control text-info border-0">
                                                    {new Intl.NumberFormat('en').format( this.setAmountTotal('monto_sugerido') )}
                                                </span>
                                            </div>
                                        </td>
                                        {id_grupo && <td nowrap="true">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text border-0">$</div>
                                                </div>
                                                <span type="text" className="form-control text-info border-0" disabled={(this.state.status === 'autorizado' || this.state.status === 'rechazado')}>
                                                    {new Intl.NumberFormat('en').format( this.setAmountTotal('monto_autorizado') )}
                                                </span>
                                            </div>
                                        </td>}
                                        {this.state.status !== 'baja' && <td></td>}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <hr/>

                        {
                            (
                                this.state.status === 'autorizacion' &&
                                user.rol.permisos.Autorizaciones && user.rol.permisos.Autorizaciones.editar
                            ) && <>
                                <button type="button" onClick={() => this.handleSubmit()} className="btn btn-primary mr-3">Autorizar</button>
                                <button type="button" onClick={() => this.handleOpenLowRequisitionWindow()} className="btn btn-primary mr-3">Rechazar</button>
                            </>
                        }
                        {
                            (
                            this.state.status === 'autorizado' && 
                            user.rol.permisos.Autorizaciones && user.rol.permisos.Autorizaciones.editar
                            ) && <>
                                <button type="button" onClick={() => this.handleCancelAutorization()} className="btn btn-danger mt-3 mt-md-0 mr-3">Cancelar autorización</button> 
                            </>
                        }

                        {
                            (this.state.status === 'autorizado'||this.state.status === 'baja'||this.state.status === 'activo'||this.state.status === 'morosidad') && <>
                                {(user.rol.permisos.Autorizados && user.rol.permisos.Autorizados.imprimir) && <GroupPrintDocuments match={this.props.match} />}
                            </>
                        }

                        <button type="button" onClick={() => this.handleSubmitActive()} className="btn btn-dark mt-3 mt-md-0 mr-3" disabled={!(this.state.status === 'autorizado' && user.rol.permisos.Autorizaciones && user.rol.permisos.Autorizaciones.editar)}>Confirmar desembolso</button>
 
                        {
                            (
                            this.state.status === 'autorizado' && 
                            user.rol.permisos.Autorizaciones && user.rol.permisos.Autorizaciones.editar
                            ) && <>
                                <p><small id="form-text-garantia" className="form-text text-muted">Al confirmar el desembolso el grupo cambiará a status Activo.</small></p>
                            </>
                        }
                        
                    </div>
                </div>

                <div className="modal fade" id="modaLowRequisition" tabIndex="-1" role="dialog" aria-labelledby="modaLowRequisitionLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={this.handleLowRequisition} className="modal-content mb-0">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modaLowRequisitionLabel">Rechazar solicitud</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="card-body">

                                <div className="form-group">
                                    <label htmlFor="status_comentario">¿Por qué se rechaza la solicitud?</label>
                                    <textarea className="form-control text-uppercase" id="status_comentario" name="status_comentario" value={this.state.status_comentario} onChange={this.handleChange} rows="3" required></textarea>
                                </div>

                                <label>Modifica el status de las clientas del grupo</label>
                            
                                {this.partnersSelected.length > 0 && this.partnersSelected.map((socio, index) => <div className="card mb-3" key={index}>
                                    <div className="card-body">
                                        <p>
                                            {socio.nombre} {socio.apellido_paterno} {socio.apellido_materno}
                                        </p>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Status</label>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_activo_${index}`} value="ACTIVO" checked={this.state.status_socios[index].status === 'ACTIVO'} onChange={this.handleChangeStatusSocios} required />
                                                    <label className="form-check-label" htmlFor={`status_activo_${index}`}>
                                                        Activa
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_voluntaria_${index}`} value="BAJA_VOLUNTARIA" checked={this.state.status_socios[index].status === 'BAJA_VOLUNTARIA'} onChange={this.handleChangeStatusSocios} required />
                                                    <label className="form-check-label" htmlFor={`status_baja_voluntaria_${index}`}>
                                                        Baja voluntaria
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_castigada_${index}`} value="BAJA_CASTIGADA" checked={this.state.status_socios[index].status === 'BAJA_CASTIGADA'} onChange={this.handleChangeStatusSocios} />
                                                    <label className="form-check-label" htmlFor={`status_baja_castigada_${index}`}>
                                                        Baja castigada
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_rechazada_${index}`} value="RECHAZADO" checked={this.state.status_socios[index].status === 'RECHAZADO'} onChange={this.handleChangeStatusSocios} required />
                                                    <label className="form-check-label" htmlFor={`status_baja_rechazada_${index}`}>
                                                        Rechazada
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_morocidad_${index}`} value="MOROSIDAD" checked={this.state.status_socios[index].status === 'MOROSIDAD'} onChange={this.handleChangeStatusSocios} />
                                                    <label className="form-check-label" htmlFor={`status_baja_morocidad_${index}`}>
                                                        Morosidad
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_fallecida_${index}`} value="FALLECIDA" checked={this.state.status_socios[index].status === 'FALLECIDA'} onChange={this.handleChangeStatusSocios} required />
                                                    <label className="form-check-label" htmlFor={`status_baja_fallecida_${index}`}>
                                                        Fallecida
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <label htmlFor={`status_comentario${index}`}>Comentario del status
                                                    {(this.state.status_socios[index].status === 'BAJA_VOLUNTARIA') && <span>*</span>}
                                                    {(this.state.status_socios[index].status === 'BAJA_CASTIGADA') && <span>*</span>}
                                                    {(this.state.status_socios[index].status === 'RECHAZADO') && <span>*</span>}
                                                    {(this.state.status_socios[index].status === 'MOROSIDAD') && <span>*</span>}
                                                </label>
                                                <textarea className="form-control text-uppercase" id={`status_comentario${index}`} name={['status_comentario',index]} value={this.state.status_socios[index].status_comentario} onChange={this.handleChangeStatusSocios} rows="3" required={
                                                    (this.state.status_socios[index].status === 'BAJA_VOLUNTARIA') ||
                                                    (this.state.status_socios[index].status === 'BAJA_CASTIGADA') ||
                                                    (this.state.status_socios[index].status === 'RECHAZADO') ||
                                                    (this.state.status_socios[index].status === 'MOROSIDAD')
                                                } ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                            
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            
                <div className="modal fade" id="removePartnerModal" tabIndex="-1" role="dialog" aria-labelledby="removePartnerModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={this.handleRemovePartner} className="modal-content mb-0">
                            <div className="modal-header">
                                <h5 className="modal-title" id="removePartnerModalLabel">Quitar clienta del grupo</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <small className="text-muted">Clienta seleccionada</small>
                                <p>
                                    {this.state.partnerRemove.nombre} {this.state.partnerRemove.apellido_paterno} {this.state.partnerRemove.apellido_materno}
                                </p>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Status</label>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="partnerRemoveStatus" id="status_activo" value="ACTIVO" onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_activo">
                                                Activa
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="partnerRemoveStatus" id="status_baja_voluntaria" value="BAJA_VOLUNTARIA" onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_baja_voluntaria">
                                                Baja voluntaria
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="partnerRemoveStatus" id="status_baja_castigada" value="BAJA_CASTIGADA" onChange={this.handleChange} />
                                            <label className="form-check-label" htmlFor="status_baja_castigada">
                                                Baja castigada
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="partnerRemoveStatus" id="status_baja_rechazada" value="RECHAZADO" onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_baja_rechazada">
                                                Rechazada
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="partnerRemoveStatus" id="status_baja_morocidad" value="MOROSIDAD" onChange={this.handleChange} />
                                            <label className="form-check-label" htmlFor="status_baja_morocidad">
                                                Morosidad
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="partnerRemoveStatus" id="status_baja_fallecida" value="FALLECIDA" onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_baja_fallecida">
                                                Fallecida
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <label htmlFor="partnerRemoveStatus_comentario">Comentario del status
                                            {(this.state.partnerRemoveStatus === 'BAJA_VOLUNTARIA') && <span>*</span>}
                                            {(this.state.partnerRemoveStatus === 'BAJA_CASTIGADA') && <span>*</span>}
                                            {(this.state.partnerRemoveStatus === 'RECHAZADO') && <span>*</span>}
                                            {(this.state.partnerRemoveStatus === 'MOROSIDAD') && <span>*</span>}
                                        </label>
                                        <textarea className="form-control text-uppercase" id="partnerRemoveStatus_comentario" name="partnerRemoveStatus_comentario" value={this.state.partnerRemoveStatus_comentario} onChange={this.handleChange} rows="3" required={
                                            (this.state.partnerRemoveStatus === 'BAJA_VOLUNTARIA') ||
                                            (this.state.partnerRemoveStatus === 'BAJA_CASTIGADA') ||
                                            (this.state.partnerRemoveStatus === 'RECHAZADO') ||
                                            (this.state.partnerRemoveStatus === 'MOROSIDAD')
                                        } ></textarea>
                                    </div>
                                </div>
                                
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            
                
            </>
        );
    }
}

export default GroupRequisition;