import React, { Component } from 'react';
import PartnerList from './../partners/PartnerList';
import { Link } from "react-router-dom";
import Spinner from './../others/Spinner';
import TablePagination from './../others/TablePagination';
import TableCountInfo from './../others/TableCountInfo';
import GroupSearch from './GroupSearch';
import config from './../../settings/config';
import Rest from '../../Server/Rest';
import Parse from 'parse';
import _ from 'underscore';
import formatString from '../../utilities/format-string';

class GroupList extends PartnerList {
    
    title = 'Créditos grupales';

    order = '-Grupo.fecha_registro,-createdAt';

    getResource = (index = 0, _where = {}) => {

        this.where = _where;

        this.setState(state => ({
            index: index
        }));
        
        this.handleSpinner();

        let where = {};
        if (_.isUndefined(_where.Grupo) && Parse.User.current().toJSON().Sucursal) {
            _where.Grupo = {
                $select: {
                    query: {
                        className: 'Grupos',
                        where: {
                            Empleado: {
                                $exists: false
                            },
                            Sucursal: Parse.User.current().toJSON().Sucursal,
                            Solicitudes: {
                                $inQuery: {
                                    className: 'Solicitudes',
                                    where: {
                                        prestamo_personalizado_interes: {
                                            $exists: false
                                        },
                                        prestamo_personalizado: {
                                            $ne: true
                                        },
                                        factor_cobro: {
                                            $exists: true
                                        },   
 
                                    }
                                }
                            }
                        }
                    },
                    key: 'objectId'
                }
            }
        }
         else
        {
            if (_.isUndefined(_where.Grupo)) {

            _where.Grupo = {
                $select: {
                    query: {
                        className: 'Grupos',
                        where: {
                            Empleado: {
                                $exists: false
                            },
                            Solicitudes: {
                                $inQuery: {
                                    className: 'Solicitudes',
                                    where: {
                                        prestamo_personalizado_interes: {
                                            $exists: false
                                        },
                                        prestamo_personalizado: {
                                            $ne: true
                                        },
                                        factor_cobro: {
                                            $exists: true
                                        },    
                   
                                    }
                                }
                            }
                        }
                    },
                    key: 'objectId' 
                }
            }
            }   
        }    
        
        where = _where;
        let end_point = `${config.api_url}/functions/getGroups`;
        let body = {
            where: where,
            order: this.order,
            include: 'Grupo,Grupo.Sucursal,Grupo.Solicitudes,Grupo.promotor,status',
            keys: 'Grupo,Grupo.nombre,Grupo.Sucursal.nombre,Grupo.status,Grupo.id_en_sucursal,Grupo.Sucursal.sigla,Grupo.Solicitudes.status,Grupo.Solicitudes.ciclo,Grupo.promotor,status',
            skip: parseInt(index),
            limit: this.limit
        };

        where = JSON.stringify(where);

        Rest.peticion(end_point, {
            method: 'POST',
            body: JSON.stringify(body)
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                this.rows = res.result.count;
                this.setState({
                    collection: _.map(res.result.results, (item, index) => { item.index = index +1 + parseInt(this.state.index); return item; }),
                    total: res.result.count
                });
            }
        })
        .then(() => {
            this.handleSpinner();
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    setStatus = (status) => {
        let text = '';
        switch (status) {
            case 'autorizacion':
                text = 'Autorización';
                break;
            case 'autorizado':
                text = 'Autorizado';
                break;
            case 'rechazado':
                text = 'Rechazado';
                break;
            case 'baja':
                text = 'Baja';
                break;
            case 'baja_voluntaria':
                text = 'Baja voluntaria';
                break;
            case 'reestructurado':
                text = 'Reestructurado';
                break;
            case 'morosidad':
                text = 'Morosidad';
                break;
            default:
                text = status;
                break;
        }
        return text;
    }
    
    setStatusSolicitud = (Solicitudes) => {
        return this.setStatus(Solicitudes[Solicitudes.length -1].status);
    }

    setCycle = (Solicitudes) => {
        return this.setStatus(Solicitudes[Solicitudes.length -1].ciclo);
    }

    setUrl = (item) => {
        return <Link to={`/grupos/${item.objectId}/${item.Grupo.objectId}/datos-generales`} title="Ver" className="btn btn-link btn-lg">
            <i className="fas fa-chevron-right"></i>
        </Link>
    }

    setDate = (item) => {
        const d = formatString.setLegibleDate((item.Grupo.fecha_registro && item.Grupo.fecha_registro.iso)||item.Grupo.createdAt);
        return  d;
    }

    setOrder = (order) => {
        const where = {
            ['Grupo']: {
                ['$select']: {
                    ['query']: {
                        ['className']: 'Grupos',
                        ['where']: {
                            ['status']: order
                        }
                    },
                    ['key']: 'objectId'
                }
            }
        }
        
        this.rows = 0;
        this.handleFilter(where);
    }

    handleFilter = (where) => {
        this.rows = 0;
        this.getResource(0, where);
    }

    setHead = <h3 className="text-uppercase text-pink pt-3 pb-3 d-flex">
        <i className="fas fa-users align-items-start mr-2">
            <small><small><i className="fas fa-dollar-sign"></i></small></small>
        </i>
        {this.title}
    </h3>

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />
                
                {this.setHead}
                
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <GroupSearch handleFilter={this.handleFilter} />
                            </div>
                        </div>
                        <br />

                        <TableCountInfo total={this.state.total} />

                        <div className="table-responsive">    
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre</th>
                                    {/* <th scope="col">No. socios</th> */}
                                    <th scope="col">ID</th>
                                    <th scope="col">Sucursal</th>
                                    <th scope="col">Promotor</th>
                                    {/* <th scope="col">Ciclo actual</th> */}
                                    <th scope="col">
                                        Status
                                        <div className="dropdown float-right d-none">
                                            <div className="text-primary" type="button" id="dropdownOrderStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                </svg>
                                            </div>
                                            <div className="dropdown-menu" aria-labelledby="dropdownOrderStatus">
                                                <h6 className="dropdown-header">Filtro por status</h6>
                                                <button className="dropdown-item" type="button" onClick={() => this.setOrder('')} value="">Status</button>
                                                <button className="dropdown-item" type="button" onClick={() => this.setOrder('activo')} value="activo">Activo</button>
                                                <button className="dropdown-item" type="button" onClick={() => this.setOrder('autorizacion')} value="autorizacion">Autorización</button>
                                                <button className="dropdown-item" type="button" onClick={() => this.setOrder('autorizado')} value="autorizado">Autorizado</button>
                                                <button className="dropdown-item" type="button" onClick={() => this.setOrder('baja')} value="baja">Baja</button>
                                                <button className="dropdown-item" type="button" onClick={() => this.setOrder('rechazado')} value="rechazado">Rechazado</button>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col">Fecha de registro</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.collection.length ? this.state.collection.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.index}</td>
                                                <td className="td-responsive-text">
                                                    
                                                    {item.Grupo.nombre}
                                                    
                                                </td>
                                                {/* <td>{item.Grupo.Socios.length}</td> */}
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code className="text-body">{this.setID(item.Grupo.id_en_sucursal, item.Grupo.Sucursal)}</code>
                                                </td>
                                                <td>{this.setLugarRegistro(item.Grupo.Sucursal)}</td>
                                                <td>{item.Grupo.promotor.nombre} {item.Grupo.promotor.apellido_paterno} {item.Grupo.promotor.apellido_materno}</td>
                                                {/* <td>{this.setCycle(item.Grupo.Solicitudes)}</td> */}
                                                <td className="text-uppercase">
                                                    {
                                                        // !(
                                                            // this.where.Grupo && this.where.Grupo.$select.query.where.status === 'fnc_cartera_vencida' ||
                                                            // this.where.Grupo && this.where.Grupo.$select.query.where.status === 'fnc_cartera_incobrable'
                                                        // ) && (
                                                            this.setStatus(item.Grupo.status)
                                                        // ) || <button type="button" className="btn btn-warning">Cartera E.J.</button>
                                                    }
                                                </td>
                                                <td>{this.setDate(item)}</td>
                                                <td className="p-1 text-right">
                                                    {this.setUrl(item)}
                                                </td>
                                            </tr>
                                        ) : <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="alert alert-warning m-0" role="alert">
                                                     <i className="fas fa-filter"></i> Realice un filtro
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>    
                    
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where} />
                            </div>
                        </div>
                    
                    </div>
                </div>

                <div className="p-5"></div>
            </>
        );
    }

}

export default GroupList;