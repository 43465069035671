import React, { useEffect, useState } from "react";
import _ from 'underscore';
import { getEconomicDataModel } from "./models";

const EconomicData = React.memo((props) => {

    const [data, setData] = useState(getEconomicDataModel());
    const [toDay] = useState(new Date().getTime());

    useEffect(() => {
        if (!_.isEmpty(props.data)) {
            setData(props.data);
        }
    }, [props.data]);

    const handleChange = (e) => {
        let target = e.target;
        let value = target.value;
        let name = target.name;

        if (!_.isEmpty(value) && target.type === "number") {
            value = parseInt(value);
        }
        
        const nextData = {...data, [name]: value };
        
        setData(nextData);

        if (props.handleChange) {
            props.handleChange(nextData, props.index);
        }
    }

    const setTotalIncome = () => {

        const negocio_venta =                parseInt(data.negocio_venta) || 0;
        const sueldo_conyugue =              parseInt(data.sueldo_conyugue) || 0;
        const ingreso_prospera =             parseInt(data.ingreso_prospera) || 0;
        const ingreso_beca =                 parseInt(data.ingreso_beca) || 0;
        const ingreso_otros =                parseInt(data.ingreso_otros) || 0;
        const ingreso_otros_ventas_totales = parseInt(data.ingreso_otros_ventas_totales) || 0;
        const ingreso_otros_trabajo =        parseInt(data.ingreso_otros_trabajo) || 0;

        let total = 
        negocio_venta +
        sueldo_conyugue +
        ingreso_prospera +
        ingreso_beca +
        ingreso_otros +
        ingreso_otros_ventas_totales +
        ingreso_otros_trabajo;

        return total;

    }

    const setTotalExpenses = () => {

        const gastos_negocio =                  parseInt(data.gastos_negocio) || 0;
        const gastos_prestamo_banco =           parseInt(data.gastos_prestamo_banco) || 0;
        const gastos_prestamo_departamental =   parseInt(data.gastos_prestamo_departamental) || 0;
        const gastos_prestamo_financiera =      parseInt(data.gastos_prestamo_financiera) || 0;
        const gastos_prestamo_otro =            parseInt(data.gastos_prestamo_otro) || 0;
        const gastos_prestamo_personal =        parseInt(data.gastos_prestamo_personal) || 0;
        const gastos_prestamo_tdd =             parseInt(data.gastos_prestamo_tdd) || 0;
        const gastos_vivienda_agua =            parseInt(data.gastos_vivienda_agua) || 0;
        const gastos_vivienda_luz =             parseInt(data.gastos_vivienda_luz) || 0;
        const gastos_vivienda_otros =           parseInt(data.gastos_vivienda_otros) || 0;
        const gastos_vivienda_renta_casa =      parseInt(data.gastos_vivienda_renta_casa) || 0;
        const gastos_vivienda_telefono =        parseInt(data.gastos_vivienda_telefono) || 0;
        const gastos_vivienda_transporte =      parseInt(data.gastos_vivienda_transporte) || 0;

        let total = 
        gastos_negocio +
        gastos_prestamo_banco +
        gastos_prestamo_departamental +
        gastos_prestamo_financiera +
        gastos_prestamo_otro +
        gastos_prestamo_personal +
        gastos_prestamo_tdd +
        gastos_vivienda_agua +
        gastos_vivienda_luz +
        gastos_vivienda_otros +
        gastos_vivienda_renta_casa +
        gastos_vivienda_telefono +
        gastos_vivienda_transporte;

        return total;
    }

    const setMonthlyTotal = () => (setTotalIncome() - setTotalExpenses());

    return <div>
        <h5>Datos económicos</h5>
        <div className="row">
            <div className="form-group col-md-6 col-xl-4">
                <label>Tipo de negocio</label>
                <input type="text" className="form-control text-uppercase" id={`negocio_tipo_${toDay}`} name="negocio_tipo" label="Tipo negocio" value={data.negocio_tipo} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Tiempo en la actividad</label>
                <input type="text" className="form-control text-uppercase" id={`negocio_tiempo_activo_${toDay}`} name="negocio_tiempo_activo" label="Tiempo activo negocio" value={data.negocio_tiempo_activo} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Destino del crédito</label>
                <select className="form-control text-uppercase" id={`destino_credito_${toDay}`} name="destino_credito" label="Destino crédito" value={data.destino_credito} onChange={handleChange} required={true} >
                    <option value="" disabled>Elija una opción...</option>
                    <option>Inversión</option>
                </select>
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Venta del negocio</label>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>
                    <input type="number" className="form-control" id={`negocio_venta_${toDay}`} name="negocio_venta" label="Venta del negocio" value={data.negocio_venta} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Gastos</label>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>
                    <input type="number" className="form-control" id={`gastos_negocio_${toDay}`} name="gastos_negocio" label="Gastos del negocio" value={data.gastos_negocio} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="form-group col-md-6 col-xl-4">
                <label>Sueldo del conyugue</label>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>
                    <input type="number" className="form-control" id={`sueldo_conyugue_${toDay}`} name="sueldo_conyugue" label="Sueldo del cónyuge" value={data.sueldo_conyugue} onChange={handleChange} required={true} />
                </div>
            </div>
        </div>

        <h5>Ingresos familiares</h5>
        <div className="row">
            <div className="col-md-6">
                <h6>Subsidio del gobierno</h6>
                <hr/>
                <div className="form-group row">
                    <label className="col-sm-4">Prospera</label>
                    <div className="input-group col-sm-8 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`ingreso_prospera_${toDay}`} name="ingreso_prospera" label="Ingreso prospera" value={data.ingreso_prospera} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4">Beca</label>
                    <div className="input-group col-sm-8 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`ingreso_beca_${toDay}`} name="ingreso_beca" label="Ingreso beca" value={data.ingreso_beca} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4">Otros</label>
                    <div className="input-group col-sm-8 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`ingreso_otros_${toDay}`} name="ingreso_otros" label="Otros ingresos" value={data.ingreso_otros} onChange={handleChange} required={true} />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <h6>Otros ingresos</h6>
                <hr/>
                <div className="form-group row">
                    <label className="col-sm-4">Ventas totales</label>
                    <div className="input-group col-sm-8 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`ingreso_otros_ventas_totales_${toDay}`} name="ingreso_otros_ventas_totales" label="Ventas totales" value={data.ingreso_otros_ventas_totales} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4">Otro trabajo</label>
                    <div className="input-group col-sm-8 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`ingreso_otros_trabajo_${toDay}`} name="ingreso_otros_trabajo" label="Ingreso otros trabajos" value={data.ingreso_otros_trabajo} onChange={handleChange} required={true} />
                    </div>
                </div>
            </div>
        </div>

        <h5>Gastos familiares</h5>
        <div className="row">
            <div className="col-md-6">
                <h6>Vivienda</h6>
                <hr/>
                <div className="form-group row">
                    <label className="col-sm-5">Renta de casa</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_vivienda_renta_casa_${toDay}`} name="gastos_vivienda_renta_casa" label="Gastos de renta" value={data.gastos_vivienda_renta_casa} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Teléfono</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_vivienda_telefono_${toDay}`} name="gastos_vivienda_telefono" label="Gastos de télefono" value={data.gastos_vivienda_telefono} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Agua</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_vivienda_agua_${toDay}`} name="gastos_vivienda_agua" label="Gastos de agua" value={data.gastos_vivienda_agua} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Luz</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_vivienda_luz_${toDay}`} name="gastos_vivienda_luz" label="Gastos de luz" value={data.gastos_vivienda_luz} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Transporte</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_vivienda_transporte_${toDay}`} name="gastos_vivienda_transporte" label="Gastos de transporte" value={data.gastos_vivienda_transporte} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Otros</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_vivienda_otros_${toDay}`} name="gastos_vivienda_otros" label="Otros gastos" value={data.gastos_vivienda_otros} onChange={handleChange} required={true} />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <h6>Préstamos</h6>
                <hr/>
                <div className="form-group row">
                    <label className="col-sm-5">Personal</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_prestamo_personal_${toDay}`} name="gastos_prestamo_personal" label="Gastos de préstamo personal" value={data.gastos_prestamo_personal} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Financieras</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_prestamo_financiera_${toDay}`} name="gastos_prestamo_financiera" label="Gastos de préstamo en financiera" value={data.gastos_prestamo_financiera} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Bancos</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_prestamo_banco_${toDay}`} name="gastos_prestamo_banco" label="Gastos de préstamo en banco" value={data.gastos_prestamo_banco} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Trarjetas de crédito</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_prestamo_tdd_${toDay}`} name="gastos_prestamo_tdd" label="Gastos de préstamo en tdd" value={data.gastos_prestamo_tdd} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Departamentales</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_prestamo_departamental_${toDay}`} name="gastos_prestamo_departamental" label="Gastos de préstamo en tienda departamental" value={data.gastos_prestamo_departamental} onChange={handleChange} required={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Otros</label>
                    <div className="input-group col-sm-7 mb-2 align-items-start">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                        <input type="number" min="0" className="form-control" id={`gastos_prestamo_otro_${toDay}`} name="gastos_prestamo_otro" label="Otro gastos de préstamos" value={data.gastos_prestamo_otro} onChange={handleChange} required={true} />
                    </div>
                </div>
            </div>
        </div>

        <h5>Totales</h5>

        <div className="row">
                    
            <div className="form-group col-md-4">
                    <label>Total ingresos</label>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>
                    <span className="form-control text-muted">{setTotalIncome()}</span>
                </div>
            </div>
            
            
            <div className="form-group col-md-4">
                    <label>Total egreso</label>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>
                    <span className="form-control text-muted">{setTotalExpenses()}</span>
                </div>
            </div>
            
            
            <div className="form-group col-md-4">
                <label>Total mensual</label>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>
                    <span className="form-control text-muted" >{setMonthlyTotal()}</span>
                </div>
            </div>
            
            <div className="form-group col-md-12">
                <label>Observación de la vivienda</label>
                <textarea className="form-control text-uppercase" id={`observacion_vivienda_${toDay}`} name="observacion_vivienda" label="Observaciones de la vivienda" value={data.observacion_vivienda} onChange={handleChange} rows="3" required={true} ></textarea>
            </div>
        </div>
    </div>
})

export default EconomicData;