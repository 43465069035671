import React, { Component } from 'react';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import _ from 'underscore';

class PartnerCredit extends Component {

    constructor (props) {
        super(props);

        this.state = {
            collection: [],
            loading: false,
            index: 0,
            total: 0
        }
    }
    
    limit   = 100;
    rows    = 0;
    where   = {};

    UNSAFE_componentWillMount = (index = 0) => {
        const where = {
            montos: {
                $inQuery: {
                    where: {
                        Socio: {
                            __type: 'Pointer',
                            className: 'Socios',
                            objectId: this.props.match.params.id_socio
                        }
                    },
                    className: 'SolicitudesMontos'
                }
            }
        }
        const params = new URLSearchParams({
            
            limit: this.limit,
            skip: index,
            where: JSON.stringify(where),
            count: 1,
            order: '-fecha_registro,-createdAt',
            include: 'montos,Grupo'
        });
        
        Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();

        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                
                this.rows = res.count;
                this.setState({
                    collection: res.results,
                    total: res.count
                });
                
            }
            
        })
        .catch(error => {
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
            
        });
    }
    setAmount = (montos, tipo) => {
        let res = 0;
        const _amount = _.find(montos, monto => {
            return _.isEqual(monto.Socio, {__type:"Pointer",className:"Socios",objectId:this.props.match.params.id_socio})
        });
        if (!_.isEmpty(_amount)) {
            res = _amount[tipo] || 0
        }
        return res;
    }

    setStatus = (status) => {
        let text = '';
        switch (status) {
            case 'autorizacion':
                text = 'Autorización';
                break;
            case 'autorizado':
                text = 'Autorizado';
                break;
            case 'rechazado':
                text = 'Rechazado';
                break;
            case 'baja':
                text = 'Baja';
                break;
            case 'baja_voluntaria':
                text = 'Baja voluntaria';
                break;
            case 'reestructurado':
                text = 'Reestructurado';
                break;
            case 'morosidad':
                text = 'Morosidad';
                break;
            default:
                text = status;
                break;
        }
        return text;
    }

    render() {
        return (
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Grupo</th>
                            <th>Ciclo</th>
                            <th>Status</th>
                            <th>Monto actual</th>
                            <th>Monto solicitado</th>
                            <th>Monto sugerido</th>
                            <th>Monto autorizado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {((this.state.collection.length > 0) && this.state.collection.map((item, index) => item.Grupo && <tr key={index}>
                            <td>{item.Grupo.nombre}</td>
                            <td>{item.ciclo}</td>
                            <td className="text-uppercase">{this.setStatus(item.status)}</td>
                            <td>{new Intl.NumberFormat('en-MX',{style:'currency',currency:'USD'}).format( this.setAmount(item.montos, 'monto_actual') )}</td>
                            <td>{new Intl.NumberFormat('en-MX',{style:'currency',currency:'USD'}).format( this.setAmount(item.montos, 'monto_solicitado') )}</td>
                            <td>{new Intl.NumberFormat('en-MX',{style:'currency',currency:'USD'}).format( this.setAmount(item.montos, 'monto_sugerido') )}</td>
                            <td>{new Intl.NumberFormat('en-MX',{style:'currency',currency:'USD'}).format( this.setAmount(item.montos, 'monto_autorizado') )}</td>
                        </tr>)) || <tr>
                            <td colSpan="7" className="text-center">
                                <div className="alert alert-warning m-0" role="alert">
                                    No se hallaron registros
                                </div>
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PartnerCredit;