function setPlainText (str) {
    for (var i = 0; i < str.length; i++) {
        if (str.charAt(i)=="Ã") str = str.replace(/Ã/,"A");
        if (str.charAt(i)=="À") str = str.replace(/À/,"A");
        if (str.charAt(i)=="Á") str = str.replace(/Á/,"A");
        if (str.charAt(i)=="Ä") str = str.replace(/Ä/,"A");
        if (str.charAt(i)=="Â") str = str.replace(/Â/,"A");
        if (str.charAt(i)=="È") str = str.replace(/È/,"E");
        if (str.charAt(i)=="É") str = str.replace(/É/,"E");
        if (str.charAt(i)=="Ë") str = str.replace(/Ë/,"E");
        if (str.charAt(i)=="Ê") str = str.replace(/Ê/,"E");
        if (str.charAt(i)=="Ì") str = str.replace(/Ì/,"I");
        if (str.charAt(i)=="Í") str = str.replace(/Í/,"I");
        if (str.charAt(i)=="Ï") str = str.replace(/Ï/,"I");
        if (str.charAt(i)=="Î") str = str.replace(/Î/,"I");
        if (str.charAt(i)=="Ò") str = str.replace(/Ò/,"O");
        if (str.charAt(i)=="Ó") str = str.replace(/Ó/,"O");
        if (str.charAt(i)=="Ö") str = str.replace(/Ö/,"O");
        if (str.charAt(i)=="Ô") str = str.replace(/Ô/,"O");
        if (str.charAt(i)=="Ù") str = str.replace(/Ù/,"U");
        if (str.charAt(i)=="Ú") str = str.replace(/Ú/,"U");
        if (str.charAt(i)=="Ü") str = str.replace(/Ü/,"U");
        if (str.charAt(i)=="Û") str = str.replace(/Û/,"U");
        if (str.charAt(i)=="ã") str = str.replace(/ã/,"a");
        if (str.charAt(i)=="à") str = str.replace(/à/,"a");
        if (str.charAt(i)=="á") str = str.replace(/á/,"a");
        if (str.charAt(i)=="ä") str = str.replace(/ä/,"a");
        if (str.charAt(i)=="â") str = str.replace(/â/,"a");
        if (str.charAt(i)=="è") str = str.replace(/è/,"e");
        if (str.charAt(i)=="é") str = str.replace(/é/,"e");
        if (str.charAt(i)=="ë") str = str.replace(/ë/,"e");
        if (str.charAt(i)=="ê") str = str.replace(/ê/,"e");
        if (str.charAt(i)=="ì") str = str.replace(/ì/,"i");
        if (str.charAt(i)=="í") str = str.replace(/í/,"i");
        if (str.charAt(i)=="ï") str = str.replace(/ï/,"i");
        if (str.charAt(i)=="î") str = str.replace(/î/,"i");
        if (str.charAt(i)=="ò") str = str.replace(/ò/,"o");
        if (str.charAt(i)=="ó") str = str.replace(/ó/,"o");
        if (str.charAt(i)=="ö") str = str.replace(/ö/,"o");
        if (str.charAt(i)=="ô") str = str.replace(/ô/,"o");
        if (str.charAt(i)=="ù") str = str.replace(/ù/,"u");
        if (str.charAt(i)=="ú") str = str.replace(/ú/,"u");
        if (str.charAt(i)=="ü") str = str.replace(/ü/,"u");
        if (str.charAt(i)=="û") str = str.replace(/û/,"u");
        if (str.charAt(i)=="Ñ") str = str.replace(/Ñ/,"n");
        if (str.charAt(i)=="ñ") str = str.replace(/ñ/,"n");
        if (str.charAt(i)=="Ç") str = str.replace(/Ç/,"c");
        if (str.charAt(i)=="ç") str = str.replace(/ç/,"c");
        if (str.charAt(i)=="'") str = str.replace(/'/, "");
        if (str.charAt(i)==" ") str = str.replace(/ /, "");
    }
    return str;
}

const validCURP = (value) => {
    return /^[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/.test( value );
    
}

const setIdBusqueda = (s) => {
    let id_busqueda =   setPlainText(s).toLowerCase();
    id_busqueda =  id_busqueda.toLowerCase();
    return id_busqueda;
}

const setLegibleDate = (date_iso) => {
    const _days = ['??','01','02','03','04','05','06','07','08','09','10',
                  '11','12','13','14','15','16','17','18','19','20',
                  '21','22','23','24','25','26','27','28','29','30',
                  '31'];
    const _months = ['01','02','03','04','05','06','07','08','09','10','11','12']
  
    let theDate = new Date(date_iso);

    return `${_days[theDate.getDate()]}/${_months[theDate.getMonth()]}/${theDate.getFullYear()}`;
}

const setLegiblePrice = (value) => value.toLocaleString('es-MX', {
    style: 'currency',
    currency: 'MXN'
});

export default {
    setPlainText,
    validCURP,
    setIdBusqueda,
    setLegibleDate,
    setLegiblePrice
}
