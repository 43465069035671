
import Home from './../pages/Home';
import Partner from './../partners/Partner';
import PartnerRegister from './../partners/PartnerRegister';
import PartnerList from './../partners/PartnerList';
import PartnerListModeration from './../partners/PartnerListModeration';
import BranchOffices from './../catalogs/BranchOffices';
import ChangeRecord from './../pages/ChangeRecord';
import GroupList from './../groups/GroupList';
import GroupListAutorization from './../groups/GroupListAutorization';
import GroupRegister from './../groups/GroupRegister';
import GroupRegisterWizard from './../groups/GroupRegisterWizard';
import Group from './../groups/Group';
import GroupListPayment from './../groups/GroupListPayment';
import EmployeeRegister from './../employees/EmployeeRegister';
import Employee from './../employees/Employee';
import EmployeeList from './../employees/EmployeeList';
import Configuration from './../system/Configuration';
import user from './../../settings/user';
import _ from 'underscore';
import GroupListAuthorized from '../groups/GroupListAuthorized';
import DocumentPaymentControl from '../documents/DocumentPaymentControl';
import DocumentGrupalPromissoryNote from '../documents/DocumentGrupalPromissoryNote';
import DocumentIndividualPromissoryNote from '../documents/DocumentIndividualPromissoryNote';
import DocumentCheckPolicy from '../documents/DocumentCheckPolicy';
import DocumentVarius from '../documents/DocumentVarius';
import GroupListPaymentReport from '../groups/GroupListPaymentReport';
import GroupRequisitions from '../groups/GroupRequisitions';
import GroupListCheck from '../groups/GroupListCheck';
import GroupListCheckRequisition from '../groups/GroupListCheckRequisition';
import GroupRequisitionPayments from '../groups/GroupRequisitionPayments';
import AccountingChanges from '../accounting/AccountingChanges';
import DocumentAccountStatement from '../documents/DocumentAccountStatement';
import ReportDay from '../Reports/ReportDay';
import ReportDayHistory from '../Reports/ReportDayHistory';
import ReportDayEdit from '../Reports/ReportDayEdit';
import ReportDayView from '../Reports/ReportDayView';
import DocumentBitacoraReport from '../documents/DocumentBitacoraReport';
import AccountRegister from '../catalogsofaccounts/AccountRegister';
import AccountsList from '../catalogsofaccounts/AccountList';
import Account from '../catalogsofaccounts/Account';
import ListBranches from '../Branches/ListBranches';
import ListDisbursmentAndPayment from '../Policies/ListDisbursmentAndPayment';
import PoliciesList from '../Policies/PoliciesList';
import PolicyDetails from '../Policies/PolicyDetails';
import GroupListLocal from '../groups/GroupListLocal';
import GroupListCreditsIndividual from '../groups/GroupListCreditsIndividual';
import GroupListPaymentCreditsIndividual from '../groups/GroupListPaymentCreditsIndividual';
import GroupListLocalNewCycle from '../groups/GroupListLocalNewCycle';
import GroupListCreditsEmployee from '../groups/GroupListCreditsEmployee';
import GroupListPaymentCreditsEmployee from '../groups/GroupListPaymentCreditsEmployee';
import GroupRequisition from '../groups/GroupRequisition';

const routes = [];

try {
    routes.push({
        title: 'Escritorio',
        path: "/escritorio",
        exact: true,
        component: Home
    });
    
    if (user.rol.es_administrador) {
        routes.push({
            path: "/solicitudes",
            exact: true,
            component: GroupRequisitions
        });
    }
    routes.push({
        path: "/grupos/:id_grupo/solicitud-actual/:id_solicitud_actual/control-pagos",
        exact: true,
        component: DocumentPaymentControl
    });
    routes.push({
        path: "/grupos/:id_grupo/solicitud-actual/:id_solicitud_actual/pagare-individual",
        exact: true,
        component: DocumentIndividualPromissoryNote
    });
    routes.push({
        path: "/grupos/:id_grupo/solicitud-actual/:id_solicitud_actual/pagare-grupal",
        exact: true,
        component: DocumentGrupalPromissoryNote
    });
    routes.push({
        path: "/grupos/:id_grupo/solicitud-actual/:id_solicitud_actual/poliza",
        exact: true,
        component: DocumentCheckPolicy
    });
    routes.push({
        path: "/grupos/:id_grupo/solicitud-actual/:id_solicitud_actual/varios",
        exact: true,
        component: DocumentVarius
    });
    routes.push({
        path: "/grupos/:id_grupo/historial/:id_solicitud_actual/control-pagos",
        exact: true,
        component: DocumentPaymentControl
    });
    routes.push({
        path: "/grupos/:id_grupo/historial/:id_solicitud_actual/pagare-individual",
        exact: true,
        component: DocumentIndividualPromissoryNote
    });
    routes.push({
        path: "/grupos/:id_grupo/historial/:id_solicitud_actual/pagare-grupal",
        exact: true,
        component: DocumentGrupalPromissoryNote
    });
    routes.push({
        path: "/grupos/:id_grupo/historial/:id_solicitud_actual/poliza",
        exact: true,
        component: DocumentCheckPolicy
    });
    routes.push({
        path: "/grupos/:id_grupo/historial/:id_solicitud_actual/varios",
        exact: true,
        component: DocumentVarius
    });

    routes.push({
        path: "/creditos-individuales/:id_grupo/solicitud-actual/:id_solicitud_actual/control-pagos",
        exact: true,
        component: DocumentPaymentControl
    });
    routes.push({
        path: "/creditos-individuales/:id_grupo/solicitud-actual/:id_solicitud_actual/pagare-individual",
        exact: true,
        component: DocumentIndividualPromissoryNote
    });
    routes.push({
        path: "/creditos-individuales/:id_grupo/solicitud-actual/:id_solicitud_actual/pagare-grupal",
        exact: true,
        component: DocumentGrupalPromissoryNote
    });
    routes.push({
        path: "/creditos-individuales/:id_grupo/solicitud-actual/:id_solicitud_actual/poliza",
        exact: true,
        component: DocumentCheckPolicy
    });
    routes.push({
        path: "/creditos-individuales/:id_grupo/solicitud-actual/:id_solicitud_actual/varios",
        exact: true,
        component: DocumentVarius
    });
    routes.push({
        path: "/creditos-individuales/:id_grupo/historial/:id_solicitud_actual/control-pagos",
        exact: true,
        component: DocumentPaymentControl
    });
    routes.push({
        path: "/creditos-individuales/:id_grupo/historial/:id_solicitud_actual/pagare-individual",
        exact: true,
        component: DocumentIndividualPromissoryNote
    });
    routes.push({
        path: "/creditos-individuales/:id_grupo/historial/:id_solicitud_actual/pagare-grupal",
        exact: true,
        component: DocumentGrupalPromissoryNote
    });
    routes.push({
        path: "/creditos-individuales/:id_grupo/historial/:id_solicitud_actual/poliza",
        exact: true,
        component: DocumentCheckPolicy
    });
    routes.push({
        path: "/creditos-individuales/:id_grupo/historial/:id_solicitud_actual/varios",
        exact: true,
        component: DocumentVarius
    });

    routes.push({
        path: "/creditos-empleados/:id_grupo/solicitud-actual/:id_solicitud_actual/control-pagos",
        exact: true,
        component: DocumentPaymentControl
    });
    routes.push({
        path: "/creditos-empleados/:id_grupo/solicitud-actual/:id_solicitud_actual/pagare-individual",
        exact: true,
        component: DocumentIndividualPromissoryNote
    });
    routes.push({
        path: "/creditos-empleados/:id_grupo/solicitud-actual/:id_solicitud_actual/pagare-grupal",
        exact: true,
        component: DocumentGrupalPromissoryNote
    });
    routes.push({
        path: "/creditos-empleados/:id_grupo/solicitud-actual/:id_solicitud_actual/poliza",
        exact: true,
        component: DocumentCheckPolicy
    });
    routes.push({
        path: "/creditos-empleados/:id_grupo/solicitud-actual/:id_solicitud_actual/varios",
        exact: true,
        component: DocumentVarius
    });
    routes.push({
        path: "/creditos-empleados/:id_grupo/historial/:id_solicitud_actual/control-pagos",
        exact: true,
        component: DocumentPaymentControl
    });
    routes.push({
        path: "/creditos-empleados/:id_grupo/historial/:id_solicitud_actual/pagare-individual",
        exact: true,
        component: DocumentIndividualPromissoryNote
    });
    routes.push({
        path: "/creditos-empleados/:id_grupo/historial/:id_solicitud_actual/pagare-grupal",
        exact: true,
        component: DocumentGrupalPromissoryNote
    });
    routes.push({
        path: "/creditos-empleados/:id_grupo/historial/:id_solicitud_actual/poliza",
        exact: true,
        component: DocumentCheckPolicy
    });
    routes.push({
        path: "/creditos-empleados/:id_grupo/historial/:id_solicitud_actual/varios",
        exact: true,
        component: DocumentVarius
    });


    if (!_.isEmpty(user.rol.permisos.Socios) && user.rol.permisos.Socios.registrar) {
        routes.push({
            title: 'Registro',
            path: "/clientas/registro",
            exact: true,
            component: PartnerRegister
        });
    }
    if (!_.isEmpty(user.rol.permisos.Socios) && user.rol.permisos.Socios.consultar) {  
        routes.push({
            title: 'Clientas',
            path: "/clientas",
            exact: true,
            component: PartnerList
        });
        
        routes.push({
            title: 'Editar clienta',
            path: "/clientas/:id_diccionario/:id_socio/:id_en_sucursal",
            component: Partner
        });
    }
    if (!_.isEmpty(user.rol.permisos.Validacion)) {
        routes.push({
            title: 'Validación',
            path: "/validacion",
            exact: true,
            component: PartnerListModeration
        });    
    }

    if (!_.isEmpty(user.rol.permisos.Validacion) && user.rol.permisos.Validacion.editar) {
        routes.push({
            title: 'Validar Clienta',
            path: "/validacion/:id_diccionario/:id_socio/:id_en_sucursal",
            component: Partner
        });    
    }

    if (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.consultar) {
        routes.push({
            title: 'Grupos',
            path: "/grupos",
            exact: true,
            component: GroupList
        });
    }
    if (!_.isEmpty(user.rol.permisos.CreditosIndividuales) && user.rol.permisos.CreditosIndividuales.consultar) {
        routes.push({
            title: 'Creditos Individuales',
            path: "/creditos-individuales",
            exact: true,
            component: GroupListCreditsIndividual
        });
    }
    if (!_.isEmpty(user.rol.permisos.CreditosIndividuales) && user.rol.permisos.CreditosIndividuales.consultar) {
        routes.push({
            title: 'Credito Individuale',
            path: "/creditos-individuales/:id_grupo",
            component: Group
        });
    }
    if (user.rol.es_administrador === true) {
        routes.push({
            title: 'Creditos de empleados',
            path: "/creditos-empleados",
            exact: true,
            component: GroupListCreditsEmployee
        });
    }
    if (user.rol.es_administrador === true) {
        routes.push({
            title: 'Credito de empleados',
            path: "/creditos-empleados/:id_grupo",
            component: Group
        });
    }
    if (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.listalocal) {
        routes.push({
            title: 'Grupos',
            path: "/grupos/grupos-por-guardar",
            exact: true,
            component: GroupListLocal
        });
    }
    if (
        (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.registrar) ||
        (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.registrar) ||
        (!_.isEmpty(user.rol.permisos.CreditosIndividuales) && user.rol.permisos.CreditosIndividuales.registrar)
    ) {
        routes.push({
            title: 'Nuevo crédito',
            path: "/grupos/registro",
            exact: true,
            component: GroupRegister
        });
    }
    if (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.wizard) {
        routes.push({
            title: 'Nuevo grupo 2',
            path: "/nuevo-grupo",
            exact: true,
            component: GroupRegisterWizard
        });
    }
    if (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.wizard) {
        routes.push({
            title: 'Nuevo ciclo',
            path: "/nuevo-ciclo/:id_diccionario/:groupId",
            exact: true,
            component: GroupRegisterWizard
        });
    }
    if (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.wizard) {
        routes.push({
            title: 'Nuevo grupo 3',
            path: "/grupos/nuevo-grupo/:localGroupId",
            exact: true,
            component: GroupRegisterWizard
        });
    }
    if (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.consultar) {
        routes.push({
            title: 'Editar grupo',
            path: "/grupos/:id_diccionario/:id_grupo",
            component: Group
        });
    }
    if (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.registrar) {
        routes.push({
            title: 'Grupos',
            path: "/grupos/documentos-por-guardar",
            exact: true,
            component: GroupListLocalNewCycle
        });
    }
    if (!_.isEmpty(user.rol.permisos.Autorizaciones) && user.rol.permisos.Autorizaciones.consultar) {
        routes.push({
            title: 'Autorizaciones',
            path: "/autorizaciones",
            exact: true,
            component: GroupListAutorization
        });
        routes.push({
            title: 'Aprobar crédito',
            path: "/autorizaciones/:id_diccionario/:id_grupo/solicitud-actual/:id_solicitud",
            exact: true,
            component: GroupRequisition
        });
    }
    if (!_.isEmpty(user.rol.permisos.Autorizados) && user.rol.permisos.Autorizados.consultar) {
        routes.push({
            title: 'Autorizados',
            path: "/autorizados",
            exact: true,
            component: GroupListAuthorized
        });
        routes.push({
            title: 'Crédito autorizado',
            path: "/autorizados/:id_diccionario/:id_grupo/solicitud-actual/:id_solicitud",
            exact: true,
            component: GroupRequisition
        });
    }
    
    if (!_.isEmpty(user.rol.permisos.Verificaciones) && user.rol.permisos.Verificaciones.consultar) {
        routes.push({
            title: 'Verificaciones',
            path: "/verificaciones",
            exact: true,
            component: GroupListCheck
        });
        routes.push({
            title: 'Verificaciones',
            path: '/verificaciones/:id_solicitud',
            component: GroupListCheckRequisition
        });
    }

    if (!_.isEmpty(user.rol.permisos.Pagos) && user.rol.permisos.Pagos.consultar) {
        routes.push({
            title: 'Pagos',
            path: "/pagos",
            exact: true,
            component: GroupListPayment
        });
        routes.push({
            title: 'Pagos',
            path: "/pagos/:query",
            exact: true,
            component: GroupListPayment
        });
        routes.push({
            path: "/pagos/estado-cuenta",
            exact: true,
            component: DocumentAccountStatement
        });
        routes.push({
            path: "/pagos/edicion/:id_solicitud",
            exact: true,
            component: GroupRequisitionPayments
        });
    }
    if (!_.isEmpty(user.rol.permisos.PagosCreditosIndividuales) && user.rol.permisos.PagosCreditosIndividuales.consultar) {
        routes.push({
            title: 'Pagos Creditos Individuales',
            path: "/pagos-creditos-individuales",
            exact: true,
            component: GroupListPaymentCreditsIndividual
        });
        routes.push({
            path: "/pagos-creditos-individuales/estado-cuenta",
            exact: true,
            component: DocumentAccountStatement
        });
        routes.push({
            path: "/pagos-creditos-individuales/edicion/:id_solicitud",
            exact: true,
            component: GroupRequisitionPayments
        });
    }
    if (!_.isEmpty(user.rol.permisos.PagosCreditosEmpleados) && user.rol.permisos.PagosCreditosEmpleados.consultar) {
        routes.push({
            title: 'Pagos Creditos a Empleados',
            path: "/pagos-creditos-empleados",
            exact: true,
            component: GroupListPaymentCreditsEmployee
        });
        routes.push({
            path: "/pagos-creditos-empleados/estado-cuenta",
            exact: true,
            component: DocumentAccountStatement
        });
        routes.push({
            path: "/pagos-creditos-empleados/edicion/:id_solicitud",
            exact: true,
            component: GroupRequisitionPayments
        });
    }
    if (!_.isEmpty(user.rol.permisos.Reportes) && user.rol.permisos.Reportes.consultar) {
        routes.push({
            title: 'Reportes',
            path: "/reportes",
            exact: true,
            component: GroupListPaymentReport
        });
    }
    if (!_.isEmpty(user.rol.permisos.Reportes) && user.rol.permisos.Reportes.consultar) {
        routes.push({
            title: 'Bitácora',
            path: "/bitacora",
            exact: true,
            component: ReportDay
        });
    }
    if (!_.isEmpty(user.rol.permisos.Reportes) && user.rol.permisos.Reportes.consultar) {
        routes.push({
            title: 'Historial de Bitácoras',
            path: "/bitacora/historial",
            exact: true,
            component: ReportDayHistory
        });
    }
    if (!_.isEmpty(user.rol.permisos.Reportes) && user.rol.permisos.Reportes.consultar) {
        routes.push({
            title: 'Editar reporte de bitácora',
            path: "/bitacora/edicion/:id_bitacora",
            exact: true,
            component: ReportDayEdit
        });
    }
    if (!_.isEmpty(user.rol.permisos.Reportes) && user.rol.permisos.Reportes.consultar) {
        routes.push({
            title: 'Detalles del reporte de bitácora',
            path: "/bitacora/visualizar/:id_bitacora",
            exact: true,
            component: ReportDayView
        });
    }
    if (!_.isEmpty(user.rol.permisos.Reportes) && user.rol.permisos.Reportes.consultar) {
        routes.push({
            path: "/bitacora/historial/bitacora-reporte",
            exact: true,
            component: DocumentBitacoraReport
        });
    }

    if (!_.isEmpty(user.rol.permisos.Contabilidad) && user.rol.permisos.Contabilidad.consultar) {
        routes.push({
            title: 'Contabilidad',
            path: "/contabilidad",
            exact: true,
            component: AccountingChanges
        });
    }

    if (!_.isEmpty(user.rol.permisos.Empleados) && user.rol.permisos.Empleados.registrar) {
        routes.push({
            title: 'Nuevo empleado',
            path: "/empleados/registro",
            component: EmployeeRegister
        });
    }
    if (!_.isEmpty(user.rol.permisos.Empleados) && user.rol.permisos.Empleados.consultar) {
        routes.push({
            title: 'Empleados',
            path: "/empleados",
            exact: true,
            component: EmployeeList
        });
    }
    if (!_.isEmpty(user.rol.permisos.Empleados) && user.rol.permisos.Empleados.consultar) {
        routes.push({
            title: 'Editar empleado',
            path: "/empleados/:id_diccionario/:id_empleado",
            component: Employee
        });
    }

    if (!_.isEmpty(user.rol.permisos.Contabilidad) && user.rol.permisos.Contabilidad.consultar) {
        routes.push({
            title: 'Sucursales',
            path: "/sucursales",
            // exact: true,
            component: ListBranches
        });
    }

    // if (user.rol.es_administrador === true) {
    if (!_.isEmpty(user.rol.permisos.Contabilidad) && user.rol.permisos.Contabilidad.consultar) {
        routes.push({
            title: 'Pagos y Desembolsos',
            path: "/pagos&Desembolsos",
            // exact: true,
            component: ListDisbursmentAndPayment
        });

        routes.push({
            title: 'Listado de pólizas',
            path: "/listaPolizas",
            // exact: true,
            component: PoliciesList
        });

        routes.push({
            title: 'Detalles de póliza',
            path: '/polizaDetalles/:id_poliza/:num_poliza/:period_poliza',
            component: PolicyDetails
        })
    }

    if (user.rol.es_administrador === true) {
        routes.push({
            title: 'Configuración',
            path: "/configuracion",
            // exact: true,
            component: Configuration
        });
    }

    if (!_.isEmpty(user.rol.permisos.Contabilidad) && user.rol.permisos.Contabilidad.consultar) {
        routes.push({
            title: 'CatCuentas',
            path: "/catCuentas/registro",
            // exact: true,
            component: AccountRegister
        });

        routes.push({
            title: 'CatCuentasList',
            path: "/catCuentas/list",
            // exact: true,
            component: AccountsList
        });

        routes.push({
            title: 'Editar Cuenta',
            path: "/account/:id_account",
            component: Account
        });
    }

    routes.push({
        title: 'Sucuarsales',
        path: "/catalogo/sucursales",
        exact: true,
        component: BranchOffices
    });
    routes.push({
        title: 'Registro de cambios',
        path: "/registro-cambios",
        exact: true,
        component: ChangeRecord
    });
} catch (error) {
    console.log('No se ha recuperado datos de usuario y de rol');
}

export default routes;