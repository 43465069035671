import React, { Component } from 'react';
import textUtils from '../../utilities/format-string';
import SelectBranchOffice from './../forms/SelectBranchOffice';
import Parse from 'parse';
import _ from 'underscore';

class SearchForm extends Component {

    constructor(props) {
        super(props);

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    state = {
        searchValue: '',
        searchKey: '',
        status: '',
        lugar_registro: Parse.User.current().toJSON().Sucursal ? Parse.User.current().toJSON().Sucursal.objectId : '',
        advancedForm: false
    }

    handleToggleForms = () => {
        this.setState(state => ({
            advancedForm: !state.advancedForm
        }))
    }

    handleSearchChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    handleSearchSubmit = (event) => {
        if (event) event.preventDefault();

        let searchValue = textUtils.setPlainText(this.state.searchValue);
        searchValue = searchValue.toLowerCase();
        searchValue = searchValue.replace(' ','');

        let where = {};
        
        if (!_.isEmpty(this.state.lugar_registro)) {
            where = {
                id_busqueda: {
                    $regex: searchValue
                },
                Socio: {
                    $inQuery: {
                        where: {
                            lugar_registro: {
                                __type: 'Pointer',
                                className: 'Sucursales',
                                objectId: this.state.lugar_registro
                            },
                            status: this.state.status.length? this.state.status : undefined
                        },
                        className: 'Socios'
                    }
                },
            }
        } else {
            where = {
                id_busqueda: {
                    $regex: searchValue
                },
                Socio: {
                    $inQuery: {
                        where: {
                            status: this.state.status.length? this.state.status : undefined
                        },
                        className: 'Socios'
                    }
                },
            };
        }
        
        this.props.handleFilter(where)
    }

    handleSearchSubmitAdvenced = (event) => {
        if (event) event.preventDefault();

        let searchValue = textUtils.setPlainText(this.state.searchValue);
        searchValue = searchValue.toUpperCase();

        let where = {};

        if (!_.isEmpty(this.state.lugar_registro)) {
            where = {
                Socio: {
                    $inQuery: {
                        where: {
                            [this.state.searchKey]: {
                                $regex: searchValue
                            },
                            lugar_registro: {
                                __type: 'Pointer',
                                className: 'Sucursales',
                                objectId: this.state.lugar_registro
                            }
                        },
                        className: 'Socios'
                    }
                }
            }
        } else {
            where = {
                Socio: {
                    $inQuery: {
                        where: {
                            [this.state.searchKey]: {
                                $regex: searchValue
                            }
                        },
                        className: 'Socios'
                    }
                }
            };
        }
        
        this.props.handleFilter(where);
    }

    resetSearchForm = () => {
        this.setState({
            searchValue: '',
            searchKey: '',
            status: '',
            lugar_registro: ''
        });
        this.props.handleFilter();
    }

    render() {
        return (
            <>
                {!this.state.advancedForm && 
                <form className="row" onSubmit={this.handleSearchSubmit}>
                    <div className="col-md-12">
                        <div className="row align-items-start mb-2">
                            <div className="col-md-4 mb-2">
                                <label className="sr-only" htmlFor="search-input">BUSCAR</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </div>
                                    </div>
                                    <span className={`btn ${!this.state.searchValue.length && 'd-none'}`} onClick={() => this.resetSearchForm()} style={styles.searchInputClose}>
                                        <i className="fas fa-times"></i>
                                    </span>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="search-input" 
                                    placeholder="BUSCAR"
                                    style={styles.searchInput}
                                    onChange={this.handleSearchChange}
                                    value={this.state.searchValue}
                                    name="searchValue" />
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <label className="sr-only" htmlFor="status">Status</label>
                                <div className="input-group">
                                    <select 
                                    id="status" 
                                    className="form-control" 
                                    name="status"
                                    value={this.state.status}
                                    onChange={this.handleSearchChange}>
                                        <option value="">STATUS</option>
                                        <option value="ACTIVO">ACTIVA</option>
                                        <option value="BAJA_CASTIGADA">BAJA CASTIGADA</option>
                                        <option value="BAJA_VOLUNTARIA">BAJA VOLUNTARIA</option>
                                        <option value="BAJA">BAJA</option>
                                        <option value="FALLECIDA">FALLECIDA</option>
                                        <option value="MOROSIDAD">MOROSIDAD</option>
                                        <option value="RECHAZADO">RECHAZADA</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                {(Parse.User.current().toJSON().Sucursal && <SelectBranchOffice name="lugar_registro" placeholder="Lugar de registro" id="lugar_registro" value={Parse.User.current().toJSON().Sucursal.objectId} disabled={Parse.User.current().toJSON().Sucursal} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleSearchChange} />) ||
                                <SelectBranchOffice name="lugar_registro" placeholder="Lugar de registro" id="lugar_registro" value={this.state.lugar_registro} onChange={this.handleSearchChange} />}
                            </div>
                            <div className="col-md-4 mb-2">
                                <button type="submit" className="btn btn-primary btn-block">
                                    <span className="d-none d-md-block">Buscar</span>
                                    <i className="fas fa-search d-md-none"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                }
                {this.state.advancedForm && 
                <form className="row mb-0 mb-md-1" onSubmit={this.handleSearchSubmitAdvenced}>
                    <div className="col-md-12">
                        <div className="row mb-2">
                            <div className="col-md-4 mb-2">
                                <label className="sr-only" htmlFor="search-input">PALABRA CLAVE</label>
                                <div className="position-relative">
                                    <span className={`btn ${!this.state.searchValue.length && 'd-none'}`} onClick={() => this.resetSearchForm()} style={styles.searchInputClose}>
                                        <i className="fas fa-times"></i>
                                    </span>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="search-input" 
                                    placeholder="PALABRA CLAVE"
                                    style={styles.searchInput}
                                    onChange={this.handleSearchChange}
                                    value={this.state.searchValue}
                                    name="searchValue"
                                    required />
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <select className="form-control" name="searchKey" value={this.state.searchKey} onChange={this.handleSearchChange} required>
                                    <option value="" disabled>CAMPO DE BÚSQUEDA</option>
                                    <optgroup label="Datos personales">
                                        <option value="apellido_materno">Apellido materno</option>
                                        <option value="apellido_paterno">Apellido paterno</option>
                                        <option value="clave_elector">Clave de elector</option>
                                        <option value="curp">CURP</option>
                                        <option value="nacimiento_entidad">Entidad de nacimiento</option>
                                        {/* <option value="estado_civil">Estado civil</option> */}
                                        {/* <option value="nacimiento_fecha">Fecha de nacimiento</option> */}
                                        <option value="conyugue_lugar_trabajo">Lugar de trabajo del conyugue</option>
                                        <option value="conyugue_nombre">Nombre del conyugue</option>
                                        <option value="nombre">Nombres(s)</option>
                                        {/* <option value="numero_dependientes">Número de dependientes</option> */}
                                        <option value="numero_ocr">Número de OCR</option>
                                        <option value="ocupacion">Ocupación</option>
                                        <option value="nacimiento_pais">País de nacimiento</option>
                                        <option value="sexo">Sexo</option>
                                        <option value="telefono">Teléfono</option>
                                    </optgroup>
                                    <optgroup label="Datos del domicilio">
                                        <option value="domicilio_cp">Código postal</option>
                                        <option value="domicilio_colonia">Fraccionamiento o Colonia</option>
                                        <option value="domicilio_direccion">Dirección</option>
                                        {/* <option value="domicilio_tipo">Domicilio</option> */}
                                        <option value="domicilio_numero_servicio">Número de servicio</option>
                                        <option value="domicilio_poblacion">Población</option>
                                        <option value="domicilio_entidad">Estado</option>
                                        {/* <option value="domicilio_tiempo_habitado">Tiempo habitado en ella</option> */}
                                        <option value="domicilio_numero_servicio_tipo">Tipo servicio</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div className="col-md-4 mb-2">
                                <label className="sr-only" htmlFor="status">Status</label>
                                <div className="input-group">
                                    <select 
                                    id="status" 
                                    className="form-control" 
                                    name="status"
                                    onChange={this.handleSearchChange}>
                                        <option value="">STATUS</option>
                                        <option value="ACTIVO">ACTIVA</option>
                                        <option value="BAJA_CASTIGADA">BAJA CASTIGADA</option>
                                        <option value="BAJA_VOLUNTARIA">BAJA VOLUNTARIA</option>
                                        <option value="BAJA">BAJA</option>
                                        <option value="FALLECIDA">FALLECIDA</option>
                                        <option value="MOROSIDAD">MOROSIDAD</option>
                                        <option value="RECHAZADO">RECHAZADA</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                            {Parse.User.current().toJSON().Sucursal && <SelectBranchOffice name="lugar_registro" placeholder="Lugar de registro" id="lugar_registro" value={Parse.User.current().toJSON().Sucursal.objectId} disabled={Parse.User.current().toJSON().Sucursal} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleSearchChange} /> ||
                                <SelectBranchOffice name="lugar_registro" placeholder="Lugar de registro" id="lugar_registro" value={this.state.lugar_registro} onChange={this.handleSearchChange} />}
                            </div>
                            <div className="col-md-4 mb-2">
                                <button type="submit" className="btn btn-primary btn-block">
                                    <span className="d-none d-md-block">Buscar</span>
                                    <i className="fas fa-search d-md-none"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>    
                }
                <div className="row">
                    <div className={this.state.advancedForm?'col-md-12':'col-md-12'}>
                        <div className="row justify-content-end">
                            {!this.state.advancedForm && <div className="col-7">
                                <p className="text-muted mt-1 mb-0"><small>Filtra nombres, apellidos, INE y OCR</small></p>
                            </div>}
                            
                            <div className={`text-right ${this.state.advancedForm?'col-6':'col-5'}`}>
                                <button type="button" className="btn btn-link btn-sm text-right" onClick={() => this.handleToggleForms()}>
                                    {this.state.advancedForm? 'Cerrar búsqueda avanzada':'Búsqueda avanzada' }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        );
    }
}

export default SearchForm;

const styles = {
    searchInputClose: {
        position: 'absolute',
        right: 0,
        zIndex: 4,
    },
    searchInput: {
        paddingRight: 36
    }
}