import React, { Component } from 'react';
import PartnerFormDataPersonal from '../../components/partners/PartnerFormDataPersonal';
import _ from 'underscore';
import config from './../../settings/config';
import Spinner from './../others/Spinner';
import Rest from './../../Server/Rest';
import textUtils from './../../utilities/format-string';
import Parse from 'parse';
import user from './../../settings/user';
import service_catalogs from './../../services/catalogs';

class PartnerEditDataPersonal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resetform: false,
            loading: false,
            readOnly: true
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }

    data = {};
    data_original = {};
    socio_objectId = '';
    id_diccionario = '';

    setData = (data, file, nota = '') => {
        this.data = data;

        if (!_.isEmpty(file)) {
            this.file = file;
            this.notaBuro = nota;
        }
    }

    // consultar datos del socio
    UNSAFE_componentWillMount = async () => {
        this.handleSpinner();

        const params = new URLSearchParams({
            keys: 'en_moderacion,Socio.domicilio_numero_servicio,Socio.domicilio_numero_servicio_tipo,Socio.id_en_sucursal,Socio.fecha_registro,Socio.lugar_registro,Socio.status,Socio.status_comentario,Socio.apellido_paterno,Socio.apellido_materno,Socio.nombre,Socio.sexo,Socio.clave_elector,Socio.numero_ocr,Socio.nacimiento_pais,Socio.nacimiento_entidad,Socio.nacimiento_fecha,Socio.curp,Socio.estado_civil,Socio.numero_dependientes,Socio.ocupacion,Socio.telefono,Socio.conyugue_nombre,Socio.conyugue_lugar_trabajo,Socio.domicilio_direccion,Socio.domicilio_colonia,Socio.domicilio_cp,Socio.domicilio_poblacion,Socio.domicilio_entidad,Socio.domicilio_tiempo_habitado,Socio.domicilio_tipo,Socio.garantia_prendaria_1_nombre,Socio.garantia_prendaria_1_marca,Socio.garantia_prendaria_1_estado,Socio.garantia_prendaria_2_nombre,Socio.garantia_prendaria_2_marca,Socio.garantia_prendaria_2_estado,Socio.garantia_prendaria_3_nombre,Socio.garantia_prendaria_3_marca,Socio.garantia_prendaria_3_estado,Socio.referencia_1_nombre_completo,Socio.referencia_1_direccion,Socio.referencia_1_telefono,Socio.referencia_2_nombre_completo,Socio.referencia_2_direccion,Socio.referencia_2_telefono,Socio.BranchOffice,Socio.registeredBy',
            excludeKeys: 'Socio.Buro,Socio.registeredBy',
            include: 'Socios'
        });
        
        Rest.peticion(`${config.api_url}/classes/SociosDiccionario/${this.props.match.params.id_diccionario}?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            this.handleSpinner();
            return res.json();
        })
        .then(res => {
            if (res.code) {
                alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
            } else {

                if (res.en_moderacion === true && !(user.rol.permisos.Validacion && user.rol.permisos.Validacion.editar === true)) {
                    alert('No tienes accesos a estos datos.');
                    window.location = '/escritorio';
                    return false;
                }
                
                this.data_original = _.clone(res.Socio);
                this.data = _.clone(res.Socio);
                this.data.fecha_registro = res.Socio.fecha_registro.iso
                this.data.lugar_registro = res.Socio.lugar_registro && res.Socio.lugar_registro.objectId || '';
                this.socio_objectId = res.Socio.objectId;
                this.id_diccionario = res.objectId;
                this.setState({
                    resetform: true
                });
            }
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
        });
    }

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    handleChangeEdit = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        
        this.setState((state) => ({
            [name]: !state[name]
        }));
    }

    handleChangeBtnSubmit = () => {
        let btn_submit = document.getElementsByClassName('btns-form-submit');

        btn_submit[0].disabled = !btn_submit[0].disabled;

        if (btn_submit[0].disabled === true) {
            btn_submit[0].innerHTML = 'Guardando...';
        } else {
            btn_submit[0].innerHTML = 'Guardar';
        }
    }

    validCURP = textUtils.validCURP;

    saveSuburb = service_catalogs.saveSuburb;

    saveRelationHistorial = async (id_busqueda, historial) => {

        let data = {
            id_busqueda: id_busqueda
        };

        if (historial) {
            data.historial = {
                __op: 'AddRelation',
                objects: [{
                    __type: 'Pointer',
                    className: 'SociosHistorial',
                    objectId: historial.objectId
                }]
            }
        }
        
        return Rest.peticion(`${config.api_url}/classes/SociosDiccionario/${this.id_diccionario}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
    }

    saveData = async (data) => {
        return Rest.peticion(`${config.api_url}/classes/Socios/${this.socio_objectId}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        }).then(res => {return res.json()}).then(res => {return res}).catch(error => {return error});
    }

    setValueSucursal = (pointer) => {
        const items = document.getElementById('lugar-registro');
        let text = '';
        for (let index = 0; index < items.length; index++) {
            if (items[index].value === pointer.objectId) {
                text = items[index].text;
            }
        }
        return text;
    }
    
    saveSocioHistorial = async (_data) => {
        
        
        if (!_.isEqual(this.data_original, _data)) {
            
            let data = {};
            _.each(this.data_original, (value, key) => {
                if(!_.isEqual(this.data_original[key], _data[key])) {
                    if (!_.isEmpty(value.__type) && value.className === 'Sucursales') {
                        data[key] = this.setValueSucursal(value);
                    } else {
                        data[key] = value;
                    }
                };
            });
            _.each(_data, (value, key) => {
                if (this.data_original[key] === undefined) {
                    data[key] = 'SIN DATO PREVIO';
                }
            });

            const currentUser = Parse.User.current().toJSON();
            const body = {
                datos_anteriores: data,
                afectado_por: currentUser.nombre+' '+currentUser.apellidos,
                usuario_email: currentUser.email,
                usuario_telefono: currentUser.telefono,
                grupo_datos: 'datos-personales',
                Rol: {
                    __type: 'Pointer',
                    className: 'Roles',
                    objectId: user.rol.objectId
                },
                User: {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: currentUser.objectId
                },
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: this.socio_objectId
                }
            }
            return Rest.peticion(`${config.api_url}/classes/SociosHistorial`, {
                method: 'POST',
                body: JSON.stringify(body)
            }).then(res => {return res.json()}).then(res => {return res}).catch(error => {return error});
        } else {
            return false;
        }
    }

    async handleSubmit (event) {

        if (event) event.preventDefault();

        const r = window.confirm('¿Todos los datos son correctos?');

        if (r === true) {
        
            let data = this.data;
            
            // formatear datos
            _.map(data, (value, key) => {
                let newValue;
                
                    if (!_.isEmpty(value) && _.isString(value)) {
                        switch (key) {
                            case 'lugar_registro':
                                newValue = {
                                    __type: 'Pointer',
                                    className: 'Sucursales',
                                    objectId: value
                                };
                                break;
                            default:
                                newValue = value.toUpperCase().trim();
                                break;
                        }
                    } else {
                        newValue = value;
                    }

                    data[key] = newValue;
            });

            if (!_.isEmpty(data.curp) && !this.validCURP(data.curp)) {

                alert('La CURP no parece cumplir con el formato válido.');
                return;
            }

            const newData       = _.omit(data, 'className','registeredBy','__type','id_en_sucursal','fecha_registro','Buro','objectId','createdAt','updatedAt');
            this.data_original  =  _.omit(this.data_original, 'className','registeredBy','__type','id_en_sucursal','fecha_registro','Buro','objectId','createdAt','updatedAt');
            
            let id_busqueda =   textUtils.setPlainText(this.data.nombre+this.data.apellido_paterno+this.data.apellido_materno).toLowerCase() +
                                this.data.curp +
                                this.data.numero_ocr;

                id_busqueda = id_busqueda.toLowerCase();
            
            this.handleChangeBtnSubmit();
            this.handleSpinner();
            
            const acualizacion = await this.saveData(newData);

            const historial = await this.saveSocioHistorial(newData);

            await this.saveSuburb(newData.domicilio_colonia, newData.domicilio_cp);

            await this.saveRelationHistorial(id_busqueda, historial);

            if (acualizacion.code || acualizacion.error) {
                alert('Ha ocurrido un error al intentar actualizar los datos. Intente más tarde o contacte a soporte.');
            } else {
                this.data_original = _.clone(newData);
                alert('Los datos han sido actualizados.');
            }

            this.handleSpinner();
            this.handleChangeBtnSubmit();
        }
        
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />

                {(user.rol.permisos.Socios && user.rol.permisos.Socios.editar === true) &&
                <div className="row">
                    <div className="col-8">
                        {!this.state.readOnly && <p className="text-info">
                            Todos los campos son obligatorios.
                        </p>}
                    </div>
                    <div className="text-right col-4 mb-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitch1" value={!this.state.readOnly} defaultChecked={!this.state.readOnly} name="readOnly" onChange={this.handleChangeEdit} />
                            <label className="custom-control-label text-primary" htmlFor="customSwitch1">Editar</label>
                        </div>
                    </div>
                </div>}
                
                <form onSubmit={this.handleSubmit}>

                    <PartnerFormDataPersonal readOnly={this.state.readOnly} required={true} resetform={this.state.resetform} data={this.data} data_original={this.data_original} setData={this.setData} partnerId={this.props.match.params.id_socio} />
                    
                    {!this.state.readOnly && <button type="submit" className="btn btn-primary btn-lg mr-2 btns-form-submit" data-loading-text="Loading...">
                        Guardar
                    </button>}
                    
                
                </form>    
            </>
        );
    }
}

export default PartnerEditDataPersonal;