import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import SelectBranchOffice from '../forms/SelectBranchOffice';
import SelectEmpleyee from '../forms/SelectEmpleyee';
import GroupListPaymentFilterReport from '../groups/GroupListPaymentFilterReport';
import Parse from 'parse';


class AccountingChangesFilterReport extends GroupListPaymentFilterReport {

    state = {
        nombre: this.props.state.nombre,
        dateStart: this.props.state.dateStart,
        dateEnd: this.props.state.dateEnd,
        fecha_real_pago: this.props.state.fecha_real_pago,
        Sucursal: this.props.state.Sucursal,
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState(state => ({
            nombre: nextProps.state.nombre,
            dateStart: nextProps.state.dateStart,
            dateEnd: nextProps.state.dateEnd,
            fecha_real_pago: nextProps.state.fecha_real_pago,
            Sucursal: nextProps.state.Sucursal,
        }))
    }

    render() {
        return (
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">
                            <i className="fas fa-filter mr-2"></i>
                            Filtro
                        </h5>
                        <form onSubmit={this.handleFilter} className="row align-items-start">
                            {(this.props.inputGroupEnabled !== true) && <div className="col-md-4 form-group">
                                <label htmlFor="nombre">Grupo</label>
                                <input type="text" className="form-control" id="nombre" name="nombre" value={this.state.nombre} onChange={this.handleChange} placeholder="Nombre del grupo" />
                            </div>}
                            <div className="col-md-4 form-group d-flex flex-column">
                                <label htmlFor="fecha">
                                    Fecha
                                </label>
                                <div className="input-group">
                                    <div className="d-flex">
                                        <DatePicker
                                        className="form-control mr-1"
                                        locale="es"
                                        selected={this.state.dateStart}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={date => this.handleChangeDate(date, 'dateStart')}
                                        selectsStart
                                        startDate={this.state.dateStart}
                                        endDate={this.state.dateEnd}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        minDate={this.filter_from}
                                        placeholderText="Inicio" />
                                        <DatePicker
                                        className="form-control ml-1"
                                        locale="es"
                                        selected={this.state.dateEnd}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={date => this.handleChangeDate(date, 'dateEnd')}
                                        selectsEnd
                                        startDate={this.state.dateStart}
                                        endDate={this.state.dateEnd}
                                        minDate={this.state.dateStart || this.filter_from}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        placeholderText="Final" />
                                    </div>
                                    <div className="custom-control custom-switch mt-3">
                                        <input className="custom-control-input" type="checkbox" id="flexSwitchCheckDefault" name="fecha_real_pago" value={this.state.fecha_real_pago} onChange={this.handleChange}/>
                                        <label className="custom-control-label" htmlFor="flexSwitchCheckDefault">Fecha real de pago</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 form-group">
                                <label htmlFor="Sucursal">Sucursal</label>
                                {Parse.User.current().toJSON().Sucursal && 
                                    <SelectBranchOffice id="Sucursal" name="Sucursal" value={this.state.Sucursal} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleChange} disabled /> || 
                                    <SelectBranchOffice id="Sucursal" name="Sucursal" value={this.state.Sucursal} onChange={this.handleChange} onlyAccountedFor={true}/>
                                }
                            </div>
                            <div className="col-md-4 mt-4 pt-2 form-group">
                                <button type="submit" className="btn btn-primary btn-block">
                                    Aplicar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
        );
    }
}

export default AccountingChangesFilterReport;