import React, { Component } from 'react';
import Spinner from './../others/Spinner';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import { Link } from "react-router-dom";
import _ from 'underscore';
import formatString from '../../utilities/format-string';

class GroupHistory extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: false,
            collection: []
        }
    }

    UNSAFE_componentWillMount = () => {
        this.getHistory();
    }

    getHistory = async () => {

        this.setState({
            loading: true
        })

        const params = new URLSearchParams({
            where: JSON.stringify({
                status: {
                    $ne: 'autorizacion'
                },
                Grupo: {
                    __type: 'Pointer',
                    className: 'Grupos',
                    objectId: this.props.match.params.id_grupo
                }
            }),
            order: '-createdAt',
            include: 'Grupo,montos'
        });

        const res = await Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
            method: 'GET'
        }).then(res => {return res.json()}).then(res => {return res;}).catch(error => {return error});
        
        if (_.isEmpty(res.code) && _.isEmpty(res.error)) {
            
            this.setState({
                collection: res.results
            })
            
        } else {
            
            alert('No se ha podido obtener el historial');
        
        }

        this.setState({
            loading: false
        })
        
    }

    setAmountTotal = (amount_name, montos) => {
        let sum = 0;
        _.each(montos, (item) => {
            if (item[amount_name] === '') {
                sum += 0;
            } else {
                sum += parseInt(item[amount_name]);
            }
        });
        
        return new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(sum);

    }

    setUrl = (id_solicitud) => {
        return <Link to={`${this.props.match.url}/${id_solicitud}`} title="Ver" className="btn btn-link btn-lg">
            <i className="fas fa-chevron-right"></i>
        </Link>
    }

    setStatus = (status) => {
        let text = '';
        switch (status) {
            case 'autorizacion':
                text = 'Autorización';
                break;
            case 'autorizado':
                text = 'Autorizado';
                break;
            case 'rechazado':
                text = 'Rechazado';
                break;
            case 'baja':
                text = 'Baja';
                break;
            case 'baja_voluntaria':
                text = 'Baja voluntaria';
                break;
            case 'reestructurado':
                text = 'Reestructurado';
                break;
            case 'morosidad':
                text = 'Morosidad';
                break;
            default:
                text = status;
                break;
        }
        return text;
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre del grupo</th>
                            <th scope="col">ID de solicitud</th>
                            <th className="text-center" scope="col">Ciclo</th>
                            <th scope="col">Monto total aprobado</th>
                            <th scope="col">Status</th>
                            <th scope="col">Fecha de registro</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (this.state.collection.length > 0 && this.state.collection.map((item, index) => <tr key={index} id={`solicitud-${item.objectId}`}>
                                <td style={{whiteSpace:'nowrap'}}>{item.Grupo.nombre}</td>
                                <td>{item.id_en_sucursal}</td>
                                <td className="text-center">{item.ciclo}</td>
                                <td>{this.setAmountTotal('monto_autorizado', item.montos)}</td>
                                <td className="text-uppercase">{this.setStatus(item.status)}</td>
                                <td>{formatString.setLegibleDate(item.createdAt)}</td>
                                <td className="p-1 text-right">
                                    {this.setUrl(item.objectId)}
                                </td>
                            </tr>))
                        }
                    </tbody>
                </table>
            </>
        );
    }
}

export default GroupHistory;